import {paymentReceiptTypes } from "./constants";

export const getPaymentReceiptRequest = () => ({
  type:paymentReceiptTypes.PAYMENT_RECEIPT_REQUEST 
});
export const getPaymentReceiptSuccess = (payload) => ({
  type: paymentReceiptTypes.PAYMENT_RECEIPT_SUCCESS,
  payload,
});
export const getPaymentReceiptError = (payload) => ({
  type: paymentReceiptTypes.PAYMENT_RECEIPT_ERROR,
  payload,
});