import React from "react";
import {
  Wrap,
  CardsWrap,
  StyledCard,
  Description,
  Wrapper,
  Dots,
} from "./styles";
import { WhiteBg } from "../styles";
import { StatsData } from "../utils";
import GlobalContainer from "share/atoms/GlobalContainer";

export default function Stats() {
  return (
    <>
      <Wrap>
        <div>
          <h4>
            <span className="cnaps">CNAPS</span>
            <span className="legacy">Legacy</span>
          </h4>
          <Description>
            CNAPS has a long history of providing a quality Olympiad preparation
            platform. Armed with data from millions of assessments, CNAPS stand
            out as the most taken Olympiad preparatory exams. The numbers speak
            for themselves.
          </Description>
        </div>
        <Wrapper
          className="wrapper"
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <Dots />
          <CardsWrap className="carddiv">
            {StatsData.map((stat) => (
              <Card data={stat} />
            ))}
          </CardsWrap>
        </Wrapper>
      </Wrap>
    </>
  );
}

function Card({ data }) {
  return (
    <React.Fragment>
      <StyledCard>
        <img src={data.icon} alt="icon" />
        <h2>{data.count}</h2>
        <p>{data.desc}</p>
      </StyledCard>
    </React.Fragment>
  );
}
