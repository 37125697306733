import styled from "styled-components";
import Slider from "react-slick";

export const Title = styled.div`
  width: 570px;
  font-size: 36px;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.common.white};
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 24px;
    text-align: center;
    word-wrap: break-word;
    padding: 0 20px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    font-size: 32px;
  }
`;

export const SubTitle = styled.h3`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border-radius: 30px;
  background-color: #fff2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 480px) {
    text-align: center !important;
  }
`;

export const Desc = styled.h3`
  width: 500px;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 18px;
  line-height: 1.78;
  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
    word-wrap: break-word;
    padding: 0 16px;
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SlideContent = styled.div`
  margin-top: 24px;

  @media screen and (max-width: 480px) {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StudentIcon = styled.div``;

export const SliderContainer = styled.div`
  display: flex !important;
  overflow: visible !important;
  width: 100%;

  @media screen and (max-width: 480px) {
    .image-block {
      display: none;
    }
  }
`;

export const CustomSlider = styled(Slider)`
  .slick-dots li.slick-active button:before {
    color: #254082;
  }

  .slick-dots li button:before {
    color: #254082;
    font-size: 10px;
  }

  .slick-dots {
    bottom: 0px;
    @media screen and (max-width: 600px) {
      bottom: -15px;
    }
    @media screen and (min-width: 1441px) {
      right: 12px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      bottom: -6px;
    }
    @media (min-width: 1024px) and (max-width: 1440px) {
      bottom: 50px;
    }

  }

  .slick-list {
    overflow: visible;
  }

  .slick-slide img {
    width: 100%;
  }
`;
