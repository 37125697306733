import React, { useState, useEffect } from "react";
import lottie from "lottie-web";
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  ArrowSection,
  ContentSection,
  Title,
  AnimationBlock,
  Content,
  AnalyticsLogo,
  Flex,
  Container,
  ResponsiveText,
} from "./styles";
import ArrowIcon from "assets/hero/arrow.svg";
import { detatils } from "./helpers";
import analyticsLogo from "share/atoms/lottieflies/analytics.lottiflies.json";
import ScrollDown from "share/atoms/scrolldown";
import { useResize } from "share/hooks/useResize";
import * as modes from "utils/modes";

function HeroScreen(props) {
  const [counter, setCounter] = useState(0);
  const { width } = useResize();
  useEffect(() => {
    setInterval(() => {
      setCounter((prev) => (prev + 1) % 3);
    }, 5000);
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#analytics"),
      animationData: analyticsLogo,
      loop: true,
    });
  }, []);
  return (
    <Container>
      <Wrapper>
        <LeftContainer>
          <ArrowSection>
            <img src={ArrowIcon} alt="" className="arrow-hero" />
          </ArrowSection>
          <ContentSection>
            <Flex
              $justify="flex-start"
              $direction={width <= modes.RESPONSIVE_MODE ? "column" : "row"}
            >
              <span>CNAPS</span>
              <AnimationBlock>
                <Title>{detatils[counter].title}</Title>
              </AnimationBlock>
            </Flex>
            <ResponsiveText>
              Council For Nurturing Academic Potential in Students
            </ResponsiveText>
            <AnimationBlock style={{ height: "100px" }}>
              <Content>{detatils[counter].content}</Content>
            </AnimationBlock>
          </ContentSection>
        </LeftContainer>
        <RightContainer>
          <AnalyticsLogo id="analytics" style={{ textAlign: "center" }} />
        </RightContainer>
        <>
          <ScrollDown onScoll={props.scroll} />
        </>
      </Wrapper>
    </Container>
  );
}

export default HeroScreen;
