import React from "react";
import SingleSelect from "share/atoms/Dropdown/single-select";
import { FaChevronDown } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubmitButton, SubmitWrapper,SpacingWrapper } from "./styles";
import { Controller, useForm } from "react-hook-form";
import useSelectInputChange from "share/hooks/useSelectInputChange";
import MonitorMultiSelect from "share/atoms/monitor-multiselect";
import { useDispatch } from "react-redux";
import { setDeliveryFilters } from "./actions/list-deliveries-action";

const Form = ({setShowFiltersForm}) => {
  const {  watch, control } = useForm();
  const dispatch = useDispatch();
  
  const handleSubmit=(event)=>{
    event.preventDefault();
    const values= watch();
    dispatch(setDeliveryFilters(values));
    setShowFiltersForm(false)
  }
  const { formsList, handleMonitorChange } = useSelectInputChange({
    status: "",
    "sub-batch":""
  });
  const stringifyData = localStorage.getItem("metadata");
  const metadata = stringifyData ? JSON.parse(stringifyData) : [];
  
  const classData=metadata.class_list.map((item)=>{
      return({
        label:item.class_name,
        value:item.class_name
      })
  })

  const statusData=metadata.delivery_status.map((item)=>({label:item["status_id"],value:item["status_name"]}))
  const subBatchData = metadata.subbatch_list.map(item=>({label:item["phase_name"],value:item["phase_id"]}))

  return (
    <>
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} sm={6} md={6} lg={3}>
          <Controller
            control={control}
            name="class_id"
            render={({ field: { onChange,value} }) => ( 
              <SingleSelect
                data={classData}
                value={value}
                onChange={onChange}
                optionClassName={"antd-multi-select-option"}
                placeholder={"Class"}
                suffixIcon={<FaChevronDown />}
              />
            )}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <Controller
            control={control}
            name="status_id"
            render={({ field: { onChange } }) => (
              <SpacingWrapper status>
              <MonitorMultiSelect
                value={formsList[0].status}
                data={statusData}
                showArrow={true}
                optionClassName={"antd-multi-select-option"}
                onChange={(e, data, key) => {
                  handleMonitorChange(e, data, "status");
                  onChange(formsList[0].status.filter(frmlst=>frmlst!=="all"))
                }}
                placeholder={"Status"}
                suffixIcon={<FaChevronDown />}
              />
              </SpacingWrapper>
            )}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
        <Controller
            control={control}
            name="sub_batch_id"
            render={({ field: { onChange} }) => (
              <SpacingWrapper subBatch>
              <MonitorMultiSelect
                value={formsList[0]["sub-batch"]}
                data={subBatchData}
                showArrow={true}
                optionClassName={"antd-multi-select-option"}
                onChange={(e, data, key) => {
                  handleMonitorChange(e, data, "sub-batch");
                  onChange(formsList[0]["sub-batch"].filter(frmlst=>frmlst!=="all"))
                }}
                placeholder={"Sub-Batch"}
                suffixIcon={<FaChevronDown />}
              />
              </SpacingWrapper>
            )}
          />
        </Col>
        <Col lg={3} >
          <SubmitWrapper>
            <SubmitButton
              type="submit"
            >
              Submit
            </SubmitButton>
          </SubmitWrapper>
        </Col>
      </Row>
      </form>
    </>
  );
};

export default Form;
