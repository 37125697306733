import DownloadIcon from "assets/dashboard/download.svg";
import { formatDate } from "utils/utility/date";
import { DownLoadLink } from "./styles";
import { saveAs } from "file-saver";
import notify from "utils/notifications";

export const ExamNames = {
  ISMO: "Mathematical Olympiad Exam Registrations",
  ISSO: "Science Olympiad Exam Registrations",
  ISKO: "Knowledge Olympiad Exam Registrations",
  ISEO: "English Olympiad Exam Registrations",
  ISCO: "Computer/Cyber Olympiad Exam Registrations",
  ROM:"Regional Olympiad Exam Registrations",
  NTEX:"National Talent Exam Registrations",
  JOSA:"Science and Astro Olympiad Exam Registrations "
};

export const createSubjectColumns = [
  {
    title: "Class",
    dataIndex: "class",
    key: "class",
    width: 100,
    fixed: "left",
  },
  {
    title: "All Exams Registered",
    dataIndex: "count",
    key: "count",
    width: 100,
  },
];

export const TotalPaymentMadeColumn = [
  {
    title: "Date of Transaction",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 100,
    fixed: "left",
  },
  {
    title: "Transcation Amount",
    dataIndex: "amount",
    key: "amount",
    width: 100,
  },
  {
    title: "Download Receipt",
    dataIndex: "download",
    key: "download",
    width: 100,
  },
];
/**
 * this function takes exam_list array and coverts into required object structure 
 * @param {Array} exams_list
 * @returns {Object} returns required object structure
 */

export const examsListModified = (exams_list) => {
  return exams_list.map((exam) => {
    let newObj = {};
    exam.exams.forEach((data) => {
      switch (data.exam_name) {
        case "ISKO":
          return (newObj["ISKO_COUNT"] = data.count);
        case "ISMO":
          return (newObj["ISMO_COUNT"] = data.count);
        case "ISSO":
          return (newObj["ISSO_COUNT"] = data.count);
        case "ISEO":
          return (newObj["ISEO_COUNT"] = data.count);
        case "ISCO":
          return (newObj["ISCO_COUNT"] = data.count);
          case "NTEX":
            return (newObj["NTEX_COUNT"] = data.count);
            case 'ROM':
              return (newObj["ROM_COUNT"] = data.count);
              case "JOSA":
                return (newObj["JOSA_COUNT"] = data.count);
        default:
          return {};
      }
    });
    return {
      class_id: exam.class_id,
      class: exam.class,
      ISKO_COUNT: 0,
      ISMO_COUNT: 0,
      ISSO_COUNT: 0,
      ISEO_COUNT: 0,
      ISCO_COUNT: 0,
      NTEX_COUNT:0,
      ROM_COUNT:0,
      JOSA_COUNT:0,
      ...newObj,
    };
  });
};

export const createTotalRegistrationColumns = [
  {
    title: "Class",
    dataIndex: "class",
    key: "class",
    width: 140,
    fixed: "left",
  },
  {
    title: "Exams",
    children: [
      {
        title: "ISMO",
        dataIndex: "ISMO_COUNT",
        key: "ISMO_COUNT",
        width: 120,
      },
      {
        title: "ISKO",
        dataIndex: "ISKO_COUNT",
        key: "ISKO_COUNT",
        width: 120,
      },
      {
        title: "ISSO",
        dataIndex: "ISSO_COUNT",
        key: "ISSO_COUNT",
        width: 120,
      },

      {
        title: "ISEO",
        dataIndex: "ISEO_COUNT",
        key: "ISEO_COUNT",
        width: 120,
      },
      {
        title: "ISCO",
        dataIndex: "ISCO_COUNT",
        key: "ISCO_COUNT",
        width: 120,
      },
      {
        title: "NTEX",
        dataIndex: "NTEX_COUNT",
        key: "NTEX_COUNT",
        width: 120,
      },
      {
        title: "ROM",
        dataIndex: "ROM_COUNT",
        key: "ROM_COUNT",
        width: 120,
      },
      {
        title: "JOSA",
        dataIndex: "JOSA_COUNT",
        key: "JOSA_COUNT",
        width: 120,
      },
    ],
  },
];

const downloadPdf = (url) => {
  if (url) {
    let arr = url.split("/");
    const fileName = arr.length ? arr[arr.length - 1] : "";
    saveAs(url, fileName);
  } else {
    notify("info", "Payment receipt not yet generated!!");
  }
};



/**
 * this function takes data and converted according to required format
 * @param {Array} dataArr
 * @returns {Array} returns required array format
 */


export const totalPaymentModifiedData = (dataArr) => {
  return dataArr.map((data) => ({
    ...data,
    createdAt: formatDate(data.created_at, "DD-MM-YYYY"),
    download: (
      <DownLoadLink onClick={() => downloadPdf(data.payment_receipt_url)}>
        <img src={DownloadIcon} alt="downloadicon" />
        Download
      </DownLoadLink>
    ),
  }));
};


/**
 * this function takes data and converted according to required antd table properties
 * @param {string} key
 * @param {Object} data
 * @param {Object} examData
 * @returns {Object} returns object with required antd tables  properties 
 */


export const getDashboardModifiedData = (key, data, examData) => {
  switch (key) {
    case "total-registrations":
      return ({
        columns: createTotalRegistrationColumns,
        title: "Total Registrations",
        dataArr: examsListModified(data["total-registrations"]["exams_list"])
      })
    case "total-payments":
      return ({
        columns: TotalPaymentMadeColumn,
        title: "Total Payment Made",
        dataArr: totalPaymentModifiedData(data["total_transactions"])
      })
    case "total-subjects":
      return ({
        columns: createSubjectColumns,
        title: ExamNames[examData["exam_name"]],
        dataArr: examData.counts
      })
    default:
      break;
  }
}

