import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';

const Button = props => (
  <StyledButton {...props}>{props.children}</StyledButton>
);

Button.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.any,
  bgcolor: PropTypes.string,
  fontFamily: PropTypes.string,
};
Button.defaultProps = {
  height: '',
  width: '',
  bgColor: '#d71a63',
  fontFamily: 'Poppins',
};
export default Button;
