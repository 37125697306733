import Button from "share/atoms/buttons";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 16px;
  padding: 24px;
  .password {
    padding-bottom: 32px;
    input {
      height: 48px;
      gap: 10px;
      padding: 16px 12px;
      border-radius: 8px;
      border: solid 1px #949497;
      background-color: #fff;
    }
    .ant-input:placeholder-shown {
      opacity: 0.5;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #242424;
    }
    .icon {
      top: 12px;
    }
  }
`;
export const PasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .label-password,
  .label-confirm-password {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #464646;
    margin-right: 200px;
    margin-bottom: 4px;
  }
  .label-password {
    margin-right: 230px;
  }
`;

export const Hint = styled.p`
  width: 320px;
  display: flex;
  span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #565656;
  }
`;
export const StyledButton = styled(Button)`
  width: 328px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 32px;
  border-radius: 24px;
  font-family: Poppins;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`;
