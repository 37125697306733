import styled from "styled-components";

export const Wrap = styled.div`
  background-color: #f9f9ff;
`;
export const Wrapper = styled.div`
  font-family: Poppins;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 24px;
  border-radius: 6px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  h1 {
    font-family: Poppins;
    color: #242424;
    text-align: center;
    margin: 10px 0px 20px 0px;
    font-size: 36px;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
  }
`;
