
import { Spinner } from 'react-bootstrap'
import { Wrapper } from './styles'
const Overlay = () => {
  return (
    <Wrapper>
      <Spinner animation="border" role="status" size="lg" variant="dark">
      </Spinner>
    </Wrapper>
  )
}

export default Overlay
