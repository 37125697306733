import React from "react";
import ResearchIcon from "assets/research.svg";
import {
  WrapButton,
  Wrapper,
  ResearchWrapper,
  ImageWrapper,
  StyledButton,
} from "./styles";
import { useHistory } from "react-router-dom";
import { SchoolContainer } from "../styles";
// import { Container } from "./styles";
import GlobalContainer from "share/atoms/GlobalContainer";

const Research = () => {
  const history = useHistory();
  return (
    <GlobalContainer style={{ position: "relative", zIndex: "1" }}>
      {/* <SchoolContainer> */}
      <Wrapper>
        <ResearchWrapper>
          <div className="header">
            <span style={{ color: "#d71a63" }}>CNAPS</span> - Research based
            Olympiads
          </div>

          <p className="description">
            CNAPS groundwork and research made it possible to compare and
            contrast the best practices from various International Olympiads to
            distill the CNAPS's olympiads to better suit academic environments
            in India.
          </p>

          <WrapButton>
            <StyledButton className="button" onClick={() => history.push("/")}>
              Go to cnaps.co.in
            </StyledButton>
          </WrapButton>
        </ResearchWrapper>
        <ImageWrapper>
          <img src={ResearchIcon} alt="research-icon" />
        </ImageWrapper>
      </Wrapper>
      {/* </SchoolContainer> */}
    </GlobalContainer>
  );
};

export default Research;
