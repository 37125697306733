import React from 'react';
import PropTypes from 'prop-types';
import {Wrapper} from './styles'

export default function List(props) {
  const { children, items, fullHeightConfig} = props;
  return <Wrapper  fullHeightConfig={fullHeightConfig}>{items.map(children)}</Wrapper> 
}

List.propTypes = {
  children: PropTypes.any,
  items: PropTypes.array,
  fullHeightConfig:PropTypes.bool
};
