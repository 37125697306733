import styled from "styled-components";
import Button from "share/atoms/Button";
import { InputLabel } from "share/atoms/form-fields";

export const LogoWrap = styled.div`
  position: relative;
  cursor: pointer;
  img {
    height: 48px;
  }
  h3 {
    margin-left: 32px;
    margin-top: -8px;
    background-image: linear-gradient(to top, #27397d, #0597c9);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const H3 = styled.h3`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-bottom: 32px;
`;

export const StyledLabel = styled(InputLabel)`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;

export const SubmitButton = styled(Button)`
      margin-top:30px;
  opacity: ${({ opacity }) => (opacity ? 0.3 : 1)};
  cursor: ${({ opacity }) => (opacity ? "not-allowed" : "pointer")};
`;

export const Error= styled.div`
 font-size:12px;
    color:${({theme})=>theme.palette.primary.main};
    max-width:320px;

`