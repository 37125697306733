import styled from "styled-components";
export const Wrapper = styled.div`
  .heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
  }
  .horizontal {
    background-color: #d1d1d8;
  }
`;

export const FormFieldsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .label {
    width: 336px;
    height: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #464646;
    text-transform: capitalize;
  }
  .input {
    height: 48px;
    width: 336px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 16px 12px;
    border-radius: 8px;
    border: solid 1px #949497;
    background-color: #ededed;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
    margin-top: 4px;
    margin-bottom: 16px;
  }
`;
