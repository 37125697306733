import { Table,Popover } from "antd";
import { getFullTime } from "utils/time/getFullTime";
import Chip from "./Chip";
import {EllipseText} from './styles'


const appendStd = (row) => {
  switch (row.toLowerCase()) {
    case "4th":
    case "5th":
    case "6th":
    case "7th":
    case "8th":
    case "9th":
    case "10th":
      return (
        <span>
          {`${row.toLowerCase().split("th")[0]}`}
          <sup>th</sup> STD
        </span>
      );
    case "1st":
      return (
        <span>
          {`${row.toLowerCase().split("st")[0]}`}
          <sup>st</sup> STD
        </span>
      );
    case "2nd":
      return (
        <span>
          {`${row.toLowerCase().split("nd")[0]}`}
          <sup>nd</sup> STD
        </span>
      );
    case "3rd":
      return (
        <span>
          {`${row.toLowerCase().split("rd")[0]}`}
          <sup>rd</sup> STD
        </span>
      );
    default:
      break;
  }
};

export const columns = [
  {
    title: "Class Name",
    dataIndex: "class_list",
    key: "class_list",
    render: (row) => {
      return row.map((r,index) => (
        <>
          <span>{appendStd(r)}</span>
          {row.length === 1||row.length===index+1 ? "" : ","}&nbsp;
        </>
      ));
    },
  },
  {
    title: "Exam Name ",
    dataIndex: "delivery_name",
    key: "delivery_name",
    width:250,
    render:(row)=> <Popover content={row} placement="bottom"><EllipseText >{row}</EllipseText></Popover>
  },
  {
    title: "Start Time",
    dataIndex: "delivery_starttime",
    key: "delivery_starttime",
    render: (row) => {
      const { YY, M, D, HRS, MIN } = getFullTime(row);
      return row ? (
        <p>{`${D}/${M}/${YY} ${HRS}:${MIN}`}</p>
      ) : (
        <p style={{ textAlign: "center" }}>- -</p>
      );
    },
  },
  {
    title: "End Time",
    dataIndex: "delivery_endtime",
    key: "delivery_endtime",
    render: (row) => {
      const { YY, M, D, HRS, MIN } = getFullTime(row);
      return row ? (
        <p>{`${D}/${M}/${YY} ${HRS}:${MIN}`}</p>
      ) : (
        <p style={{ textAlign: "center" }}>- -</p>
      );
    },
  },

  {
    title: "Students Scheduled",
    dataIndex: "scheduled_count",
    key: "scheduled_count",
    render: (row) => {
      return <p>{row || row === 0 ? row : "--"}</p>;
    },
  },
  {
    title: "Students In Progress",
    dataIndex: "inprogress_count",
    key: "inprogress_count",
    render: (row) => {
      return <p>{row || row === 0 ? row : "--"}</p>;
    },
  },

  {
    title: "Students Submitted",
    dataIndex: "submitted_count",
    key: "submitted_count",
    render: (row) => {
      return <p>{row || row === 0 ? row : "--"}</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "",
    key: "x",
    render: (key) => {
      if (key.delivery_status === "Up coming") {
        return (
          <Chip
            label="Up coming"
            color="#008eff"
            bgColor="#bae2ff"
            delivery_id={key.delivery_id}
            index={key.key}
          ></Chip>
        );
      } else if (key.delivery_status === "In progress") {
        return (
          <Chip
            label="In progress"
            color="#328048"
            bgColor="#eafbe7"
            delivery_id={key.delivery_id}
            index={key.key}
          ></Chip>
        );
      }
      return (
        <Chip
          label="Completed"
          color="#d02b20"
          bgColor="#fcecea"
          delivery_id={key.delivery_id}
          index={key.key}
        ></Chip>
      );
    },
  },
];
export const data = [
  {
    key: 1,
    auto_submit_time: "2022-08-09T21:32:00.000Z",
    batch_ids: ["c9Mdf67EMS"],
    batch_list: ["DIGITAL NARAYANA", "narayana", "narayana", "narayana"],
    class_list: ["8TH STD"],
    college_ids: [],
    college_list: [],
    delivery_endtime: "2022-08-09T20:00:00.000Z",
    delivery_id: "6NTvDgspeF",
    delivery_name: "03 VII_GOING TO_VIII_ACST",
    delivery_start_date: "2022-08-09",
    delivery_starttime: "2022-08-09T16:00:00.000Z",
    delivery_status: "In progress",
    delivery_uri: "http://tao.gcf.education/gcf.rdf#16600392001086175851",
    exam_type: "Others",
    instructions_url:
      "https://gcf-media.s3.ap-south-1.amazonaws.com/instructions/nReward+-+vi_x.html",
    max_marks: "180",
    narayana_mapping_id: 110516,
    registration_status_list: ["reservation", "admission"],
    state_list: [],
    subbatch_ids: ["sQiQR6teJV"],
    subbatch_list: ["ACST JUNE"],
    test_duration: "60",
    test_id: "OcNC0Vvk8E",
    test_question_count: "45",
    year_list: ["2022-2023"],
    class: "6thstd",
    exam_name: "International Standard Mathematical",
  },
  {
    key: 2,
    delivery_status: "Up coming",
  },
  {
    key: 3,
    delivery_status: "Completed",
  },
];
