import React from 'react'
import {MainWrapper, Card, CardHeader, CardFooter, HeaderText} from './styles'
import { FaArrowRight } from "react-icons/fa"
import { FaRupeeSign } from "react-icons/fa"

function ExamsCard({exam, isHome, handleExamDetails}) {
    return (
        <>
            <MainWrapper className=" col-12 col-md-6  col-xl-4">
                <Card>
                    {!exam.lastCard ? <>
                        <CardHeader isHome={isHome}>
                            <img src={exam.icon} alt="ISMO" />
                            <HeaderText>{exam.name}</HeaderText>
                        </CardHeader>
                        {isHome && (
                            <CardFooter>
                                View National Toppers here <FaArrowRight />
                            </CardFooter>
                        )}
                        {!isHome && (
                            <CardFooter onClick={() => handleExamDetails(exam.abr)}>
                                Click here to Know More <FaArrowRight />
                            </CardFooter>
                        )}
                    </> : <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        Stay Tuned <br />
                        For more updates...

                    </div>}
                </Card>
            </MainWrapper>
        </>
    )
}

export default ExamsCard