import React from "react";
import AwardVideoGallery from "./videogallery";
import AwardImageGallery from "./imagegallery";
import {
  SectionContainer,
  FeatureHeaderWrapper,
  TopEllipticalWrapper,
} from "./styles";
import spikes from "../../assets/award-end-line.svg";
import { useResize } from "share/hooks/useResize";

function AwardCeremony() {
  const {width} = useResize();
  const style = {
    position: width < 800 ? "absolute" : "relative",
    top: width < 800 ? 0 :-10,
    right: width < 800 ? 11 :20,
    transform: width < 800 ? "rotate(11deg)" : "rotate(10deg)",
  };

  return (
    <>
    <TopEllipticalWrapper>
        <FeatureHeaderWrapper>
          {width < 800 ?  <h1 style={{position:"relative"}}>
            Celebrating Excellence :<br/>
            <span style={{ color: "#d71a63" }}>
              Award Ceremonies Recap{" "}
              <img
                src={spikes}
                style={style}
              />
            </span>
          </h1> :(  <h1 style={{position:"relative"}}>
            Celebrating Excellence :{" "}
            <span style={{ color: "#d71a63" }}>
              Award Ceremonies Recap{" "}
              <img
                src={spikes}
                style={style}
              />
            </span>
          </h1>)}
        
        </FeatureHeaderWrapper>
      </TopEllipticalWrapper>
      <SectionContainer>
        <AwardVideoGallery />
        <AwardImageGallery />
      </SectionContainer>
    </>
  );
}

export default AwardCeremony;
