import React, { useEffect, useState } from "react";
import OlympiadCard from "share/molecules/card/home-olympiad-card";
import {
  OlympidaCardsWrapper,
  CarasoulContainer,
  OlympiadWrapper,
} from "./styles";
import { useResize } from "share/hooks/useResize";
import Carousel from "./carasoul";
import { getPadding } from "./helper";

function OlympiadCards({
  examsData,
  handleExamDetails,
  navigateToStudentRegistration,
}) {
  const { width } = useResize();
  const [carasoulMeasures, setCarasoulMeasures] = useState({
    cPadding: "20%",
    mWidth: "500px",
  });
  useEffect(() => {
    if (width <= 800) {
      const val = getPadding(width);
      setCarasoulMeasures(val);
    }
  }, [width]);
  return (
    <>
      {width <= 800 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "40px",
          }}
        >
          <CarasoulContainer>
            <Carousel
              data={examsData}
              centerPadding={carasoulMeasures.cPadding}
              handleMoreInfo={handleExamDetails}
              handleBuy={navigateToStudentRegistration}
            />
          </CarasoulContainer>
        </div>
      ) : (
        <>
          <>
            {examsData.map((exam, idx) => (
              <>
                <OlympiadCard
                  exam={exam}
                  handleMoreInfo={handleExamDetails}
                  handleBuy={navigateToStudentRegistration}
                />
              </>
            ))}
          </>
        </>
      )}
    </>
  );
}

export default OlympiadCards;
