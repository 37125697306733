
export const microServices ={
  CNAPSMONITOR: 'cnaps-monitor/v1',
}
export const endpoints = {
    LOGIN: `${microServices.CNAPSMONITOR}/auth/login`,
    PROFILE: ``,
    LOGOUT: ``,
    GET_META_DATA:`${microServices.CNAPSMONITOR}/usage/meta-data`,
  };
  
  export const loginTypes = {
    LOGIN_REQUEST: "monitor/login/LOGIN_REQUEST",
    LOGIN_SUCCESS: "monitor/login/LOGIN_SUCCESS",
    USER_ID_LOGIN_ERROR: "monitor/login/USER_ID_LOGIN_ERROR",
    PASSWORD_LOGIN_ERROR:"monitor/login/PASSWORD_LOGIN_ERROR",
  };
  