import styled from "styled-components";

export const Wrap = styled.div``;

export const Container = styled.div`
  background-color: red;
  max-width: 1400px;
  width: 100%;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  @media screen and (max-width: 1366px) {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  height: 120px;
  border-radius: 6px;
  margin-top: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  line-height: 36px;
  letter-spacing: 0.37px;
  flex-wrap: wrap;
`;

export const HeaderText = styled.h4`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: 0.37px;
  color: #d71a63;
`;

export const DescriptionText = styled.p`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: 0.37px;
  color: #242424;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DownLoadLink = styled.div`
 font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
 color:#242424;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  cursor: pointer;
  img{
  }
 `