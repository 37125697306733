import * as actionTypes from '../actionTypes'

export const getStudentDetailsMonitorRequest=(payload)=>{
    return{
        type: actionTypes.GET_STUDENT_DETAILS_FOR_MONITOR_REQUEST,
        payload
    }
}

export const getStudentDetailsMonitorSuccess=(payload)=>{
    return{
        type: actionTypes.GET_STUDENT_DETAILS_FOR_MONITOR_SUCCESS,
        payload
    }
}

export const getStudentDetailsMonitorFail=(payload)=>{
    return{
        type: actionTypes.GET_STUDENT_DETAILS_FOR_MONITOR_FAIL,
        payload
    }
}