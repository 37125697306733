import styled from "styled-components";
import { Input } from "antd";

export const StyledInput = styled(Input)`
  position: relative;
  border: 0;
  width: 328px;
  border-bottom: 1px solid #949497;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-bottom: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 0;
  padding-left: 0;
  border-color: ${({ error, isMonitorFeature }) =>
    error && isMonitorFeature ? "red" : " #949497"};
  outline: none;
  &:focus {
    outline: none;
    box-shadow: none;
    /* border-color: #949497; */
    border-color: ${({ error, isMonitorFeature }) =>
      error && isMonitorFeature ? "red" : " #949497"};
    border-right-width: none !important;
  }
  &:hover {
    /* border-color: #949497; */
    border-color: ${({ error, isMonitorFeature }) =>
      error && isMonitorFeature ? "red" : " #949497"};
    border-right-width: none !important;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  width: ${({ $width }) => $width || "328px"};
  position: relative;
`;
export const VisibilityIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 2px;
  cursor: pointer;
`;
