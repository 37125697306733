import Dashboard from "school-admin/Dashboard";
import NewRegistration from "school-admin/NewRegistration";
import StudentList from "school-admin/StudentList";
import { AiOutlineUserAdd } from 'react-icons/ai'
import { AiOutlineUser } from 'react-icons/ai'
import { FiHome, FiFileText } from "react-icons/fi";
import CustomIcon from "share/atoms/CustomIcon";
import StudentHome from "student/StudentHome";
import StudentExams from "student/StudentExams";


export const studentRoutes = [
  {
    path: "/student/home",
    icon: FiHome,
    multi: false,
    visible: false,
    name: "Home",
    component: StudentHome,
    isImage: true,
  },
  {
    path: "/student/exams",
    icon: FiFileText,
    name: "Exams",
    multi: ["/exams", "/result", "/answerkey", "/instructions", "/thankyou","/proctor"],
    visible: false,
    component: StudentExams,
  },
];

export const schoolRoutes = [
  {
    path: "/school/dashboard",
    icon: CustomIcon,
    multi: false,
    visible: false,
    name: "Dashboard",
    component: Dashboard,
    isImage: true,
  },
  {
    path: "/school/new-registration",
    icon: AiOutlineUserAdd,
    name: "New Registration",
    multi: false,
    visible: false,
    component: NewRegistration,
  },
  {
    path: "/school/student-list",
    icon: AiOutlineUser,
    name: "Student List",
    multi: false,
    visible: false,
    component: StudentList,
  },
  
];