import * as actionTypes from "../actionTypes";
import produce from "immer";

const initialState = {
  data: [],
  error: "",
  isLoading: false,
  pagination: {},
  filtersData: {},
  totalRecords: 0,
};

const studentListMonitorReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_STUDENT_LIST_FOR_MONITOR_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        draft.filtersData = action.payload;
        break;
      case actionTypes.GET_STUDENT_LIST_FOR_MONITOR_SUCCESS:
        draft.data = action.payload.students_list;
        draft.isLoading=false
        if (action.payload.pagination?.current_page === 1&&action.payload.students_list.length>0) {
          draft.totalRecords = action.payload.pagination.total_records;
        } else if (action.payload.students_list.length === 0) {
          draft.totalRecords = 0;
        }
        // draft.pagination =
        //   action.payload.pagination !== undefined
        //     ? action.payload.pagination
        //     : state.pagination;
        // draft.totalRecords =
        //   action.payload.pagination.total_records !== 0 &&
        //   action.payload.pagination !== 1
        //     ? action.payload.pagination === 0
        //       ? state.totalRecords - 1
        //       : action.payload.pagination.total_records
        //     : state.totalRecords;
        // draft.isLoading = false;
        break;
      case actionTypes.GET_STUDENT_LIST_FOR_MONITOR_FAIL:
        draft.error = action.payload;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export default studentListMonitorReducer;
