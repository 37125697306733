import SideContentLayout from "layouts/SideContentLayout";
import React from "react";
import logo from "assets/frame.svg";
import { LogoWrap, H3, SubmitButton, StyledLabel,Error } from "./styles";
import { useHistory } from "react-router-dom";
import { TextInput, PasswordInput, FieldWrap } from "share/atoms/form-fields";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import saga from './saga'
import { useInjectSaga } from "utils/saga/injectSaga";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "./actions";
import md5 from "md5";
const key="monitorLogin"

const Login = () => {
  const history = useHistory();
  const {userIdError,passwordError}= useSelector(state=>state.monitorLogin);
  const navigate = () => {
    history.push("/monitor/login");
  };
  
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const loginScheme = yup.object().shape({
    userId: yup.string().required("Required"),
    password: yup.string().required("Required"),
  });
  const {
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm({ 
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(loginScheme) });
    

    const handleSubmit = (event)=>{
      event.preventDefault();
      const {userId,password}=watch();
      dispatch(loginRequest({
        username:userId,
        password:md5(password)
      }))
    }

    const isValidUserId = (userId) => {
      if (userId === "" || userIdError) return true;
      else return false;
    };

    const isValidPassword = (password) => {
      if (password === "" || passwordError) return true;
      else return false;
    };

    const isValidField = (values) => {
      const { userId, password } = values;
      if (userId && password && password.length > 4) return true;
      return false;
    };

  return (
    <SideContentLayout>
      <LogoWrap className="gutter-64">
        <img src={logo} alt="cnaps logo" onClick={navigate} />
        <h3>Monitor</h3>
      </LogoWrap>
      <div style={{ height: "calc(100vh - 90px)" }} className="d-col d-center">
        <H3>Monitor Login</H3>

        <form
          className="d-col d-center"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <FieldWrap>
            <StyledLabel className="d-flex" htmlFor="userId">
              User Id
            </StyledLabel>
            <Controller
              control={control}
              name="userId"
              render={({
                field: { onChange },
              }) => (
                <TextInput
                id="userId"
                name="userId"
                type="text"
                autoComplete="off"
                autoFocus={false}
                handleChange={onChange}
                
                error={
                  isValidUserId(watch().userId)
                }
                isMonitorFeature
              />
              )}
            />
             <Error>{errors?.userId?.message}</Error>
          </FieldWrap>
          <FieldWrap>
            <StyledLabel className="d-flex" htmlFor="password">
              Password
            </StyledLabel>

            <Controller
              control={control}
              name="password"
              render={({
                field: { onChange },
              }) => (
                <PasswordInput
              id="password"
              name="password"
              type="password"
              autoFocus={false}
              onChange={onChange}
              error={
                isValidPassword(watch().password)
              }
              isMonitorFeature
            />
              )}
            />
               <Error>{errors?.password?.message}</Error>
          </FieldWrap>
          <SubmitButton
            width="100%"
            type="submit"
              opacity={!isValidField(watch())}
          >
            Sign In
          </SubmitButton>
        </form>
      </div>
    </SideContentLayout>
  );
};

export default Login;
