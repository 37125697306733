import { createSelector } from "reselect";

const selectRouter = (state) => state.router;
const selectProccedStatus = (state) =>
  window?.location?.pathname?.includes("scheduled_test")
    ? state?.proctor?.proceedTestReducer
    : state.instructions;
const selectTestAnswers = (state) => state.answersReducer;
const selectTestTimes = (state) => state.spentTimesReducer;
const selectQuestionPaper = (state) => state.test.questionPaper;
const selectProfile = (state) => state.test.profileReducer;
const selectAppReducer = (state) => state.appReducer;

const makeSelectRouter = () =>
  createSelector(selectRouter, (res) => res.location);

const makeSelectProceedStatus = () =>
  createSelector(selectProccedStatus, (res) =>
    res ? res.data : { duration: 300000 }
  );

const makeSelectAnswers = () =>
  createSelector(selectTestAnswers, (res) => res.answers);

const makeSelectTimes = () =>
  createSelector(selectTestTimes, (res) => res.times);

const makeSelectQuestionPaper = () =>
  createSelector(selectQuestionPaper, (res) => res.data);

const makeSelectSpent = () =>
  createSelector(selectTestTimes, (res) => (isNaN(res.spent) ? 0 : res.spent));

const makeSelectProfile = () =>
  createSelector(selectProfile, (res) =>
    localStorage.getItem("admsnNumber")
      ? { admissionNumber: localStorage.getItem("admsnNumber") }
      : { admissionNumber: 1234 }
  );

const makeSelectAppReducer = () =>
  createSelector(selectAppReducer, (res) => res);

export {
  selectRouter,
  makeSelectRouter,
  selectProccedStatus,
  makeSelectProceedStatus,
  selectTestAnswers,
  makeSelectAnswers,
  selectTestTimes,
  makeSelectTimes,
  makeSelectSpent,
  makeSelectProfile,
  selectAppReducer,
  makeSelectAppReducer,
  makeSelectQuestionPaper,
};
