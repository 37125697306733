
import { combineReducers } from 'redux';
import getStatsByDeliveryIdReducer from './get-stats-by-id-reducer';
import deliveryListReducer from './list-deliveries-reducer';

 const DeliveryReducer = combineReducers({
    deliveryList:deliveryListReducer,
    getStatsByDeliveryId:getStatsByDeliveryIdReducer
  });

  export default DeliveryReducer



 