import styled from "styled-components";

export const BottomSheetContainer = styled.div`
  max-height: 800px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 223;
  transform: ${({ $show }) =>
    $show ? "translateY(0px)" : "translateY(1000px)"};
  transition: all 0.5s ease-in-out;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 40px 16px 26px 16px;
`;

export const BottomSheetOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 222;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${({ $show }) => ($show ? 0.5 : 0)};
  transition: all 0.5s ease-in-out;
`;

export const BottomSheetClose = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  background: none;
  color: #242424;
  border-radius: 4px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;

  &:focus {
    border: none;
    outline: none;
  }
`;
