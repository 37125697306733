import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useNetworkStatus, useScroll } from "share/hooks";
import { Detector } from "react-detect-offline";
import { render } from "@testing-library/react";
import { getBrowserDetails } from "analytics";

export const NoInternet = styled.h3`
  color: transparent;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  font-family: Poppins;
  padding: 15px 0;
  ${({ isOnline }) =>
    !isOnline &&
    css`
      color: #ffff;
    `}
`;
export const NoInternetContainer = styled.div`
  max-height: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
  ${({ isOnline }) =>
    !isOnline &&
    css`
      max-height: 36px;
    `}
  transition:max-height 1s ease;
`;

export default function NoInternetComponent() {
  const isFireFox = getBrowserDetails().includes("Firefox");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsOnline(window.navigator.onLine);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <NoInternetContainer isOnline={isOnline}>
      <NoInternet isOnline={isOnline}>No Internet Connection!</NoInternet>
    </NoInternetContainer>
  );
}
