import GlobalContainer from "share/atoms/GlobalContainer";
import styled from "styled-components";
import Button from "share/atoms/Button";

export const Container = styled(GlobalContainer)`
  background-color: #ffffff;
  position: sticky;
  top: ${({ $top }) => ($top ? $top : "0px")};
  left: 0px;
  z-index: 10;
  box-shadow: 0px 6px 10px -12px #182441;

  @media screen and (max-width: 800px) {
    top: 0px;
  }
  @media screen and (max-width: 479px) {
    top: ${({ $top }) => ($top ? $top : "0px")};
  }
`;

export const SideBarIcon = styled.div`
  display: none;
  height: 56px;
  @media screen and (max-width: 800px) {
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px 8px; */
  }
`;

export const RouterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  @media screen and (max-width: 1279px) {
    right: 40px;
  }
  @media screen and (max-width: 1024px) {
    right: 0px;
  }
  /* @media screen and (max-width: 800px) {
    display: none;
  } */
`;

export const CustomButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
`;

export const SignInButton = styled(CustomButton)`
  background-color: #ffffff;
  color: #254082;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #254082;
    color: #ffffff !important;
    filter: brightness(1);
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const SignUpButton = styled(CustomButton)`
  background-color: #d71a63;
  color: #ffffff;
  transition: all 0.1s ease-in-out;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
`;
