/**
 * Asynchronously loads the component for Assignments page
 */
import React from 'react';
import loadable from 'utils/loadable';
import PageLoader from 'share/molecules/page-loader';

// Need to add loading component
export default loadable(() => import("./index1"), {
  fallback: (
    <h4>
      <PageLoader />
    </h4>
  ),
});
