import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { routes } from "./helpers";
import {
  NavBarWrapper,
  StyledNavLink,
  DropDownWrapper,
  MainWrapper,
} from "./styles";
import DropdwonMain from "../Dropdown-main";
import { useResize } from "share/hooks/useResize";

function NavBar(props) {
  const history = useHistory();
  const pathname = history.location.pathname;
  const excludeActiveClassList = ["/explore", "/contact-us"];
  const { isSideBar } = props;
  let url = window.location.href;
  let hostName = url.split("//")[1].split("/")[0];
  url = [url.split("//")[0], hostName].join("//");

  const isActivated = !excludeActiveClassList.includes(pathname);
  const activeClassStyles = {
    borderBottom: "2px solid #d71a63",
    paddingBottom: "0px",
    color: "#d71a63",
  };
  const handleNavLink = () => {
    if (props.handleSidebar) props.handleSidebar();
  };
  const { width, height } = useResize();
  return (
    <MainWrapper $isScrollable={width <= 800 && height <= 393}>
      <NavBarWrapper isSideBar={isSideBar}>
        {routes.map((route, index) => {
          if (!route.isSideMenuItem || isSideBar) {
            if (route.isDropdown) {
              return (
                <DropDownWrapper $isResponsive={width < 800}>
                  <DropdwonMain
                    list={route.list}
                    name={route.name}
                    handleClose={props.handleSidebar}
                  />
                </DropDownWrapper>
              );
            } else {
              return (
                <StyledNavLink
                  exact
                  activeStyle={isActivated && activeClassStyles}
                  to={route.path}
                  className={
                    route.isButton
                      ? ` btn btn-primary login-btn hide-mobile ${
                          index + 1 === routes.length ? "mr-0" : ""
                        }`
                      : route.className
                      ? `${route.className}`
                      : ""
                  }
                  key={index}
                  isSideBar={isSideBar}
                  activateFocus={route.path !== "/login"}
                  onClick={handleNavLink}
                >
                  {route.name}
                </StyledNavLink>
              );
            }
          }
        })}
      </NavBarWrapper>
    </MainWrapper>
  );
}

export default withRouter(NavBar);
