import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Table, Input } from "antd";
import {
  BreadcrumbWrapper,
  Heading,
  PaperForm,
  StudentHeading,
  TableWrapper,
  Wrapper,
  InputWrapper,
  TextWrapper,
} from "./styles";
import { dataSource, columns } from "./helper";
import Form from "./form";
import { useHistory, useLocation } from "react-router-dom";
import { getQueryParam } from "utils/utility/url";
import SchoolAdminHeader from "share/organisms/headers/school-admin-header";
import { useDispatch, useSelector } from "react-redux";
import { getStudentListMonitorRequest } from "./actions/student-list-monitor-actions";
import { useInjectReducer } from "utils/saga/injectReducer";
import { useInjectSaga } from "utils/saga/injectSaga";
import reducer from "./reducers";
import saga from "./saga";
import Overlay from "share/atoms/overlay/index.js";
import { useQuery } from "share/atoms/use-query";
import SearchIcon from "assets/monitoring/search.svg";
import { IoIosClose } from "react-icons/all";
import Loader from "share/atoms/loader";

const MonitorTestTakers = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const key = "monitorTestTakers";
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const delivery_id = getQueryParam("delivery_id", location);
  const state = useSelector(
    (state) => state?.monitorTestTakers?.studentListMonitor
  );

  const open = useSelector(({ backDrop }) => backDrop.open);
  const [value, setValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [showOpen, setShowOpen] = useState(false);
  const query = useQuery();
  const[innerWidth,setInnerWidth] = useState(window.innerWidth);
  const[pageDetails,setPageDetails]= useState(null);
  
  useEffect(() => {
    const payload = {
      delivery_id: delivery_id,
      page_number: 1,
      page_limit: 100,
    };
    dispatch(getStudentListMonitorRequest(payload));
  }, [delivery_id]);

  useEffect(() => {
    if (state?.data) {
      setDataSource(state.data);
    }
  }, [state?.data]);

  useEffect(() => {
    function handleWindowResize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  /**
 * this function  filters the data 
 * @param {Object} e
 * @returns {void}
 */
  const handleSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    const filteredData = state?.data.filter((entry) => {
      return entry.admission_number
        .toLowerCase()
        .includes(currValue.toLowerCase());
    });
    setDataSource(filteredData);
  };
/**
 * this function  takes the given data  and add key to the each array
 * @param {Array} data
 * @returns {Array}  data with index
 */
  const getDataWithIndex = (data) => {
    return data.map((dta, index) => ({ key: index, ...dta }));
  };

  const handleClose = () => {
    setShowOpen(false);
    setValue("");
    setDataSource(state.data);
  };

  const handlePageSize = (page, filters, sorter, extra) => {
    setValue("");
    setPageDetails({
      pageNumber:page.current,
      pageLimit:page.pageSize
    })
    if (extra.action !== "sort") {
      const payload = {
        delivery_id: delivery_id,
        page_number: page.current,
        page_limit: page.pageSize,
      };
      dispatch(getStudentListMonitorRequest(payload));
    }
  };

  const FilterByNameInput = !showOpen ? (
    <TextWrapper>
      <p>Admission Number</p>
      <img
        src={SearchIcon}
        className="search-icon"
        onClick={() => setShowOpen(true)}
      />
    </TextWrapper>
  ) : (
    <InputWrapper>
      <input
        placeholder="Admission Number"
        value={value}
        onChange={handleSearch}
        className="input-styles"
      />

      <div className="close-icon" onClick={handleClose}>
        <IoIosClose fontSize="25px" />
      </div>
    </InputWrapper>
  );

  return (
    <>
      {open && <Overlay />}
      {state?.isLoading&&<Overlay/>}
      <SchoolAdminHeader />
      <TableWrapper>
        <BreadcrumbWrapper>
          <Breadcrumb.Item
            onClick={() => history.push("/monitor/deliveries")}
            style={{ cursor: "pointer" }}
          >
            <span className="heading">List Deliveries</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="value">{query.get("exam_name")}</span>
          </Breadcrumb.Item>
        </BreadcrumbWrapper>
        <Heading>Monitor Test Takers</Heading>
        <PaperForm>
          <Form  setPageDetails={setPageDetails}/>
        </PaperForm>
        <StudentHeading>Students List</StudentHeading>
        <Wrapper>
          <Table
            dataSource={getDataWithIndex(dataSource)}
            columns={columns(FilterByNameInput,innerWidth,setShowOpen,setPageDetails)}
            onChange={handlePageSize}
            scroll={{
              x: 2500,
              y: 600,
            }}
            bordered
            pagination={{
              pageSizeOptions: [100, 200, 300, 400, 600, 800, 1000, 2000],
              defaultPageSize: 100,
              current:pageDetails?pageDetails.pageNumber:1,
              pageSize:pageDetails?pageDetails.pageLimit:100,
              total: state?.totalRecords,
              showTotal: (total, range) => (
                <p> {`${range[0]}-${range[1]} of ${total} items`}</p>
              ),
            }}
          />
        </Wrapper>
      </TableWrapper>
    </>
  );
};

export default MonitorTestTakers;
