import styled from "styled-components";
import Button from "share/atoms/Button";
export const Timer = styled.div`
  display: inline-block;
  width: 92px;
`;
const getPaperStatus = ({ paperStatus }) => {
  if (["started", "in_progress", "submitted", "expired"].includes(paperStatus))
    return `opacity:1;cursor:pointer;`;

  return `opacity:0.3;cursor:not-allowed !important;`;
};

export const StrokeButton = styled(Button)`
  border: 2px solid #464646;
  outline: none;
  background-color: #ffff;
  border-radius: 24px;
  color: #464646;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  &.strk-btn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &:hover {
    background-color: #254082;
    font-weight: 600;
    color: #fff;
  }
  @media screen and (max-width: 1366px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
export const StyledButton = styled(Button)`
  ${(props) => getPaperStatus(props)}
`;
export const Wrapper = styled.div`
  min-height: 324px;
  display: flex;
  font-family: Poppins;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: white;
  padding: 24px;
  margin-top: 16px;
  position: relative;
`;
export const Logo = styled.div`
  img {
    width: 100px;
  }
  padding-right: 24px;
`;

export const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  .exam-details-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
`;
export const Exam = styled.div`
  display: flex;
  justify-content: space-around;
  flex-grow: 1;

  & .exam {
    text-align: center;
  }

  & .examInfo {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #565656;
    margin-top: 18px;
    margin-bottom: 8px;
  }

  & .examDetails {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #242424;
    cursor: pointer;
    padding: 0 4px 4px;
  }
  .pdf-icon {
    color: #d71a63;
    padding-right: 4px;
  }
  .download-icon {
    padding-right: 6px;
  }
`;
export const VLine = styled.div`
  width: 1px;
  height: 66px;
  background-color: #d1d1d8;
  margin-top: 14px;
`;
export const HLine = styled.div`
  height: 1px;
  background-color: #d1d1d8;
`;

export const ButtonGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  .button-certificate {
    height: 48px;
    padding: 12px 24px;
    border-radius: 24px;
    background-color: #254082;
    border: 0;
    outline: 0;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #ffffff;
  }
  button {
    height: 48px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

export const Hint = styled.p`
  /* color: #d71a63;
  margin-bottom: 0; */
  padding: 12px 24px;
  border-radius: 24px;
  background-color: #ffdce9;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #d71a63;
`;

export const Badge = styled.div`
  background-image: url(${({ bgImage }) => bgImage});
  height: 48px;
  width: 230px;
  position: absolute;
  right: -4px;
  top: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  padding-top: 5px;
  .secondary {
    margin-left: 5px;
    font-weight: bold;
  }
`;
