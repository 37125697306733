import produce from 'immer';
import { getDashboardTypes } from './constants';

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

export const DashboardReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case getDashboardTypes.GET_DASHBOARD_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case getDashboardTypes.GET_DASHBOARD_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case getDashboardTypes.GET_DASHBOARD_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });
  
export default DashboardReducer
