import React from 'react';
import PropTypes from 'prop-types';
import { StyledLabel } from './styles';
const InputLabel = (props) => <StyledLabel {...props}>{props.children}</StyledLabel>;

InputLabel.propTypes = {
  children: PropTypes.any,
};

export default InputLabel;
