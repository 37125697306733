import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "utils/history";
import configureStore from "utils/store/configureStore";

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");
const isProd = process.env.NODE_ENV === "production";
// Disable console logging in Production
if (isProd) {
  if (!window.console) window.console = {};
  let logMethods = ["log", "debug", "warn", "info", "error"];
  logMethods.forEach((method) => (console[method] = () => {}));
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
