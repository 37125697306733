import React from 'react'
import 'antd/dist/antd.css';
import { Table } from 'antd';
import { TableWrap, PaginatedButton, Indicator } from './styles';

export default function AntdTable(props) {
  const { scroll } = props
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <PaginatedButton>Previous</PaginatedButton>;
    }
    if (type === "next") {
      return <PaginatedButton>Next</PaginatedButton>;
    }
    return originalElement;
  }
  return (
    <React.Fragment>
      <TableWrap padding={props.padding}>
        <Table
          bordered
          pagination={{
            position: ["none", "bottomCenter"],
            itemRender: itemRender,
            total: props.total,
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`
          }}
          {...props}
          scroll={scroll || {x:true}}
        />
        {props.isIndicator&&<Indicator>
          <div className="indicator-wrapper">
          <div class="green"></div>
           <p className="text">Yes</p>
          </div>
          <div className="indicator-wrapper">
          <div class="red"></div>
           <p className="text"> NA:Not Approved</p>
          </div>
        </Indicator>}
      </TableWrap>
    </React.Fragment>
  );
}