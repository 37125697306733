import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { setLocalObj } from "utils/utility/local-storage";
import { loginTypes } from './constants';
import notify from "utils/notifications";
import LoginService from './services/login';
import * as actions from './actions';

const loginService = new LoginService();

export function* loginWorker(action) {
  try {
    const res = yield call(loginService.loginApi, action.payload);

    if (res.data.status === 'success') {
      const tokenData = res.data.data;
      yield localStorage.setItem('login-type', 'monitor')
      yield setLocalObj('token', tokenData.jwt_token)
      yield setLocalObj("token-data", tokenData);
      yield put(actions.loginSuccess(tokenData));
      //  if(tokenData)
      // yield put(push('/monitor/deliveries'));
      yield loginService.getMetaDataApi("login-page")
      
    } else {
      notify("error", res.data.message);
      if(res.data.errorCode==="INVALID_USER_ID")  yield put(actions.userIdLoginError(res.data));
      else {
        yield put(actions.passwordLoginError(res.data))};
      // yield put(actions.loginError(res.data));
    }
  } catch (e) {
    console.log(e)
    notify("error", "Something went wrong");
    // yield put(actions.loginError(e));
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(loginTypes.LOGIN_REQUEST, loginWorker);
}
