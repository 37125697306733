const CNAPS_ADMIN = "cnaps-admin/v1/da";

export const endpoints = {
  DASHBOARD: `${CNAPS_ADMIN}/dashboard`,
  STUDENT_LIST: `${CNAPS_ADMIN}/school-student-list`,
  APPROVE_STUDENT_LIST: `${CNAPS_ADMIN}/approve-school-studentlist`,
  DOWNLOAD_EXCEL: `${CNAPS_ADMIN}/get-download-school-student-list`,
};

export const divisionalActTypes = {
  GET_DASHBOARD_REQUEST: " divisional/GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "divisional/GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_ERROR: "divisional/GET_DASHBOARD_ERROR",

  GET_STUDENT_LIST_REQUEST: " divisional/GET_STUDENT_LIST_REQUEST",
  GET_STUDENT_LIST_SUCCESS: "divisional/GET_STUDENT_LIST_SUCCESS",
  GET_STUDENT_LIST_ERROR: "divisional/GET_STUDENT_LIST_ERROR",

  GET_APPROVE_STUDENT_LIST_REQUEST:
    " divisional/GET_APPROVE_STUDENT_LIST_REQUEST",
  GET_APPROVE_STUDENT_LIST_SUCCESS:
    "divisional/GET_APPROVE_STUDENT_LIST_SUCCESS",
  GET_APPROVE_STUDENT_LIST_ERROR: "divisional/GET_APPROVE_STUDENT_LIST_ERROR",

  DOWNLOAD_EXCEL: "divisional/DOWNLOAD_EXCEL",
};
