import styled from 'styled-components';
import Button from "share/atoms/Button";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  max-width: 1400px;
  width: 100%;
  height: 68px;
`;

export const Wrapper = styled.div`
  height: calc(100vh - 68px);
  background-color: #f9f9ff;
  padding: 20px 30px;
`;

export const Heading = styled.h5`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CancelBtn = styled(Button)`
  background-color: transparent;
  color: #242424;
  font-weight: bold;
`;

export const CardWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : "355px")};
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding:  24px;
  margin-top:24px;
`;

export const AccordionWrapper = styled(CardWrapper)`
  height: auto;
  padding: 24px;
`;


export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 357px;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 48px;
  border-right: 1px solid #ebe8e9;
  height: 226px;
  opacity:${({ opacity }) => (opacity ? 0.3 : 1)};
  &:last-child{
  border-right:none;
  }
`;

export const Image = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  margin-bottom: 28px;
`;

export const CardHeading = styled.h6`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.palette.typography.fontFamily};
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  margin-bottom: 24px;
`;

export const Description = styled.p`
   width: 270px;
  height: 44px;
  margin: 24px 0 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
   a {
    font-weight: bold;
    border-bottom: 1px solid  rgba(70, 70, 70,0.6);
  }
`;

export const SummaryWrapper = styled.div`
  margin-top: 27px;
  padding: 24px 24px 16px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-bottom: 20px;
  }
`;

export const SubTotal = styled.div`
font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #242424;
  margin: 25px 10px;
  margin-right:16px;
`

export const UserID = styled.div`
font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
`

export const Edit = styled(UserID)`
cursor: pointer;
`

export const CustomizableTitle = styled.div`
.heading{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.sub-heading{
 font-size: 12px;
  font-weight: normal;
}
`