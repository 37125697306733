import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import ExamPageContent from "./exam-page-content";
import HomePageConent from "./home-page-content";
import SwitchBar from "share/molecules/switch-bar";
import OlympiadAccessDetails from "share/molecules/olympiad-access-details";
import OlympiadCards from "./olympiad-cards";
import ExamsCard from "share/molecules/card/exams-card";
import ForSchools from "../ForSchools";
import Footer from "../Footer";
import { useRouteMatch } from "react-router-dom";
import { useScrollTo } from "share/hooks/useScrollTo";
import {
  H1,
  Content,
  SwitchWrapper,
  FlexibleWrapper,
  CardsContainer,
  Container,
  FlexibleTitleWrapper,
  HeaderWrapper,
} from "./styles";
import {
  allExamsData,
  examsTabData,
  firstToFifthExamsData,
  sixthToTenthExamsData,
  examsCompleteDetails,
} from "./helper";
import Header from "share/organisms/headers/features-header";
import { FeatureHeader } from "share/organisms/headers";

export const Grid = styled.div`
  & .exams-header {
    grid-area: header;
  }

  & .exams-switchbar {
    grid-area: switchbar;
    text-align: center;
  }
  & .exams-content {
    grid-area: content;
  }
  & .exams-access {
    grid-area: access;
  }
  & .exams-cards {
    grid-area: cards;
  }

  display: grid;
  grid-template-areas:
    "header header header"
    "switchbar switchbar switchbar"
    "content cards cards"
    "access cards cards";

  @media screen and (max-width: 1162px) {
    grid-template-areas:
      "header"
      "content"
      "switchbar"
      "cards"
      "access";
  }

  @media screen and (max-width: 800px) {
    grid-template-areas:
      "header"
      "content"
      "switchbar"
      "cards"
      "access";
  }
`;

function Main(props) {
  useScrollTo(0, 0);
  const router = useRouteMatch();
  const location = useLocation();
  const isExamsPage = location.pathname === "/exams";
  const [examsData, setExamsData] = useState(allExamsData);
  const handleExamDetails = (val) => {
    const { history } = props;
    let DATA = examsCompleteDetails;
    localStorage.setItem("examName", DATA[val].name);
    localStorage.setItem("exam_data", JSON.stringify(DATA[val]));

    history.push({ pathname: "/exam-details" });
  };
  const navigateToStudentRegistration = () => {
    if (window?.innerWidth > 480) props.history.push("/student/registration");
    else window.open(process.env.REACT_APP_PLAY_STORE_URL);
  };
  const handleSwitch = (opt) => {
    switch (opt) {
      case "all":
        setExamsData(allExamsData);
        break;
      case "1":
        setExamsData(firstToFifthExamsData);
        break;
      case "2":
        setExamsData(sixthToTenthExamsData);
        break;
      default:
        setExamsData(allExamsData);
        break;
    }
  };
  return (
    <>
      {router.url === "/exams" && <FeatureHeader {...props} />}
      <Container $isExamPage={isExamsPage}>
        <Grid>
          <HeaderWrapper
            className="exams-header exam-main"
            $isExamPage={isExamsPage}
          >
            <H1 $isExamPage={isExamsPage}>Our Olympiads</H1>
            <Content isHome={props.isHome}>
              {isExamsPage ? <ExamPageContent /> : <HomePageConent />}
            </Content>
          </HeaderWrapper>
          <div className="exams-switchbar exam-main">
            {!isExamsPage && (
              <SwitchWrapper>
                <SwitchBar onSwitch={handleSwitch} />
              </SwitchWrapper>
            )}
          </div>
          {!isExamsPage && (
            <div className="exams-content exam-main">
              <FlexibleWrapper>
                <FlexibleTitleWrapper>
                  <span className="heading">Flexible</span>
                  <span className="heading">Online Exams</span>
                </FlexibleTitleWrapper>
                <p className="paragraph">
                  More than 600000 students have participated in our exams till
                  now. Now, you can also participate in India's best online
                  competitions. You can register one or more olympiads.
                </p>
              </FlexibleWrapper>
            </div>
          )}
          {!isExamsPage && (
            <div className="exams-access exam-main">
              <OlympiadAccessDetails />
            </div>
          )}
          <div className="exams-cards exam-main">
            <CardsContainer $isExam={!isExamsPage}>
              {isExamsPage ? (
                examsData.map((exam, idx) => (
                  <>
                    <ExamsCard
                      exam={exam}
                      isHome={props.isHome}
                      handleExamDetails={handleExamDetails}
                    />
                  </>
                ))
              ) : (
                <OlympiadCards
                  examsData={examsData}
                  navigateToStudentRegistration={navigateToStudentRegistration}
                  handleExamDetails={handleExamDetails}
                />
              )}
            </CardsContainer>
          </div>
        </Grid>
      </Container>
      <div>
        <ForSchools />
      </div>
      {isExamsPage && <Footer />}
    </>
  );
}

export default Main;
