import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import WelcomeCard from "share/molecules/card/welcome-card";
import ExamDetailsCard from "share/molecules/card/student-exam-details-card";
import ScheduledExamsService from "../StudentExams/services";
import { getLocalObj } from "utils/utility/local-storage";
import { openPdfInWindow } from "utils/utility/global-window";
import { navigateToTestEnginge } from "utils/utility/test-engine";
import {
  Heading,
  Count,
  HeadingWrap,
  InstructionWrapper,
  ValuesList,
} from "./styles";

const scheduledExamsService = new ScheduledExamsService();

export default function StudentHome(props) {
  const history = useHistory();
  const [exam, setExam] = useState(false);
  const [purchasedCount, setPurchasedCount] = useState(0);
  const tokenData = getLocalObj("token-data");

  const getExams = async () => {
    const res = await scheduledExamsService.getScheduledExams();
    if (res?.data?.data?.available_exam) {
      setExam(res.data.data.available_exam);
      if (res.data.data.purchased_exam_list.length > 0)
        setPurchasedCount(res.data.data.purchased_exam_list.length);
    }
  };

  useEffect(async () => {
    getExams();
  }, []);

  const handleTest = (data, type) =>
    navigateToTestEnginge(data, type, props.history);

  if (localStorage.getItem("token") && !tokenData?.is_exams_purchased)
    return <Redirect to="/student/exam-selection" />;

  return (
    <WithSidepanelLayout>
      <WelcomeCard name={tokenData?.student_name} />
      <HeadingWrap>
        {/* <Heading>Your Upcoming Exams </Heading> */}
        <Heading>Exams</Heading>
        {/* <Count className="count">Total {purchasedCount}</Count> */}
      </HeadingWrap>
      <ExamDetailsCard
        handleTest={handleTest}
        handlePdf={openPdfInWindow}
        exam={exam}
        isLevelTwo={exam.is_level_two}
        getExams={getExams}
      />
      <InstructionWrapper>
        <h1 className="heading">Instructions</h1>

        <ValuesList>
          {exam.instruction_list &&
            exam.instruction_list.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
        </ValuesList>
      </InstructionWrapper>
    </WithSidepanelLayout>
  );
}
