import styled from "styled-components";

export const Button  = styled.button`
  padding: 6px 11px;
  border-radius: 4px;
  background-color:${({toggle})=>toggle?"rgba(70, 70, 70, 0.2)":"#d71a63"};
  outline: none;
  border:none;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: ${({toggle})=>toggle?"#464646":"#fff"};
  &:focus{
      outline: none;
  }
`