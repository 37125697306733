import React from 'react'

export default function CustomHeaderIcon(props) {
  const getCustomHeaderIcon = {
    profile: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path fill="#242424" d="M10.434 9.063C9.64 9.063 9.285 9.5 8 9.5c-1.313 0-1.668-.438-2.46-.438-2.024 0-3.665 1.668-3.665 3.692v.684c0 .738.574 1.312 1.313 1.312h9.624c.711 0 1.313-.574 1.313-1.313v-.683c0-2.024-1.668-3.691-3.691-3.691zm2.379 4.374H3.187v-.683c0-1.313 1.04-2.379 2.352-2.379.41 0 1.04.438 2.461.438 1.395 0 2.023-.438 2.434-.438 1.312 0 2.379 1.066 2.379 2.379v.684zM8 8.625c2.16 0 3.938-1.75 3.938-3.938C11.938 2.527 10.16.75 8 .75c-2.188 0-3.938 1.777-3.938 3.938 0 2.187 1.75 3.937 3.938 3.937zm0-6.563c1.422 0 2.625 1.204 2.625 2.626 0 1.449-1.203 2.625-2.625 2.625-1.45 0-2.625-1.176-2.625-2.625 0-1.422 1.176-2.625 2.625-2.625z" />
      </svg>
    ),
    changePassword: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
        <path fill="#242424" d="M13.813 13.563c.464 0 .874-.383.874-.876 0-.464-.41-.874-.874-.874-.493 0-.876.41-.876.874 0 .493.383.876.876.876zm-5.25.874h-7v-.683c0-1.313 1.039-2.352 2.351-2.379.41 0 1.04.438 2.461.438 1.148 0 1.887-.301 2.188-.383v-.93c0-.11 0-.273.027-.41-.711.082-1.094.41-2.215.41-1.313 0-1.668-.438-2.46-.438C1.89 10.09.25 11.73.25 13.755v.684c0 .738.574 1.312 1.313 1.312h7.164c-.22-.492-.165-.82-.165-1.313zm8.312-4.812H16V8.312c0-1.203-.984-2.187-2.188-2.187-1.23 0-2.187.984-2.187 2.188v1.312h-.875c-.492 0-.875.41-.875.875v4.375c0 .492.383.875.875.875h6.125c.465 0 .875-.383.875-.875V10.5c0-.465-.41-.875-.875-.875zm-3.938-1.313c0-.464.383-.874.876-.874.464 0 .874.41.874.875v1.312h-1.75V8.312zm3.5 6.126h-5.25v-3.5h5.25v3.5zM6.376 1.75c-2.188 0-3.938 1.777-3.938 3.938 0 2.187 1.75 3.937 3.938 3.937 2.16 0 3.938-1.75 3.938-3.938 0-2.16-1.778-3.937-3.938-3.937zm0 6.563c-1.45 0-2.625-1.176-2.625-2.626 0-1.421 1.176-2.625 2.625-2.625C7.797 3.063 9 4.266 9 5.688c-.027 1.449-1.203 2.625-2.625 2.625z" />
      </svg>
    ),
    paymentReceipt: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path fill="#242424" d="M11.281 8.625H4.72c-.137 0-.219.11-.219.219v.875c0 .136.082.219.219.219h6.562c.11 0 .219-.083.219-.22v-.874c0-.11-.11-.219-.219-.219zm1.531-7.71l-1.175.847-1.067-.739c-.465-.328-1.066-.328-1.53 0L8 1.762l-1.066-.739C6.742.887 6.387.777 6.168.777c-.246 0-.574.11-.766.246l-1.066.739L3.16.914c-.547-.383-1.285 0-1.285.656v12.387c0 .629.738 1.04 1.285.656l1.176-.875 1.066.766c.465.328 1.067.328 1.532 0L8 13.738l1.04.766c.464.328 1.066.328 1.53 0l1.067-.766 1.175.875c.547.383 1.313 0 1.313-.656V1.57c0-.656-.766-1.039-1.313-.656zm0 12.058l-1.175-.848-1.832 1.313-1.832-1.313-1.805 1.313-1.832-1.313-1.149.848V2.555l1.149.847L6.168 2.09 8 3.402 9.805 2.09l1.832 1.312 1.175-.847v10.418zm-1.53-7.41H4.718c-.137 0-.219.109-.219.218v.875c0 .137.082.219.219.219h6.562c.11 0 .219-.082.219-.219v-.875c0-.11-.11-.218-.219-.218z" />
      </svg>
    ),
    faq:(<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.667 1.333A4.674 4.674 0 0 0 1 5.999c0 .739.195 1.428.501 2.05l-.474 1.696c-.145.52.373 1.04.893.895v-.001l1.7-.474c.62.306 1.308.5 2.047.5h.053c.325 2.256 2.268 4 4.613 4 .739 0 1.427-.194 2.048-.5l1.698.474h.001c.52.145 1.038-.373.893-.893l-.474-1.697c.306-.621.501-1.311.501-2.05a4.674 4.674 0 0 0-4.667-4.666l-.048.003a4.674 4.674 0 0 0-4.618-4.003zm0 1a3.659 3.659 0 0 1 3.625 3.122 4.655 4.655 0 0 0-2.103 1.107L6.153 4.035a.5.5 0 0 0-.508-.375.5.5 0 0 0-.465.375L3.704 7.644a.5.5 0 0 0 .926.378l.281-.689h1.6a4.633 4.633 0 0 0-.827 2.331l-.017.002a3.642 3.642 0 0 1-1.782-.463.5.5 0 0 0-.377-.045l-1.387.387.387-1.386a.5.5 0 0 0-.044-.378A3.639 3.639 0 0 1 2 5.999a3.659 3.659 0 0 1 3.667-3.666zm0 3.152.348.848h-.694l.346-.848zm4.666.848A3.659 3.659 0 0 1 14 9.999c0 .649-.169 1.254-.463 1.782a.5.5 0 0 0-.046.378l.388 1.386-1.387-.387a.5.5 0 0 0-.377.045 3.642 3.642 0 0 1-1.782.463 3.656 3.656 0 0 1-3.658-3.492.5.5 0 0 0-.006-.123l-.002-.052a3.659 3.659 0 0 1 3.666-3.666zm0 1.333c-.914 0-1.666.752-1.666 1.667v1c0 .817.611 1.474 1.392 1.61.17.256.644.89 1.608.89a.5.5 0 1 0 0-1c-.148 0-.255-.102-.378-.175.419-.303.711-.773.711-1.325v-1c0-.915-.752-1.667-1.667-1.667zm0 1a.66.66 0 0 1 .667.667v1a.656.656 0 0 1-.68.662.657.657 0 0 1-.653-.662v-1a.66.66 0 0 1 .666-.667z" fill="#000"/>
    </svg> ),
    logout: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path fill="#242424" d="M3.625 2.5C2.175 2.5 1 3.676 1 5.125v5.25C1 11.825 2.176 13 3.625 13h2.297c.164 0 .328-.137.328-.328v-.656c0-.164-.164-.329-.328-.329H3.625c-.738 0-1.313-.574-1.313-1.312v-5.25c0-.71.575-1.313 1.313-1.313h2.297c.164 0 .328-.136.328-.328v-.656c0-.164-.164-.328-.328-.328H3.625zm6.316.547l-.546.547c-.137.11-.137.328 0 .465l3.09 2.98H5.702c-.191 0-.328.164-.328.328v.766c0 .191.137.328.328.328h6.781l-3.09 3.008c-.136.136-.136.355 0 .465l.547.546c.11.137.329.137.465 0l4.485-4.484c.136-.137.136-.328 0-.465l-4.485-4.484c-.136-.137-.355-.137-.465 0z" />
      </svg>
    )
  }
  return getCustomHeaderIcon[props.icon]
}