import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledInput = styled.input`
  width: 167px;
  height: 30px;
  padding: 4px 63px 4px 8px;
  border-radius: 4px;
  border: solid 1px #e0dede;
  background-color: #f3f3f3;
  color: #242424;
  font-weight: 600;
`;

export const ModalHeader = styled(Modal.Header)`
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
`;
export const Heading = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-bottom: 0;
`;
export const CustomTable = styled.table`
  margin: 20px;
  th,
  td {
    padding: 15px;
    border: 1px solid #e0dede;
  }
  td {
    width: 80%;
    opacity: ${({ showOpacity }) => (showOpacity ? 0.5 : 1)};
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
  }
  td.exams {
    opacity: 1;
    ul {
      opacity: 0.5;
    }
  }
  th {
    background-color: #f1f1f1;
    font-size: 14px;
    font-weight: 600;
    color: #464646;
  }
  h4 {
    text-decoration: underline;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    color: black;
    opacity: 1 !important;
  }
`;
export const Ul = styled.ul`
  margin-left: 12px;
  li {
    margin-top: 7px;
  }
`;

export const PopUpBtn = styled.div`
    display: flex;
    justify-content: end;
    padding: 0px 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`
