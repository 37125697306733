import React from "react";
import { Wrapper, Wrap } from "./styles";
import Header from "../../../share/organisms/headers/features-header";
import Container from "../../../share/atoms/container/index";
import Footer from "../../LandingPage/Footer/index";
import { useScrollTo } from "share/hooks/useScrollTo";

const TermsAndConditionsPage = (props) => {
  useScrollTo(0, 0);
  return (
    <div style={{ backgroundColor: "#f9f9ff" }}>
      {!props.isHome && <Header {...props} />}
      <Wrap>
        <Container>
          <Wrapper>
            <iframe
              width="100%"
              style={{ height: "100vh", border: "none" }}
              src="https://downloads.cnaps.co.in/privacy-policy/v1/terms-and-conditions-cnaps.html"
              title="terms"
            />
          </Wrapper>
        </Container>
      </Wrap>
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
