import * as types from "./types";

//proceed check
export const checkTestStatusRequest = () => ({
  type: types.CHECK_TEST_STATUS_REQUEST,
});

export const checkTestStatusSuccess = (payload) => ({
  type: types.CHECK_TEST_STATUS_SUCCESS,
  payload,
});

export const checkTestStatusError = (error) => ({
  type: types.CHECK_TEST_STATUS_ERROR,
  error,
});

//qp actions
export const getQuestionPaperRequest = (payload) => ({
  type: types.GET_QUESTION_PAPER_REQUEST,
  payload,
});

export const getQuestionPaperSuccess = (questionPaper) => ({
  type: types.GET_QUESTION_PAPER_SUCCESS,
  questionPaper,
});

export const getQuestionPaperError = (error) => ({
  type: types.GET_QUESTION_PAPER_ERROR,
  error,
});

export const currentQuestion = (payload) => ({
  type: types.CURRENT_QUESTION,
  payload,
});

export const setAnswers = (answers) => ({
  type: types.SET_ANSWERS,
  answers,
});

export const updateAnswer = (answer) => ({
  type: types.UPDATE_ANSWER,
  answer,
});

export const updateAnswerSuccess = (answers) => ({
  type: types.UPDATE_ANSWER_SUCCESS,
  answers,
});

// profile
export const profileRequest = (payload) => ({
  type: types.PROFILE_REQUEST,
  payload,
});

export const profileSuccess = (payload) => ({
  type: types.PROFILE_SUCCESS,
  payload,
});

export const profileError = (error) => ({
  type: types.PROFILE_ERROR,
  error,
});

// spent times
export const setSpentTimes = (times) => ({
  type: types.SET_SPENT_TIMES,
  times,
});

export const updateSpentTime = (time) => ({
  type: types.UPDATE_SPENT_TIME,
  time,
});

// count spent time
export const countSpentTime = (time) => ({
  type: types.COUNT_SPENT_TIME,
  time,
});

// previous answers api
export const getPreviousAnswersRequest = (payload) => ({
  type: types.GET_PREVIOUS_ANSWERS_REQUEST,
  payload,
});

export const getPreviousAnswersSuccess = (payload) => ({
  type: types.GET_PREVIOUS_ANSWERS_SUCCESS,
  payload,
});

export const getPreviousAnswersError = (error) => ({
  type: types.GET_PREVIOUS_ANSWERS_ERROR,
  error,
});

// sync answers api
export const syncAnswersRequest = (payload) => ({
  type: types.SYNC_ANSWERS_REQUEST,
  payload,
});

export const syncAnswersSuccess = (payload) => ({
  type: types.SYNC_ANSWERS_SUCCESS,
  payload,
});

export const syncAnswersError = (error) => ({
  type: types.SYNC_ANSWERS_ERROR,
  error,
});

// submit answers api
export const submitAnswersReset = (payload) => ({
  type: types.SUBMIT_DATA_RESET,
  payload,
});

export const submitAnswersRequest = (payload) => ({
  type: types.SUBMIT_ANSWERS_REQUEST,
  payload,
});

export const submitAnswersSuccess = (payload) => ({
  type: types.SUBMIT_ANSWERS_SUCCESS,
  payload,
});

export const submitAnswersError = (error) => ({
  type: types.SUBMIT_ANSWERS_ERROR,
  error,
});

// set current time

export const setCurrentTime = (payload) => ({
  type: types.SET_CURRENT_TIME,
  payload,
});

//get current time
export const getCurrentTime = () => ({
  type: types.GET_CURRENT_TIME,
});

export const updateSecQuesIds = (payload) => ({
  type: types.UPDATE_SECTION_QUESTION_IDS,
  payload,
});

// question drawer

export const toggeMenuDrawer = () => ({
  type: types.TOGGLE_QUESTIONS_DRAWER,
});

export const setImageProctorRequest = (payload) => ({
  type: types.SET_IMAGE_PROCTOR_REQUEST,
  payload,
});

export const setImageProctorSuccess = (payload) => ({
  type: types.SET_IMAGE_PROCTOR_SUCCESS,
  payload,
});

export const setImageProctorError = (error) => ({
  type: types.SET_IMAGE_PROCTOR_ERROR,
  error,
});

export const updateProctorEventRequest = (payload) => ({
  type: types.UPDATE_PROCTOR_EVENT_REQUEST,
  payload,
});

export const updateProctorEventSuccess = (payload) => ({
  type: types.UPDATE_PROCTOR_EVENT_SUCCESS,
  payload,
});

export const updateProctorEventError = (error) => ({
  type: types.UPDATE_PROCTOR_EVENT_ERROR,
  error,
});
// tab switched
export const tabSwitchedRequest = (payload) => ({
  type: types.TAB_SWITCHED_REQUEST,
  payload,
});
export const tabSwitchedSuccess = (payload) => ({
  type: types.TAB_SWITCHED_SUCCESS,
  payload,
});
export const tabSwitchedError = (payload) => ({
  type: types.TAB_SWITCHED_ERROR,
  payload,
});

//audio detected

export const audioDetectedRequest = (payload) => ({
  type: types.AUDIO_PROCTORING_REQUEST,
  payload,
});

export const audioDetectedSuccess = (payload) => ({
  type: types.AUDIO_PROCTORING_SUCCESS,
  payload,
});

export const audioDetectedError = (payload) => ({
  type: types.AUDIO_PROCTORING_ERROR,
  payload,
});

export const getSigneUrlRequest = (payload) => ({
  type: types.GET_SIGNED_URL_REQUEST,
  payload,
});

export const getSigneUrlSuccess = (payload) => ({
  type: types.GET_SIGNED_URL_SUCCESS,
  payload,
});

export const getSigneUrlError = (payload) => ({
  type: types.GET_SIGNED_URL_ERROR,
  payload,
});
