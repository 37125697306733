import styled, {css} from "styled-components";

export const MainWrapper = styled.div`
position: relative;
& .new-icon{
  /* height: 30px;
  object-fit: contain; */
 position: absolute;
 z-index:1;
  top:-5px;
  left:10px;
}
.fifty-off{
  position: absolute;
 z-index:1;
  top:-5px;
  right:40px;
}
.curve-line{
  position: absolute;
  z-index:1;
  top:14px;
  right:25px;
}

.fifty-off-with-arc{
  position: absolute;
   z-index:1;
  top:-5px;
  right:25px;
}
`
export const Card = styled.div`
  display: flex;
  flex-basis: 32%;
  flex-flow: column;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
  padding: 10px 20px 32px 20px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  align-items: stretch;
  &:hover{
    box-shadow: 0 20px 20px -20px rgba(36, 36, 36, 0.25);
  }
  ${(props) =>
    props.isHome &&
    css`
      max-width: 458px;
    `}
  @media (min-width: 768px) and (max-width: 1023px)  and (orientation: portrait) {
    min-width: 320px;
    margin: 15px auto;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CardBody = styled.div`
  display: none;
  position: relative;
  flex-flow: column;
  margin-top: 26px;
  ${(props) =>
    props.isHome &&
    css`
      display: flex;
    `}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  img {
    width: 164px;
    height: 84px;
  }
  @media screen and (max-width: 600px) {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    img {
      width: 164px;
      height: 84px;
      /* margin-bottom: 10px; */
    }
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #18a0fb;
  margin-top: 20px;
  cursor: pointer;
  svg {
    margin-left: 15px;
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 0px;
    svg {
      margin-left: 15px;
      font-size: 18px;
    }
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  text-align: center;
  /* flex-flow: column; */
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    width: 110%;
  }
  ${(props) =>
    !props.isHome &&
    css`
      text-align: center;
    `}
`;
