import React from "react";
import { Select } from "antd";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
const { Option } = Select;

export default function MultiSelect({
  data,
  optionClassName,
  value,
  field,
  onChange,
  disabled,
  hasDuplicateOptions,
  ...rest
}) {

  const getValue = () => {
    if (!value) return [];
    let arr = [];
       // eslint-disable-next-line array-callback-return
    value.map((item) => {
      if (item !== "all") arr.push(item);
    });
    return arr;
  };
  const uniqueOptions=data
  return (
    <div>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        optionLabelProp="label"
        maxTagCount="responsive"
        dropdownClassName="multi-select-drop"
        getPopupContainer={(trigger) => trigger.parentNode}
        value={getValue()}
        onChange={(e) => onChange(e, uniqueOptions, field)}
        {...rest}
      >
        <Option className={optionClassName} value="all" label="Select All" disabled={disabled}>
          <div className="demo-option-label-item">
            <span role="img" aria-label="Select All">
              {value.includes("all") ? <FaCheckSquare /> : <FaRegSquare />}
            </span>
            &nbsp;&nbsp;Select All
          </div>
        </Option>
        {uniqueOptions &&
          uniqueOptions.map((opt,index) => (
            <Option
            key={`${opt.value}~${opt.label}-${index}`}
              className={optionClassName}
              value={`${opt.value}`}
              label={opt.label}
            >
              <div className="demo-option-label-item">
                <span role="img" aria-label={opt.label}>
                  {value.includes(`${opt.value}`) ? (
                    <FaCheckSquare />
                  ) : (
                    <FaRegSquare />
                  )}
                </span>
                &nbsp;&nbsp;
                {opt.label}
              </div>
            </Option>
          ))}
      </Select>
    </div>
  );
}
