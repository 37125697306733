/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import history from "utils/history";

import testReducer, {
  spentTimesReducer,
  currentQuestionReducer,
  sectionQuesIds,
  answersReducer,
} from "containers/test/reducers";
import resultReducer from "containers/result/reducers";
import answerKeyReducer from "containers/answer-key/reducer";
import instructionsReducer from "containers/instructions/reducers";
import appReducer from "../../reducer";
import { examDetailsReducer } from "containers/instructions/reducers";
import DivisionalAcctReducer from "school-admin/DivisionalAccntDashboard/reducer";
import StudentListReducer from "school-admin/StudentList/reducer";
import RegistrationReducer from "school-admin/Registration/reducer";
import NewRegistrationReducer from "school-admin/NewRegistration/reducer";
import loginReducer from "school-admin/Login/reducer";
import ForgotPasswordReducer from "school-admin/ForgotPassword/reducer";
import studentLoginReducer from "student/Login/reducer";
import studentForgotPasswordReducer from "student/ForgotPassword/reducer";
import SchoolRegistrationReducer from "student/Registration/reducer";
import StudentPaymnentReceiptReducer from "student/PaymenReceipt/reducers";

import proctorReducer from "containers/proctoring/reducers";

//student reducers
import ExamSelectionReducer from "student/ExamsSelection/reducers";
//monitor
import monitorLoginReducer from "monitoring/Login/reducer";
import backdropReducer from "share/molecules/backdrop/reducer";
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: {},
    test: testReducer,
    appReducer,
    spentTimesReducer,
    answersReducer,
    sectionQuesIds,
    currentQuestion: currentQuestionReducer,
    result: resultReducer,
    answerKey: answerKeyReducer,
    instructions: instructionsReducer,
    examDetails: examDetailsReducer,
    divisionalAccountant: DivisionalAcctReducer,
    studentList: StudentListReducer,
    register: RegistrationReducer,
    studentRegister: SchoolRegistrationReducer,
    newRegistration: NewRegistrationReducer,
    forgotPwd: ForgotPasswordReducer,
    login: loginReducer,
    studentLogin: studentLoginReducer,
    studentForgotPwd: studentForgotPasswordReducer,
    ExamSelectionReducer,
    router: connectRouter(history),
    studentPaymentReceipt: StudentPaymnentReceiptReducer,
    monitorLogin: monitorLoginReducer,
    backDrop: backdropReducer,
    proctor: proctorReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
