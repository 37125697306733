import React from 'react'
import { TableContent } from './styles'

function BodyItem({ href, children, ...rest }) {
    return (
        <TableContent {...rest}>
            {
                href ?
                    <a href={href} target='_blank'>
                        {children}
                    </a> :
                    children
            }
        </TableContent>
    )
}

export default BodyItem