import React from 'react';
import PropTypes from 'prop-types'
import {
  StyledWrapper,
  Wrapper,
  TestDetails,
  Description,
  IconWrapper,
} from './styles';

const SidebarItems = ({ item }) => {
  return (
    <StyledWrapper>
      <IconWrapper>{item.icon}</IconWrapper>
      <Wrapper>
        <TestDetails>{item.testDetails}</TestDetails>
        <Description>{item.description}</Description>
      </Wrapper>
    </StyledWrapper>
  );
};

export default SidebarItems;

SidebarItems.propTypes={
  item:PropTypes.object
}