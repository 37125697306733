import React from "react";
import {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  H2,
  Button,
} from "./styles";

const Modal = ({
  open,
  handleClose,
  body,
  centered,
  size,
  onSubmit,
  btnSet,
  children,
  header,
  headerText,
  footer,
  ...rest
}) => {
  const closeModal = () => {
    handleClose();
  };
  const handleSubmit = () => {
    onSubmit();
  };
  const {
    inLoading: okLoading,
    label: okLabel,
    type: okType,
    show: showOk,
  } = btnSet.ok;
  const {
    inLoading: cancelLoading,
    label: cancelLabel,
    type: canelType,
    show: showCancel,
  } = btnSet.cancel;
  return (
    <>
      {open && (
        <ModalContainer {...rest} centered={centered}>
          <ModalContent size={size} {...rest}>
            {children ? (
              children
            ) : (
              <>
                {header ? (
                  header
                ) : (
                  <ModalHeader>
                    <H2>{headerText}</H2>
                  </ModalHeader>
                )}

                <ModalBody>{body}</ModalBody>
                {footer ? (
                  footer
                ) : (
                  <ModalFooter>
                    {showOk && (
                      <Button
                        variant={okType}
                        disabled={okLoading}
                        onClick={handleSubmit}
                      >
                        {okLabel ? okLabel : "Yes"}
                      </Button>
                    )}
                    {showCancel && (
                      <Button
                        variant={canelType}
                        size={size}
                        disabled={cancelLoading}
                        onClick={closeModal}
                      >
                        {cancelLabel ? cancelLabel : "Cancel"}
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </>
            )}
          </ModalContent>
        </ModalContainer>
      )}
    </>
  );
};
Modal.defaultProps = {
  open: false,
  handleClose: () => {},
  onSubmit: () => {},
  size: "sm",
  btnSet: {
    ok: {
      show: true,
      type: "outline",
    },
    cancel: {
      show: true,
      type: "primary",
    },
  },
  headerText: "Header",
};
export default Modal;

export {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  H2 as ModalH2,
  Button as ModalButton,
};
