import { put, call, all, takeLatest ,delay} from "redux-saga/effects";
import { backdropRequest } from "share/molecules/backdrop/actions";
import * as actionTypes from "./actionTypes";
import MonitiorTestTakers from "./services";

function* getStudentListForMonitorWorker(action) {
    try {
      const res = yield call(
        MonitiorTestTakers.getStudentListMonitorApi,
        action.payload
      );
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_SUCCESS,
          payload: res.data.data,
        });
        // yield call(getNewMetadata(delivery_id));
      } else {
        yield put({
          type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_FAIL,
          payload: "something went wrong",
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_FAIL,
        payload: e.message,
      });
    }
  }


  const getRefreshMonitorData = (data, delivery,index) => {
    if (!delivery || delivery.length === 0) return [];
    const updatedData = [...data];
    updatedData[index] = { ...data[index], ...delivery };
    return updatedData;
  };
  
  function* getStudentDetailsForMonitorWorker(action) {
    try {
      const { admission_number, tableData, delivery_id,index } = action.payload;
      yield put(backdropRequest(true));
      const res = yield call(MonitiorTestTakers.getStudentDetailsMonitorApi, {
        admission_number,
        delivery_id,
      });
      yield put(backdropRequest(false));
      if (res.data.status === "success") {
        if (tableData && res.data.data.students_list.length > 0) {
          const mData = yield getRefreshMonitorData(
            tableData,
            res?.data?.data?.students_list[0],
            index
          );
          yield delay(5)
          yield put({
            type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_SUCCESS,
            payload: { students_list: mData, pagination: 1 },
          });
        } else if (tableData && res.data.data.students_list.length === 0) {
          const updatedData = tableData.filter(
            (item) => item.admission_number !== admission_number
          );
          yield put({
            type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_SUCCESS,
            payload: { students_list: updatedData, pagination: 0 },
          });
        }
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_FAIL,
        payload: e.message,
      });
    }
  }

export default function* () {
    yield takeLatest(actionTypes.GET_STUDENT_LIST_FOR_MONITOR_REQUEST, getStudentListForMonitorWorker);
    yield takeLatest(
      actionTypes.GET_STUDENT_DETAILS_FOR_MONITOR_REQUEST,
      getStudentDetailsForMonitorWorker
    )
  }