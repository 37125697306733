
/**
 * this function returns the array of values
 * @param {Array} res
 * @returns {Array} return array of values
 */

export const getExamColumns = (res) => {
  if (!res || !res.registration_summary) return [];
  let ob = {};
     // eslint-disable-next-line array-callback-return
  res.registration_summary.map((item) => {
       // eslint-disable-next-line array-callback-return
    item.exams_list.map((exm) => {
      ob[exm.exam_name] = {
        title: exm.exam_name,
        dataIndex: exm.exam_name,
        key: exm.exam_name,
        width: 100,
      };
    });
  });
  return Object.values(ob);
};

/**
 * this function returns the array of values
 * @param {Array} res
 * @returns {Array} return array of values
 */
export const getExamCols = (res) => {
  if (!res || !res) return [];
  let ob = {};
  res.map((item) => {
    item.exams_list.map((exm) => {
      ob[exm.exam_name] = {
        title: exm.exam_name,
        dataIndex: exm.exam_name,
        key: exm.exam_name,
        width: 70,
        render:(row)=>{
          return row==="NA"?<p style={{color:"red",fontFamily:"Poppins"}}>{row}</p>:<p style={{color:"green",fontFamily:"Poppins"}}>{row}</p>
        }
      };
    });
  });
  return Object.values(ob);
};


/**
 * this function takes the array and return obj
 * @param {Array} exam_list
 * @returns {Object} return object
 */
export const getExamValues = (exam_list) => {
  if (!exam_list) return {};
  let ob = {};
  exam_list.map((exm) => {
    ob[exm.exam_name] = exm.isSelected&&exm.is_approved===false ? "NA" :exm.isSelected? "Yes":"";
  });
  return ob;
};
