import { getFullTime } from "utils/time/getFullTime";
import RefreshIconTitle from "./refresh-icon-title";
import ActionButtons from "./student-monitor-actions";
import RefreshIconBody from './refresh-icon-body'
import {Paragraph} from './styles'

export const dataSource = [
  {
    absent_status: true,
    admission_number: "1725163",
    auto_submit_time: null,
    batch_name: "CIO",
    class_name: "12TH STD",
    college_name: "VSP-SPARK",
    delivery_id: "SH22IfuCQv",
    delivery_uri: "http://tao.gcf.education/gcf.rdf#16602009456751886396",
    end_date_time: null,
    exam_status: "not_started",
    last_sync: 0,
    platform: "",
    registration_status_name: "admission",
    section_name: "300",
    start_date_time: null,
    state_name: "ANDHRA PRADESH",
    student_name: "VIVEK VARDHAN",
    subbatch_name: "STAR SUPER CHAINA(A)",
    time_left: 0,
    year_name: "2021-2022",
  },
  {
    absent_status: true,
    admission_number: "16",
    auto_submit_time: null,
    batch_name: "CIO",
    class_name: "11TH STD",
    college_name: "VSP-SPARK",
    delivery_id: "SH22IfuCQv",
    delivery_uri: "http://tao.gcf.education/gcf.rdf#16602009456751886396",
    end_date_time: null,
    exam_status: "not_started",
    last_sync: 0,
    platform: "",
    registration_status_name: "admission",
    section_name: "300",
    start_date_time: null,
    state_name: "ANDHRA PRADESH",
    student_name: "VIVEK VARDHAN",
    subbatch_name: "STAR SUPER CHAINA(A)",
    time_left: 0,
    year_name: "2021-2022",
  },
];
 export const appendStd = (row)=>{
    if(parseInt(row)===1) return <>{row}<sup>st</sup> STD</>
     else if(parseInt(row)===2) return <>{row}<sup>nd</sup> STD</>
     else if(parseInt(row)===3) return <>{row}<sup>rd</sup> STD</>
     else return <>{row}<sup>th</sup> STD</>
 }


export const columns = (FilterByNameInput,innerWidth,setShowOpen,setPageDetails)=>([
  {
    title:<RefreshIconTitle   setPageDetails={setPageDetails}/>,
    fixed: innerWidth<768?"":"left",
    width:50,
    render:(row)=>{
      return(
        <RefreshIconBody row={row} setShowOpen={setShowOpen} />
      )
    },
  },
  {
    title: FilterByNameInput,
    dataIndex: "admission_number",
    key: "admission_number",
    width:180,
    fixed: innerWidth<768?"":"left",

  },
  {
    title: "student Name",
    dataIndex: "student_name",
    key: "student_name",
    width:150,
    fixed: innerWidth<768?"":"left",
  },

  {
    title: "Class",
    dataIndex: "class_name",
    key: "class_name",
    width:120,
    sorter: (a, b) => Number(a.class_name)-Number(b.class_name),
    render:(row)=>appendStd(row)

  },

  {
    title: "Exam Status",
    dataIndex: "exam_status",
    key: "exam_status",
    width:150,
    sorter: (a, b) => a.exam_status.localeCompare(b.exam_status),
  },

  {
    title: "Branch",
    dataIndex: "branch",
    key: "branch",
    width:200,
    sorter: (a, b) => a.branch.localeCompare(b.branch),
  },
  {
    title: "Sub-Batch",
    dataIndex: "subbatch_name",
    key: "subbatch_name",
    width:150,
    sorter: (a, b) => a.subbatch_name.localeCompare(b.subbatch_name),
  },

  {
    title: <><Paragraph>StartDateTime</Paragraph> <Paragraph> (DD-MM-YY) (HH:MM)</Paragraph> </>,
    dataIndex: "start_date_time",
    key: "start_date_time",
    width:220,
    render:(row)=>{
      const {YY, MM,D, HRS, MIN,type } = getFullTime(row)
      return row? `(${D}-${MM}-${YY}) ${HRS}:${MIN} ${type}`:<p style={{textAlign:"center"}}>--</p>
    }
  },

  {
    title: <><Paragraph>End DateTime</Paragraph> <Paragraph>(DD-MM-YY) (HH:MM)</Paragraph> </>,
    dataIndex: "end_date_time",
    key: "end_date_time",
    width:180,
    render:(row)=>{
      const {YY, MM,D, HRS, MIN,type } = getFullTime(row)
      return row? `(${D}-${MM}-${YY}) ${HRS}:${MIN} ${type}`:<p style={{textAlign:"center"}}>--</p>
    }
  },

  {
    title: <><Paragraph>Auto Submit Time</Paragraph> <Paragraph> (HH:MM)</Paragraph> </>,
    dataIndex: "auto_submit_time",
    key: "auto_submit_time",
    width:160,
    render:(row)=>{
      const {YY, M,D, HRS, MIN,type } = getFullTime(row)
      return row? `${HRS}:${MIN} ${type}`:<p style={{textAlign:"center"}}>--</p>
    }
  },

  {
    title: "Time left (mins)",
    dataIndex: "time_left",
    key: "time_left",
    width:160,
  },
  {
    title: "Last Sync (Mins)",
    dataIndex: "last_sync",
    key: "last_sync",
    width:160,
  },

  {
    title: "Platform",
    dataindex: "platform",
    key: "platform",
    width:150,
  },
  {
    title: 'Actions',
    dataIndex: '',
    key: 'x',
    width:250,
    render: (row) => {
     return <ActionButtons  row={row}/>
    },
  }

]);
