import styled from "styled-components";

export const Count = styled.div`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #242424;
`;

export const CountWithSymbol = styled(Count)`
  &::before {
    content: "₹";
    font-size: 30px;
    padding-right: 8px;
    font-family: sans-serif;
  }
`;

export const Wrap = styled.div`
  label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 8px;
  }
  &.border-left {
    border-left: 1px solid #999999;
    padding-left: 16px;
  }
`;

export const TotalCounts = ({ students, exams }) => {
  return (
    <div className="d-flex">
      <Wrap style={{ width: "50%" }}>
        <label>Students</label>
        <Count>{students}</Count>
      </Wrap>
      <Wrap className="border-left">
        <label>Exams</label>
        <Count>{exams}</Count>
      </Wrap>
    </div>
  );
};

export const CardWrap = styled.div`
  width: 100%;
  min-height: 158px;
  border-radius: 6px;
  margin-top: 24px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  & h2:first-child:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
  }
  .title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.37px;
    text-align: left;
    color: #464646;
    padding-right: 4px;
  }
`;

export const IconWrap = styled.div`
  font-weight: bold;
  vertical-align: "middle";
  width: 20px;
  height: 20px;
  color: #d71a63;
  cursor: ${({ cursor }) => (cursor ? cursor : "pointer")};
`;
