import styled from "styled-components";
import dots from "assets/landingpage/cnaps-legacy/3_3dots.svg";
import MoreDots from "assets/landingpage/cnaps-legacy/5_3dots.svg";
import spikes from "assets/landingpage/cnaps-legacy/spikes.svg";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;
export const Dots = styled.div`
  position: absolute;
  z-index: 1;
  background: url(${dots}), url(${MoreDots}), url(${spikes});
  background-position: top left, bottom right, bottom left;
  background-repeat: no-repeat;
  width: 110%;
  height: 323px;
  top: 10px;
  left: -43px;
  @media screen and (max-width: 768px) {
    left: -2px;
    width: 100%;
    height: 320px;
  }
  @media screen and (max-width: 430px) {
    left: 8px;
    width: 100%;
    height: 430px;
  }
  @media screen and (max-width: 425px) {
    left: 45px;
    width: 84%;
    height: 420px;
  }
  @media screen and (max-width: 375px) {
    left: 10px;
    width: 100%;
    height: 420px;
  }
  @media screen and (max-width: 360px) {
    left: 14px;
  }
  @media screen and (max-width: 390px) {
    width: 100%;
    height: 430px;
    left: 5px;
  }
  @media screen and (max-width: 1280px) {
    left: 0px;
    width: 105%;
    height: 330px;
  }
  @media screen and (max-width: 1268px) {
    left: 0px;
    width: 103%;
    height: 330px;
  }
  @media screen and (max-width: 1198px) {
    left: 0px;
    width: 100%;
    height: 330px;
  }
  @media screen and (max-width: 703px) {
    left: 42px;
    width: 88%;
    height: 580px;
  }
  @media screen and (max-width: 540px) {
    left: 0px;
    width: 100%;
    height: 439px;
  }
  @media screen and (max-width: 360px) {
    left: 10px;
    width: 100%;
    height: 439px;
  }
  @media screen and (width: 280px) {
    display: none;
  }
`;
export const Wrap = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: clamp(1.5rem, 0.4268rem + 5.3659vw, 4.25rem);
  padding-bottom: clamp(1.25rem, -0.311rem + 7.8049vw, 5.25rem);

  h4 {
    span {
      font-family: Poppins;
      font-size: 44px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #242424;
    }
  }
  h4 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    .cnaps {
      color: #d71a63;
      font-weight: bold;
      font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20 to 36
      margin-right: 12px;
    }
    .legacy {
      color: #242424;
      font-weight: bold;
      font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20 to 36
    }
  }
  @media screen and (max-width: 574px) {
    padding-bottom: 24px;
  }
  @media screen and (width: 1920px) {
    padding-bottom: 100px;
  }
  @media screen and (min-width: 200px) and (max-width: 800px) {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    margin-bottom: 20px;
  }
`;
export const Description = styled.div`
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
`;
export const CardsWrap = styled.div`
  margin-top: 40px;
  display: flex;
  position: relative;
  top: 15px;
  gap: 24px;
  @media screen and (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (max-width: 1240px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    left: -7px;
  }
  @media screen and (max-width: 1270px) {
    left: 25px;
  }
  @media screen and (max-width: 1198px) {
    left: -7px;
  }
  @media screen and (max-width: 950px) {
    margin-bottom: 24px;
  }
`;
export const StyledCard = styled.div`
  width: 267px;
  height: 236px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #fff;
  position: relative;
  z-index: 99;
  h2 {
    color: #d71a63;
    font-size: clamp(1rem, 0.9024rem + 0.4878vw, 1.25rem);
    margin-top: 32px;
    text-align: center;
    font-weight: 600;
  }
  p {
    margin-top: 8px;
    font-family: Poppins;
    font-size: clamp(0.75rem, 0.6524rem + 0.4878vw, 1rem);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
  }
  @media screen and (max-width: 320px) {
    width: 144.1px;
    height: 168px;
    h2 {
      margin-top: 16px;
    }
    p {
      margin-bottom: 24px;

      padding: 0 10px;
    }
    img {
      margin-top: 24px;
      width: 48px;
      height: 48px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 144.1px;
  }
  @media screen and (max-width: 320px) {
    width: 100px;
  }

  @media screen and (max-width: 1024px) {
    width: 220px;
    height: 236px;
  }
  @media screen and (width: 1920px) {
    width: 370px;
    height: 238px;
  }
  @media screen and (max-width: 1283px) {
    width: 267px;
    height: 236px;
  }

  @media screen and (max-width: 1239px) {
    width: 260px;
    height: 236px;
  }
  @media screen and (max-width: 1198px) {
    width: 243px;
    height: 236px;
  }
  @media screen and (max-width: 1075px) {
    width: 220px;
    height: 236px;
  }
  @media screen and (max-width: 1004px) {
    width: 198px;
    height: 236px;
  }
  @media screen and (max-width: 912px) {
    width: 170px;
    height: 236px;
  }
  @media screen and (max-width: 823px) {
    width: 160px;
    height: 236px;
  }
  @media screen and (max-width: 736px) {
    width: 140px;
    height: 236px;
  }
  @media screen and (max-width: 703px) {
    width: 267px;
    height: 236px;
  }
  @media screen and (max-width: 540px) {
    width: 230px;
    height: 168px;
    padding: 16px;
  }
  @media screen and (max-width: 515px) {
    width: 215px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 485px) {
    width: 200px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 455px) {
    width: 190px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 435px) {
    width: 175px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 425px) {
    width: 170px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 393px) {
    width: 154px;
    height: 168px;
    padding: 0 16px;
  }
  @media screen and (max-width: 375px) {
    width: 150px;
    height: 168px;
    padding: 16px;
  }
  @media screen and (max-width: 360px) {
    width: 144.1px;
    height: 168px;
    padding: 16px;
  }

  @media screen and (width: 414pxpx) {
    width: 144.1px;
    height: 168px;
    padding: 0 16px;
  }
`;
