import { loginTypes } from './constants';

//school admin login actions
export const loginRequest = payload => ({
  type: loginTypes.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = payload => ({
  type: loginTypes.LOGIN_SUCCESS,
  payload,
});
export const loginError = error => ({
  type: loginTypes.LOGIN_ERROR,
  error,
});

export const resetError = (error) => ({
  type: loginTypes.RESET_ERROR,
  error,
});

//school admin logout actions
export const logoutRequest = (payload) => ({
  type: loginTypes.LOGOUT_REQUEST,
  payload,
});
export const logoutSuccess = (payload) => ({
  type: loginTypes.LOGOUT_SUCCESS,
  payload,
});
export const logoutError = (error) => ({
  type: loginTypes.LOGOUT_ERROR,
  error,
});

//school admin profile actions
export const profileRequest = (payload) => ({
  type: loginTypes.PROFILE_REQUEST,
  payload,
});
export const profileSuccess = (payload) => ({
  type: loginTypes.PROFILE_SUCCESS,
  payload,
});
export const profileError = (error) => ({
  type: loginTypes.PROFILE_ERROR,
  error,
});
