import React from "react";
import { Wrapper, Heading } from "./styles";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import WithoutSidepanelLayout from "layouts/WithoutSidepanelLayout";
import { getLocalObj } from "utils/utility/local-storage";

const DropdownOptionTemp = ({ children, headingText }) => {
  const tokenData = getLocalObj("token-data");
  const loginType = localStorage.getItem("login-type");
  const getLayout = (data) => {
    if (
      (loginType === "student" && tokenData.is_exams_purchased) ||
      loginType !== "student"
    ) {
      return <WithSidepanelLayout>{data}</WithSidepanelLayout>;
    }
    return <WithoutSidepanelLayout>{data}</WithoutSidepanelLayout>;
  };
  return getLayout(
    <Wrapper>
      <Heading>{headingText}</Heading>
      {children}
    </Wrapper>
  );
};

export default DropdownOptionTemp;
