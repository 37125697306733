import request from "utils/axios/service";
import { endpoints } from "./constants";

class AccountDeletionService {
  async postLoginStudent(req) {
    return await request.post(endpoints.LOGIN_STUDENT, req);
  }
  async postSendOTP(req) {
    return await request.post(endpoints.SEND_OTP, req);
  }
  async postValidateOTP(req) {
    return await request.post(endpoints.VALIDATE_OTP, req);
  }
  async deleteStudent(req) {
    return await request.delete(endpoints.REMOVE_ACCOUNT, req);
  }
}

export default AccountDeletionService;
