import querystring from "query-string";
import { http } from "./axiosInstance";
const { REACT_APP_SERVER_URL, REACT_APP_CDN_SERVER_URL } = process.env;

function apiRequest(props) {
  const { url, init, query, option } = props;
  const strQuery = query ? `?${querystring.stringify(query)}` : "";
  // let API_ENDPOINT = REACT_APP_SERVER_URL;
  let API_ENDPOINT = null;
  if (url.includes("scheduled/download"))
    API_ENDPOINT = REACT_APP_CDN_SERVER_URL;
  else API_ENDPOINT = REACT_APP_SERVER_URL;
  const fetchUrl = `${API_ENDPOINT}/${url}${strQuery}`;
  if (init.method === "POST") {
    return http.post(fetchUrl, option);
  }
  if (init.method === "PATCH") {
    return http.patch(fetchUrl, option);
  }
  if (init.method === "DELETE") {
    return http.delete(fetchUrl, option);
  }
  return http.get(fetchUrl, { params: option });
}

const request = {
  get: (url, option) =>
    apiRequest({
      url,
      init: {
        method: "GET",
      },
      option,
    }),

  delete: (url, option) =>
    apiRequest({
      url,
      init: {
        method: "DELETE",
      },
      option,
    }),

  post: (url, option) =>
    apiRequest({
      url,
      init: {
        method: "POST",
      },
      option,
    }),
  patch: (url, option) =>
    apiRequest({
      url,
      init: {
        method: "PATCH",
      },
      option,
    }),
};

export default request;
