import React, { useEffect, useState } from "react";
import RegistrationTemp from "share/templates/RegistrationTemp";
import ReceiptIcon from "assets/file-text.svg";
import CongragulationIcon from "assets/congragulationicon.svg";
import DownloadIcon from "assets/school-admin/icons/download.svg";
import AntdTable from "share/organisms/table/antd-table";
import { useDispatch } from "react-redux";
import { downloadPdf } from "../helper";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "utils/utility/url";
import { getColumns, getTableDataSource } from "school-admin/StudentList/utils";
import { getStudentListRequest } from "../actions";
import {
  CongraguPoppinsnCard,
  DownloadReceiptWrap,
  CongratsLogoWrap,
  HeadingWrap,
} from "./styles";
import { downloadExcelSheet } from "school-admin/StudentList/actions";
import saga from "./saga";
import injectSaga from "utils/saga/injectSaga";

const key = "congragulationScreen";
const CongragulationScreen = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const [tableData, setTableData] = useState(false);
  const groupId = getQueryParam("groupId", location);
  const admsnNumber = getQueryParam("admissionNumber", location);

  const studentListCallback = (data) => {
    let columns = getColumns(data.students_list);
    columns = columns.filter((col) => col.title !== "Action");
    const dataSource = getTableDataSource(data.students_list);
    setTableData({ columns, dataSource, pdfUrl: data.payment_receipt_url });
  };

  useEffect(async () => {
    const reqBody = { group_id: groupId };
    setData(reqBody);
    dispatch(
      getStudentListRequest({
        admsnNumber,
        groupId,
        callback: studentListCallback,
      })
    );
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const downloadExcelFile = () => {
    let reqBody = { group_id: groupId };
    if (admsnNumber) reqBody = { admission_number: admsnNumber };
    dispatch(downloadExcelSheet(reqBody));
  };

  return (
    <RegistrationTemp>
      <CongraguPoppinsnCard>
        <DownloadReceiptWrap onClick={() => downloadPdf(tableData.pdfUrl)}>
          <img className="receipt-icon" src={ReceiptIcon} alt="receipt"></img>
          <p className="receipt-text">Download Receipt</p>
        </DownloadReceiptWrap>
        <CongratsLogoWrap>
          <img className="congrats-icon" src={CongragulationIcon} alt="congrats" />
          <h3 className="congrats-text">Congratulations</h3>
          <p className="confirm-text">Confirm your Payment </p>
        </CongratsLogoWrap>
      </CongraguPoppinsnCard>
      {tableData && (
        <React.Fragment>
          <HeadingWrap>
            <div className="title">Registered Students list </div>
            <div className="download-link" onClick={downloadExcelFile}>
              <img src={DownloadIcon} alt=" " />
              Download Excel List
            </div>
          </HeadingWrap>
          <CongraguPoppinsnCard className="mt-2">
            <AntdTable
              className="p-4"
              columns={tableData.columns}
              dataSource={tableData.dataSource}
              padding={"8px 4px 8px 4px"}
            />
          </CongraguPoppinsnCard>
        </React.Fragment>
      )}
    </RegistrationTemp>
  );
};

const withSaga = injectSaga({ key, saga });

export default withSaga(CongragulationScreen);
