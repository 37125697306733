import React from 'react'
import {ChipWrapper,Wrap} from './styles'
import RefreshIcon from 'assets/monitoring/refresh-icon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { refreshDeliveryRequest } from './actions/refresh-delivery';

const Chip = ({label,color,bgColor,delivery_id,index}) => {
  const { data, filtersData } = useSelector(({delivery}) => delivery.deliveryList);
  const dispatch = useDispatch();
  const handleRefresh = () => {
    dispatch(
      refreshDeliveryRequest({
        delivery_id: delivery_id,
        tableData: data,
        filtersData,
        index : index
      })
    );
  };
    return (
         <Wrap>
          <img src={RefreshIcon} alt="refresh-icon" className="refresh-icon" title="refresh" onClick={handleRefresh}/>
        <ChipWrapper color={color} bgColor={bgColor}>
          {label}
        </ChipWrapper>
        </Wrap>
    )
}

export default Chip
