import styled,{css} from "styled-components";
import Container from "share/atoms/container";

export const Styles = styled.div`
  width:100%;
  height: 72px;
  margin: 0 0 32px;
  padding: 12px 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  /* position:sticky; */
  /* top:0; */
  z-index:9999;
  ${({isNotCommonLayout})=>isNotCommonLayout && css`
     margin: 0;
  `};
  
`;


export const StyledContainer=styled(Container)`
display:flex;
justify-content:space-between;
`

export const LogoIcon=styled.img`
cursor:pointer;
`

export const BreadCrumbsContainer=styled(Container)`
margin:20px auto;

`