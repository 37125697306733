export const proctotInstructions = [
  "Candidature for ranking",
  "Qualification for certification",
  "Qualification for rewards",
];

export const withoutSidePanelStyles = {
  backgroundColor: "#f9f9ff",
  height: "100vh",
  padding: "0px 0p 100px 0px",
};

export const commonStyles = {
  //*width : height = 1 : 0.75
  width: "360px",
  height: "270px",
  borderRadius: "8px",
  backgroundColor: "#464646",
  position:'relative'
};


