import React, { useRef, useEffect } from "react";
import HomeHeader from "share/organisms/headers/landing-header";
import Container from "share/atoms/container/";
import { useHistory } from "react-router-dom";
import { useScroll } from "share/hooks";
import styled from "styled-components";
import Exams from "./Exams";
import Explore from "./Explore";
import WhyChooseUs from "./WhyChooseUs";
import AvailablePlatforms from "./AvailablePlatforms";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import HeroScreen from "share/organisms/hero";
import Legacy from "./Legacy";
import Stats from "school-admin/Landing/Stats";
import { useScrollTo } from "share/hooks/useScrollTo";
import { SchoolLandingLayout } from "school-admin/Landing/styles";
import AwardCeremony from "containers/award-ceremony";

const StyledDiv = styled.div`
  /* @media screen and (max-width:600px){
  margin-bottom:60px;
} */
`;
function LandingHome(props) {
  useScrollTo(0, 0);
  const history = useHistory();
  const examsSection = useRef(null);
  const [exploreSection, scrollToExplore] = useScroll();
  const [contactUsSection, scrollToContactus] = useScroll();
  const [platformsSection, scrollToPlatform] = useScroll();
  const [awardSection,scrollToAward] = useScroll();
  // const pathName = history.location.pathname;

  useEffect(() => {
    const pathName = history.location.pathname;
    if (pathName === "/explore") {
      scrollToExplore();
    } else if (pathName === "/contact-us") {
      scrollToContactus();
    } else if (pathName === "/available-platforms") {
      scrollToPlatform();
    }
  }, [history.location]); // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToSection = (view) => {
    window.scrollTo({ top: view.current.getBoundingClientRect().top - 128 });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroScreen scroll={() => scrollToSection(examsSection)} {...props} />
      <section ref={examsSection}>
        <Exams {...props} isHome />
      </section>
      <StyledDiv ref={exploreSection} />
      <Explore />
      <StyledDiv ref={awardSection} />
      <AwardCeremony   />
      <WhyChooseUs ref={platformsSection} />
      <SchoolLandingLayout style={{ backgroundColor: "#ffffff" }}>
        <Stats />
      </SchoolLandingLayout>

      <StyledDiv ref={platformsSection} />
    
      <AvailablePlatforms />
      <Footer />
    </div>
  );
}

export default LandingHome;
