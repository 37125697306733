import styled from "styled-components";

export const FooterWrapper = styled.div`
  background-color: #182441;
  padding: 55px 0 49px 0;
  .footer-copyright {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #ffffff;
  }
  .image-container {
    .image {
      height: 48px;
      margin-bottom: 9px;
    }
  }
  .nav-item-header {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 16px;
  }

  .footer-links {
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        border-bottom: 2px solid #ffffff;
      }
    }
    & .nav-item {
      margin-bottom: 10px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
  @media (max-width: 576px) {
    .image-container,
    .footer-links,
    .nav-item-header {
      text-align: center;
    }
  }
  @media screen and (min-width: 200px) and (max-width: 576px) {
    padding: 55px 0 0px 0;
    .image-container {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  .heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 12px;
  }
  .list-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 12px;
  }
  .list-mail {
    &:hover {
      border-bottom: 2px solid #fff;
    }
  }
  @media (max-width: 900px) {
    .heading,
    .list-item {
      display: flex;
      justify-content: left;
    }
  }
  @media (max-width: 575px) {
    .heading,
    .list-item {
      display: flex;
      justify-content: center;
    }
  }
`;
export const HorizontalLine = styled.div`
  @media (max-width: 576px) {
    width: 100%;
    height: 1px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 28px 0 10px 0;
  }
`;
