import styled from "styled-components";

export const SearchListDiv = styled.div`
  margin-top: 24px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

export const UserID = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
`;

export const Edit = styled(UserID)`
  cursor: pointer;
`;

export const ActionButton = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  cursor: pointer;
  width:40px;
`;
