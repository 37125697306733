export const microServices ={
    CNAPSMONITOR: 'cnaps-monitor/v1',
}

export const endpoints={
    GET_STUDENT_LIST_FOR_MONITOR:`${microServices.CNAPSMONITOR}/delivery/student-list-for-monitor`,
    GET_STUDENT_DETAILS_FOR_MONITOR : `${microServices.CNAPSMONITOR}/delivery/student-list-for-monitor`,
    GET_STUDENT_DETAILS:`${microServices.CNAPSMONITOR}/student/details`,
    UPDATE_PASSWORD:`${microServices.CNAPSMONITOR}/student/update-password`,
    EXTEND_TIME:`${microServices.CNAPSMONITOR}/delivery/extend-student-time`,
}