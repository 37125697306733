import ImageProctor from "./imageProctor";
import SoundProctor from "./soundProctor";
import { cb } from "./helper";
import AudioProctor from "./audioProctor";
import MicProctor from "./micProctor";
import DbProctor from "./dbProctor";

class Proctor {
  constructor() {
    this.sound = new SoundProctor();
    this.image = new ImageProctor();
    this.audio = new AudioProctor();
    this.mic = new MicProctor();
    this.db = new DbProctor();
  }
  decorator(constraints) {
    return new Promise((resolve, reject) => {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => resolve(stream))
          .catch((err) => reject(err.message));
      } else {
        reject("no media found");
      }
    });
  }
  initiate(audioLimit, audioTiming, snapTimer, snapInterval, callback) {
    cb(callback, "all", "loading", "");
    this.decorator({ video: true, audio: true })
      .then((stream) => {
        this.sound.start(stream, audioLimit, audioTiming, callback);
        this.image.start(stream, snapTimer, callback);
      })
      .catch((err) => {
        cb(callback, "all", "denied", err);
      });
  }
  initiateVideoCapture(snapTimer, snapInterval, callback) {
    cb(callback, "video", "loading", "");
    this.decorator({ video: true })
      .then((stream) => {
        this.image.start(stream, snapTimer, callback);
      })
      .catch((error) => {
        cb(callback, "video", "denied", error.message);
      });
  }
  initiateAudioCapture(audioLimit, audioTiming, callback) {
    cb(callback, "audio", "loading", "");
    this.decorator({ audio: true })
      .then((stream) => {
        this.sound.start(stream, audioLimit, audioTiming, callback);
      })
      .catch((error) => {
        cb(callback, "audio", "denied", error.message);
      });
  }
  ejectVideoCapture() {
    this.image.stop();
  }
  ejectAudioCapture() {
    this.sound.stop();
  }
  eject() {
    this.image.stop();
    this.sound.stop();
  }
  inititateMicCapture(audioLimit, audioInterval, callback) {
    // this.audio.start(audioLimit, audioInterval, callback)
    // this.mic.start(audioLimit, audioInterval, callback);
    this.db.start(audioLimit, audioInterval, callback);
  }
  ejectMicCapture() {
    this.db.stop();
  }
}

export default Proctor;
