import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)`
  color: #254082;
  text-decoration: none;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  transition: 300ms;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-weight: 500;

  ${(props) =>
    !props.isSideBar &&
    css`
      @media screen and (max-width: 1024px) {
        margin: 0 0 0 0;
      }
    `}

  &:hover {
    text-decoration: none;
    color: #254082;
    ${({ activateFocus }) =>
      activateFocus &&
      css`
        border-bottom: 2px solid #254082;

        @media screen and (max-width: 1023px) {
          border-bottom: 2px solid #254082;
        }
      `}
  }
  &.btn:hover {
    color: #d71a63;
    background-color: rgba(255, 255, 255, 1) !important;
  }
  &.btn:active,
  &.btn-primary:active {
    color: #d71a63 !important;
    border: 0px solid white !important;
    background-color: rgba(255, 255, 255, 1) !important;
    box-shadow: none !important;
  }
  &.btn:focus,
  &.btn-primary.dropdown-toggle:focus {
    color: #d71a63 !important;
    border: 0px solid white !important;
    background-color: rgba(255, 255, 255, 1) !important;
    box-shadow: none !important;
  }
  ${(props) =>
    props.isSideBar &&
    css`
      margin: 13px 13px 20px 0px;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 450;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: left;
      color: #254082;
    `}
`;

export const MainWrapper = styled.div`
  ${({ $isScrollable }) =>
    $isScrollable &&
    `
    max-height: 80vh;
    overflow-y: scroll !important;
-webkit-overflow-scrolling: touch;
  `}
`;

export const NavBarWrapper = styled.div`
  position: relative;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: ${(props) => (props.isSideBar ? "column" : "row")};
  gap: ${({ isSideBar }) => (isSideBar ? "0px" : "24px")};

  @media screen and (max-width: 1023px) {
    align-items: flex-start;
    text-align: center;
  }

  @media screen and (max-width: 812px) {
    gap: ${({ isSideBar }) => (isSideBar ? "0px" : "16px")};
  }

  @media screen and (max-width: 480px) {
    .hide-mobile {
      display: none;
    }
  }
`;

export const DropDownWrapper = styled.div`
  padding-top: ${({ $isResponsive }) => $isResponsive && "13px"};
  width: ${({ $isResponsive }) => $isResponsive && "100%"};
  text-align: ${({ $isResponsive }) => $isResponsive && "left"};
  margin-bottom: ${({ $isResponsive }) => $isResponsive && "16px"};
`;
