import { getDashboardTypes } from './constants'

export const getPendingRegistrationsRequest = (payload) => ({
  type: getDashboardTypes.GET_PENDING_REGISTRATIONS_REQUEST,
  payload,
});

export const getDashboardRequest = () => ({
  type: getDashboardTypes.GET_DASHBOARD_REQUEST
});

export const getDashboardSuccess = payload => ({
  type: getDashboardTypes.GET_DASHBOARD_SUCCESS,
  payload,
});

export const getDashboardError = error => ({
  type: getDashboardTypes.GET_DASHBOARD_ERROR,
  error,
});


