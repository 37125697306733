import * as actionTypes from "../actionTypes";
import produce from "immer";

const initialState = {
  data: [],
  error: "",
  isLoading: false,
};

export const getStatsByDeliveryIdReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_STATS_BY_DELIVERY_ID_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case actionTypes.GET_STATS_BY_DELIVERY_ID_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case actionTypes.GET_STATS_BY_DELIVERY_ID_FAIL:
        draft.error = action.payload;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

  export default getStatsByDeliveryIdReducer