import styled from "styled-components";
import PlatformBackground from "assets/landingpage/available/platform-background.svg";
import GlobalContainer from "share/atoms/GlobalContainer";

export const MainContainer = styled(GlobalContainer)`
  background-color: #f9f9ff;
  padding-top: 69px;
  padding-bottom: clamp(5rem, 3.7308rem + 5.641vw, 7.75rem);
`;

export const Wrapper = styled.div``;
export const Header = styled.div`
  text-align: center;
  font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem);
  font-weight: bold;
  color: #242424;

  & .highlight {
    color: #d71a63;
  }
`;

export const Tag = styled.div`
  font-size: 16px;
  color: #464646;
  line-height: 1.5;
  text-align: center;
  margin: 24px 0px 28px 0px;
`;

export const Container = styled.div`
  width: 100%;
  height: 500px;
  background: url(${PlatformBackground}) 100% top no-repeat,
    linear-gradient(to top, #182441, #182441) left bottom no-repeat;
  background-size: cover;
  border-radius: 6px;
  display: flex;
  color: #ffffff;
  margin-top: 150px;
  position: relative;
  padding-top: 72px;
  padding-bottom: clamp(2.5rem, 1.3462rem + 5.1282vw, 5rem);
  padding-left: clamp(1rem, -0.3846rem + 6.1538vw, 4rem);
  padding-right: clamp(1rem, -0.3846rem + 6.1538vw, 4rem);

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 450px;
    /* max-height: 406px; */
    height: 600px;
  }
`;

export const LeftSection = styled.div`
  width: 50%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  & .title {
    font-size: clamp(1.5rem, 0.8077rem + 3.0769vw, 3rem);
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
  }
  & .logos {
    margin-top: 48px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  & .content {
    font-size: clamp(0.875rem, 0.5865rem + 1.2821vw, 1.5rem);
    font-weight: 400;
    text-align: center;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  top: 130px;
  height: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    top: -35px;
    height: 260px;
  }

  & img {
    width: 100%;
    max-width: 400px;
  }
`;

export const PlayStoreWrapper = styled.div`
  text-align: center;
  padding: 0px 0px 46px 0px;

  @media screen and (max-width: 800px) {
    padding: 0px 0px 0px 0px;
  }

  img {
    width: clamp(9.25rem, 8.5577rem + 3.0769vw, 10.75rem);
    height: clamp(3rem, 2.7692rem + 1.0256vw, 3.5rem);
  }
`;
