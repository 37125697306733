import * as actionTypes from "../actionTypes";

export const refreshDeliveryRequest = (payload) => ({
  type: actionTypes.GET_STATS_BY_DELIVERY_ID_REQUEST,
  payload,
});

export const refreshDeliverySuccess = (payload) => ({
  type: actionTypes.GET_STATS_BY_DELIVERY_ID_SUCCESS,
  payload,
});

export const refreshDeliveryError = (payload) => ({
  type: actionTypes.GET_STATS_BY_DELIVERY_ID_FAIL,
  payload,
});
