import React from 'react'
import RefreshIcon from 'assets/monitoring/monitor-refresh-icon.svg'
import {RefreshWrapper} from './styles'
import { getStudentListMonitorRequest } from './actions/student-list-monitor-actions'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getQueryParam } from 'utils/utility/url'

const RefreshIconTitle=({setPageDetails})=>{
     const location = useLocation();
     const dispatch = useDispatch();
     const handleRefresh=()=>{
      setPageDetails({
        pageNumber:1,
        pageLimit:100
      })
        const payload = {
            delivery_id: getQueryParam("delivery_id",location),
            page_number:1,
            page_limit:100
          };
          dispatch(getStudentListMonitorRequest(payload));
     }

    return (
      <RefreshWrapper>
      <img src={RefreshIcon} onClick={handleRefresh}/>
      </RefreshWrapper>
    )
  }
export default RefreshIconTitle
