import React from "react";

export default function useModal() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleModal = (content = false) => {
    setIsVisible(!isVisible);

    if (content) {
      setModalContent(content);
    }
  };

  return { isVisible, modalContent, handleModal };
}
