import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .slick-prev:before,
  .slick-next:before {
    color: yellow;
  }
`;

export const Container = styled.div`
  justify-content: flex-start;
  gap: 14px;
  border-radius: 8px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  margin: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 100px;
`;

export const FeatureHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  h1 {
    text-align: center;
    font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); // 20 to 36
  }
  h6 {
    font-family: Poppins;
    font-size: clamp(1rem, 0.8846rem + 0.5128vw, 1.25rem);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    max-width: 90%;
  }

  h2 {
    align-self: stretch;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--gray-24);
  }
`;

export const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 36px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  &.slick-prev:before,
  &.slick-next:before {
    color: yellow;
  }
`;

export const Image = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 6px;
  }
    &:hover{
    cursor:pointer
    }
`;

export const Caption = styled.div`
  margin-top: 14px;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #333;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;

  ${(props) => (props.direction === "right" ? `right: -35px;` : `left: -35px;`)}

  @media (max-width: 800px) {
    ${(props) => (props.direction === "right" ? `right: 5px;` : `left: 5px;`)}
  }
`;

export const StyledSlider = styled.div`
  margin-bottom: 3em;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  padding: 12px;
  position:relative;
  .slick-prev,
  .slick-next {
    width: 41px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
    background-color: var(--white);
    color: red;
    cursor: pointer;
    z-index: 1;
  }

  .slick-prev {
    left: -50px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .slick-next {
    right: -50px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .slick-next:before {
    content: ">";
    color: red;
    font-weight: bold;
  }

  .slick-prev:before {
    content: "<";
    color: red;
    font-weight: bold;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: relative;
  border-radius: 16px;
  width: 60%;
  max-height: 60%;
  max-width: 1200px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
    @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

export const PrevButton = styled.button`
  position: absolute;
  top: 45%;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

export const NextButton = styled.button`
  position: absolute;
  top: 45%;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;

export const StyledLeftButtonContainer = styled(StyledSlider)`
  position: absolute;
  top: 45%;
  left: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 41px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: var(--white);
  color: red;
  cursor: pointer;
  z-index: 1;
`;

export const StyledRightButtonContainer = styled(StyledSlider)`
  position: absolute;
  top: 45%;
  right: 0px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  outline:none;
  border:none
  width: 41px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: var(--white);
  color: red;
  cursor: pointer;
  z-index: 1;
`;
