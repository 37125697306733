import React from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Styles = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction:column;
`;
const PageLoader = () => {
  return (
    <Styles>
      <Spinner animation="border" role="status" />
      <div><br/>Loading...</div>
    </Styles>
  );
};

export default PageLoader;
