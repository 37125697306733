import React from "react";
import {StyledDiv,StyledText,ContactUsCardWrapper,IconDiv,EmailDiv} from './styles'
import MailIcon from "assets/MailIcon.svg";

export default function ContactUs(props) {
    return (
      <StyledDiv id="contact-us">
        <h1>contact Us</h1>
        <StyledText>
          Get in touch with our customer support team for any queries/feedback.
        </StyledText>
        <ContactUsCardWrapper>
          <IconDiv>
            <img src={MailIcon} alt="mail" />
          </IconDiv>
          <EmailDiv>
            <p>Send us an email</p>
            <a href="mailto:support@cnaps.in">
              <b>support@cnaps.co.in</b>
            </a>
          </EmailDiv>
        </ContactUsCardWrapper>
      </StyledDiv>
    );
  }

