import { getLocalObj } from "./local-storage";

/**
 * this function checks the logged user is narayana student or not
 * @returns {Boolean} true/false
 */
export const isNarayanaSchool = () => {
  const tokenData = getLocalObj("token-data");

  return tokenData.is_narayana_school;
};


/**
 * this function returns the errorlist in an array format from api response
 * @param {Object} e
 * @returns {Array} errors array
 */
export const getErrorList = (e) => {
  // if(e.isHeaderValidationError || e){
    if (e && e.data.length > 0) return e.data;
    else if (e && e.message) return [{ error: e.message }];
    else return ["Something went wrong!"];
  // }
  // else{
  //   return [e.data.processed_file_url]
  // }
};
