import { call, put, takeLatest } from "redux-saga/effects";
import { getDashboardTypes } from "./constants";
import { push } from "connected-react-router";
import notify from "utils/notifications";
import * as actions from "./actions";
import { setLocalObj } from "utils/utility/local-storage";
import { getExamValues, getExamCols } from "utils/utility/table";
import DashboardService from "./services/index";
import NewRegistrationService from "../NewRegistration/services/index";

const newRegistrationService = new NewRegistrationService();
const dashboardService = new DashboardService();

const getColumns = (data, examFunc) => {
  return [
    {
      title: "Student name",
      dataIndex: "name",
      key: "name",
      width: 60,
      fixed: "left"
    },
    {
      title: "Sur name",
      dataIndex: "sur_name",
      key: "surname",
      width: 60,
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      width: 60,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 60,
    },
    {
      title:"Roll number",
      dataIndex:"roll_number",
      width: 80,
      key:"rollnumber"
    },

    {
      title: "Exams",
      children: examFunc(data),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed:"right"
    },
  ];
};

const getPendingTableDataSource = (data) => {
  if (!data) return [];
  return data.map((item) => {
    let exams = getExamValues(item.exams_list);
    return {
      ...item,
      ...exams,
    };
  });
};

function* dashboardWorker() {
  try {
    const res = yield call(dashboardService.getTotalDashboard);
    if (res.data.status === "success") {
      yield put(actions.getDashboardSuccess(res.data.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.getDashboardError(res.data.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.getDashboardError(e));
  }
}

function* getPendingRegistrationsWorker(action) {
  try {
    const res = yield call(newRegistrationService.pendingRegistrations);
    if (res.data.status === "success") {
      const columns = getColumns(res.data.data, getExamCols);
      const dataSource = getPendingTableDataSource(res.data.data);
      yield setLocalObj("studentSummary", { columns, dataSource });
      yield put(push(action.payload));
    } else {
      notify("error", res.data.message);
    }
  } catch (error) {
    notify("error", "Something went wrong!");
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(getDashboardTypes.GET_DASHBOARD_REQUEST, dashboardWorker);
  yield takeLatest(
    getDashboardTypes.GET_PENDING_REGISTRATIONS_REQUEST,
    getPendingRegistrationsWorker
  );
}
