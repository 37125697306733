import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
  box-sizing:border-box
}
  html,
  body {
    height: 100%;
    width: 100%;
  }

  html{
    font-size:${({ theme }) => theme.palette.typography.htmlFontSize};
  }

  body {
    font-family: ${({ theme }) => theme.palette.typography.fontFamily};
    color:${({ theme }) => theme.palette.text.primary};
    .no-arrows{
      .ril-prev-button, .ril-next-button{
        display:none
      }
    }
  }


  #app {
    background-color: ${({ theme }) => theme.palette.common.white};
    min-height: 100%;
    min-width: 100%;
  }

  h1{
    ${({ theme }) => theme.palette.typography.h1}
  }
  h2{
    ${({ theme }) => theme.palette.typography.h2}
  }
  h3{
    ${({ theme }) => theme.palette.typography.h3}
  }
  h4{
    ${({ theme }) => theme.palette.typography.h4}
  }
  h5{
    ${({ theme }) => theme.palette.typography.h5}
  }
  h6{
    ${({ theme }) => theme.palette.typography.h6}
  }
  p{
    ${({ theme }) => theme.palette.typography.body1};
    
  }
  img,a {
    user-drag:none;
  }
  label{
    color:${({ theme }) => theme.palette.text.secondary};
    ${({ theme }) => theme.palette.typography.body1}
  }
  .gutter-8{
    padding-left:8px !important;
    padding-right:8px !important;
  }
  .gutter-16{
    padding-left:16px !important;
    padding-right:16px !important;
  }
  .gutter-24{
    padding-left:24px !important;
    padding-right:24px !important;
  }
  .gutter-48{
    padding-left:48px !important;
    padding-right:48px !important;
  }
  .gutter-64{
    padding-left:64px !important;
    padding-right:64px !important;
  }
  .d-flex{
    display:flex;
  }
  .d-col{
    display:flex;
    flex-direction:column;
  }
  .d-center{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .d-justify-start{
    justify-content:flex-start;
  }
  .d-justify-end{
    justify-content:flex-end;
  }
  .d-justify-center{
    justify-content:center;
  }
  .d-justify-between{
    justify-content:space-between;
  }
  .d-align-start{
    align-items:flex-start;
  }
  .d-align-end{
    align-items:flex-end;
  }
  .d-align-center{
    align-items:center;
  }
  .d-align-between{
    align-items:space-between;
  }
 input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  /* -webkit-text-fill-color: green; */
  -webkit-box-shadow: 0 0 0px 1000px tansparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
  .antd-multi-select-option .ant-select-item-option-state {
    display: none !important;
  }
  .antd-multi-select-option .demo-option-label-item{
    color:#464646;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    height: 24px;
    svg{
      color: #d71a63 !important;
      width: 19px;
      height: 19px;
    }
  }
  .ant-select-multiple .ant-select-selector,.ant-select-single .ant-select-selector{
    height: 48px!important;
    border-radius: 8px !important;
    border: solid 1px #949497 !important ;
    background-color: #ffffff !important;
    box-shadow:none !important;
    .ant-select-selection-overflow {
      height: inherit;
      border-radius: 8px;
    }
    .ant-select-selection-overflow-item-suffix {
      top: 8px !important;
    }
  }
  
  .ant-select-multiple .ant-select-arrow svg ,.ant-select-single .ant-select-arrow svg{
    width: 16px;
    height: 16px;
    color: #242424;
    position: absolute;
    right:10px;
    bottom:1px;
  }
  .ant-select-dropdown{
     min-width: 336px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
  }
.ant-select-selection-placeholder{
  opacity: 0.5;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2e3c3f;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
padding-top:8px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search{
  left:0px;
  display:flex;
  align-items:center;
}
.ant-select-single.ant-select-open .ant-select-selection-item{
  color:#bfbfbf !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  padding-top:10px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.anticon-close{
  position: relative;
  bottom:3px;
}
iframe .razorpay-container{ 
  z-index: 0;
  .mchild{
    width:64%;
    height:68%;
    #content{
      height:100%;
    }
  }
}
`;
export default GlobalStyle;
