import React from "react";
import PropTypes from "prop-types";
import Button from "share/atoms/Button";
import {
  StyledModal,
  ModalFooter,
  ModalBody,
  TextButton,
  Icon,
  Title,
  Description,
} from "./styles";

function ModalTemp(props) {
  return (
    <StyledModal size={props.size} className={props.className} show={true}>
      <ModalBody>
        {props.icon && <Icon src={props.icon} />}
        {props.title && <Title>{props.title}</Title>}
        {props.desc && <Description>{props.desc}</Description>}
        {props.children}
      </ModalBody>
      {props.footerVisible && (
        <ModalFooter>
          <TextButton className={props.cancelBtnClass} onClick={props.onCancel}>
            {props.cancelBtn ? props.cancelBtn : "Cancel"}
          </TextButton>
          <Button className={props.primaryBtnClass} onClick={props.onOk}>
            {props.primaryBtn ? props.primaryBtn : "Yes"}
          </Button>
        </ModalFooter>
      )}
    </StyledModal>
  );
}

export default ModalTemp;

ModalTemp.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  primaryBtn: PropTypes.string,
  cancelBtn: PropTypes.string,
  primaryBtnClass: PropTypes.string,
};
ModalTemp.defaultProps = {
  title: "",
  desc: "",
  icon: "",
  primaryBtn: "Yes",
  cancelBtn: "Cancel",
  primaryBtnClass: "",
  footerVisible: true,
};
