import produce from "immer";
import { combineReducers } from "redux";
import * as types from "./types";

export const initialState = {
  isLoading: true,
  error: false,
  data: false,
};

const getProctorConfigReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PROCTOR_CONFIG_REQUEST:
        draft.isLoading = true;
        break;
      case types.GET_PROCTOR_CONFIG_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.GET_PROCTOR_CONFIG_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

const setProctorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_IMAGE_PROCTOR_REQUEST:
        draft.isLoading = true;
        break;
      case types.SET_IMAGE_PROCTOR_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.SET_IMAGE_PROCTOR_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

const updateProctorConsentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_PROCTOR_CONSENT_REQUEST:
        draft.isLoading = true;
        break;
      case types.UPDATE_PROCTOR_CONSENT_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.UPDATE_PROCTOR_CONSENT_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

const updateProctorEventtReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_PROCTOR_EVENT_REQUEST:
        draft.isLoading = true;
        break;
      case types.UPDATE_PROCTOR_EVENT_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.UPDATE_PROCTOR_EVENT_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

const proceedTestReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.PROCEED_TEST_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.PROCEED_TEST_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.PROCEED_TEST_ERROR:
        draft.error = action.error;
        draft.data = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const examDetailsReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.EXAM_DETAILS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.EXAM_DETAILS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.EXAM_DETAILS_ERROR:
        draft.error = action.error;
        draft.data = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const proctorReducer = combineReducers({
  setProctorReducer: setProctorReducer,
  getProctorConfigReducer: getProctorConfigReducer,
  updateProctorConsentReducer: updateProctorConsentReducer,
  updateProctorEventtReducer: updateProctorEventtReducer,
  proceedTestReducer: proceedTestReducer,
  examDetailsReducer: examDetailsReducer,
});

export default proctorReducer;
