import styled from "styled-components";

export const StyledButton = styled.button`
  width: ${({ width }) => (width ? width : "fit-content")};
  border: 0;
  height: ${({ height }) => height};
  font-family: ${({ fontFamily }) => fontFamily};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "100px"};
  background-color: ${({ bgColor }) => bgColor};
  margin: 10px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: ${({ color }) => (color ? color : "#fff")};
  cursor: ${({ cursor }) => (cursor ? "not-allowed" : "pointer")};
  gap: 10px;
  opacity: ${({ opacity }) => (opacity ? 0.3 : 1)};
  padding: ${({ padding }) => (padding ? padding : "12px 32px")};
  &:focus,
  &:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 1366px) {
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 500;
  }
  &:hover{
    filter:brightness(0.85);
  }
`;
