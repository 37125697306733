/**
 * this function checks if the parameters is truthy value or not
 * @param {*} val
 * @returns {boolean} true/false
 */
export const isTruthy = (val) =>
  val && val !== null && val !== undefined && val !== false;

  /**
 * this function checks if the parameters is truthy value and array or not 
 * @param {*} val
 * @returns {boolean} true/false
 */
export const isArr = (val) => isTruthy(val) && Array.isArray(val);

  /**
 * this function checks if the parameters is array,truthyvalue and array length is greater than 0
 * @param {*} val
 * @returns {boolean} true/false
 */
export const hasArrData = (val) => isArr(val) && val.length > 0;

/**
 * this function checks if the parameters is array,truthy value and array length is equals to 0
 * @param {*} val
 * @returns {boolean} true/false
 */
export const isEmptyArr = (val) => isArr(val) && val.length === 0;

/**
 * this function checks to return "--" or val
 * @param {*} value
 * @returns {number/string} "--" or val
 */
export const withPlaceholder = (value) => {
  return isTruthy(value) ? (value === 0 || value ? value : "--") : "--";
};


/**
 * this function checks to filter the value with id and return array
 * @param {Array} array
 * @param {string} id
 * @param {string} value
 * @returns {Array} returns Array
 */
export const arrFilterBy = (arr, id, value) => {
  return arr.filter((each) => each[id] === value);
};


/**
 * this function takes the object and convert into array of values
 * @param {Object} obj
 * @returns {Array} returns Array
 */
export const getValues = (obj) => {
  if (!obj) return [];
  return Object.values(obj);
};

/**
 * this function takes the object and convert into array of keys
 * @param {Object} obj
 * @returns {Array} returns Array
 */
export const getKeys = (obj) => {
  if (!obj) return [];
  return Object.keys(obj);
};

/**
 * this function checks if the object is empty
 * @param {Object} obj
 * @returns {boolean} true/false
 */
export const isEmptyObj = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * this function checks if the object is empty
 * @param {Array} data
 * @param {string} label
 * @param {string} value
 * @returns {Array} returns emptyArray or lists of objects
 */

export const labelValueConvertion = (data, label, value) => {
  if (!isTruthy(data) || !hasArrData(data)) return [];
  return data.map((item) => ({ label: item[label], value: item[value] }));
};

/**
 * this function remove "all" string from array
 * @param {Array} data
 * @returns {Array} remove "all" string from array
 */

export const removeAllStringFromList = (data) => {
  if (!isTruthy(data) || !hasArrData(data)) return [];

  return data.filter((item) => item !== "all").map((itm) => itm.split("~")[0]);
};