import React from "react";
import notify from "utils/notifications";
import history from "utils/history";
import NewRegistrationService from "./services/index";
import StudentListService from "school-admin/StudentList/services";
import * as actions from "./actions";
import { put, call, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { initPaymentProcess } from "utils/payment";
import { getLocalObj, setLocalObj } from "utils/utility/local-storage";
import { getQueryParam } from "utils/utility/url";
import { getExamColumns, getExamCols } from "utils/utility/table";
import { newRegistrationTypes } from "./constants";
import {
  getColumns,
  getTableDataSource,
  getPendingTableDataSource,
} from "./helper";

const newRegistrationService = new NewRegistrationService();
const studentListService = new StudentListService();

function* BulkRegistrationManualWorker(action) {
  try {
    const res = yield call(newRegistrationService.sendBulkRegistrationManual, {
      students_list: action.payload.students_list,
    });
    if (res.data.status === "success") {
      const resData = res.data.data;
      const columns = getColumns(resData, getExamColumns);
      const dataSource = getTableDataSource(resData);
      setLocalObj("studentSummary", { columns, dataSource });
      yield put(actions.bulkRegistrationManualSuccess(res.data.data));
      yield put(
        replace(`/school/new-registration/summary?groupId=${resData.group_id}`)
      );
    } else {
      // notify("error", res.data.message);
      action.payload.errorCallback(res.data);
      yield put(actions.bulkRegistrationManualFailure(res.data));
    }
  } catch (error) {
    notify("error", "Something went wrong!");
    yield put(actions.bulkRegistrationManualFailure(error));
  }
}

function* bulkRegistrationExcelUploadWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.excelUpload,
      action.payload.data,
      action.payload.config
    );
    if (res.data.status === "success") {
      const columns = yield getColumns(res.data.data, getExamColumns);
      const dataSource = yield getTableDataSource(res.data.data);
      yield setLocalObj("studentSummary", { columns, dataSource });
      action.payload.successcallback(res.data);
      yield put(actions.bulkRegistrationExcelUploadSuccess(res.data));
    } else {
      action.payload.errorcallback(res.data);
      yield put(actions.bulkRegistrationExcelUploadFailure(res.data));
    }
  } catch (error) {
    notify("error", "Something went wrong!");
    yield put(actions.bulkRegistrationExcelUploadFailure(error));
  }
}

function* deleteStudentWorker(action) {
  try {
    const { reqBody, callback } = action.payload;
    const res = yield call(newRegistrationService.deleteStudent, reqBody);
    if (res.data.status === "success") {
      callback();
    } else notify("error", res.data.message);
  } catch (error) {
    notify("error", "Something went wrong!");
  }
}

function* getPendingRegistrationsWorker(action) {
  try {
    const res = yield call(newRegistrationService.pendingRegistrations);
    if (res.data.status === "success") {
      const columns = getColumns(res.data.data, getExamCols);
      const dataSource = getPendingTableDataSource(res.data.data);
      yield setLocalObj("studentSummary", { columns, dataSource });
      yield put(push(action.payload));
    } else {
      notify("error", res.data.message);
    }
  } catch (error) {
    notify("error", "Something went wrong!");
  }
}

function* examPhaseSelectionWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.sendExamPhaseSelection,
      action.payload
    );
    if (res.data.status === "success") {
      notify("success", res.data.message);
      yield put(actions.examPhaseSelectionSuccess(res.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.examPhaseSelectionFailure(res.data));
    }
  } catch (error) {
    notify("error", "Something went wrong!");
    yield put(actions.examPhaseSelectionFailure(error));
  }
}

function* examPhaseListWorker(action) {
  try {
    const res = yield call(newRegistrationService.getExamPhaseList);
    if (res.data.status === "success") {
      yield put(actions.examPhaseListSuccess(res.data.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.examPhaseListFailure(res.data.data));
    }
  } catch (error) {
    notify("error", "Something went wrong!");
    yield put(actions.examPhaseListFailure(error));
  }
}

function* studentPaymentSummaryWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.getStudentPaymentSummary,
      action.payload
    );
    if (res.data.status === "success") {
      yield put(actions.getStudentPaymentSummarySuccess(res.data.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.getStudentPaymentSummaryFailure(res.data));
    }
  } catch (error) {
    notify("error", "Something went wrong!");
    yield put(actions.getStudentPaymentSummaryFailure(error));
  }
}

function* saveListWorker(action) {
  try {
    const res = yield call(newRegistrationService.saveList, action.payload);
    if (res.data.status === "success") {
      const tokenData = getLocalObj("token-data");
      const feature = sessionStorage.getItem("feature");
      if (tokenData.is_narayana_school && feature !== "pending") {
        notify("success", res.data.message || "Request sent successfully");
      }
      if (action.payload.method === "payment")
        yield put(actions.initBulkRegPaymentRequest(action.payload));
      else {
        notify("success", "Successfully Saved");
        yield put(replace("/school/new-registration"));
      }
    } else {
      notify("error", res.data.message);
    }
  } catch (error) {
    notify("error", "Something went wrong!");
  }
}

function* initBulkRegPaymentWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.initBulkRegPayment,
      action.payload
    );
    if (res.data.status === "success") {
      yield put(actions.initBulkRegPaymentSuccess(res.data.data));
      initPaymentProcess(res.data.data);
    } else {
      notify("error", res.data.message);
    }
  } catch (error) {
    notify("error", "Something went wrong!");
  }
}

//Student filters
export function* studentFiltersWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.studentFilters,
      action.payload
    );
    if (res.data.status === "success") {
      const classList = Object.values(res.data.data);
      const data={
        class_list:classList,
        backend_data:res.data.data
      }
      yield put(actions.studentFiltersSuccess(data));
    } else {
      notify("error", res.data.message);
      yield put(actions.studentFiltersError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.studentFiltersError(e));
  }
}

//Individual registration sagas
export function* getStudentDataWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.getStudentData,
      action.payload
    );
    if (res.data.status === "success") {
      yield put(actions.getStudentDataSuccess(res.data.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.getStudentDataError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.getStudentDataError(e));
  }
}

export function* individualPaymentSummaryWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.individualPaymentSummary,
      action.payload
    );
    if (res.data.status === "success") {
      yield put(actions.getStudentPaymentSummarySuccess(res.data.data));
    } else {
      notify("error", res.data.message);
      yield put(actions.getStudentPaymentSummaryFailure(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.getStudentPaymentSummaryFailure(e));
  }
}

/**
 * this function checks different users and navigate to payment
 * @param {Object} action
 * @returns {void} 
 */
export function* initIndividualPaymentWorker(action) {
  try {
    const res = yield call(
      newRegistrationService.initIndividualPayment,
      action.payload
    );

    if (res.data.status === "success") {
      const tokenData = getLocalObj("token-data");
      const admsnNumber = getQueryParam("admissionNumber", history.location);
      yield put(actions.initIndividualPaymentSuccess(res.data.data));
      if (!tokenData.is_narayana_school) initPaymentProcess(res.data.data);
      if (admsnNumber && tokenData.is_narayana_school) {
        notify("success", res.data.message);
        yield put(replace("/school/new-registration"));
      }
    } else {
      notify("error", res.data.message);
      yield put(actions.initIndividualPaymentError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.initIndividualPaymentError(e));
  }
}

export function* getStudentsListWorker(action) {
  try {
    const { admsnNumber, groupId, callback } = action.payload;
    const feature = sessionStorage.getItem("feature");
    const reqBody = {};
    if (admsnNumber) {
      reqBody["admission_number"] = admsnNumber;
    } else if (feature === "pending") {
      reqBody["pending_group_id"] = groupId;
    } else reqBody["group_id"] = groupId;
    if (groupId || admsnNumber) {
      const res = yield call(studentListService.studentList, reqBody);
      if (res.data.status === "success") {
        callback(res.data.data);
      } else {
        notify("error", res.data.message);
      }
    }
  } catch (e) {
    notify("error", "Something went wrong!");
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(
    newRegistrationTypes.GET_STUDENT_DATA_REQUEST,
    getStudentDataWorker
  );
  yield takeLatest(
    newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_REQUEST,
    individualPaymentSummaryWorker
  );
  yield takeLatest(
    newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_REQUEST,
    initIndividualPaymentWorker
  );

  yield takeLatest(
    newRegistrationTypes.STUDENT_FILTERS_REQUEST,
    studentFiltersWorker
  );
  yield takeLatest(
    newRegistrationTypes.BULK_REGISTRATION_MANUAL_REQUEST,
    BulkRegistrationManualWorker
  );
  yield takeLatest(
    newRegistrationTypes.EXAM_PHASE_SELECTION_REQUEST,
    examPhaseSelectionWorker
  );
  yield takeLatest(
    newRegistrationTypes.DELETE_STUDENT_REQUEST,
    deleteStudentWorker
  );
  yield takeLatest(
    newRegistrationTypes.EXAM_PHASE_LIST_REQUEST,
    examPhaseListWorker
  );
  yield takeLatest(
    newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_REQUEST,
    studentPaymentSummaryWorker
  );
  yield takeLatest(newRegistrationTypes.SAVE_LIST_REQUEST, saveListWorker);
  yield takeLatest(
    newRegistrationTypes.PENDING_REGISTRATIONS_REQUEST,
    getPendingRegistrationsWorker
  );
  yield takeLatest(
    newRegistrationTypes.INIT_BULK_REG_PAYMENT_REQUEST,
    initBulkRegPaymentWorker
  );
  yield takeLatest(
    newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_REQUEST,
    bulkRegistrationExcelUploadWorker
  );
  yield takeLatest(
    newRegistrationTypes.STUDENT_LIST_REQUEST,
    getStudentsListWorker
  );
}
