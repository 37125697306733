import { endpoints } from "../types";
import request from "utils/axios/service";
import { EXAM_TYPES } from "utils/utility/test-engine";

const getProceedUrl = (params) => {
  if (params.exam_category === EXAM_TYPES.SCHEDULED_TEST)
    return endpoints.SCHEDULE_PROCEED_TEST;
  return endpoints.MOCK_PROCEED_TEST;
};

class InstructionService {
  async getExamDetails(req) {
    return await request.get(endpoints.EXAM_DETAILS, req);
  }
  async proceedTest(req) {
    return await request.get(getProceedUrl(req), req);
  }
}

export default InstructionService;
