import styled from "styled-components";
import Button from "share/atoms/Button";
import Tick from "assets/exams/IconTick.svg";

export const MainHeader = styled.div`
  color: #242424;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 21px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 16px 64px 16px;
  padding-bottom: 64px;
`;

export const Header = styled.div`
  width: 100%;
  & .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin: 8px 0;
    color: #242424;
  }
  & .tag {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #464646;
    margin-bottom: 22px;
  }
`;

export const Footer = styled.footer``;

export const Body = styled.div`
  margin: 10px 0px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
  text-transform: none;
  font-weight: bold;
  margin: 16px;
  opacity: ${({ $isDisabled }) => ($isDisabled ? "0.3" : "1")};
  cursor: ${({ $isDisabled }) =>
    $isDisabled ? "not-allowed" : "pointer"} !important;
`;

export const ValueList = styled.ul`
  margin-left: 16px;
  list-style-image: url(${Tick});
  margin-bottom: 0;
  font-family: Poppins;
  li {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5;
    color: #464646;
  }
`;

export const AccessCardContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #edeff9;
  width: 286px;
  height: 56px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  & .icon {
    display: flex;
    align-items: center;
  }
  & .text {
    display: inline-block;
    margin-left: 14px;
    color: #242424;
    font-size: 16px;
  }
  & .accessibility {
    display: flex;
    gap: 12px;
    color: ${({ $isActive }) => ($isActive ? "green" : "#e21313")};
    font-size: 12px;
  }
`;

export const ImageContainer = styled.div``;

export const AccessCardWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: center;
  margin-top: 18px;
`;

export const LoadingImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  gap: 6px;
`;
