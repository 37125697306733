import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import GlobalStyle from "./global-styles";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "utils/themes/index";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingHome from "student/LandingPage";
import TestScreen from "containers/test/loadable";
import NotFoundPage from "share/atoms/not-found";
import injectSaga from "utils/saga/injectSaga";
import { connect } from "react-redux";
// import saga from "containers/login/saga";
import NoInternetComponent from "share/organisms/no-internet";
import ContentProgressBar from "share/molecules/content-progress";
import PDFViewer from "share/organisms/pdf-viewer/loadable";
import { checkTestStatusRequest } from "containers/test/actions";
import ErrorPage from "containers/error-page/";
import { getLocalObj, isAuthorized } from "utils/utility/local-storage";
import { TEST_ENGINE_ROUTES, HOME_SCREEN_ROUTES } from "./routes";
import saga from "./saga";
import styled from "styled-components";
// school imports
import schoolRoutes from "routes/school-routes";

//student imports
import studentRoutes from "routes/student-routes";
//monitor imports
import monitorRoutes from "routes/monitor-routes";
import Features from "containers/Features";
import NotificationStrip from "share/molecules/notification-strip";
import { StickyHeader } from "share/organisms/headers";
import { useResize } from "share/hooks/useResize";
import ToastCloseIcon from "assets/x.svg";

const StyledToastContainer = styled(ToastContainer)`
  width: 354px !important;
  height: 54px !important;
  .Toastify__toast {
    padding: 0px !important;
    min-height: 0px !important;
    margin: 0px !important;
  }
  .Toastify__toast-body {
    padding: 0px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const NewStyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    padding: 0;
    border-radius: 6px;
    cursor: default;
  }
  .Toastify__toast-body {
    padding: 0 !important;
  }
`;

toast.configure();
const key = "auth";

function App(props) {
  const { width } = useResize();
  const locate = useLocation();
  localStorage.setItem("domain", "cnaps");
  const [isStripVisible, setIsStripVisible] = useState({
    notificationStrip: true,
    stickyHeaderStrip: true,
  });
  const [isBarVisible, setIsBarDisable] = useState(true);
  useEffect(() => {
    if (window.location.pathname.includes("/test/")) {
      props.checkTestStatus();
    }
  }, []);
  const isNavbarVisible = HOME_SCREEN_ROUTES.some(
    (item) => item.path === locate.pathname
  );
  const handleStripVisible = (pageName) => {
    if (pageName === "notificationStrip") {
      setIsStripVisible({
        ...isStripVisible,
        notificationStrip: false,
      });
    } else if (pageName === "strickyHeaderStrip") {
      setIsStripVisible({
        ...isStripVisible,
        stickyHeaderStrip: false,
      });
    }
  };
  useEffect(() => {
    const { stickyHeaderStrip, notificationStrip } = isStripVisible;
    if (!stickyHeaderStrip || !notificationStrip) {
      if (width >= 800) {
        setIsBarDisable(notificationStrip);
      } else {
        setIsBarDisable(stickyHeaderStrip);
      }
    }
  }, [width, isStripVisible]);
  const CloseButton = ({ closeToast }) => (
    <button
      className="material-icons"
      onClick={closeToast}
      style={{
        border: "none",
        outline: "none",
        background: "transparent",
        paddingRight: "10px",
      }}
    >
      <img src={ToastCloseIcon} alt="" />
    </button>
  );
  return (
    <ThemeProvider theme={defaultTheme}>
      <NoInternetComponent />
      {/* <StyledToastContainer hideProgressBar={true} closeButton={false} /> */}
      <NewStyledToastContainer closeButton={CloseButton} />
      <GlobalStyle />
      <NotificationStrip handleStrip={handleStripVisible} />
      <StickyHeader handleStrip={handleStripVisible} />
      {locate.pathname === "/contact-us" ? (
        <Features topPos={"0px"} />
      ) : (
        (isNavbarVisible || locate.pathname === "/") && (
          <Features topPos={isBarVisible ? "64px" : "0px"} />
        )
      )}
      <Switch>
        <PrivateRoute path="/test/:details" component={TestScreen} {...props} />
        {HOME_SCREEN_ROUTES.map((route, idx) => (
          <RedirectRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
            {...props}
          />
        ))}

        <PrivateRoute exact path="/" component={LandingHome} {...props} />

        <Route exact path="/error" component={ErrorPage} {...props} />
        <Route {...props} path="/pdf-viewer/:id" component={PDFViewer} />

        {/* student & school routes start*/}
        {[...studentRoutes, ...schoolRoutes, ...monitorRoutes].map((rout) => (
          <PrivateRoute
            key={rout.path}
            exact={rout.exact}
            path={rout.path}
            component={rout.component}
            {...props}
          />
        ))}
        {/* student & school routes end */}

        {/* test engine routes start*/}
        {TEST_ENGINE_ROUTES.map((route) => (
          <PrivateRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
            {...props}
          />
        ))}
        {/* test engine routes end */}

        <Route component={NotFoundPage} {...props} />
      </Switch>
    </ThemeProvider>
  );
}

const PrivateRoute = ({ ...props }) => {
  let paths = [
    "/",
    "/login",
    "/school",
    "/school/login",
    "/school/registration",
    "/school/forgot-password",
    "/student",
    "/student/login",
    "/student/registration",
    "/student/forgot-password",
    "/monitor/login",
  ];
  const { path } = props;
  const tokenData = getLocalObj("token-data");
  if (localStorage.getItem("token") && paths.includes(path)) {
    if (tokenData && tokenData.role === "admin")
      return <Redirect to="/school/dashboard" />;
    else if (
      tokenData &&
      (tokenData.role === "da" || tokenData.role === "super_admin")
    )
      return <Redirect to="/school/divisional-accountant" />;
    else if (tokenData && tokenData.role === "monitor")
      return <Redirect to="/monitor/deliveries" />;
    else return <Redirect to="/student/home" />;
  } else if (tokenData) {
    if (tokenData.role === "admin" && path.includes("divisional-accountant")) {
      return <Redirect to="/school/dashboard" />;
    } else if (
      (tokenData.role === "da" || tokenData.role === "super_admin") &&
      !path.includes("divisional-accountant")
    )
      return <Redirect to="/school/divisional-accountant" />;
    else if (!tokenData.role && path.includes("/school"))
      return <Redirect to="/student/home" />;
    else if (!tokenData.role || tokenData.role === "monitor")
      return <Route {...props} />;
  } else if (!localStorage.getItem("token") && !paths.includes(path))
    return <Redirect to="/" />;

  return <Route {...props} />;
};

const RedirectRoute = ({ ...props }) => {
  const { path } = props;

  if (isAuthorized() && (path !== "/" || path !== "/student/home")) {
    return (
      <Redirect
        to={{
          pathname: `/student/home`,
        }}
      />
    );
  }

  return <Route {...props} />;
};

const mapStateToProps = ({ auth }) => ({
  authData: auth,
});

const mapDispatchToProps = {
  checkTestStatus: checkTestStatusRequest,
};

const withSaga = injectSaga({ key, saga });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSaga(withRouter(App)));
