import React,{useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { Styles,StyledContainer,LogoIcon ,BreadCrumbsContainer} from "./styles";
import CNAPSLOGO from 'assets/HamburgerLogo.svg'
import BreadCrumbs from 'share/molecules/bread-crumbs'
import DropdownMenu from 'share/molecules/dropdown-menu'
import {connect} from 'react-redux';
import ProfileIcon from "assets/home-header/ProfileIcon";
import LogoutIcon from "assets/home-header/LogoutIcon";
import FaqIcon from "assets/home-header/FaqIcon";
import {StickyHeader} from 'share/organisms/headers'
import Modal from "share/organisms/modal";
import { getStudentProfileDetails } from "utils/utility/local-storage";

const routesList = [
  { to: "/", label: "Home" },
  { to: "/result", label: "Test Result" },
  { to: "/instructions", label: "Test Instructions" },
  { to: "/profile", label: "Profile" },
  { to: "/faqs", label: "Faqs" },
];
const subRoutesList = {
  "/result": [{ to: "/answerkey", label: "Answer Key" }],
};

const HomeHeader = (props) => {
  let history = useHistory();
  const [activeModal, setActiveModal] = useState("");

  let pathName = history.location.pathname;
  let routesWithoutLayout = [
    "/settings",
    "/settings/profile",
    "/settings/faqs",
  ];
  let isNotCommonLayout = routesWithoutLayout.includes(pathName);
  const [studentName, setStudentName] = useState("");
  // let studentName=getStudentProfileDetails().studentName

  const handleLogout = () => {
    setActiveModal("logout");
  };

  const confirmLogout = () => {
    const req = {
      from_admin:
        localStorage.getItem("shadow_login") === "true" ? true : false,
      callback: () => setActiveModal(""),
    };
    props.logout(req);
  };
  let profileData = [
    {
      id: 1,
      label: "Profile",
      icon: ProfileIcon,
      route: "/profile",
    },
    {
      id: 2,
      label: "FAQ's",
      icon: FaqIcon,
      route: "/faqs",
    },
    {
      id: 3,
      label: "Logout",
      icon: LogoutIcon,
      route: "/logout",
      fn: handleLogout,
    },
  ];
  const { isLoading } = props.logoutData;
  useEffect(() => {
    if (props.profileDetails.data) {
      setStudentName(props.profileDetails.data.studentName);
    } else {
      let details = getStudentProfileDetails();
      if (details) setStudentName(details.studentName);
    }
  }, [props.profileDetails]);

  const handleLogoClick = () => {
    let homePaths = ["/", "/home"];
    if (!homePaths.includes(pathName)) history.push({ pathname: "/" });
  };
  return (
    <>
      <StickyHeader {...props}/>
      <Styles isNotCommonLayout={isNotCommonLayout}>
        <Modal
          open={activeModal === "info"}
          handleClose={setActiveModal}
          headerText="Logout"
          body="Are you sure you want to log out?"
          onSubmit={confirmLogout}
        />

        <Modal
          open={activeModal === "logout"}
          handleClose={setActiveModal}
          headerText={
            <div>
              <br />
              Logout
            </div>
          }
          body={
            <div>
              <br />
              Are you sure you want to log out?
            </div>
          }
          onSubmit={confirmLogout}
          btnSet={{
            ok: {
              show: true,
              type: "outline",
              isLoading: isLoading,
              label: isLoading ? "Signing out.." : "Yes",
            },
            cancel: {
              show: true,
              type: "primary",
            },
          }}
        />

        <StyledContainer>
          <LogoIcon src={CNAPSLOGO} alt="cnaps" onClick={handleLogoClick} />
          <DropdownMenu
            data={profileData}
            name={studentName}
            history={history}
          />
        </StyledContainer>
        <BreadCrumbsContainer>
          <BreadCrumbs
            subroutesList={subRoutesList}
            routesList={routesList}
            history={history}
          />
        </BreadCrumbsContainer>
      </Styles>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const logoutData = auth.logoutReducer;
  const profileDetails = auth.profileReducer;
  return {
    logoutData,
    profileDetails,
  };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);


