import React from "react";
import { AccessCardContainer } from "./styles";
import CameraIcon from "assets/proctor/camera.svg";
import MicIcon from "assets/proctor/mic.svg";
import DisabledIcon from "assets/proctor/disabled.svg";
import EnabledIcon from "assets/proctor/enabled.svg";

function AccessCard({ type, isActive }) {
  const getDetails = () => {
    switch (type) {
      case "audio":
        return {
          text: "Microphone",
          icon: MicIcon,
        };
      case "video":
        return {
          text: "Camera",
          icon: CameraIcon,
        };
      default:
        return {
          text: "",
          icon: "",
        };
    }
  };
  return (
    <AccessCardContainer $isActive={isActive === "granted"}>
      <div className="icon">
        <img src={getDetails().icon} alt="icon" />
        <span className="text">{getDetails().text}</span>
      </div>
      <div className="accessibility">
        <div>
          {isActive === "granted"
            ? "Allowed"
            : isActive === "denied"
            ? "Grant Access"
            : "Not Allowed"}
        </div>
        {isActive === "granted" ? (
          <img src={EnabledIcon} alt="enabled" />
        ) : (
          <img src={DisabledIcon} alt="disabled" />
        )}
      </div>
    </AccessCardContainer>
  );
}

export default AccessCard;
