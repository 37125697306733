import styled from 'styled-components';

export const Image = styled.img`
  height: 25px;
  width: 25px;
`;

export const ComputerIconWrapper = styled.div`
  background: #f0ae0b;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 6px 12px 0 rgba(249, 197, 70, 0.4);
`;
export const OnlineLearningWrapper = styled(ComputerIconWrapper)`
  background-color: #09bb89;
  box-shadow: 0 6px 12px 0 rgba(38, 194, 150, 0.4);
`;
export const BrowserIconWrapper = styled(OnlineLearningWrapper)`
  background-color: #4884fa;
  box-shadow: 0 6px 12px 0 rgba(72, 132, 250, 0.4);
`;
