import styled from "styled-components";

export const Wrap = styled.div`
  background-color: #f9f9ff;
`;
export const Wrapper = styled.div`
  font-family: Poppins;
  padding: 24px;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 120px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 60px;
  margin-bottom: 60px;
`;
