import React from "react";
import styled from "styled-components";
import { Select } from "antd";
const { Option } = Select;

const Wrap = styled.div`
  .ant-select-dropdown {
    padding: 0;
  }
  .ant-select-item-option {
    background-color: white;
    padding: 12px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }
  .ant-select-item-option-disabled {
    background-color: #c4c4c4;
  }
`;

export default function SingleSelect({
  data,
  optionClassName,
  value,
  field,
  onChange,
  handleSelect,
  ...rest
}) {
  return (
    <Wrap>
      <Select
        showSearch
        style={{ width: "100%" }}
        optionLabelProp="label"
        value={value}
        onChange={(value) => onChange(value)}
        dropdownClassName="multi-select-drop"
        getPopupContainer={(trigger) => trigger.parentNode}
        {...rest}
      >
        {data &&
          data.map((opt) => (
            <Option
              className={optionClassName}
              key={opt.value}
              value={opt.value}
              label={opt.label}
              disabled={opt.isDisabled || false}
            >
              <div className="demo-option-label-item">
                &nbsp;&nbsp;{opt.label}
              </div>
            </Option>
          ))}
      </Select>
    </Wrap>
  );
}
