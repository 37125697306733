import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1366px) {
    width: 100%;
  }
`;

export const Container = styled.div`
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 36px;
  letter-spacing: 0.37px;
  flex-wrap: wrap;
  .welcome-text {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.37px;
    text-align: left;
    color: #d71a63;
  }
  .name {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.37px;
    text-align: left;
    color: #242424;
  }
`;
