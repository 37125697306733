import React, { useEffect, useState } from "react";
import MultiSelect from "share/atoms/Dropdown/multi-select";
import SingleSelect from "share/atoms/Dropdown/single-select";
import TextInput from "share/atoms/form-fields/TextInput";
import { getQueryParam } from "utils/utility/url";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { genderNames } from "./helper";
import { FaChevronDown } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {
  ButtonWrapper,
  Wrapper,
  CustomizeInputLabel,
  DeleteButton,
  ErrorText,
} from "./styles";

const IndividualRegistrationForm = ({
  data,
  index,
  handleInputChange,
  handleSelect,
  handleChange,
  handleDelete,
  showBorder,
  setFormsList,
  formsList,
  // mobileErrorCallback,
}) => {
  const location = useLocation();
  const admissionNumber = getQueryParam("admissionNumber", location);
  const { filters } = useSelector((state) => state.newRegistration);
  const { studentDetails } = useSelector((state) => state.newRegistration);
  // const [mobileError, setMobileError] = useState("");
  const [rollNumberError, setRollNumberError] = useState("");

  const enableValidation = process.env.REACT_APP_VALIDATE_PROD === "true";
  // console.log("abc", process.env.REACT_APP_VALIDATE_PROD === "true");
    // Roll number validation function
    const validateRollNumber = (rollNumber) => {
      if (enableValidation) {
        const rollNumberRegex = /^\d{7}$/;
        if (!rollNumberRegex.test(rollNumber)) {
          setRollNumberError("Invalid roll number format. Enter 7 digits only.");
        } else {
          setRollNumberError("");
        }
      }
    };
  
    const handleRollNumberChange = (event) => {
      const { value } = event.target;
      handleInputChange(event, index);
      if (enableValidation) {
        validateRollNumber(value);
      } else {
        // Clear any previous error in development
        setRollNumberError("");
      }
    };

  useEffect(() => {
    const dummyFormList = [...formsList];
    const resultFormList = dummyFormList.map((frm) => {
      if (data.class === frm.class && data.id === frm.id) {
        return { ...frm, exams: [] };
      } else {
        return frm;
      }
    });
    setFormsList(resultFormList);
  }, [data.class]);

  /**
   * this function returns the examlist
   * @returns {Array} returns examlist
   */
  const getKeyValue = () => {
    if (admissionNumber) {
      return studentDetails.data ? studentDetails.data["exams_list"] : [];
    } else if (data.class && filters.data.backend_data) {
      return filters?.data?.backend_data[data.class]?.examList;
    }
    return filters.data ? filters.data["exam_list"] : [];
  };

  /**
   * this function takes the data and display the drowndown data
   * @param {Array} arr
   * @returns {Array} returns dropdown data
   */
  const getDropdownData = (arr) => {
    if (arr && arr.length) {
      if (admissionNumber)
        return arr.reduce((acc, item) => {
          if (!item.is_paid)
            return (acc = [
              ...acc,
              {
                label: item.name || item.exam_name,
                value: item.name || item.exam_name,
              },
            ]);
          else return acc;
        }, []);
      else
        return arr.map((item) => ({
          label: item.name || item.exam_name,
          value: item.name || item.exam_name,
        }));
    }
    return [];
  };

  /**
   * Validate mobile number
   * @param {string} number - The mobile number to validate
   * @returns {boolean} - Returns true if the mobile number is valid
   */
  // const validateMobileNumber = (number) => {
  //   const mobileRegex = /^[6-9]\d{9}$/;
  //   return mobileRegex.test(number);
  // };

  // const handleMobileInputChange = (event, index) => {
  //   let { value } = event.target;
  //   const newvalue = value.replace(/[^0-9]+/g, "");
  //   if (!["6", "7", "8", "9"].includes(newvalue[0])) {
  //     setMobileError("Mobile numbers should start from 6, 7, 8 or 9 only");
  //     mobileErrorCallback(true);
  //   } else if (newvalue.length != 10) {
  //     setMobileError("Mobile numbers should be 10 digits only");
  //     mobileErrorCallback(true);
  //   } else {
  //     setMobileError("");
  //     mobileErrorCallback(false);
  //   }
  //   event.target.value = newvalue;
  //   handleInputChange(event, index);
  // };

  return (
    <Wrapper key={index} showBorder={showBorder}>
      <div className="row">
        <div className="col-xl-4">
          <CustomizeInputLabel>Student Name</CustomizeInputLabel>
          <TextInput
            variant="outlined"
            placeholder="Enter Student Name"
            name="name"
            disabled={admissionNumber ? true : false}
            value={data.name}
            onChange={(event) => handleInputChange(event, index)}
          />
        </div>
        <div className="col-xl-4">
          <CustomizeInputLabel>Sur Name</CustomizeInputLabel>
          <TextInput
            variant="outlined"
            placeholder="Enter Sur Name"
            name="surname"
            disabled={admissionNumber ? true : false}
            value={data.surname}
            onChange={(event) => handleInputChange(event, index)}
          />
        </div>
        <div className="col-xl-4">
          <CustomizeInputLabel>Class</CustomizeInputLabel>
          <SingleSelect
            data={
              filters && filters.data
                ? getDropdownData(filters.data["class_list"])
                : []
            }
            field="class"
            value={data.class}
            disabled={admissionNumber ? true : false}
            optionClassName={"antd-multi-select-option"}
            onChange={(value) => {
              handleSelect(value, "class", index);
            }}
            placeholder={"Select"}
            suffixIcon={<FaChevronDown />}
          />
        </div>
        <div className="col-xl-4">
          <CustomizeInputLabel>Gender</CustomizeInputLabel>
          <SingleSelect
            data={genderNames}
            field="gender"
            disabled={admissionNumber ? true : false}
            value={data.gender}
            optionClassName={"antd-multi-select-option"}
            onChange={(value) => {
              handleSelect(value, "gender", index);
            }}
            placeholder={"Select"}
            suffixIcon={<FaChevronDown />}
          />
        </div>
        <div className="col-xl-4">
          <CustomizeInputLabel>Select Exam</CustomizeInputLabel>
          <MultiSelect
            value={data.exams}
            data={getDropdownData(getKeyValue())}
            field={"exams"}
            showArrow={true}
            optionClassName={"antd-multi-select-option"}
            onChange={(e, data, key) => handleChange(e, data, key, index)}
            placeholder={"Select"}
            suffixIcon={<FaChevronDown />}
            disabled={Array.isArray(data.class)}
          />
        </div>
        <div className="col-xl-4">
          <CustomizeInputLabel>Roll Number</CustomizeInputLabel>
          <TextInput
            variant="outlined"
            placeholder="Enter Roll Number"
            name="rollnumber"
            value={data.rollnumber}
            disabled={admissionNumber ? true : false}
            onChange={handleRollNumberChange}
          />
          {rollNumberError && <ErrorText>{rollNumberError}</ErrorText>}
        </div>
        {/* <div className="col-xl-4">
          <CustomizeInputLabel>Mobile Number</CustomizeInputLabel>
          <TextInput
            variant="outlined"
            placeholder="Enter Mobile Number"
            name="mobile_number"
            value={data.mobile_number}
            disabled={admissionNumber ? true : false}
            onChange={(event) => handleMobileInputChange(event, index)}
          />
          {mobileError && <ErrorText>{mobileError}</ErrorText>}
        </div> */}
      </div>
      {index >= 1 && (
        <ButtonWrapper>
          <DeleteButton onClick={() => handleDelete(index)}>
            <MdDelete fontSize="30px" className="deleteicon" />
          </DeleteButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default IndividualRegistrationForm;
