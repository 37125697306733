import {keyframes} from 'styled-components';


export const fromRight = keyframes`
    from {
    transform: translate(150%, 0);
    }
    25% {
    transform: translate(150%, 0);
    }
    to {
    transform: translate(0, 0);
    }
`;


export const fromLeft=keyframes`
    from {
        transform: translate(-150%, 0);
    }
    25% {
        transform: translate(-150%, 0);
    }
    to {
        transform: translate(0, 0);
    }
`

export const fromTop=keyframes`
from {
      transform: translate(0, -150%);
    }
    25% {
      transform: translate(0, -150%);
    }
    to {
      transform: translate(0, 0);
    }
`
export const fromBottom=keyframes`
from {
      transform: translate(0,-150%);
    }
    25% {
      transform: translate(-150%,0);
    }
    to {
      transform: translate(0, 0);
    }
`
export const fadeIn=keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
}`
