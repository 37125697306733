import React, { useEffect, useState } from "react";
import Header from "../../../share/organisms/headers/features-header";
import Container from "../../../share/atoms/container/index";
import Footer from "../../LandingPage/Footer/index";
import { Wrapper, Wrap, DeleteButton } from "./styles";
import AuthenticationBlock from "./auth";
import VerificationBlock from "./verify";
import BasicModal from "share/atoms/basic-modal";
import { useResize } from "share/hooks/useResize";
import { useHistory } from "react-router-dom";
import ModalBlock from "./modal";
import BottomSheet from "share/organisms/bottom-sheet";
import AccountDeletionService from "./service";
import notify from "utils/notifications";
import { onlineDecorator } from "utils/helpers";
import { getCustomHeight, getDeviceType, getBrowserType } from "./helper";

const PrivacyPolicyPage = (props) => {
  const accountDeletionService = new AccountDeletionService();
  const history = useHistory();

  const [isModalClose, setIsModalClose] = useState(false);
  const [stepCount, setStepCount] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [OTP, setOTP] = useState("");

  const { width, height: screenHeight } = useResize();

  useEffect(() => [window.scrollTo(0, 0)], []);

  useEffect(() => {
    if (!isModalClose) {
      sessionStorage.clear();
    }
  }, [isModalClose]);

  const handleClose = () => {
    sessionStorage.clear();
    setIsModalClose((prev) => !prev);
    setStepCount(1);
  };
  const handleAuth = (isIndpendentStudent, phnNmbr) => {
    if (isIndpendentStudent) {
      setPhoneNumber(phnNmbr);
      sessionStorage.setItem("otp-sec", 60);
      setStepCount(2);
    } else {
      setStepCount(3);
    }
  };
  const handleVerify = (otp) => {
    setOTP(otp);
    setStepCount(4);
  };
  const handleDeleteCallback = async () => {
    try {
      const res = await accountDeletionService.deleteStudent({ otp: OTP });
      if (res.data.status === "success") {
        setStepCount(5);
      } else {
        notify("error", res.data.message);
      }
    } catch (error) {
      notify("error", error.data.message);
    }
  };
  const handleDelete = onlineDecorator(handleDeleteCallback);
  const modalRender = () => {
    if (isModalClose && stepCount === 1) {
      return <AuthenticationBlock handleAuth={handleAuth} />;
    }
    if (isModalClose && stepCount === 2) {
      return (
        <VerificationBlock
          phoneNumber={phoneNumber}
          handleClose={handleClose}
          handleVerify={handleVerify}
        />
      );
    }
    if (isModalClose && stepCount === 3) {
      return (
        <ModalBlock
          type="not_allowed"
          isModalClose={isModalClose}
          handleClose={handleClose}
        />
      );
    }
    if (isModalClose && stepCount === 4) {
      return (
        <ModalBlock
          type="warning"
          isModalClose={isModalClose}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      );
    }
    if (isModalClose && stepCount === 5) {
      return (
        <ModalBlock
          type="success"
          isModalClose={isModalClose}
          handleClose={() => history.push("/")}
        />
      );
    }
  };
  const getDeleteButton = () => {
    return width <= 980 &&
      getDeviceType() === "computer" &&
      getBrowserType() === "samsung" ? (
      <DeleteButton
        onClick={handleDeleteAccount}
        style={{
          scale: "1.4",
          padding: "10px 24px",
          marginLeft: "38px",
        }}
      >
        Delete My Account
      </DeleteButton>
    ) : width <= 980 &&
      getDeviceType() === "computer" &&
      getBrowserType() === "chrome" ? (
      <DeleteButton
        onClick={handleDeleteAccount}
        style={{
          scale: "1.2",
          padding: "10px 24px",
          marginLeft: "24px",
        }}
      >
        Delete My Account
      </DeleteButton>
    ) : (
      <DeleteButton onClick={handleDeleteAccount}>
        Delete My Account
      </DeleteButton>
    );
  };

  const handleDeleteAccount = onlineDecorator(() => setIsModalClose(true));
  return (
    <>
      {width <= 480 && [1, 2, 4].includes(stepCount) ? (
        <BottomSheet
          show={isModalClose}
          handleClose={handleClose}
          style={stepCount === 4 ? { padding: "24px 16px" } : {}}
          isEnableCloseIcon={[1, 2].includes(stepCount)}
          isDisableOverlayAction={true}
        >
          {modalRender()}
        </BottomSheet>
      ) : (
        <BasicModal
          show={isModalClose}
          handleClose={handleClose}
          width={"512px"}
          padding={width <= 480 ? "16px" : "48px"}
          isDisableCloseButton={stepCount === 3 || stepCount === 5}
        >
          {modalRender()}
        </BasicModal>
      )}
      <div style={{ backgroundColor: "#f9f9ff" }}>
        {!props.isHome && <Header {...props} />}
        <Wrap>
          <Container>
            <Wrapper>
              <div style={{ height: "100vh", overflowY: "scroll" }}>
                <iframe
                  id="iframe"
                  width="100%"
                  style={{
                    height: getCustomHeight(screenHeight),
                    border: "none",
                  }}
                  src="https://downloads.cnaps.co.in/privacy-policy/v1/CNAPS_PRIVACY_POLICY_WITH_DELETE.html"
                  title="privacy-policy"
                />
                {getDeleteButton()}
              </div>
            </Wrapper>
          </Container>
        </Wrap>
        <Footer />
      </div>
    </>
  );
};
export default PrivacyPolicyPage;
