import React from "react";
import { Wrapper, FormFieldsWrapper } from "./styles";
const ProfileDetails = ({ heading, dataArr }) => {
  return (
    <Wrapper>
      <div className="heading">{heading}</div>
      <hr className="horizontal" />
      {dataArr &&
        dataArr.map(({ label, value }) =>
          (value||label=="Mobile Number") ? (
            <FormFieldsWrapper key={label}>
              <div className="label">{label}</div>
              <div className="input">{value}</div>
            </FormFieldsWrapper>
          ) : null
        )}
    </Wrapper>
  );
};

export default ProfileDetails;
