import { Breadcrumb } from "antd";
import styled from "styled-components";
import { SubmitWrapper } from "../ListDeliveries/styles";

//index
export const BreadcrumbWrapper = styled(Breadcrumb)`
  .heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.57;
    color: #d71a63;
  }

  .value {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: #242424;
  }
  .ant-breadcrumb-separator {
    color: #d71a63;
  }
`;
export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: #242424;
  margin: 12px 0px 16px 0px;
`;

export const PaperForm = styled.div`
  padding: 0 16px 0 12px;
  border-radius: 6px;
  background-color: #ffffff;
`;
export const StudentHeading = styled(Heading)`
  margin-top: 20px;
  margin-bottom: 12px;
`;
export const TableWrapper = styled.div`
  padding: 24px;
`;
//form
export const SpacingWrapper = styled.div`
  margin: 12px 0px 12px 0px;
  @media screen and (max-width: 767px) {
    margin: 16px 0px 16px 0px;
  }
`;
export const Wrappering = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .lines {
    width: 1px;
    height: 100%;
    background-color: #e7e9ed;
    position: absolute;
    right: 50%;
    top: 0%;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      right: -0%;
    }
  }
  .or-styles {
    position: absolute;
    /* top: calc(50% - 16px);
    right: calc(100% - 16px); */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    padding: 2px 5px 2px 4px;
    border-radius: 16px;
    background-color: #254082;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    color: #fff;
    z-index: 0;
  }
  @media screen and (max-width: 767px) {
    padding: 5px 0 5px 0;
  }
`;

export const StyledSubmitWrapper = styled(SubmitWrapper)`
  /* margin: 16px 0px 0px 0px; */
  height:100%;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin: 0px 0px 16px 0px;
  }
`;
//refresh-icon
export const RefreshWrapper = styled.div`
  cursor: pointer;
`;
//table
export const Wrapper = styled.div`
  padding: 12px;
  border-radius: 6px;
  background-color: #fff;
  .ant-table-thead > tr > th {
    line-height: 1.33;
    font-size: 14px;
    font-weight: 500;
    background-color: #f2f2f2;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
     color: #242424;
    padding: 8px 12px;
    border:0.5px solid #e6e6e6
  }
  .ant-table-tbody > tr > td {
    background-color: #fff;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background:  #fff !important;
}   

  //pagination
  .ant-pagination{
    display:flex;
    align-items: center;

  }
  .ant-select-show-arrow{
    min-width:140px;
  }

  .ant-pagination-item-active  {
    border-color: #254082;
}

  .ant-pagination-item-active a {
    color: #254082;
}

.ant-pagination-item:hover{
  border-color:rgba(37,64,130,0.8);
}

 .ant-pagination-item:hover a {
   color:rgba(37,64,130,0.8);
 }

`;

//input
export const InputWrapper = styled.div`
  min-width: 150px;
  position: relative;
  .input-styles {
    margin: 0px;
    padding: 8px;
    width: 100%;
    outline: none;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #253858;
  }
  .close-icon {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 2;
    top: 2px;
    height: 30px;
    cursor: pointer;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    color: #242424;
    line-height: 1.33;
    font-size: 14px;
    font-weight: 500;
  }
  .search-icon {
    cursor: pointer;
  }
`;

//helper
export const Paragraph = styled.p`
  color: #242424;
  line-height: 1.33;
  font-size: 14px;
  font-weight: 500;
`;
