import Button from "share/atoms/buttons";
import styled from "styled-components";
import GlobalContainer from "share/atoms/GlobalContainer";
export const Container = styled(GlobalContainer);

export const Wrapper = styled.div`
  display: flex;
  background-color: #f9f9ff;

  padding: 100px 0;
  @media screen and (max-width: 1024px) {
    padding: 50px 0;
    gap: 45px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ResearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    font-family: Poppins;
    font-size: clamp(1.5rem, 1.1538rem + 1.5385vw, 2.25rem);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-top: 24px;
  }

  @media screen and (max-width: 912px) {
    .header {
      text-align: left;
    }
    .description {
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    .header {
      text-align: center;
    }
    .description {
      text-align: center;
    }
  }
`;

export const WrapButton = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: left;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
export const StyledButton = styled(Button)`
  width: fit-content;
  border: none;
  height: 48px;
  border-radius: 60px;
  background-color: #d71a63;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 38px;
  @media screen and (max-width: 1024px) {
  }
`;

export const ImageWrapper = styled.div`
  img {
    width: clamp(18.75rem, 12.9808rem + 25.641vw, 31.25rem);
  }
  @media screen and (max-width: 912px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
