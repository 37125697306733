import React from "react";
import { CardWrapper, Logo, Title, Content, LeftTopImg } from "./style";
import LeftDotsLogo from "assets/landingpage/legacy/left-dots.svg";

function Card({ item, id }) {
  return (
    <CardWrapper>
      <Logo>
        <img src={item.logo} alt="" />
      </Logo>
      <Title>{item.title}</Title>
      <Content>{item.content}</Content>
    </CardWrapper>
  );
}

export default Card;
