import React from "react";
import PropTypes from "prop-types";
import { StyledInput } from "./styles";
// import useFocus from 'share/hooks/useFocus'

const TextInput = ({ handleChange, name, type, error, ...rest }) => {
  // const [inputRef, setFocus] = useFocus();
  // useEffect(() => {
  //   setFocus();
  // }, []);
  return (
    <StyledInput
      onChange={handleChange}
      autoComplete="off"
      error={error}
      // ref={inputRef}
      name={name}
      {...rest}
      type={type}
    />
  );
};

TextInput.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  handleChange: () => {},
  name: "",
  type: "",
};
export default TextInput;
