import * as types from "./types";

export const getProctorConfigRequest = (payload) => ({
  type: types.GET_PROCTOR_CONFIG_REQUEST,
  payload,
});

export const getProctorConfigSuccess = (payload) => ({
  type: types.GET_PROCTOR_CONFIG_SUCCESS,
  payload,
});

export const getProctorConfigError = (error) => ({
  type: types.GET_PROCTOR_CONFIG_ERROR,
  error,
});

export const setImageProctorRequest = (payload) => ({
  type: types.SET_IMAGE_PROCTOR_REQUEST,
  payload,
});

export const setImageProctorSuccess = (payload) => ({
  type: types.SET_IMAGE_PROCTOR_SUCCESS,
  payload,
});

export const setImageProctorError = (error) => ({
  type: types.SET_IMAGE_PROCTOR_ERROR,
  error,
});

export const updateProctorConsentRequest = (payload) => ({
  type: types.UPDATE_PROCTOR_CONSENT_REQUEST,
  payload,
});

export const updateProctorConsentSuccess = (payload) => ({
  type: types.UPDATE_PROCTOR_CONSENT_SUCCESS,
  payload,
});

export const updateProctorConsentError = (error) => ({
  type: types.UPDATE_PROCTOR_CONSENT_ERROR,
  error,
});

export const updateProctorEventRequest = (payload) => ({
  type: types.UPDATE_PROCTOR_EVENT_REQUEST,
  payload,
});

export const updateProctorEventSuccess = (payload) => ({
  type: types.UPDATE_PROCTOR_EVENT_SUCCESS,
  payload,
});

export const updateProctorEventError = (error) => ({
  type: types.UPDATE_PROCTOR_EVENT_ERROR,
  error,
});

export const proceedTestRequest = (payload) => ({
  type: types.PROCEED_TEST_REQUEST,
  payload,
});
export const proceedTestSuccess = (payload) => ({
  type: types.PROCEED_TEST_SUCCESS,
  payload,
});
export const proceedTestError = (error) => ({
  type: types.PROCEED_TEST_ERROR,
  error,
});

export const examDetailsRequest = (payload) => ({
  type: types.EXAM_DETAILS_REQUEST,
  payload,
});
export const examDetailsSuccess = (payload) => ({
  type: types.EXAM_DETAILS_SUCCESS,
  payload,
});
export const examDetailsError = (error) => ({
  type: types.EXAM_DETAILS_ERROR,
  error,
});
