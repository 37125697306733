import React, { useState, useRef } from "react";
import Arrow from "./assets/arrow-down";
import {
  AccordionItemWrapper,
  AccordionHeader,
  AccordionBody,
  Title,
  AccordionContainer,
  ExpansionText,
  BorderBottom,
  DescriptiveWrapper,
} from "./styles";
const Accordion = ({
  data = [],
  arrows = true,
  defaultStyles = true,
  exapandText = "",
  isExpanded,
  leftComp,
  variant = "normal",
}) => {
  return (
    <AccordionContainer variant={variant}>
      {data.map((each, index) => (
        <AccordionItem
          variant={variant}
          leftComp={leftComp}
          isAlreadyExpanded={isExpanded}
          {...each}
          arrows={arrows}
          defaultStyles={defaultStyles}
          exapandText={exapandText}
        />
      ))}
    </AccordionContainer>
  );
};

const AccordionItem = ({
  leftComp,
  isAlreadyExpanded,
  activeColor,
  color,
  title,
  body,
  desc,
  arrows,
  defaultStyles,
  exapandText,
  variant = "normal",
}) => {
  const [isExpanded, setIsExpanded] = useState(isAlreadyExpanded);
  const [setHeight, setHeightState] = useState("0px");
  const content = useRef(null);

  const handleToggleAccordion = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
    setHeightState(isExpanded ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {leftComp && <div>{leftComp}</div>}
      <AccordionItemWrapper>
        <AccordionHeader
          defaultStyles={defaultStyles}
          isExpanded={isExpanded}
          onClick={handleToggleAccordion}
          color={color}
          activeColor={activeColor}
        >
          <Title variant={variant}>{title}</Title>

          {arrows && <Arrow fill={isExpanded ? activeColor : color} />}
        </AccordionHeader>
        <DescriptiveWrapper>
          {variant === "descriptive" && desc}
        </DescriptiveWrapper>
        <AccordionBody maxHeight={setHeight} ref={content}>
          {isExpanded && body}
          {!arrows && (
            <ExpansionText onClick={handleToggleAccordion}>
              {isExpanded ? "Collapse " : "Expand "}
              {exapandText}
            </ExpansionText>
          )}
          {variant === "normal" && <BorderBottom />}
        </AccordionBody>
      </AccordionItemWrapper>
    </div>
  );
};

AccordionItem.defaultProps = {
  activeColor: "#d71a63",
  color: "#242424",
};

Accordion.defaultProps = {};
export default Accordion;
