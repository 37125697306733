import AnalyticsLogo from "assets/landingpage/explore/analytics.svg";
import CertificateLogo from "assets/landingpage/explore/certificate.svg";
import OnlieExamLogo from "assets/landingpage/explore/online-exam.svg";
import UseLogo from "assets/landingpage/explore/use.svg";

export const exploredata = [
  {
    title: "Flexible & Proctored Online Exam",
    content:
      "Write your preferred All India Exam online from either web or mobile from anywhere with tap of a button.",
    image: OnlieExamLogo,
  },
  {
    title: "Performance Analytics",
    content:
      "Get deep insights to the Exam performance with 5Q Analysis . We provide best-in-industry standards advanced analytics.",
    image: AnalyticsLogo,
  },
  {
    title: "Easy and Simple to use",
    content:
      "You can write the test from anywhere with a click of a button. You can attempt the exam from laptop, desktop or mobile.",
    image: UseLogo,
  },
  {
    title: "Certificate & Prizes",
    content:
      "Get an authorized certificate of participation in this prestigious All India Olympiads and win exciting prizes.",
    image: CertificateLogo,
  },
];
