import { getIdsAndCategory } from "./url";

export const checkInternet = () => {
  return navigator.onLine;
};

export const getSecQuesIds = (obj) => {
  let ob = {};
  if (obj) {
    Object.keys(obj).map((item) => {
      if (obj[item].answer && obj[item].status !== "not_visited")
        ob[obj[item].section_id] = ob[obj[item].section_id]
          ? [...ob[obj[item].section_id], obj[item].question_id]
          : [obj[item].question_id];
    });
    return ob;
  }
  return ob;
};

export const getTestIndex = (path) => {
  if (!path || !path.includes(",")) return false;
  const flags = path.split(",");
  let idx = -1;
  for (let i = 0; i < flags.length; i++) {
    if (flags[i] == 0) {
      idx = i;
      break;
    }
  }
  return idx;
};

export const filterAndJoin = (path) => {
  if (path && !path.includes(",")) return 1;
  const arr = path && path.split(",");
  const index = getTestIndex(path);
  const res = arr.map((item, idx) => {
    if (idx === index) return 1;
    else return item;
  });
  return res.join(",");
};

export const getIds = (location) => {
  const arr = location.pathname.split("/")[1].split("-");
  const params = {
    test_id: arr[0],
    delivery_id: arr[1],
    exam_category: arr[2],
    flags: arr[3],
  };

  return params;
};

export const getParams = (location) => {
  const arr = location.pathname.split("/")[2].split("-");
  const idx = getTestIndex(arr[3]);
  const params = {
    test_id: getIdsAndCategory(arr[0], idx),
    delivery_id: getIdsAndCategory(arr[1], idx),
    exam_category: getIdsAndCategory(arr[2]),
    flags: arr[3],
  };

  return params;
};

export const getFlags = (path) => {
  return path.split("-")[3];
};

export const getAnswersByQuestionId = (arr) => {
  let template = {};
  arr.map((ans) => {
    template = { ...template, [ans.question_id]: ans };
  });

  return template;
};

export const EXAM_TYPES = {
  MOCK_TEST: "mock_test",
  SCHEDULED_TEST: "scheduled_test",
  MISSED_TEST: "missed_test",
};

export const isMissedTest = (examCategory) =>
  examCategory === EXAM_TYPES.MISSED_TEST;

export const isMockTest = (examCategory) =>
  examCategory === EXAM_TYPES.MOCK_TEST;

export const isScheduledTest = (examCategory) =>
  examCategory === EXAM_TYPES.SCHEDULED_TEST;

export const isMissedScheduledTest = (category) =>
  [EXAM_TYPES.MISSED_TEST, EXAM_TYPES.SCHEDULED_TEST].includes(category);

export const isMissedScheduledTestPath = (pathname) =>
  pathname.includes(EXAM_TYPES.SCHEDULED_TEST) ||
  pathname.includes(EXAM_TYPES.MISSED_TEST);

export const navigateToTestEnginge = (exam, type, history) => {
  const {
    test_id,
    delivery_id,
    mock_test,
    paper_status,
    exam_model_id,
    category,
    exam_name,
  } = exam;

  if (type === EXAM_TYPES.SCHEDULED_TEST || type === EXAM_TYPES.MISSED_TEST) {
    if (
      paper_status === "started" ||
      paper_status === "in_progress" ||
      paper_status === "expired"
    ) {
      sessionStorage.setItem("model-name", exam_name);
      history.push(`/instructions/${test_id}-${delivery_id}-${type}-0`);
    } else if (paper_status === "submitted")
      history.push(
        `/result/${test_id}-${delivery_id}-${category}?olympiad=${exam_name}&examCategory=${type}`
      );
  } else {
    sessionStorage.setItem("mock-model-id", exam_model_id);
    history.push(
      `/instructions/${mock_test?.mock_test_id}-${mock_test?.mock_delivery_id}-${type}-0`
    );
  }
};
