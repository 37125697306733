import CustomHeaderIcon from "share/custom-header-icon";
import { getLocalObj } from "utils/utility/local-storage";

export const options = [
  {
    id: 1,
    icon: CustomHeaderIcon,
    name: "Profile",
    iconName: "profile",
  },
  {
    id: 5,
    icon: CustomHeaderIcon,
    name: "Logout",
    iconName: "logout",
  },
];
const faqs = {
  id: 4,
  icon: CustomHeaderIcon,
  name: "FAQ'S",
  iconName: "faq",
};
const changePwd = {
  id: 2,
  icon: CustomHeaderIcon,
  name: "Change Password",
  iconName: "changePassword",
};
const history = {
  id: 3,
  icon: CustomHeaderIcon,
  name: "Purchase History",
  iconName: "paymentReceipt",
};

export const getOptions = () => {
  const loginType = localStorage.getItem("login-type");
  const tokenData = getLocalObj("token-data");
  if (loginType === "student" && tokenData?.is_independent_student) {
    return [
      options[0],
      { ...changePwd },
      { ...history },
      { ...faqs },
      options[1],
    ];
  } else if (loginType === "student") {
    return [options[0],{...changePwd}, { ...faqs }, options[1]];
  } else if (tokenData?.role === "da"||tokenData?.role==="super_admin") {
    return [
      {
        id: 5,
        icon: CustomHeaderIcon,
        name: "Logout",
        iconName: "logout",
      },
    ];
  }
  else if(loginType==="monitor"){
      return [options[1]];
  }
  
  else if (tokenData?.role === "admin") {
    return [options[0], { ...changePwd }, options[1]];
  } else {
    return [
      options[0],
      { ...changePwd },
      { ...history },
      { ...faqs },
      options[1],
    ];
  }
};