import styled from "styled-components";
import Tick from "assets/exams/IconTick.svg";
export const WrapContainer = styled.div`
  padding: 5% 10%;
  background-color: #f9f9ff;
`;
export const Wrap = styled.div`
  padding: 60px 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 25px auto 120px auto; */
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  padding-bottom: 24px;
`;
const Heading = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px 35px;
  @media screen and (max-width: 600px) {
    margin: 4px 8px;
  }
`;
const HeadingText = styled.div`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`;
const ColoredText = styled.div`
  margin-left: 10px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #d71a63;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`;
const ColoredText2 = styled.div`
  /* margin: 5px 35px; */
  margin: 16px 24px 12px 24px;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #464646;
`;
const HeadingText2 = styled.div`
  /* margin-top: 20px; */
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (width: 360px) {
    font-size: 15px;
  }
`;
const StyledDiv = styled.div`
  /* height: 224px; */
  /* margin: 4px 35px; */
  margin: 12px 24px 0 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;
const ValuesList = styled.ul`
  margin-left: 20px;
  /* list-style-image: url(${Tick}); */
  li {
    margin-bottom: 10px;
  }
`;
const Div2 = styled.div`
  /* height: 224px; */
  /* margin: 5px 35px; */
  margin: 0 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  ul {
    margin-left: 20px;
    li {
      /* margin: 3px 10px; */
      margin: 12px 0;
    }
  }
`;
const SubHeading = styled.div`
  /* margin: 17px 35px; */
  margin: 24px 24px 16px 24px;
  font-family: Poppins;
  font-size: ${(props) => (props.type === "h" ? "20px" : "20px")};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #d71a63;
`;

const SmallDiv = styled.div`
  width: 556px;
  height: 209px;
  background-color: #f9f9ff;
  margin: 20px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  display: flex;
  padding: 25px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const CardHeading = styled.div`
  width: 369px;
  height: auto;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;
const Description = styled.div`
  margin-top: 14px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.75; */
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
`;
export {
  Wrapper,
  ValuesList,
  Heading,
  HeadingText,
  ColoredText,
  ColoredText2,
  HeadingText2,
  StyledDiv,
  Div2,
  SubHeading,
  SmallDiv,
  Right,
  Left,
  CardHeading,
  Description,
};
