import { put, call, select, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import request from "utils/axios/request";
import InstructionService from "containers/instructions/services";
import notify from "utils/notifications";
import { getParams } from "utils/utility/test-engine";
import { CHECK_TEST_STATUS_REQUEST } from "containers/test/types";
import { getValues } from "containers/test/helpers";
import { makeSelectRouter } from "containers/test/selectors";
import * as actions from "containers/test/actions";
import * as storageUtils from "utils/localStorageUtils";
import * as schoolActions from "school-admin/Login/actions";
import {
  loginTypes,
  endpoints as schoolEndpoints,
} from "school-admin/Login/constants";

const instructionService = new InstructionService();

const getFlags = (flags) => {
  if (flags.includes("`")) {
    return flags.split("`")[0];
  } else return flags;
};

export function* proceedTestStatus(action) {
  try {
    const location = yield select(makeSelectRouter());
    const params = yield getParams(location);
    const flags = yield getFlags(params.flags);
    params["flags"] = flags;
    const res = yield call(instructionService.proceedTest, params);
    if (res.data.status === "success") {
      const { data } = res.data;
      if (
        data &&
        (data.exam_state === "suspended" || data.exam_state === "submitted")
      ) {
        notify("error", res.data.message);
        yield put(actions.checkTestStatusError(res.data));
      } else {
        yield put(actions.checkTestStatusSuccess(data));
        if (
          params.exam_category === "mock_test" &&
          data.exam_state === "not_started"
        ) {
          const id = getValues(params).join("-");
          const profile = localStorage.getItem("admsnNumber")
            ? { admissionNumber: localStorage.getItem("admsnNumber") }
            : { admissionNumber: 1234 };
          const uniqId = `${id}-${profile.admissionNumber}`;
          // if (storageUtils.getTestAnswers(uniqId))
          //   storageUtils.removeTestAnswers(uniqId);
          if (storageUtils.getSyncIntervals(uniqId))
            storageUtils.removeSyncIntervals(uniqId);
          if (storageUtils.getPreviousTime(uniqId))
            storageUtils.removePreviousTime(uniqId);
          // if (storageUtils.getExamStatus(uniqId))
          //   storageUtils.removeExamStatus(uniqId);
          if (storageUtils.getQuestionPreviousTime(`${uniqId}-question_time`))
            storageUtils.removeQuestionPreviousTime(`${uniqId}-question_time`);
        }
      }
    } else if (res.data.status === "error") {
      const { data } = res.data;
      if (
        data &&
        (data.exam_state === "suspended" || data.exam_state === "submitted")
      ) {
        notify("error", res.data.message);
        yield put(actions.checkTestStatusError(res.data));
        yield put(
          replace(
            `/thankyou/${params.test_id}-${params.delivery_id}-${
              params.exam_category
            }-${1}`
          )
        );
      } else if (res.data.errorCode === "ATTENDANCE_NOT_TAKEN") {
        notify("error", res.data.message);
        yield put(actions.checkTestStatusError(res.data));
      }
    }
  } catch (e) {
    // notify('error', e.response);
    yield put(actions.checkTestStatusError(e.response));
  }
}

function* schoolLogoutWorker(action) {
  try {
    const res = { data: { status: "success" } };
    if (res.data.status === "error") {
      yield put(schoolActions.logoutError(res.data));
      // notify('error', res.data.message);
    } else {
      yield put(schoolActions.logoutSuccess(res.data));
      yield localStorage.removeItem("token");
      yield localStorage.removeItem("token-data");
      const type = localStorage.getItem("login-type");
      localStorage.removeItem("login-type");
      if (type === "student") yield put(push(`/`));
      else yield put(push(`/school`));
    }
  } catch (e) {
    yield put(schoolActions.logoutError(e));
  }
}

function* schoolProfileWorker(action) {
  try {
    const res = yield call(request.get, schoolEndpoints.PROFILE);
    if (res.data.status === "success") {
      yield localStorage.setItem("profile", JSON.stringify(res.data.data));
      yield put(schoolActions.profileSuccess(res.data.data));
    } else {
      yield put(schoolActions.profileError(res.data));
    }
  } catch (e) {
    yield put(schoolActions.profileError(e));
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(loginTypes.LOGOUT_REQUEST, schoolLogoutWorker);
  yield takeLatest(loginTypes.PROFILE_REQUEST, schoolProfileWorker);
  yield takeLatest(CHECK_TEST_STATUS_REQUEST, proceedTestStatus);
}
