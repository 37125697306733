import DropdownOptionTemp from "../DropdownOptionTemp";
import { Wrapper } from "./styles";
const FaqTemplate = () => {
  return (
    <DropdownOptionTemp headingText={"FAQs"}>
      <Wrapper>
        <iframe
          width="100%"
          style={{ border: "none", height: "800px" }}
          src="https://downloads.cnaps.co.in/faqs/v1/CNAPS_FAQS.html"
          title="instructions"
        />
      </Wrapper>
    </DropdownOptionTemp>
  );
};

export default FaqTemplate;
