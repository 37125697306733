
import { combineReducers } from 'redux';
import studentListMonitorReducer from './student-list-monitor-reducers';


 const MonitorTestTakersReducer = combineReducers({
    studentListMonitor:studentListMonitorReducer
  });

  export default MonitorTestTakersReducer



 