import produce from 'immer';
import { combineReducers } from 'redux';
import { forgotTypes } from './constants';

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

export const sendOtpReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case forgotTypes.SEND_OTP_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case forgotTypes.SEND_OTP_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case forgotTypes.SEND_OTP_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
});

export const verifyOtpReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case forgotTypes.VERIFY_OTP_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case forgotTypes.VERIFY_OTP_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case forgotTypes.VERIFY_OTP_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      case forgotTypes.RESET_OTP:
        draft.error = false;
        draft.isLoading = false;
        draft.data = false;
      default:
        draft = state;
        break;
    }
});


const ForgotPasswordReducer = combineReducers({
  sendOtpReducer,
  verifyOtpReducer,
});

export default ForgotPasswordReducer;
