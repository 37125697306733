import { Redirect } from "react-router-dom";

export const redirectToPath = (path) => {
  return (
    <Redirect
      to={{
        pathname: path,
      }}
    />
  );
};

export const multiPaperExist = (location) => {
  if (location.pathname.includes(",")) return true;
  return false;
};

export const getIdsAndCategory = (path, idx) => {
  return path.includes(",") ? path.split(",")[idx] : path;
};

/**
 * this function returns path based on the route
 * @returns {string} return path
 */
export const getRouteBasePath = () => {
  return `/${window.location.pathname.split("/")[1]}`;
};

/**
 * this function helps to  access parameters of value  through key in the url
 * @param {string} param
 * @param {Object} location
 * @returns {string|boolean} return value from params 
 */

export const getQueryParam = (param, location) => {
  let str = location.pathname.includes(param)
    ? location.pathname
    : location.search;
  let result = str.match(new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)"));
  return result ? result[3].replaceAll("%22", "") : false;
};

/**
 * to check if it is school path or not
 * @param {Object} location
 * @param {string} path
 * @returns {boolean} return true/false
 */
export const isPathExist = (location, path) =>
  location.pathname.split("/").includes(path);
