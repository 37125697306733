import {
    PROCEED_TEST_REQUEST,
    PROCEED_TEST_SUCCESS,
    PROCEED_TEST_ERROR,
    EXAM_DETAILS_SUCCESS,
    EXAM_DETAILS_ERROR,
    EXAM_DETAILS_REQUEST
  } from './types';
  
  export const proceedTestRequest = payload => ({
    type: PROCEED_TEST_REQUEST,
    payload,
  });
  export const proceedTestSuccess = payload => ({
    type: PROCEED_TEST_SUCCESS,
    payload,
  });
  export const proceedTestError = error => ({
    type: PROCEED_TEST_ERROR,
    error,
  });

  export const examDetailsRequest = payload => ({
    type: EXAM_DETAILS_REQUEST,
    payload,
  });
  export const examDetailsSuccess = payload => ({
    type: EXAM_DETAILS_SUCCESS,
    payload,
  });
  export const examDetailsError = error => ({
    type: EXAM_DETAILS_ERROR,
    error,
  });
  