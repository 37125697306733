import ExcelIcon from "assets/school-admin/icons/excel.svg";
import RegistrationIcon from "assets/school-admin/icons/registration.svg";
import TimeIcon from "assets/school-admin/icons/time.svg";
import notify from "utils/notifications";
import { CustomizableTitle } from "./styles";
import { saveAs } from "file-saver";
import { getExamValues } from "utils/utility/table";

export const getColumns = (data, examFunc) => {
  return [
    {
      title: "Student name",
      dataIndex: "name",
      key: "name",
      width: 60,
      fixed: "left",
    },
    {
      title: "Sur name",
      dataIndex: "sur_name",
      key: "surname",
      width: 60,
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      width: 60,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 60,
    },
    {
      title: "Roll number",
      dataIndex: "roll_number",
      key: "rollnumber",
      width:90
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 140
    },
    {
      title: "Exams",
      children: examFunc(data),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 60,
      fixed: "right",
    },
  ];
};

export const getTableDataSource = (data) => {
  if (!data) return [];
  return data.registration_summary.map((item) => {
    let exams = getExamValues(item.exams_list);
    return {
      ...item,
      ...exams,
    };
  });
};

export const getPendingTableDataSource = (data) => {
  if (!data) return [];
  return data.map((item) => {
    let exams = getExamValues(item.exams_list);
    return {
      ...item,
      ...exams,
    };
  });
};

export const downloadPdf = (url) => {
  if (url) {
    try {
      let arr = url.split("/");
      const fileName = arr ? arr[arr.length - 1] : "";
      saveAs(url, fileName);
    } catch (error) {
      notify("error", "Something went wrong!");
    }
  } else {
    notify("info", "Payment receipt not yet generated");
  }
};

export const NewRegistratonData = [
  {
    id: 1,
    iconName: ExcelIcon,
    headingText: "Bulk Registration",
    buttonText: "Upload Excel Sheet",
    descriptionText: "Excel sheet fill and upload",
    feature: "bulk",
    hasDownloadText: true,
    width: "230px",
    disabled: false,
  },
  {
    id: 2,
    headingText: "Individual Registration",
    iconName: RegistrationIcon,
    buttonText: "Register Individually",
    feature: "individual",
    descriptionText:
      "Make student entries one by one by filling out the student registration forms.",
    hasDownloadText: false,
    path: "/school/new-registration/individual",
    width: "271px",
    disabled: false,
  },
  {
    id: 3,
    headingText: "Pending Registrations",
    iconName: TimeIcon,
    buttonText: "View",
    feature: "pending",
    descriptionText: "Please complete the payments to register these students",
    hasDownloadText: false,
    path: "/school/new-registration/summary",
    width: "192px",
    disabled: false,
  },
  ,
];

export const paymentColumns = [
  {
    title: "S. No",
    dataIndex: "sno",
    key: "sno",
    width: 50,
    fixed: "left",
  },
  {
    title: "Exams",
    dataIndex: "exam_name",
    key: "exam_name",
    width: 60,
  },
  {
    title: "No of Students Registered",
    dataIndex: "count",
    key: "count",
    width: 60,
  },
  {
    title: () => (
      <CustomizableTitle>
        <p className="heading">Each Exam Fee</p>
        <p className="sub-heading">(Includes tax)</p>
      </CustomizableTitle>
    ),
    dataIndex: "fee",
    key: "fee",
    width: 60,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 60,
  },
];

export const handlingChargeCol = [
  {
    title: "",
    dataIndex: "text",
    key: "text",
    fixed: "left",
  },
  {
    title: "All Exam Registered",
    dataIndex: "total_exams",
    key: "total_exams",
  },
  {
    title: "Per Registration",
    dataIndex: "per_reg_charge",
    key: "per_reg_charge",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
];

export const getExamPhaseNames = {
  ISMO: "Mathematical olympaid",
  ISSO: "Science Olympaid",
  ISKO: "Knowledge Olympaid",
  ISEO: "English Olymopaid",
  ISCO: "Computer Olympaid",
};

export const getSelectedValues = (data) => {
  const selectedRadio = data.exam_phase_list.map((d) => ({
    exam_id: d.exam_id,
    phase_list: d.phase_list.filter((list) => list.is_selected === true),
  }));
  return selectedRadio.flatMap((selected) =>
    selected.phase_list.map((list) => ({
      exam_id: selected.exam_id,
      phase_id: list.phase_id,
      is_selected: list.is_selected,
    }))
  );
};
