export const DISCRIPTION_LIST = [
  {
    heading: ``,
    type: "h",
    content: [
      `CNAPS started as an idea a decade ago, to create a conducive atmosphere for nurturing the young minds. CNAPS is born out of the need to have a free-flowing, thought-sharing forum. We help students to bloom their minds, open their souls to the pure ecstasy of Mathematical and Scientific study by nurturing the inherent academic potentials.`,
      `CNAPS endeavours to identify the talent right from high school through quantitative and qualitative assessment methods and provide them a pedestal to explore their mathematical and scientific talents through the medium of nurture programs envisaged by the council.`,
    ],
  },
  {
    heading: `Our Philosophy`,
    type: "h",
    content: [
      `Modern education is characterized by the absence of a voice encouraging and nudging students towards ‘pure pursuit’ of knowledge.  There is an expansive void created by commercialization and vocationalization of education. CNAPS intends to fill the fissures and chasms that are the long-standing results of monetization of Mathematics and Science.`,
      <div>
        <div style={{ marginBottom: "16px" }}>
          The following are the beneficiaries of our philosophy:
        </div>
        <b>School and College Students</b> – Expand their horizons towards ‘pure
        pursuit’ of Science and Mathematics <br />
        <b>Society </b>– Innovative and practical solutions to our problems
      </div>,
      `CNAPS philosophy is rooted in three core themes. DISCOVER – IGNITE – NURTURE `,
    ],
  },
];

export const DISCOVER_LIST = [
  {
    heading: `Discover– Identifying the potential students`,
    subHeading: `Talent is not always visible`,
    desc: (
      <div>
        {/* Under the ‘Discover’ philosophy, CNAPS aims to spot the latent potential
        in school students. <br />
        CNAPS identifies the future talent in school students through
        quantitative and qualitative assessment methods. Precisely through our
        ISMO, ISSO and ISKO Olympiads. These are the flagship programs of CNAPS. */}
        <ul>
          <li>
            Stimulating the young potential students through inspirational
            interactions with renowned personalities
          </li>
          <li>
            Conducting thought-provoking seminars and workshops to awaken and
            encourage the natural inquisitiveness towards Mathematics and
            Science
          </li>
        </ul>
      </div>
    ),
  },
  {
    heading: `IGNITE`,
    subHeading: `Recognizing the right talent is futile unless the talent is inspired and shown the right direction`,
    desc: (
      <div>
        <ul>
          <li>
            Stimulating the young potential students through inspirational
            interactions with renowned personalities
          </li>
          <li>
            Conducting thought-provoking seminars and workshops to awaken and
            encourage the natural inquisitiveness towards Mathematics and
            Science
          </li>
        </ul>
      </div>
    ),
  },
  {
    heading: `Nurture`,
    subHeading: `Provide ample platform for potential students to share, express, discuss and learn`,
    desc: (
      <div>
        <ul>
          <li>
            Conceptualize and conduct Training workshops and Discussion Sessions
            for the students.{" "}
          </li>
          <li>Encourage them with recognition and appropriate rewards</li>
        </ul>{" "}
      </div>
    ),
  },
];
