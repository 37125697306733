const CNAPS_STUDENT = `cnaps-student/v1/student/`;

export const endpoints = {
  GET_EXAMS_LIST: `${CNAPS_STUDENT}other-exam-list`,
  INITIATE_PAYMENT: `${CNAPS_STUDENT}initiate-payment`,
  PROCESS_ORDER: `${CNAPS_STUDENT}process-order`,
};

export const examSelectionTypes = {
  GET_EXAMS_LIST_REQUEST: "exam-selection/GET_EXAMS_LIST_REQUEST",
  GET_EXAMS_LIST_SUCCESS: "exam-selection/GET_EXAMS_LIST_SUCCESS",
  GET_EXAMS_LIST_ERROR: "exam-selection/GET_EXAMS_LIST_ERROR",
};
