const CNAPS_ADMIN = 'cnaps-admin/v1/admin';

export const endpoints = {
  SENDOTP: `${CNAPS_ADMIN}/forgot-password`,
  VALIDATEOTP: `${CNAPS_ADMIN}/validate-otp`,
  UPDATE_PASSWORD: `${CNAPS_ADMIN}/update-password`,
};

export const forgotTypes = {
  VERIFY_OTP_REQUEST: "forgot-password/VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "forgot-password/VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "forgot-password/VERIFY_OTP_ERROR",
  RESET_OTP: "registration/RESET_OTP",

  SEND_OTP_REQUEST: "forgot-password/SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "forgot-password/SEND_OTP_SUCCESS",
  SEND_OTP_ERROR: "forgot-password/SEND_OTP_ERROR",

  UPDATE_PWD_REQUEST: "forgot-password/UPDATE_PWD_REQUEST",
  UPDATE_PWD_SUCCESS: "forgot-password/UPDATE_PWD_SUCCESS",
  UPDATE_PWD_ERROR: "forgot-password/UPDATE_PWD_ERROR",
};
