import { endpoints } from "../types";
import request from "utils/axios/service";
import { EXAM_TYPES, isMissedScheduledTest } from "utils/utility/test-engine";

const getQPUrl = (params) => {
  return `${endpoints.SCHEDULE_QUESTION_PAPER}/${params.test_id}/${params.delivery_id}`;
};

class TestService {
  async downloadQuestionPaper(req, params) {
    const isMissedScheduled = isMissedScheduledTest(params.exam_category);
    const reqBody = isMissedScheduled ? req : params;
    return await request.get(getQPUrl(params), reqBody);
  }
  async previousUserAnswers(req) {
    return await request.get(endpoints.PREVIOUS_USER_ANSWERS, req);
  }
  async updateUserAnswers(req) {
    return await request.post(endpoints.UPDATE_USER_ANSWERS, req);
  }
  async submitUserAnswers(req) {
    return await request.post(endpoints.SUBMIT_USER_ANSWERS, req);
  }
  async setImageProctor(req) {
    return await request.post("", req);
  }
  async updateProctorEvent(req) {
    return await request.post(endpoints.UPDATE_PROCTOR_EVENT, req);
  }
  async tabSwitch(req) {
    return await request.post(endpoints.TAB_SWITCHED, req);
  }
  async audioProctoring(req) {
    return await request.post(endpoints.AUDIO_PROCTOR, req);
  }
  async getPreSignedUrl(req) {
    return await request.get(endpoints.SIGNED_URL, req);
  }
}

export default TestService;
