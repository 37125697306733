import React from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

const StyledRadio=styled(Form.Check)`
.form-check-input {
    width:18px;
    height:18px;
    &:checked{
    background-color: #d71a63 !important;
    border-color: #d71a63 !important;
    }
}


`
const Radio = ({...rest}) => {
    return (
        <StyledRadio
            inline
            label="1"
            // name="group1"
            type={"radio"}
            // id={`inline-${type}-1`}
            {...rest}
      />
    )
}

export default Radio
