export function calculateTimeLeft(difference) {
  let timeLeft = {};
  if (difference > 0) {
    const hr = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const min = Math.floor((difference / 1000 / 60) % 60);
    const sec = Math.floor((difference / 1000) % 60);
    timeLeft = {
      hrs: hr > 9 ? hr : `0${hr}`,
      min: min > 9 ? min : `0${min}`,
      sec: sec > 9 ? sec : `0${sec}`,
      ms: Math.floor(difference - 1000),
    };
  }
  return timeLeft;
}
