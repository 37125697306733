import * as types from "containers/test/types";
import produce from "immer";

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

const appReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CHECK_TEST_STATUS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.CHECK_TEST_STATUS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case types.CHECK_TEST_STATUS_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export default appReducer;
