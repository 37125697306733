import { ModalWrapper, CloseButton } from "./styles";
import CloseIcon from "assets/deletion/close.svg";
import { useResize } from "share/hooks/useResize";

const BasicModal = ({
  children,
  show,
  handleClose,
  width,
  padding,
  isDisableCloseButton = false,
}) => {
  const { width: rWidth } = useResize();
  return (
    <ModalWrapper
      show={show}
      onHide={() => {}}
      $width={width}
      $padding={padding}
      $isBottomSheet={rWidth <= 480}
      $show={show}
    >
      <ModalWrapper.Body>
        {!isDisableCloseButton && (
          <CloseButton src={CloseIcon} alt="close" onClick={handleClose} />
        )}
        {children}
      </ModalWrapper.Body>
    </ModalWrapper>
  );
};

export default BasicModal;
