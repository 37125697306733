import request from "utils/axios/service";
import { endpoints } from "../constants";
import axios from 'axios'

class StudentListService {
  studentList(req) {
    return request.get(endpoints.STUDENT_LIST, req);
  }

  studentFilters(req) {
    return request.get(endpoints.STUDENT_FILTERS, req);
  }

  updateStudentDetails(req) {
    return request.get(endpoints.UPDATE_STUDENT_DETAILS);
  }

  downloadExcelSheet(req) {
    return axios.get(
      `${process.env.REACT_APP_SERVER_URL}/${endpoints.DOWNLOAD_EXCEL}`,
      {
        params: {
          ...req,
        },
        headers: {
          // eslint-disable-next-line no-useless-concat
          authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        responseType: "arraybuffer",
      }
    );
  }
  downloadStudentResult(req) {
    return axios.get(
      `${process.env.REACT_APP_SERVER_URL}/${endpoints.DOWNLOAD_SCHOOL_RESULT}`,
      {
        params: {
          ...req,
        },
        headers: {
          // eslint-disable-next-line no-useless-concat
          authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        responseType: "arraybuffer",
      }
    );
  }
}

export default StudentListService;
