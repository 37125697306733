import { endpoints } from "./endpoints";
import request from "utils/axios/service";

class ProctorServieces {
  async getProctorConfig(req) {
    return await request.get(endpoints.GET_PROCTOR_CONFIG, req);
  }
  async updateProctorConsent(req) {
    return await request.post(endpoints.UPDATE_PROCTOR_CONSENT, req);
  }
  async updateProctorEvent(req) {
    return await request.post(endpoints.UPDATE_PROCTOR_EVENT, req);
  }
  async proceedTest(req) {
    return await request.get(endpoints.SCHEDULE_PROCEED_TEST, req);
  }
  async getExamDetails(req) {
    return await request.get(endpoints.EXAM_DETAILS, req);
  }
}

export default ProctorServieces;
