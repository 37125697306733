export const Circle = () => {
    return (
      <div
        style={{
          width: "7px",
          height: "7px",
          backgroundColor: "black",
          borderRadius: "50%",
          margin: "0 3px 0 3px ",
        }}
      />
    );
  };