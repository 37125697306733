/**
 * this function checks the  user is  authorized  or not
 * @returns {string} token string
 */

export const isAuthorized = () => {
  return localStorage.getItem("token");
};

/**
 * this function checks the  school is  authorized  or not
 * @returns {string} token string
 */
export const isSchoolAuthorized = () => {
  return localStorage.getItem("school-token");
};

export const getStudentProfileDetails = () => {
  return JSON.parse(localStorage.getItem("profile"));
};

/**
 * this function takes the  key for localstorage ,parse and gives the object
 * @param {string} key
 * @returns {Object|string} gives object or string
 */

export const getLocalObj = (key) => {
  const strObj = localStorage.getItem(key);
  if (strObj) return JSON.parse(strObj);
  return strObj;
};


/**
 * this function takes the  key  and obj, set to the localstorage
 * @param {string} key
 * @param {Object} ob
 * @returns {void}
 */

export const setLocalObj = (key, ob) => {
  localStorage.setItem(key, JSON.stringify(ob));
};
