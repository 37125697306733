import {
  EllipseText,
  NormalText,
} from "school-admin/DivisionalAccntDashboard/ApprovalsList/styles";
import { getExamCols, getExamValues } from "utils/utility/table";

export const getColumns = (data) => {
  console.log(data);
  return [
    {
      title: "User ID",
      dataIndex: "admissionNumber",
      key: "admissionNumber",
      width: 60,
      fixed: "left",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
      width: 60,
      render: (text) =>
        text.length >= 6 ? (
          <EllipseText title={text}>{text}</EllipseText>
        ) : (
          <NormalText>{text}</NormalText>
        ),
    },
    {
      title: "Sur name",
      dataIndex: "sur_name",
      key: "sur_name",
      width: 60,
      render: (text) =>
        text.length >=5 ? (
          <EllipseText title={text}>{text}</EllipseText>
        ) : (
          <NormalText>{text}</NormalText>
        ),
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      width: 60,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 60,
    },

    {
      title: "Roll number",
      dataIndex: "roll_number",
      key: "rollnumber",
      width: 60,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: 60,
      render: (text) => (text ? text : ""),
    },
    {
      title: "Exams",
      children: getExamCols(data),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 120,
      fixed: "right",
    },
  ];
};

export const getTableDataSource = (data) => {
  if (!data) return [];
  return data.map((item) => {
    let exams = getExamValues(item.exams_list);
    return {
      ...item,
      ...exams,
      action: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <span id={"student-list-edit"}>Edit</span>
          <span id={"student-list-view"}>View</span>
        </div>
      ),
    };
  });
};
