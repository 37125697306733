import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RegisterIndividually from './register-individually';
import NewRegistration from './new-registration';
import Summary from './Summary'
import PaymentSummary from './payment-summary'
import CongragulationScreen from "./congratulations-screen";
import injectSaga from "utils/saga/injectSaga";
import saga from "./saga";

const key = "newRegistration";

function NewRegistrationPage(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={NewRegistration} />
      <Route
        exact
        path={`${match.url}/individual`}
        component={RegisterIndividually}
      />
      <Route
        path={`${match.url}/individual:details`}
        component={RegisterIndividually}
      />
      <Route exact path={`${match.url}/summary`} component={Summary} />
      <Route path={`${match.url}/summary:details`} component={Summary} />
      <Route
        exact
        path={`${match.url}/payment-summary`}
        component={PaymentSummary}
      />
      <Route
        path={`${match.url}/payment-summary:details`}
        component={PaymentSummary}
      />
      <Route
        exact
        path={`${match.url}/congrats`}
        component={CongragulationScreen}
      />
      <Route
        path={`${match.url}/congrats:details`}
        component={CongragulationScreen}
      />
    </Switch>
  );
}

const withSaga = injectSaga({ key, saga });

export default withSaga(NewRegistrationPage);