import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  max-width: 1400px;
  width: 100%;
  height: 68px;
`;
export const Wrapper = styled.div`
  background-color: #f9f9ff;
  padding: 0 0 24px;
`;
export const Heading = styled.h5`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
`;
