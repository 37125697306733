import React from "react";
import { HomePageContentWrapper } from "./styles";

function HomePageConent() {
  return (
    <>
      <HomePageContentWrapper>
        CNAPS’s meticulous research on some of the most famous Olympiads across
        the world helped amalgamate the findings in proposing three prestigious
        international standard Olympiads
      </HomePageContentWrapper>
    </>
  );
}

export default HomePageConent;
