import styled from 'styled-components';

export const StyledInput = styled.div`
  input {
    width: 64px !important;
    height: 58px !important;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #949497;
    font-size: 24px;
    margin-right: 10px;
    font-family: ${({ theme }) => theme.palette.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: bold;
    &:focus {
      outline: none;
      color: ${({ theme }) => theme.palette.primary.main};
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
