import { Breadcrumb } from "react-bootstrap";
import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
  background-color: #f9f9ff;
  .container {
    padding: 24px 0 0;
    flex-grow: 1;
  }
`;

export const BreadcrumbWrapper = styled(Breadcrumb)`
  .breadcrumb {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    background: transparent;
    padding-left: 0;
  }
  .breadcrumb-item a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overview-link {
    width: 68px;
    height: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #d71a63;
    text-decoration: underline;
    margin-left: 12px;
    margin-right: 12px;
  }
  .breadcrumb-item::before {
    content: "";
  }
  .breadcrumb-item {
    display: flex;
    justify-content: center;
    align-items: center;
    .info {
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: #000000;
    }
  }
`;
