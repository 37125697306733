import { getQueryParam } from "utils/utility/url";

export const getRouteDetails = (routeList, path) => {
  if (path === "/") {
    let route = "Overview";
    const pathname = window.location.pathname;
    if (pathname.includes("/result") || pathname.includes("/answerkey")) {
      let rt = getQueryParam("olympiad", window.location);
      route = rt ? rt.replaceAll("%20", " ") : route;
    }
    return { to: "/student/exams", label: route };
  }
  return routeList.filter((each) => each.to === path)[0];
};
