// import Footer from 'share/organisms/footer'
import Footer from "student/LandingPage/Footer";
import React from "react";
import Header from "share/organisms/headers/features-header";
import {
  Wrap,
  Container,
  Wrapper,
  Heading,
  HeadingText,
  StyledDiv,
  SubHeading,
  WrapContainer,
} from "./styles";
import { useScrollTo } from "share/hooks/useScrollTo";

const WHY_OLYMPIAD_DATA = [
  {
    heading: `The History of Olympiad Examinations`,
    type: "h",
    content: [
      `The history of Olympiad examinations dates to 1895 when the first Olympiad examination in Mathematics was conducted in Hungary.`,
      `Thereafter, it became customary for all the European countries to conduct Olympiad examinations in their respective countries to identify the apex talent and gifted brains so that such talent can be nurtured and fine-tuned. `,
      `The first International level Mathematical Olympiad was conducted in 1959 at Romania. Till today, this event remains the ‘Curtain Raiser’ for the Olympiad activity in all other subjects like Science, Physics, Chemistry and Biology.`,
    ],
  },
  {
    heading: `But let us ask ourselves what ‘Olympiad’ signifies!!`,
    type: "sh",
    content: [
      `Olympic Games represent the pinnacle of human endeavour and resilience. They originated in ancient Greece and were held every four years. ‘Olympiad’ denotes the period of four years between two games and is characterized by rigorous and arduous preparations for the impending games. Across the passage of time, the word Olympiad has come to refer to any event that brings out the apex talent and excellence. `,
      `Similarly, in the field of academics, Olympiad examinations are the ultimate test of a student’s knowledge, skill, mental stamina, and personal competence.`,
      `The Olympiad movement across the globe is aimed at bringing the most gifted secondary and higher secondary students of the world together in a friendly competition of the highest level`,
      `The Olympiads lead us directly to many career benefits; they provide a stimulus to begin a career in science or mathematics, to undertake a lifelong journey into the realms of exciting intellectual challenges`,
    ],
  },
];

const WHY_CNAPS_OLYMPIAD = [
  `CNAP’S groundwork and academic collaborations made it possible to compare the awareness and preparation standards of various International Olympiads and competitions with those present in the Indian sub-continent. We have distilled the best practices of the Olympiad examinations and redesigned the exam pattern that suits the requirements and academic environment present in India.`,
  <div>
    CNAPS is a research-oriented organization driven by academicians and eminent
    educators and alumni from the most respected institutions from around the
    world and it adopts a scientific approach to talent identification –{" "}
    <b style={{ fontWeight: "600" }}>through Olympiad Examinations.</b>
  </div>,
  <div>
    CNAPS’s meticulous inquiry on some of the most famous Olympiads across the
    world helped amalgamate the findings in proposing our prestigious
    international standard Olympiads in the fields of:{" "}
    <b style={{ fontWeight: "600" }}>Mathematics</b>,{" "}
    <b style={{ fontWeight: "600" }}>Science</b>,{" "}
    <b style={{ fontWeight: "600" }}>Astronomy</b>,{" "}
    <b style={{ fontWeight: "600" }}>General Knowledge</b>,{" "}
    <b style={{ fontWeight: "600" }}>Computers</b>,{" "}
    <b style={{ fontWeight: "600" }}>English</b>,{" "}
    <b style={{ fontWeight: "600" }}>Reasoning</b>,{" "}
    <b style={{ fontWeight: "600" }}>Mental Ability</b> etc.
  </div>,
];

const WhyOlympiad = (props) => {
  useScrollTo(0, 0);
  return (
    <>
      <Header {...props} />
      <WrapContainer>
        <Wrap>
          <Container>
            <Wrapper>
              <Heading>
                <HeadingText>What is Olympiad?</HeadingText>
              </Heading>
              {WHY_OLYMPIAD_DATA.map((eachData) => {
                return (
                  <>
                    <SubHeading type={eachData.type}>
                      {eachData.heading}
                    </SubHeading>
                    {eachData.content.map((eachPara) => (
                      <>
                        <StyledDiv>{eachPara}</StyledDiv>
                      </>
                    ))}
                  </>
                );
              })}
            </Wrapper>
            <br />
            <Wrapper>
              <Heading>
                <HeadingText>Why CNAPS Olympiads?</HeadingText>
              </Heading>
              {WHY_CNAPS_OLYMPIAD.map((eachPara) => (
                <StyledDiv>{eachPara}</StyledDiv>
              ))}
            </Wrapper>
          </Container>
        </Wrap>
      </WrapContainer>
      <Footer />
    </>
  );
};

export default WhyOlympiad;
