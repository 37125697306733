import request from "utils/axios/service";
import { endpoints } from "../constants";

class ExamSelectionService {
  async getExamsList(req) {
    return await request.get(endpoints.GET_EXAMS_LIST, req);
  }
  async initiatePayment(req) {
    return await request.post(endpoints.INITIATE_PAYMENT, req);
  }
  async processOrder(req) {
    return await request.post(endpoints.PROCESS_ORDER, req);
  }
}

export default ExamSelectionService;
