import styled from 'styled-components'

export const Wrapper = styled.div`
  padding:24px;
  margin-top:16px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
`
