import styled from "styled-components";
import React, { useState, useEffect } from "react";

import {useHistory} from 'react-router-dom'
const Wrapper = styled.div`
  position: fixed;
  background: linear-gradient(
    to right,
    #d71a63 ${({ scroll }) => scroll},
    transparent 0
  );
  width: 100%;
  height: 4px;
  z-index: 6;
`;

const ContentProgressBar = () => {

  const [scroll, setScroll] = useState(0);
  const history=useHistory()

  const calculateScrollDistance = () => {
    const [scrollTop, winHeight, docHeight] = [
      window.pageYOffset,
      window.innerHeight,
      getDocHeight(),
    ];

    if (docHeight > 1000) {
      const totalDocScrollLength = docHeight - winHeight;
      const scrollPostion = Math.floor(
        (scrollTop / totalDocScrollLength) * 100
      );

      setScroll(scrollPostion);
    }else{
      setScroll(0);
    }
  };

  const reDraw = () => {
    requestAnimationFrame(() => {
      calculateScrollDistance();
    });
  };

  useEffect(() => {
    setScroll(0)
  }, [])

  useEffect(() => {
    setScroll(0)
  }, [history.location])


  useEffect(() => {
    window.addEventListener("scroll", reDraw, false);
    return () => {
      window.removeEventListener("scroll", reDraw, false);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return <Wrapper scroll={`${scroll}%`} />;
};

const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};
export default ContentProgressBar;
