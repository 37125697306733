import React, { useEffect, useState } from "react";
import { Wrap } from "./styles";
import { getQueryParam } from "utils/utility/url";
import BreadCrumbItem from "share/atoms/bread-crumb-item";
import { getRouteDetails } from "./helpers";
/**
 * Place breadcrumbs component within the browser router
 */

const BreadCrumbs = ({ routesList, subroutesList, history }) => {
  const [breadcrumbsList, setBreadcrumbsList] = useState([]);
  const homeRoute = [getRouteDetails(routesList, "/")];

  useEffect(() => {
    let pathName = `/${history.location.pathname.split("/")[1]}`;
    if (pathName !== "/") {
      let nextList = homeRoute;
      let currentRoute = getRouteDetails(routesList, pathName);
      if (currentRoute) {
        nextList.push(currentRoute);
        setBreadcrumbsList(nextList);
      } else {
        let currentSubRoute = false;
        for (let key in subroutesList) {
          let correctedPath = pathName;
          if (pathName.split("/") > 1) {
            correctedPath = `/${pathName.split("/")[0]}`;
          }
          currentSubRoute = getRouteDetails(subroutesList[key], correctedPath);
          if (currentSubRoute) {
            let nextRouteList = homeRoute;
            let getParentRoute = getRouteDetails(routesList, key);
            nextRouteList.push(getParentRoute);
            nextRouteList.push(currentSubRoute);
            setBreadcrumbsList(nextRouteList);
            break;
          } else {
            setBreadcrumbsList([]);
          }
        }
      }
    } else {
      setBreadcrumbsList([]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onBreadCrumClick = (path) => {
    // if (
    //   history?.location?.pathname?.includes("answerkey") &&
    //   path === "/result"
    // ) {
    //   history.push({
    //     pathname: history?.location?.pathname.replace("answerkey", "result"),
    //   });
    // } else
    history.push({ pathname: path });
  };

  return (
    <Wrap>
      {breadcrumbsList.length && !breadcrumbsList.includes(undefined)
        ? breadcrumbsList.map(({ to, label }, index) => (
            <BreadCrumbItem
              key={to}
              to={to}
              label={label}
              position={index}
              totalCount={breadcrumbsList.length}
              history={history}
              onClick={onBreadCrumClick}
            />
          ))
        : null}
    </Wrap>
  );
};

BreadCrumbs.defaultProps = {
  routesList: [],
};
export default BreadCrumbs;
