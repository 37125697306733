import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import Moment from 'moment';
import packageJson from '../package.json';
import {
  AnalyticsBaseAttributes,
  AnalyticsEndAttributes,
  AnalyticsFilterAttributes,
} from 'analyticsConstants';

const amplifyConfig = {
  Auth: {
    identityPoolId: 'ap-south-1:293d3ebf-c097-4fe8-b812-3ce83a92ad27',
    region: 'ap-south-1',
  },
};

const analyticsConfig = {
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: '3cda2b10f7124605a68029e3fc5d8597',
    // Amazon service region
    region: 'ap-south-1',
    mandatorySignIn: false,
  },
};

// ------------------------------------------------------------------------------------------------
// ----------------------------------F--U--N--C--T--I--O--N--S-------------------------------------
// ------------------------------------------------------------------------------------------------

// configure amplify and auth to record event(s)
function configureAWS() {
  // Initialize Amplify
  Auth.configure(amplifyConfig);
  Analytics.configure(analyticsConfig);
}

// return app version as current app version
function getAppVersion() {
  return packageJson.version;
}

// return platform as 'web'
function getPlatform() {
  return 'web-cnaps';
}

// return timestamp
function getTimestamp() {
  const time = new Date().getTime();
  const timestamp = Moment(time).format('YYYY-MM-DD HH:mm:ss');
  return timestamp.toString();
}

// return os version of the client
export function getOSDetails() {
  // system
  const nAgt = navigator.userAgent;
  const nVer = navigator.appVersion;
  let os = '';
  const clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];

  for (const id in clientStrings) {
    const cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  let osVersion = '';

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS X':
      osVersion = /Mac OS X ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion[3] | 0}`;
      break;
    default:
      break;
  }
  return `${os} ${osVersion}`;
}

export const setSessionUUID = () => {
  try {
    function setUUID() {
      let d = new Date().getTime();
      if (Date.now) {
        d = Date.now(); // high-precision timer
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function(c) {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        },
      );
      return uuid;
    }

    const sessionUUID = setUUID();
    localStorage.setItem('sessionID', sessionUUID);
  } catch (err) {
    console.log(err);
  }
};

//UUID generator
function getUUID() {
  if (localStorage.getItem('sessionID') === null) {
    setSessionUUID();
    const sessionUUID = localStorage.getItem('sessionID');
    return sessionUUID;
  } else {
    const sessionUUID = localStorage.getItem('sessionID');
    return sessionUUID;
  }
}

// it will return browser details of the client
export function getBrowserDetails() {
  // browser
  const nAgt = navigator.userAgent;
  let browser = navigator.appName;
  let version = `${parseFloat(navigator.appVersion)}`;
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset;
  let verOffset;
  let ix;

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 4);
  }
  // Edge
  else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
    browser = 'Microsoft Edge';
    version = nAgt.substring(verOffset + 5);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

  majorVersion = parseInt(`${version}`, 10);
  if (isNaN(majorVersion)) {
    version = `${parseFloat(navigator.appVersion)}`;
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  return `${browser} ${version}`;
}

// return admission number of the currently logged in student
function getAdmissionNumber() {
  const admsnNumber = localStorage.getItem("admsnNumber");
  if (admsnNumber) return admsnNumber;
  return "";
}

// return student id of the currently logged in student
function getStudentID() {
  const studentData = JSON.parse(localStorage.getItem('profile'));
  if(studentData !==undefined && studentData !== null){
    const value = studentData.studentId
    return value;
    }else{
    return '';
    }
}

// get generic attributes for the event
function getGenericEventAttributes() {
  return {
    [AnalyticsBaseAttributes.APP_VERSION]: getAppVersion(),
    [AnalyticsBaseAttributes.PLATFORM]: getPlatform(),
    [AnalyticsBaseAttributes.OS_VERSION]: getOSDetails(),
    [AnalyticsBaseAttributes.BROWSER_VERSION]: getBrowserDetails(),
    [AnalyticsBaseAttributes.STUDENT_ID]: getStudentID(),
    [AnalyticsBaseAttributes.ADMISSION_NUMBER]: getAdmissionNumber(),
    [AnalyticsBaseAttributes.TIMESTAMP]: getTimestamp(),
    [AnalyticsBaseAttributes.UUID]: getUUID(),
  };
  // console.log('^^^^^^'+[AnalyticsBaseAttributes.TIMESTAMP]);
}

// merge generic attributes with custom attributes and return
function getAllEventAttributes(customEventAttributes, eventGroupName) {
  return {
    ...getGenericEventAttributes(),
    ...customEventAttributes,
    [AnalyticsBaseAttributes.EVENT_GROUP]: eventGroupName,
  };
}

// generic function to store analytics into server
function recordAnalytics(eventName, eventAttributes) {
  configureAWS();
  // Record a custom event
  const allEventAttribute = getAllEventAttributes(eventAttributes, eventName);
  Analytics.record({
    name: eventName,
    attributes: allEventAttribute,
  })
    .then(() => {
      console.log('event logged successfully');
      console.log('event logged successfully');
      console.log(allEventAttribute);
    })
    .catch(error => {
      console.log('Error logging events: ' + error);
    });
}

// return the common attributes as an object for each events like FROM, TO, UAA etc
function getCommonAttributes(
  userActionArea,
  fromNode,
  toNode,
  validationError,
) {
  return {
    [AnalyticsEndAttributes.USER_ACTION_AREA]: userActionArea,
    [AnalyticsEndAttributes.FROM_NODE]: fromNode,
    [AnalyticsEndAttributes.TO_NODE]: toNode,
    [AnalyticsEndAttributes.VALIDATION_ERROR]: validationError,
  };
}

// return the event filter attributes as an object for each events like EVENT_TYPE, USER_ACTION_KEY etc
function getFilterAttributes(eventType, actionKey, navigationKey) {
  return {
    [AnalyticsFilterAttributes.EVENT_TYPE]: eventType,
    [AnalyticsFilterAttributes.USER_ACTION_KEY]: actionKey,
    [AnalyticsFilterAttributes.USER_NAVIGATION_KEY]: navigationKey,
  };
}
// ----------------------------------F--U--N--C--T--I--O--N--S-------------------------------------

export { recordAnalytics, getCommonAttributes, getFilterAttributes };
