import styled from "styled-components";
import DottedImage from "assets/image-dots.png";
import ResponsiveDotsImage from "assets/responsive-image-dots.png";
export const HeaderWrap = styled.div`
  box-shadow: none;
  background-color: transparent;
  background: #182441;
  height: 227px;
  width: 100%;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 227px;
    background: url(${DottedImage}) no-repeat center top;
    position: absolute;
    top: 0;
    left: 0;
  }
  // margin-bottom: 60px;
  @media screen and (min-width: 300px) and (max-width: 540px) {
    &:before {
      background: url(${ResponsiveDotsImage}) no-repeat center top;
      top: -12px;
    }
  }
`;

export const Title = styled.div`
  font-size: 42px;
  font-weight: 900;
  margin: 10px 0px;
  color: red;
  color: ${({ theme }) => theme.palette.common.white};
  @media screen and (width: 375px) {
    white-space: nowrap;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: red;
  }
`;

export const SubTitle = styled.h4`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
`;

export const Home = styled.div`
  padding-right: 15px;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;
export const BlurDiv = styled.div`
  background-image: radial-gradient(
    circle at 24% 100%,
    #b265ff,
    rgba(14, 39, 128, 0.03) 126%
  );
  position: absolute;
  right: 100px;
  width: 345px;
  height: 35px;
  top: 110px;
  filter: blur(60px);
  @media screen and (min-width: 200px) and (max-width: 820px) {
    background-image: radial-gradient(
      circle at 100% 15%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 35%
    );
    background-image: radial-gradient(
      circle at 69% 15%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 90%
    );
    height: 60px;
    right: 49px;
    top: 88px;
    filter: blur(80px);
  }
  @media screen and (width: 1920px) {
    background-image: radial-gradient(
      circle at 24% 100%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 250%
    );

    filter: blur(70px);
  }
  @media screen and (width: 1024px) {
    background-image: radial-gradient(
      circle at 24% 100%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 250%
    );
    right: 5%;
    filter: blur(80px);
  }
  @media screen and (width: 1280px) {
    background-image: radial-gradient(
      circle at 24% 100%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 250%
    );
    right: 5%;
    filter: blur(80px);
  }
  @media screen and (min-width: 820px) and (max-width: 912px) {
    background-image: radial-gradient(
      circle at 24% 100%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 250%
    );
    height: 30px;
    right: 5px;
    filter: blur(90px);
  }
`;
