import React from "react";
import { CardWrapper, ResponsiveImgContainer } from "./styles";
import ProgressBar from "share/atoms/progress-bar";

function Card({ item, id, isActive, handleSelect, isResponsive }) {
  return (
    <>
      <CardWrapper $isActive={isActive} onClick={() => handleSelect(id)}>
        <div className="progress-section">
          <ProgressBar />
        </div>
        <div className="main-section">
          <div className="title">{item.title}</div>
          <div className="content">{item.content}</div>
        </div>
      </CardWrapper>
      {isResponsive && (
        <ResponsiveImgContainer $isActive={isActive}>
          <img src={item.image} alt=""/>
        </ResponsiveImgContainer>
      )}
    </>
  );
}

export default Card;
