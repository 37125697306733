import React from "react";
import SchoolAdminHeader from "share/organisms/headers/school-admin-header";
import CnapsLogo from "assets/frame.svg";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadCrumbs from "share/molecules/bread-crumbs";
import CheveronRight from "assets/chevron-right 2.svg";
import Home from "assets/home.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { BreadcrumbWrapper, Wrap } from "./styles";

const routesList = [
  { to: "/result", label: "Test Result" },
  { to: "/instructions", label: "Test Instructions" },
];
const subRoutesList = {
  "/result": [{ to: "/answerkey", label: "Answer Key" }],
};

function WithoutSidepanelLayout(props) {
  const history = useHistory();
  const location = useLocation();
  const { institute, school_name } = { ...location.state };
  const loginType = localStorage.getItem("login-type");
  const daState = JSON.parse(localStorage.getItem("da_state"));
  const { institute: dtInstitute, school_name: dtSchoolName } = { ...daState };
  return (
    <Wrap className="d-flex d-col">
      <SchoolAdminHeader
        className="divisional-header"
        leftContent={
          <div className="logo-wrap">
            <img
              style={{ cursor: "pointer" }}
              src={CnapsLogo}
              onClick={() => history.push("/")}
              alt="cnaps logo"
            />
            {loginType !== "student" ? <h3>School</h3> : null}
          </div>
        }
      />
      <div className="container">
        {props.showBreadcrumb && (
          <BreadCrumbs
            subroutesList={subRoutesList}
            routesList={routesList}
            history={history}
          />
        )}
        {props.isDA && (
          <BreadcrumbWrapper>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: "/school/divisional-accountant" }}
            >
              <img src={Home} alt="home" className="image" />
              <div className="overview-link">Overview</div>
              <img src={CheveronRight} alt="cheveron-right" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {institute && school_name ? (
                <div className="info">
                  <span>{institute}</span>, <span>{school_name}</span>
                </div>
              ) : (
                <div className="info">
                  <span>{dtInstitute}</span>, <span>{dtSchoolName}</span>
                </div>
              )}
            </Breadcrumb.Item>
          </BreadcrumbWrapper>
        )}
        {props.children}
      </div>
    </Wrap>
  );
}

export default React.memo(WithoutSidepanelLayout);
