import styled, { css } from 'styled-components';
import { Input } from "antd";

export const StyledInput = styled(Input)`
  border: 0;
  width: 328px;
  border-bottom: 1px solid #949497;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-bottom: 10px;
  margin-bottom: ${({marginBottom})=>marginBottom?"0px":"10px"};
  background-color: transparent;
  padding-left:0;
  border-radius: 0;
  border-color:${({error})=>error?"red":" #949497"};
  ${({ variant }) =>
    variant === "outlined" &&
    css`
      border-radius: 8px;
      border: solid 1px #949497;
      width: 336px;
      width: 100%;
      height: 48px;
      padding: 15px;
      font-size: 16px;
      background-color: #fff;
      opacity: initial;
    `};
  &:placeholder-shown {
    opacity: 0.7;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2e3c3f;
    padding: 15px;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    /* border-color: #949497; */
    border-color:${({error,isMonitorFeature})=>error&&isMonitorFeature?"red":"#949497"};
    border-right-width: none !important;
  }
  &:hover {
    /* border-color: #949497; */
    border-color:${({error,isMonitorFeature})=>error&&isMonitorFeature?"red":"#949497"};
    border-right-width: none !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #212226;
    transition: background-color 1000s ease-in-out 0s;
  }
   input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;
