import styled, { keyframes } from "styled-components";

export const ProgressAnimation = keyframes`
    100% { background-size: 100% }   
`;

export const StyledProgressBar = styled.div`
  width: 92px;
  height: 4px;
  background: linear-gradient(#d71a63 0 0) 0/0% no-repeat #d1a1b3;
  animation: ${ProgressAnimation} 3s infinite linear;
  border-radius: 10px;
  transform: rotate(90deg);
  position: absolute;
  left: -30px;
`;
