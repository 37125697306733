import styled from "styled-components";

export const Wrap = styled.div`
  background-color: #f9f9ff;
`;
export const Wrapper = styled.div`
  font-family: Poppins;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 24px;
  border-radius: 6px;
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  .main-heading {
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-size: 36px;
    margin: 10px 0px 20px 0px;
    text-align: center;
    @media screen and (max-width: 540px) {
      font-size: 24px;
      text-align: center;
    }
  }
`;

export const RefundWrapper = styled.div`
  .heading {
    padding: 12px;
    color: #d71a63;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }
  .paragraph {
    margin-left: 10px;
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.5;
    color: #464646;
  }
`;

export const CancellationWrapper = styled.div`
  .heading {
    padding: 12px;
    color: #d71a63;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
  }
  .paragraph {
    margin-left: 10px;
    font-family: Poppins;
    font-size: 16px;
    color: #464646;
  }
`;
