import React, { useState } from "react";
import styled, { css } from "styled-components";
import PlayStoreIcon from "assets/playstore.svg";
import CloseIcon from "assets/CloseIcon.svg";
import { useNetworkStatus } from "share/hooks";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const { REACT_APP_PLAY_STORE_URL } = process.env;
const Wrapper = styled.div`
  position: sticky;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffff;
  top: ${({ isOnline }) => (!isOnline ? "5%" : "0%")};
  justify-content: space-between;
  display: flex;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  z-index: 100;
  transition: all 0.3s ease;
  @media screen and (max-width: 600px) {
    ${({ show }) =>
      show &&
      css`
        max-height: 64px;
        padding: 14px 32px 14px 24px;
      `}
    opacity: 1;
  }
  .sticky-text {
    font-weight: 500;
  }
  @media screen and (min-width: 480px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    .playstore-image {
      display: none;
    }
  }
`;
const CloseImage = styled.img`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const PlayStoreImage = styled.img`
  width: 132px;
  height: 44px;
  cursor: pointer;
`;

const sessionKey = "sticky_header";

const StickyHeader = (props) => {
  const location = useLocation();
  const [showSticky, setShowSticky] = useState(true);
  const isOnline = useNetworkStatus();
  const handleStickyClose = () => {
    props.handleStrip('strickyHeaderStrip')
    setShowSticky(!showSticky);
  };

  return (
    <Wrapper show={showSticky} isOnline={isOnline}>
      <CloseImage src={CloseIcon} alt="close" onClick={handleStickyClose} />
      {location.pathname === "/school" ? (
        <div>
          <h4 className="sticky-text">
            Use your browser on laptop/desktop for CNAPS school{" "}
          </h4>
        </div>
      ) : (
        <div>
          Now Also
          <br />
          <h3>Available On </h3>
        </div>
      )}
      <div>
        <a target="_blank" href={REACT_APP_PLAY_STORE_URL}>
          <PlayStoreImage
            src={PlayStoreIcon}
            className={`${
              location.pathname === "/school" ? "playstore-image" : ""
            }`}
          />
        </a>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  isPresent: state?.auth?.stickyReducer?.isPresent,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StickyHeader);
