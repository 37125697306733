import { put, call, select, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import {
  GET_PROCTOR_CONFIG_REQUEST,
  UPDATE_PROCTOR_CONSENT_REQUEST,
  UPDATE_PROCTOR_EVENT_REQUEST,
  PROCEED_TEST_REQUEST,
  EXAM_DETAILS_REQUEST,
} from "./types";
import TestService from "../test/services";
import { GET_SIGNED_URL_REQUEST } from "../test/types";
import ProctorServieces from "./services";
import { config } from "./data";
import { getSigneUrlRequest } from "containers/test/actions";
import { makeSelectRouter } from "../test/selectors";
import { getParams } from "utils/utility/test-engine";
import * as actions from "./actions";
import { setAnswers } from "../test/actions";
import { checkTestStatusSuccess } from "../test/actions";
import { getValues } from "utils/utility/obj-arr";
import * as storageUtils from "utils/localStorageUtils";
import notify from "utils/notifications";
import {examDetailsRequest} from '../instructions/actions'

const proctorService = new ProctorServieces();
const testService = new TestService();

function* proceedTestWorker(action) {
  const {
    payload: { path },
  } = action;
  try {
    const location = yield select(makeSelectRouter());
    const params = yield getParams(location);
    const res = yield call(proctorService.proceedTest, params);
    if (res.data.status === "success") {
      const { data } = res.data;
      if (
        data &&
        (data.exam_state === "suspended" || data.exam_state === "submitted")
      ) {
        notify("error", res.data.message);
        yield put(actions.proceedTestError(res.data));
      } else {
        yield put(setAnswers(false));
        yield put(checkTestStatusSuccess(data));
        yield put(actions.proceedTestSuccess(data));
        const id = getValues(params).join("-");
        storageUtils.setSyncIntervals(id, data.sync_interval || 300000);
        if (
          params.exam_category === "mock_test" &&
          data.exam_state === "not_started"
        ) {
          const profile = localStorage.getItem("admsnNumber")
            ? { admissionNumber: localStorage.getItem("admsnNumber") }
            : { admissionNumber: 1234 };
          const uniqId = `${id}-${profile.admissionNumber}`;
          // if (storageUtils.getTestAnswers(uniqId))
          //   storageUtils.removeTestAnswers(uniqId);
          if (storageUtils.getSyncIntervals(uniqId))
            storageUtils.removeSyncIntervals(uniqId);
          if (storageUtils.getPreviousTime(uniqId))
            storageUtils.removePreviousTime(uniqId);
          if (storageUtils.getExamStatus(uniqId))
            storageUtils.removeExamStatus(uniqId);
          if (storageUtils.getQuestionPreviousTime(`${uniqId}-question_time`))
            storageUtils.removeQuestionPreviousTime(`${uniqId}-question_time`);
        }
        yield put(replace(`${location.pathname.replace(path, "test")}`));
      }
    } else if (
      res.data.status === "error" &&
      res.data.errorCode === "ATTENDANCE_NOT_TAKEN"
    ) {
      notify("error", res.data.message);
      yield put(actions.proceedTestError(res.data));
      yield put(push(`${location.pathname.replace(path, "submitted")}`));
    } else {
      notify("error", res.data.message);
      yield put(actions.proceedTestError(res.data));
    }
  } catch (e) {
    if (e.status == "401") {
      notify("error", e.data.message || "");
    }
    yield put(actions.proceedTestError(e));
  }
}

function* proctorConfigWorker(action) {
  const { exam_id, test_id } = action.payload;
  try {
    const res = yield call(proctorService.getProctorConfig, { exam_id });
    if (res.status === 200 && res.data.status === "success") {
      yield sessionStorage.setItem(
        "proctor-config",
        JSON.stringify(res.data.data)
      );
      // yield put(getSigneUrlRequest({ test_id }));
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

function* updateProctorConsentWorker(action) {
  try {
    const res = yield call(proctorService.updateProctorConsent, action.payload);
  } catch (error) {
    console.log("error", error.message);
  }
}

function* updateProctorEventWorker(action) {
  try {
    const res = yield call(
      proctorService.updateProctorEvent,
      action.payload
    );
  } catch (error) {
    console.log("error", error.message);
  }
}

function* getSignedUrlWorker(action) {
  const { test_id } = action.payload;
  try {
    const sessionData = sessionStorage.getItem("proctor-config");
    const eventsData = JSON.parse(sessionData);
    let snapEventData = eventsData?.events?.find(
      (item) => item?.name === "snap"
    );
    const res = yield call(testService.getPreSignedUrl, {
      event_id: snapEventData?.eventid,
      test_id,
    });
    if (res.data.status === "success") {
      sessionStorage.setItem("signedUrls", JSON.stringify(res.data.data));
    } else {
      console.log("someting went wrong");
    }
  } catch (error) {
    console.log("error ", error.message);
  }
}

function* examDetailsWorker(action) {
  try {
    // yield put(examDetailsRequest(action.payload))
    const res = yield call(proctorService.getExamDetails, action.payload)
    if (res.data.status === "success") {
      yield put(actions.examDetailsSuccess(res.data.data));
    } else {
      yield put(actions.examDetailsError(res.data));
    }
  } catch (e) {
    yield put(actions.examDetailsError(e));
  }
}

export default function* () {
  yield takeLatest(GET_PROCTOR_CONFIG_REQUEST, proctorConfigWorker);
  yield takeLatest(UPDATE_PROCTOR_CONSENT_REQUEST, updateProctorConsentWorker);
  yield takeLatest(UPDATE_PROCTOR_EVENT_REQUEST, updateProctorEventWorker);
  yield takeLatest(GET_SIGNED_URL_REQUEST, getSignedUrlWorker);
  yield takeLatest(PROCEED_TEST_REQUEST, proceedTestWorker);
  yield takeLatest(EXAM_DETAILS_REQUEST, examDetailsWorker);
}
