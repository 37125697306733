import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import copy from 'copy-to-clipboard';
import {
  WrapHeader,
  Title,
  Info,
  HorizantalLine,
  ButtonWrapper,
  Button,
  TextWrapper,
  Wrap,
  Header,
  HeadingWrapper,
  MainHeading,
  ButtonText
} from "./student-monitor-actions/styles";
import { PasswordInput } from "share/atoms/form-fields";
import EditIcon from "assets/monitoring/edit-icon.svg";
import request from "utils/axios/request";
import {endpoints} from './constants'
import notify from "utils/notifications";

const StudentDetails = ({ admissionNumber }) => {
  const [edit, setEdit] = useState(false);
  const [password, setPassword] = useState("");
  const [studentDetails, setStudentDetails] = useState({});
  
  const getStudentDetailsData = async () => {
    const response = await request.get(endpoints.GET_STUDENT_DETAILS, {
      admission_number: admissionNumber,
    });
    if (response.status === 200 && response.data.status === "success") {
      setStudentDetails(response.data.data);
    } else {
      notify("error", response.data.message);
    }
  };

  useEffect(() => {
      getStudentDetailsData();
  }, []);
  

  const handleEdit = () => {
    setEdit(true);
  };

  const onSave = async (admissionNumber) => {
    const response = await request.post(
      endpoints.UPDATE_PASSWORD,
      {
        admission_number: admissionNumber,
        password: password,
      }
    );
    if (response.status === 200 && response.data.status === "success") {
      setEdit(false);
      notify("success",response.data.message)
      setTimeout(() => {
        getStudentDetailsData();
      }, 2000);
    } else {
      notify("error",response.data.message)
    }
  };
  const {password:pswd,login_url,student_name,student_id,class_name,email,phone_number,parent_name,school_name,subbatch,branch,district,state_name,admission_number  } = studentDetails

  const loginStudentHandler = () => {
    copy(login_url)
    notify("success","copied to clipboard successfully")
  };

  return (
    <>
       <Container fluid="md">
      <HeadingWrapper>
        <MainHeading >Student Details</MainHeading>
        <ButtonText onClick={loginStudentHandler} >Login as Student</ButtonText>
      </HeadingWrapper>
    </Container>
      <Container fluid="md">
        <HorizantalLine />
        <Container fluid="md">
          <Wrap>
            <Row>
              <Col md={3} sm={4} xs={12}>
                <Header>Student Details</Header>
              </Col>
              <Col md={9} sm={8} xs={12}>
                <Row>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>Student Name</Title>
                      <Info>{student_name?student_name :"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>Admission Number</Title>
                      <Info>{admission_number?admission_number:"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>Class</Title>
                      <Info>{class_name?class_name:"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <Title>Email-ID</Title>
                    <Info>{email?email:"--"}</Info>
                  </Col>
                  <Col md={4} xs={4}>
                    <Title>Phone Number</Title>
                    <Info>{phone_number?phone_number:"--"}</Info>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Wrap>

          <HorizantalLine />
          <Wrap>
            <Row>
              <Col md={3} sm={4} xs={12}>
                <Header>Parent Details</Header>
              </Col>
              <Col md={9} sm={8} xs={12}>
                <Title>Parent Name</Title>
                <Info>{parent_name?parent_name:"--"}</Info>
              </Col>
            </Row>
          </Wrap>
          <HorizantalLine />
          <Wrap>
            <Row>
              <Col md={3} sm={4} xs={12}>
                {" "}
                <Header>Password Details </Header>
              </Col>
              <Col md={9} sm={8} xs={12}>
                <Title>Password</Title>
                {edit ? (
                  <ButtonWrapper>
                    <PasswordInput
                      handleChange={(e) => setPassword(e.target.value)}
                      name="password"
                      type="password"
                      value={password}
                    />
                    <Button isPassword={password.length >= 1} onClick={()=>onSave(admissionNumber)}>Save</Button>
                  </ButtonWrapper>
                ) : (
                  <>
                    <TextWrapper>
                      <Info>{pswd}</Info>
                      <div onClick={handleEdit} className="edit-wrap">
                        <img src={EditIcon} alt="edit-icon" />
                        <div className="edit-text">Edit</div>
                      </div>
                    </TextWrapper>
                  </>
                )}
              </Col>
            </Row>
          </Wrap>

          <HorizantalLine />
          <Wrap>
            <Row>
              <Col md={3} sm={4} xs={12}>
                <Header>School Details</Header>
              </Col>
              <Col md={9} sm={8} xs={12}>
                <Row>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>School Name</Title>
                      <Info>{school_name?school_name:"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>Sub-Batch</Title>
                      <Info>{subbatch?subbatch:"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <div className="title-wrapper">
                      <Title>Branch</Title>
                      <Info>{branch?branch:"--"}</Info>
                    </div>
                  </Col>
                  <Col md={4} xs={4}>
                    <Title>District</Title>
                    <Info>{district?district:"--"}</Info>
                  </Col>
                  <Col md={4} xs={4}>
                    <Title>State</Title>
                    <Info>{state_name?state_name:"--"}</Info>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Wrap>
        </Container>
      </Container>
    </>
  );
};


export default StudentDetails;
