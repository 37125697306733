import StudentLogin from "student/Login/Loadable";
import StudentForgotPassword from "student/ForgotPassword/Loadable";
import StudentRegistration from "student/Registration/Loadable";
import ExamSelection from "student/ExamsSelection/Loadable";
import StudentHome from "student/StudentHome/Loadable";
import StudentExams from "student/StudentExams/Loadable";
import StudentCongratulations from "student/StudentCongratulations/Loadable";
import Profilescreen from "share/templates/ProfileScreen";
import ChangePasswordScreen from "share/templates/ChangePasswordTemp";
import FaqTemplate from "share/templates/FaqTemp";
import PaymentReceipt from "student/PaymenReceipt";

const studentRoutes = [
  { component: StudentLogin, exact: true, path: "/student/login" },
  {
    component: StudentForgotPassword,
    exact: false,
    path: "/student/forgot-password",
  },
  {
    component: StudentRegistration,
    exact: false,
    path: "/student/registration",
  },
  { component: ExamSelection, exact: true, path: "/student/exam-selection" },
  { component: StudentHome, exact: true, path: "/student/home" },
  { component: StudentExams, exact: true, path: "/student/exams" },
  {
    component: StudentCongratulations,
    exact: true,
    path: "/student/congratulations",
  },
  { component: Profilescreen, exact: true, path: "/student/profile" },
  {
    component: ChangePasswordScreen,
    exact: true,
    path: "/student/change-password",
  },
  {
    component: FaqTemplate,
    exact: true,
    path: "/student/faqs"
  },
  {
    component:PaymentReceipt,
    exact:true,
    path:"/student/payment-receipt"
  }
];

export default studentRoutes;
