import React, { useEffect } from "react";
import RegistrationTemp from "share/templates/RegistrationTemp";
import AntdTable from "share/organisms/table/antd-table";
import ModalTemp from "share/templates/ModalTemp";
import useModal from "share/hooks/useModal";
import saveListPayLaterIcon from "assets/save-list-pay-later.svg";
import confirmIcon from "assets/confirm.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getQueryParam } from "utils/utility/url";
import { getLocalObj } from "utils/utility/local-storage";
import { paymentColumns, handlingChargeCol } from "../helper";
import { PayNowButton, PayLater, BtnWrap } from "./styles";
import { SummaryWrapper, SubTotal } from "../styles";
import { getAmount, getDataSource, getChargesDataSource } from "./helpers";
import {
  getStudentPaymentSummaryRequest,
  individualPaymentSummaryRequest,
  initBulkRegPaymentRequest,
  initIndividualPaymentRequest,
  saveListRequest,
} from "../actions";

//Payment Summary page returns the summary of the payment which has to be done and shows the payment to pay  based on the school admin 
function PaymentSummary() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isVisible, handleModal } = useModal();
  const { isVisible: approvalModal, handleModal: setApprovalModal } =
    useModal();
  const feature = sessionStorage.getItem("feature");
  const { studentPaymentSummary } = useSelector(
    (state) => state.newRegistration
  );

  const phnNum = getQueryParam("mobile_number", location);

  useEffect(() => {
    const groupId = getQueryParam("groupId", location);
    const admissionNumber = getQueryParam("admissionNumber", location);
    const phnNum = getQueryParam("mobile_number", location);
    console.log("adm", phnNum);
    if (groupId) {
      dispatch(getStudentPaymentSummaryRequest({ group_id: groupId }));
    } else if (admissionNumber) {
      const exams = getQueryParam("exams", location);
      const reqBody = {
        admission_number: admissionNumber,
        mobile_number: phnNum,
        "cart_exam_list[]": exams.split(","),
      };
      dispatch(individualPaymentSummaryRequest(reqBody));
    } else {
      dispatch(getStudentPaymentSummaryRequest());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
 * this function save the payment for future pay 
 * @param {string} type
 * @returns {void} 
 */
  const handleSaveList = (type) => {
    const id = getQueryParam("groupId", location);
    const reqBody = { group_id: id };
    if (type === "payment") {
      reqBody["method"] = type;
      dispatch(saveListRequest(reqBody));
    } else {
      dispatch(saveListRequest(reqBody));
    }
  };

  /**
 * this function returns admission number
 * @returns {boolean} return admission number
 */
  const individualStudentApproval = () => {
    const admissionNumber = getQueryParam("admissionNumber", location);
    const { cart_exam_list } = studentPaymentSummary.data;
    if (admissionNumber) {
      const reqBody = {
        admission_number: admissionNumber,
        mobile_number:phnNum ? phnNum :"",
        cart_exam_list: cart_exam_list,
      };
      dispatch(initIndividualPaymentRequest(reqBody));
    }
    return admissionNumber;
  };

  /**
 * this function handle the payment process
 * @param {Object} e
 * @returns {void} return admission number
 */
  const handlePaymentProcess = (e) => {
    const admissionNumber = individualStudentApproval();
    if (feature !== "pending" && !admissionNumber) handleSaveList("payment");
    else if (!admissionNumber) dispatch(initBulkRegPaymentRequest());
    e.preventDefault();
  };

  const tokenData = getLocalObj("token-data");
  const admsnNumber = getQueryParam("admissionNumber", location);

  return (
    <RegistrationTemp headingText={"Payment Summary"}>
      <SummaryWrapper>
        {isVisible && (
          <ModalTemp
            icon={saveListPayLaterIcon}
            title={"Save list & Pay later"}
            desc={"Student Registration list Save"}
            primaryBtn="Save"
            primaryBtnClass="bg-green"
            onCancel={handleModal}
            onOk={handleSaveList}
          />
        )}
        {approvalModal && (
          <ModalTemp
            icon={confirmIcon}
            title={"Request Confirmation"}
            desc={"Do you want to save the list and request for approval?"}
            primaryBtn="Confirm"
            onCancel={setApprovalModal}
            onOk={handleSaveList}
          />
        )}
        <AntdTable
          columns={paymentColumns}
          dataSource={getDataSource(studentPaymentSummary)}
          pagination={false}
        />
        <SubTotal>
          Sub Total Amount : {getAmount("total", studentPaymentSummary)}
        </SubTotal>
        <h4>Amount to be retained by school as handling charges</h4>
        <AntdTable
          columns={handlingChargeCol}
          dataSource={getChargesDataSource(studentPaymentSummary)}
          pagination={false}
          padding={"8px 4px 8px 4px"}
        />
        <SubTotal>{getAmount("total", studentPaymentSummary)}</SubTotal>
        <SubTotal>-{getAmount("amount", studentPaymentSummary)}</SubTotal>
        <SubTotal>
          Final Total Amount :{" "}
          ₹ {getAmount("amount_to_be_paid", studentPaymentSummary)
            ? getAmount("amount_to_be_paid", studentPaymentSummary)
            : getAmount("total", studentPaymentSummary) -
              getAmount("amount", studentPaymentSummary)}
        </SubTotal>
      </SummaryWrapper>
      <BtnWrap>
        {/* if there is no admission number and school is not narayana and feature is not equal to pending save list and pay later will render*/}
        {!admsnNumber &&
          !tokenData.is_narayana_school &&
          feature !== "pending" && (
            <PayLater onClick={handleModal}>Save list & Pay Later</PayLater>
          )}
          {/* if school is not narayana ,pay now button is rendered */}
        {!tokenData.is_narayana_school && (
          <PayNowButton onClick={handlePaymentProcess}>Pay Now</PayNowButton>
        )}
        {/* if admission number and school is narayana,request for approval button is rendered */}
        {admsnNumber && tokenData.is_narayana_school && (
          <PayNowButton onClick={individualStudentApproval}>
            Request for Approval
          </PayNowButton>
        )}
        {/* if there is no admission number and school is narayana and feature is not equals to pending, Request for Approval button is rendered */}
        {!admsnNumber &&
          tokenData.is_narayana_school &&
          feature !== "pending" && (
            <PayNowButton onClick={setApprovalModal}>
              Request for Approval
            </PayNowButton>
          )}
      </BtnWrap>
    </RegistrationTemp>
  );
}

export default PaymentSummary;
