import React, { useState } from "react";
import List from "share/atoms/ListView";
import NavItem from "./nav-item";
import { useHistory } from "react-router-dom";
import { studentRoutes, schoolRoutes } from "./routes";
import { ListWrap, Overlay } from "./styles";
import { FiMenu } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import CnapsLogo from "assets/frame.svg";
// import CnapsLogo from "assets/school-admin/icons/sidepanel-logo.svg";

export default function SidePanel() {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const loginType = localStorage.getItem("login-type");

  const navigateToHome = () => {
    history.push("/");
  };

  const routes = loginType === "student" ? studentRoutes : schoolRoutes;

  return (
    <React.Fragment>
      <Overlay isExpanded={isExpanded} />
      <ListWrap
        className={isExpanded && `side-panel-expanded`}
        loginType={loginType}
      >
        <div
          className="hamburger-menu"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FiMenu />
        </div>
        <div className="close-icon" onClick={() => setIsExpanded(!isExpanded)}>
          <FaTimes />
        </div>
        <div className="logo-wrap">
          <img onClick={navigateToHome} src={CnapsLogo} alt="cnaps logo" />
          {loginType === "school" && <h3>School</h3>}
        </div>
        <List items={routes}>
          {(item) => <NavItem key={item.path} route={item} />}
        </List>
      </ListWrap>
    </React.Fragment>
  );
}
