import React from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "share/atoms/form-fields";
import { SpacingWrapper,StyledSubmitWrapper } from "./styles";
import OR from "./or-logo";
import MonitorMultiSelect from "share/atoms/monitor-multiselect";
import useSelectInputChange from "share/hooks/useSelectInputChange";
import { FaChevronDown } from "react-icons/fa";
import { SubmitButton, SubmitWrapper } from "../ListDeliveries/styles";
import { getQueryParam } from "utils/utility/url";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getStudentListMonitorRequest } from "./actions/student-list-monitor-actions";

const Form = ({setPageDetails}) => {
  const { watch, control } = useForm();
  const { formsList, handleMonitorChange } = useSelectInputChange({
    "school-list": "",
  });
  const location = useLocation();
  const dispatch = useDispatch();

  const stringifyData = localStorage.getItem("metadata");
  const metadata = stringifyData ? JSON.parse(stringifyData) : [];
  const schoolListData =  metadata&&metadata.school_list?metadata.school_list.map((item) => ({
    label: item["school_name"],
    value: item["school_id"],
  })):[]

  const handleSubmit = (event) => {
    event.preventDefault();
    let reqBody 
          if(watch("admission_number")){
            reqBody={
            admission_number: watch("admission_number"),
            delivery_id: getQueryParam("delivery_id", location),
            page_number:1,
            page_limit:100,
          }
          setPageDetails({
            pageNumber:1,
            pageLimit:100
          })
          }
          else if(watch("school_list_id")&&watch("school_list_id").length>0){
            reqBody={
              school_list: watch("school_list_id"),
             delivery_id: getQueryParam("delivery_id", location),
             page_number: 1,
              page_limit: 100,
            }
            setPageDetails({
              pageNumber:1,
              pageLimit:100
            })
          } 
          else {
            reqBody={
             delivery_id: getQueryParam("delivery_id", location),
             page_number: 1,
              page_limit: 100,
            }
            setPageDetails({
              pageNumber:1,
              pageLimit:100
            })
          }
    dispatch(getStudentListMonitorRequest(reqBody));
  };
  return (
    <form onSubmit={handleSubmit} >
      <Row>
        <Col xs={12} sm={12} md={3}  xl={3}>
          <Controller
            control={control}
            name="admission_number"
            render={({ field: { onChange, value } }) => (
              <SpacingWrapper >
                <TextInput
                  variant="outlined"
                  placeholder="Admission Number"
                  value={value}
                  onChange={onChange}
                  marginBottom
                />
             </SpacingWrapper>
            )}
          />
        </Col>
        <Col md={1} lg={1} sm={12} xs={12}>
          <OR />
        </Col>
        <Col  xs={12} sm={12} md={3} lg={3}>
          <Controller
            control={control}
            name="school_list_id"
            render={({ field: { onChange } }) => (
              <SpacingWrapper>
                <MonitorMultiSelect
                  value={formsList[0]["school-list"]}
                  data={schoolListData}
                  showArrow={true}
                  optionClassName={"antd-multi-select-option"}
                  disabled={schoolListData.length===0}
                  onChange={(e, data, key) => {
                    handleMonitorChange(e, data, "school-list");
                    onChange(
                      formsList[0]["school-list"].filter(
                        (frmlst) => frmlst !== "all"
                      )
                    );
                  }}
                  placeholder={"School or Branch"}
                  suffixIcon={<FaChevronDown />}
                />
              </SpacingWrapper>
            )}
          />
        </Col>
        <Col md={5}>
            <StyledSubmitWrapper>
              <SubmitButton>Submit</SubmitButton>
            </StyledSubmitWrapper>
        </Col>
      </Row>
    </form>
  );
};

export default Form;
