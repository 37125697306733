import { Table } from "antd";
import styled,{css} from "styled-components";
import FilterButton from 'share/atoms/filter-buttons/filter-button/index'

//index
export const Heading = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: #242424;
`;

export const PaperForm = styled.div`
  padding: 16px;
  border-radius: 6px;
  background-color: #fff;
  margin: 16px 0px;
`;

export const Paper = styled.div`
  padding: 16px 16px 0;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 16px;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper= styled.div`
padding:24px;
`

//collapsable form 
export const CollapsableWrapper = styled.div`
width: 100%;
display: flex;
margin-top: -10px;
padding: 16px;
border-top: 1px solid rgba(0,0,0,0.1);
background: white;
box-shadow: 0 2px 8px 0 rgba(148, 148, 151, 0.2);
`


export const TextWrapper = styled.div`
 font-family: Poppins;
 color: #242424;
  .label{
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  }
  .value{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  }
`

export const SubBatchWrapper = styled.div`
display: flex;
justify-content: space-between;
  font-family: Poppins;
  font-size: 12px;
  .label{
  font-weight: bold;
  line-height: 1.67;
  text-align: left;
  color: #242424
  }
  .items{
  font-weight: 600;
  line-height: 1.33;
  }
`
export const ExamNameWrapper = styled.div`
.label{
  font-weight: bold;
  line-height: 1.67;
  text-align: left;
  color: #242424;
  font-family: Poppins;
  font-size: 12px;
  }
` 


export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding: 6px 10px;
  border-radius: 24px;
  background-color: ${({ feature }) =>
    feature === "download" ? "#d71a63" : "#254082"};
     cursor: pointer;
  .icon {
    margin-right: 6px;
  }
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.18px;
  color: #ffffff;
  margin-left: 10px;
  opacity: ${({disabled})=>disabled?0.5:1};
  pointer-events: ${({disabled})=>disabled?"none":"auto"};
`;

export const DownloadWrapper = styled.div`
display: flex;
padding:16px 12px;
flex-direction: column;
cursor: pointer;
.wrap{
  display: flex;
  margin-bottom: 16px;

}
.icon{
  margin-right:8px
}

`

//chip

export const Wrap = styled.div`
display: flex;
align-items: center;
.refresh-icon{
  margin-right: 8px;
  cursor: pointer;
}
`
export const ChipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-family: Poppins;
  font-size: 12px;
  line-height: 2;
  width: 100px;
`;

//form 

export const SubmitWrapper = styled.div`
display: flex;
justify-content: flex-end;
`

export const SubmitButton = styled.button`
  display: flex;
   flex-direction: column;
  justify-content: center;
  align-items: center; 
  border-radius: 8px;
  background-color: #d71a63;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #fff;
  padding: 8px 20px;
  border:0;
  outline: 0;
  &:focus{
   outline: 0;
  }
`
export const SpacingWrapper = styled.div`
@media screen and (max-width:576px){
  ${({status})=> status&& css`
  margin-top:16px;
  `}
  ${({subBatch})=> subBatch&& css`
  margin-top:16px;
  margin-bottom: 16px;
  `}
}

@media screen and (min-width:576px) and (max-width:991px){
  ${({status})=> status&& css`
  margin-top:0;
  `}
  ${({subBatch})=> subBatch&& css`
  margin-top:16px;
  `}
}

`

//filter-chips

export const StyledFilterButton= styled.button`
  padding: 6px 11px;
  border-radius: 4px;
  background-color:white;
  outline: none;
  border:none;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color:black;
  &:focus{
      outline: none;
  }

`

export const StyledTable = styled(Table)`

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  width:0px;
}
  table {
    border-spacing: 0 8px;
  }
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    color: #242424;
    font-weight: 500;
    line-height: 1.67;
  }
  .ant-table-row {
    box-shadow: 0 2px 8px 0 rgba(151, 151, 151, 0.2);
    border: solid 1px #ededed;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}   
.ant-table-tbody > tr > td{
  color: #242424;
}
.ant-table-expanded-row td{
 padding: 0;
}
.ant-table-body{
  overflow: auto !important;
}
`;


export const EllipseText = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:220px;
  text-align: left;
`;

export const EllipseTextExam = styled(EllipseText)`
 width:310px;
  margin-top: 10px;
   font-size: 14px;
   font-weight: 500;
   line-height: 1.57;
`