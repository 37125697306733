class SoundDetector {
  isAlerting = false;
  threshold = 20;
  alertDuration = 1000;
  volumeHistory = [];
  currentVolumeBuffer = [];
  currentTime = 0;
  measuringFrequency = 200;
  averageAudioDtected = null;

  constructor() {
    this.startAudio = this.startAudio.bind(this);
    this.processAudio = this.processAudio.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.mediaStream = null;
    this.alertBox = document.createElement("div");
    this.callback = null;
    this.dbList = [];
  }

  start(stream, audioLimit, audioTiming, callback) {
    this.createAlert(true);
    this.callback = callback;
    this.threshold = audioLimit;
    this.alertDuration = audioTiming;
    this.mediaStream = stream;
    this.startAudio(stream, this.currentTime);
  }
  getMilliSeconds(num) {
    return (num % 1) * 1000;
  }
  startAudio(stream) {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
      this.currentTime = this.getMilliSeconds(this.audioContext.currentTime);
      this.analyzer = this.audioContext.createAnalyser();
      this.analyzer.fftSize = 1024;
      const source = this.audioContext.createMediaStreamSource(stream);
      source.connect(this.analyzer);
      this.processAudio();
    }
  }

  arraySum(arr) {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  toggleCheck(averageVolume) {
    const averageDecibelValuesListLength =
      this.alertDuration / this.measuringFrequency;

    if (this.dbList.length >= averageDecibelValuesListLength) {
      this.dbList.shift();
    }
    this.dbList.push(averageVolume);

    if (this.dbList.length === averageDecibelValuesListLength) {
      const average = this.arraySum(this.dbList) / this.dbList.length;
      this.callback({
        type: "audio",
        state: "alert",
        data: `sound level exceeded the threshold for ${this.audioTiming} milli seconds!`,
      });
      if (average >= this.threshold) {
        this.averageAudioDtected = average;
        sessionStorage.setItem(
          "averageAudioDetected",
          this.averageAudioDtected
        );
        return true;
      }
      return false;
    }
  }
  processAudio() {
    const bufferLength = this.analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    this.currentTime = this.getMilliSeconds(this.audioContext.currentTime);

    this.analyzer.getByteFrequencyData(dataArray);
    const sum = dataArray.reduce((acc, val) => acc + val, 0);
    const averageVolume = sum / bufferLength;
    if (this.currentTime > this.measuringFrequency) {
      const averageAudioDetected = this.toggleCheck(averageVolume);
      if (averageAudioDetected) {
        if (!this.isAlerting) {
          this.isAlerting = true;
          this.alertTimeout = window.setTimeout(
            this.handleAlert(),
            this.alertDuration
          );
        }
      } else {
        if (this.isAlerting) {
          this.isAlerting = false;
          window.clearTimeout(this.alertTimeout);
          // window
        }
      }

      this.currentTime = 0;
    }

    requestAnimationFrame(this.processAudio);
  }

  handleAlert() {
    this.callback({
      type: "audio",
      state: "alert",
      data: `sound level exceeded the threshold for ${this.audioTiming} milli seconds!`,
      testing: this.averageAudioDtected,
    });
  }

  stop() {
    this.alertBox.style.display = "none";
    if (this.mediaStream) {
      this.mediaStream
        .getTracks()
        .map((track) => track.kind === "audio" && track.stop());
    }
    if (this.isAlerting) {
      window.clearTimeout(this.alertTimeout);
    }
  }
  createAlert(isAlert) {
    // this.alertBox.style.position = "absolute";
    // this.alertBox.style.left = 0;
    // this.alertBox.style.top = 0;
    // this.alertBox.style.zIndex = 1000;
    // this.alertBox.style.width = "20px";
    // this.alertBox.style.height = "20px";
    // this.alertBox.style.borderRadius = "50%";
    // this.alertBox.style.backgroundColor = isAlert ? "red" : "green";
    document.body.appendChild(this.alertBox);
  }
}

export default SoundDetector;
