import React from "react";

import { DISCRIPTION_LIST, DISCOVER_LIST } from "../utils";
import {
  WrapContainer,
  Wrap,
  Wrapper,
  ValuesList,
  Heading,
  HeadingText,
  ColoredText,
  ColoredText2,
  HeadingText2,
  StyledDiv,
  Div2,
  SubHeading,
} from "../styles";
import GlobalContainer from "share/atoms/GlobalContainer";

const AboutUsDesc = () => {
  return (
    // <WrapContainer>
    <GlobalContainer style={{ backgroundColor: "#f9f9ff" }}>
      <Wrap>
        <Wrapper>
          <Heading>
            <HeadingText>Welcome To</HeadingText>{" "}
            <ColoredText>CNAPS</ColoredText>
          </Heading>
          <Heading>
            <HeadingText2>
              Council For Nurturing Academic Potential in Students
            </HeadingText2>
          </Heading>
          {DISCRIPTION_LIST.map((data) => (
            <>
              {data.heading && (
                <SubHeading type={data.type}>{data.heading}</SubHeading>
              )}
              {data.content.map((eachPara) => (
                <StyledDiv>{eachPara}</StyledDiv>
              ))}
            </>
          ))}
          {DISCOVER_LIST.map((data) => (
            <>
              <SubHeading type={"sh"}>{data.heading}</SubHeading>
              <ColoredText2>{data.subHeading}</ColoredText2>
              <Div2>{data.desc}</Div2>
            </>
          ))}

          <SubHeading>Mission Statement</SubHeading>
          <StyledDiv>
            To create and nurture a fraternity of young students with latent
            academic potentials by fostering intellectual solidarity
          </StyledDiv>
          <SubHeading>Values</SubHeading>
          <StyledDiv>The core and abiding values of CNAPS are</StyledDiv>
          <StyledDiv>
            <ValuesList>
              <li>Enthusiasm to spot talent</li>
              <li>Willingness to nurture the inherent academic potential</li>
              <li>Responsibility to share and contribute</li>
            </ValuesList>
          </StyledDiv>
        </Wrapper>
      </Wrap>
    </GlobalContainer>
    // </WrapContainer>
  );
};
export default AboutUsDesc;
