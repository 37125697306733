import React, { useEffect, useRef, useState } from "react";
import {
  Caption,
  FeatureHeaderWrapper,
  ImageWrapper,
  Image,
  Container,
  StyledSlider,
  Modal,
  ModalContent,
  CloseButton,
  PrevButton,
  NextButton,
  StyledLeftButtonContainer,
  StyledRightButtonContainer,
} from "./styles"; // Make sure to add styles for PrevButton and NextButton
import { awardImageGallery } from "utils/award-ceremonies";
import Slider from "react-slick";
import { useResize } from "share/hooks/useResize";
import { getPadding } from "student/LandingPage/Exams/helper";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";

function AwardImageGallery() {
  const { width } = useResize();
  const images = awardImageGallery;
  const [currentCount, setCurrentCount] = useState("");
  const [cPadding, setCPadding] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const slideRef = useRef();
  const [curentBanners, setCurrentBanners] = useState([]);

  useEffect(() => {
    if (width <= 800) {
      const val = getPadding(width);
      const { cPadding } = val;
      setCPadding(cPadding);
    }
  }, [width]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => setCurrentCount(current),
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
  };

  const openModal = (banners, image, index) => {
    setSelectedImage(image);
    setCurrentBanners(banners);
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handlePrev = () => {
    const newIndex =
      (currentImageIndex - 1 + images.length) % curentBanners.length;
    setCurrentImageIndex(newIndex);
    setSelectedImage(curentBanners[newIndex].image);
  };

  const handleNext = () => {
    const newIndex = (currentImageIndex + 1) % curentBanners.length;
    setCurrentImageIndex(newIndex);
    setSelectedImage(curentBanners[newIndex].image);
  };

  const onLeftSlide = () => {
    slideRef.current.slickPrev();
  };

  const onRightSlide = () => {
    slideRef.current.slickNext();
  };
  return (
    <>
      <FeatureHeaderWrapper>
        {width < 800 ? (
          <h2>
            Frames of Celebration{" "}
            <span>
              <br />
            </span>
            Award Ceremony Visuals
          </h2>
        ) : (
          <h2>Frames of Celebration : Award Ceremony Visuals</h2>
        )}
      </FeatureHeaderWrapper>
      <div style={{ position: "relative" }}>
        <StyledLeftButtonContainer>
          <FaChevronLeft onClick={onLeftSlide} />
        </StyledLeftButtonContainer>

        <StyledSlider>
          <Slider {...settings} ref={slideRef}>
            {images.map((image, index) => (
              <div key={index}>
                <ImageWrapper>
                  {image.banners.map((item) => (
                    <Image
                      key={item.id}
                      onClick={() =>
                        openModal(image.banners, item.image, index)
                      }
                    >
                      <img src={item.image} alt="Award ceremony" />
                    </Image>
                  ))}
                </ImageWrapper>
                <Caption>{image.caption}</Caption>
              </div>
            ))}
          </Slider>
          <StyledRightButtonContainer>
            <FaChevronRight onClick={onRightSlide} />
          </StyledRightButtonContainer>
        </StyledSlider>
      </div>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CloseButton onClick={closeModal}>
              <FaTimesCircle />
            </CloseButton>
            <PrevButton onClick={handlePrev}>
              <FaChevronLeft />
            </PrevButton>
            <img src={selectedImage} alt="Expanded view" />
            <NextButton onClick={handleNext}>
              <FaChevronRight />
            </NextButton>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default AwardImageGallery;
