import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 1140px;
  margin: 5px auto;
  @media screen and (min-width: 320px) and (max-width: 1140px) {
    width: 94%;
    margin: auto;
  }
`;

const Container = ({ children, ...rest }) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

export default Container;
