import React, { useEffect, useState } from "react";
import {
  HeaderWrap,
  LogoImage,
  LogoBackgroundImage,
  ArrowImage,
  CorosalWrapper,
  Wrapper,
  Wrap,
  MobileWrapper,
  MobileArrowImage,
  UpdateWrapper,
  Content,
  Box
} from "./styles";
import Logo from "assets/frame.svg";
import LogoBackground1 from "assets/cnaps-bg.svg";
import LogoBackground from "assets/intersect.svg";
import Features from "containers/Features";
import Carousel from "share/atoms/carousel";
import DownArrow from "assets/DownArrow.svg";
import { StickyHeader } from "share/organisms/headers";
import { ModalWrap } from "./styles";
import StudentImage from "assets/slide-1.svg";
import StudentImage2 from "assets/slide-2.svg";
import StudentImage3 from "assets/slide-3.svg";
import useModal from "share/hooks/useModal";
import ModalTemp from "share/templates/ModalTemp";
import Button from "share/atoms/Button";
import { saveAs } from "file-saver";
import { FaTimes } from "react-icons/fa";
import LandingPageService from "student/LandingPage/service";
import downloadCertificateIcon from "assets/download-certificate.svg";
import notify from "utils/notifications";
import { TextInput } from "share/atoms/form-fields";
// import ResultImage from 'assets/result.svg'
import { useHistory } from "react-router-dom";

const landingPageService = new LandingPageService();

function LandingHeader(props) {
  const [certificate, setCertificate] = useState(false);
  const [hallticket, setHallticket] = useState("");
  const { isVisible, handleModal: openHallTicketModal } = useModal();
  const [alertMsgData, setAlertMsgData] = useState({})
  const { isVisible: downloadModal, handleModal: openDownloadModal } =
    useModal();
  const history = useHistory();

  useEffect(async () => {
    const res = await landingPageService.getAlertMessages();
    setAlertMsgData(res.data.data)
  }, [])

  const submitHallTicket = async (e) => {
    e.preventDefault();
    const res = await landingPageService.submitHallTicket({
      hall_ticket_number: hallticket,
    });
    if (res?.data?.status === "success") {
      openHallTicketModal();
      openDownloadModal();
      setCertificate(res.data.data.certificate_url);
      setHallticket("");
    } else if (res?.data?.status === "error") {
      notify("error", res.data.message);
    }
  };

  const onHallTicketChange = (e) => {
    setHallticket(e.target.value);
  };

  const downloadCertificate = () => {
    if (certificate) saveAs(certificate);
  };

  return (
    <Box>
      {isVisible && (
        <ModalTemp footerVisible={false} onCancel={openHallTicketModal}>
          <ModalWrap>
            <div className="cancel-wrap">
              <span onClick={openHallTicketModal}>
                <FaTimes />
              </span>
            </div>
            <div className="content-wrap">
              <h2 className="title">Download Certificate </h2>
              <p className="desc">
                Enter your unique id to download your CNAPS 2020-21 Certificate
              </p>
              <form onSubmit={submitHallTicket}>
                <TextInput
                  className="input"
                  placeholder="Enter Hall Ticket/Unique Id"
                  value={hallticket}
                  handleChange={onHallTicketChange}
                />
                <Button
                  type="submit"
                  disabled={!hallticket || hallticket.length < 5}
                >
                  Submit
                </Button>
              </form>
            </div>
          </ModalWrap>
        </ModalTemp>
      )}
      {downloadModal && (
        <ModalTemp footerVisible={false} onCancel={openDownloadModal}>
          <ModalWrap>
            <div className="cancel-wrap">
              <span onClick={openDownloadModal}>
                <FaTimes />
              </span>
            </div>
            <div className="content-wrap">
              <h2 className="title">Download Certificate </h2>
              <p className="desc">
                Congratulations on your performance!! Download your certificate
                below.
              </p>
              <img
                className="download-image"
                src={downloadCertificateIcon}
                alt="download"
                onClick={downloadCertificate}
              />
            </div>
          </ModalWrap>
        </ModalTemp>
      )}
      {/* <StickyHeader {...props} /> */}
      <Wrap>
        <div className="container header-wrapper">
          <HeaderWrap bgImage={LogoBackground}>
            <div className="ViewBlocker">
              {/* <LogoBackgroundImage src={LogoBackground1} /> */}
              {/* <LogoImage src={Logo} /> */}
              {/* <Features {...props} /> */}
            </div>
            {/* <CertificateWrap>
              <div className="certificate-card">
                Now get your certificates for CNAPS 2020 &nbsp;
                <a onClick={openHallTicketModal}>Click here</a>
              </div>
            </CertificateWrap> */}
            {/* <ResultWrapper>
              {alertMsgData.is_alert_available && <div className="result-card">
                <img src={ResultImage} />
                <span>ISMO</span> Results are out now!! Please <a onClick={() => history.push("/student/login")}>Sign in </a>to view your results
                {alertMsgData.message} &nbsp;  Please <a onClick={() => history.push("/student/login")}>Sign in </a>to view your results
              </div>}
            </ResultWrapper> */}
            {/* <UpdateWrapper>
              <Content>
                <span className="year">CNAPS 2023 -2024</span> registrations are open . <a  className="register-now" onClick={() => history.push("/student/registration")}>Register Now</a>
              </Content>
            </UpdateWrapper> */}
          </HeaderWrap>
          <CorosalWrapper>
            <Carousel data={SLIDE_DATA} />
          </CorosalWrapper>
          <ArrowImage>
            <img src={DownArrow} onClick={props.scroll} alt="arrow down" />
          </ArrowImage>
        </div>
      </Wrap>
      <Wrapper></Wrapper>
      <MobileWrapper className="show-mobile">
        <MobileArrowImage>
          <img src={DownArrow} onClick={props.scroll} alt="arrow down" />
        </MobileArrowImage>
      </MobileWrapper>
    </Box>
  );
}

const SLIDE_DATA = [
  {
    subTitle: "Online Olympiads",
    title: "Council For Nurturing Academic Potential in Students",
    desc: `CNAPS endeavours to identify the latent potential through
  quantitative and qualitative assessments and nurture the students
  thereafter.`,
    image: StudentImage,
  },
  {
    subTitle: "Overall Student Assessment",
    title: "Research based Olympiad",
    desc: `CNAPS groundwork and research made it possible to compare and
  contrast the best practices from various International Olympiads and
  competitions to distill the CNAPS's olympiads to better suit
  academic environments in India.`,
    image: StudentImage3,
  },
  {
    subTitle: "Test Results",
    title: `Best in industry Student's performance analytics`,
    desc: (
      <span>
        "Something that is not measured is not understood",
        <br />
        5Q Analysis is provided for the first time in the country.
      </span>
    ),
    image: StudentImage2,
  },
];

export default LandingHeader;
