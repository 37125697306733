import React from 'react';
import { Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import CloseIcon from 'assets/CloseIcon.svg'

const StyledModal = styled(Modal)`
  .modal-dialog {
    position: relative;
    margin: 0 auto 0 0;
    animation: ${({ theme }) => theme.keyframes.fromRight} 0.3s;

    .modal-content {
      position: fixed;
      top: 0px;
      right: 0px;
      width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "276px")};
    }
  }
`;

const CrossIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      background-image: none;
      cursor:pointer;
      color: black;
      top: 16px;
      right: 16px;
      svg {
        font-size: 25px;
      }
    `}
`;

const SidePanelDrawer = props => {
  const { onHide, children, isFullWidth } = props;

  return (
    <StyledModal
      show={true}
      onHide={onHide}
      animation={true}
      isFullWidth={isFullWidth}
    >
      <CrossIcon onClick={onHide} isFullWidth={isFullWidth}>
        <img src={CloseIcon} alt="close"/>
      </CrossIcon>
      {children}
    </StyledModal>
  );
};

export default SidePanelDrawer;
