import React from "react";
import { Wrapper, BannerWrapper } from "./styles";
import DashboardImage from "assets/dashboard-img.png";
import Vector from "assets/Vector.svg";

const Banner = () => {
  return (
    <BannerWrapper>
      <Wrapper>
        <div className="sub-heading">
          Council for Nurturing
          <br />
          academic potential in Students
        </div>
        <p className="description">
          CNAPS endeavours to identify the latent potential through
          <br />
          quantitative and qualitative assessments and nurture the students
          thereafter.
        </p>
      </Wrapper>
      <div className="col-xl-6  right-block">
        <div style={{ overflow: "hidden" }}>
          <img src={Vector} className="vector-img" alt="vector" />
          <img src={DashboardImage} className="image" alt="dashboard" />
        </div>
      </div>
    </BannerWrapper>
  );
};

export default Banner;
