import React from "react";

import { Popover } from "antd";
import ListView from "share/molecules/collapsable/list";
import Monitor from "assets/monitoring/monitor.svg";
import { Button } from "./styles";
import Downlaod from 'assets/monitoring/download.svg'
import DownArrow from 'assets/monitoring/arrow-down.svg'
import DownloadBlack from 'assets/monitoring/download-black.svg'
import { TextWrapper,SubBatchWrapper,DownloadWrapper,CollapsableWrapper,ExamNameWrapper,EllipseTextExam} from './styles'
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDeliveryFiltersRequest } from "./actions/get-delivery-filters";
import DeliveryService from "./services";

const CollapsableForm = ({ row }) => {
  const content = () => {
    return row?.subbatch_list?.map((item, index) => <div key={index}>{item}</div>);
  };
 const history = useHistory();
 let { url } = useRouteMatch();
 const dispatch = useDispatch();

 const downloadResult = async () => {
  const payload = {
    reqBody: { delivery_id: row["delivery_id"], name: row["delivery_name"] },
    dispatch: dispatch,
  };
  DeliveryService.downloadResultApi(payload);
};

const downloadTestTakers = () => {
  const payload = {
    reqBody: { delivery_id: row["delivery_id"], name: row["delivery_name"] },
    dispatch: dispatch,
  };
  DeliveryService.downloadMonitorTestTakersApi(payload);
};

const downloadResponse = () => {
  const payload = {
    reqBody: {
      delivery_id: row["delivery_id"],
      name: row["delivery_name"],
      test_id: row["test_id"],
    },
    dispatch: dispatch,
  };
  DeliveryService.downloadResponseApi(payload);
};


 const downloadContent=()=>{
   return (
     <DownloadWrapper>
       <div className="wrap" onClick={downloadResult}>
       <img src={ DownloadBlack} className="icon"/>
       <p>Download Result</p>
       </div>
       <div className="wrap" onClick={downloadTestTakers}>
       <img src={ DownloadBlack} className="icon"/>
       <p>Download Monitor Test Takers</p>
       </div>
       <div className="wrap" onClick={downloadResponse}>
       <img src={ DownloadBlack} className="icon"/>
       <p>Download Response</p>
       </div>
     </DownloadWrapper>
   )
 }

 const handleMonitorTestTakers = () => {
  const reqBody = {
    delivery_id: row["delivery_id"],
    delivery_name: row["delivery_name"],
    path: history.location.pathname,
  };
  dispatch(getDeliveryFiltersRequest(reqBody));
};


  return (
    <CollapsableWrapper >
      <div style={{ width: "300px", display: "flex",padding:0}}>
        <div style={{ width: "50%", borderRight: "1px solid #edf0f5" }}>
          <TextWrapper>
          <div className="label">Delivery Id</div>
          <div className="value">{row["delivery_id"]}</div>
          </TextWrapper>
        </div>
        <div
          style={{
            width: "50%",
            paddingLeft: "10px",
            borderRight: "1px solid #edf0f5",
          }}
        >
          <TextWrapper>
          <div className="label">Test Id</div>
          <div className="value">{row["test_id"]}</div>
          </TextWrapper>
        </div>
      </div>
        <div
        style={{
          width: "calc(100% - 1200px)",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRight: "1px solid #edf0f5",
          }}
        >
          <SubBatchWrapper>
            <div className="label">Sub batch</div>
            <Popover content={content} trigger="click" placement="bottom">
              <div style={{ textDecoration: "underline", cursor: "pointer" }} className="items">
                {row?.subbatch_list.length} items
              </div>
            </Popover>
          </SubBatchWrapper>
          <div style={{ width: "150px", overflow: "hidden" }}>
            <ListView item={row?.subbatch_list?row.subbatch_list:[]} />
          </div>
        </div>
      </div>

       <div style={{
          width: "calc(100% - 1100px)",
          display: "flex",
         
        }}>
          <div  style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRight: "1px solid #edf0f5",
          }}>
            <ExamNameWrapper>
            <div className="label">Exam Model</div>
            <Popover content={row.exam_name} placement="bottom"><EllipseTextExam >{row.exam_name}</EllipseTextExam></Popover>
            </ExamNameWrapper>
          </div>
       </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "650px",
          alignItems: "center",
        }}
      >
        <Button feature="test" onClick={handleMonitorTestTakers} 
        disabled={row["delivery_status"]==="Completed"}
        >
          <img src={Monitor} alt="monitor" className="icon" />
          <p>Monitor Test Takers</p>
        </Button>
        <Popover content={downloadContent} trigger="click" placement="bottom">
         <Button feature="download">
         <img src={Downlaod} alt="monitor" className="icon" />
          <p>Download</p>
          <img src={DownArrow} alt="monitor" className="icon" />
         </Button>
        </Popover>
      </div>
    </CollapsableWrapper>
  );
};

export default CollapsableForm;
