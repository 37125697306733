import styled from "styled-components";
import Button from "share/atoms/Button";

export const PayNowButton = styled(Button)`
  height: 48px;
  padding: 12px 16px;
  font-family: Poppins;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.41px;
  text-align: center;
  color: #ffffff;
`;

export const PayLater = styled(PayNowButton)`
  background-color: #1e5190;
  width: 204px;
`;

export const BtnWrap = styled.div`
  display: flex;
  margin: 15px 0px;
  justify-content: flex-end;
`;

