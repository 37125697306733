import React, { useState, useEffect } from "react";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import ExamDetailsCard from "share/molecules/card/student-exam-details-card";
import StudentExamCard from "share/molecules/card/student-exam-card";
import useModal from "share/hooks/useModal";
import useOnChange from "../ExamsSelection/useOnChange";
import ExamFooter from "share/molecules/exams-footer";
import ModalTemp from "share/templates/ModalTemp";
import ScheduledExamsService from "./services";
import ExamSelectionService from "../ExamsSelection/services";
import confirmIcon from "assets/confirm.svg";
import { Redirect } from "react-router-dom";
import { initPaymentProcess } from "utils/payment";
import {
  Wrap,
  Heading,
  OlympiadsWrap,
  ImportantWrapper,
  ListWrapper,
} from "./styles";
import { getLocalObj } from "utils/utility/local-storage";
import { openPdfInWindow } from "utils/utility/global-window";
import { navigateToTestEnginge } from "utils/utility/test-engine";
import { hasArrData } from "utils/utility/obj-arr";
import {
  getSelectedExams,
  getObjectKeys,
  getSelectedExamsCount,
  getSelectedExamsAmount,
  getSelectedExamIds,
} from "utils/utility/student";
import SmallRupee from "assets/small-rupee-copy.svg";

const DISCOUNT_AMOUNT = process.env.REACT_APP_DISCOUNT_AMOUNT;

const scheduledExamsService = new ScheduledExamsService();
const examSelectionService = new ExamSelectionService();

export default function StudentExams(props) {
  const [scheduledExams, setScheduledExams] = useState(false);
  const [olympiads, setOlympiads] = useState(false);
  const { value: selectedPhases, onChange: phasesChange } = useOnChange({});
  const { value: selectedExams, onChange: examsChange } = useOnChange({});
  const { isVisible, handleModal } = useModal();

  const getExams = async () => {
    const examsRes = await scheduledExamsService.getScheduledExams();
    if (examsRes && examsRes.data)
      setScheduledExams(examsRes.data.data.purchased_exam_list);
  };

  useEffect(async () => {
    getExams();
    const olympiadRes = await examSelectionService.getExamsList();
    if (olympiadRes && olympiadRes.data) setOlympiads(olympiadRes.data.data);
  }, []);

  const onPhaseChange = (e, exam) => phasesChange(e, exam);

  const onCheckboxChange = (e, exam) => examsChange(e.target.checked, exam);

  const handleTest = (data, type) =>
    navigateToTestEnginge(data, type, props.history);

  const handlePayment = async () => {
    const obj = getSelectedExamIds(selectedExams, selectedPhases);
    const keys = getObjectKeys(obj);
    let isAbleToPay = keys ? keys.every((id) => obj[id]) : null;
    if (!isAbleToPay || !keys) handleModal();
    else {
      let reqBody = getSelectedExams(keys, olympiads, selectedPhases);
      let res = await examSelectionService.initiatePayment(reqBody);
      initPaymentProcess(res.data.data, "student");
    }
  };

  const tokenData = getLocalObj("token-data");
  const hasExams = hasArrData(scheduledExams);
  const hasOlympiads = hasArrData(olympiads);

  if (localStorage.getItem("token") && !tokenData.is_exams_purchased)
    return <Redirect to="/student/exam-selection" />;

  const getTotalDiscount = () => {
    if (olympiads.length > 0) {
      return olympiads.reduce((curr, acc) => curr + acc.discount, 0);
    }
  };
  return (
    <WithSidepanelLayout>
      {isVisible && (
        <ModalTemp
          icon={confirmIcon}
          title="Information"
          primaryBtnClass="d-none"
          desc="Please select exam date "
          onCancel={handleModal}
        />
      )}
      {hasExams && (
        <>
          <Wrap>
            <Heading className="mt-0">Exams</Heading>
            {/* <p className="count">Total {scheduledExams.length}</p> */}
          </Wrap>
          {scheduledExams.map((exam) => (
            <ExamDetailsCard
              key={exam.test_id + exam.delivery_id}
              handleTest={handleTest}
              handlePdf={openPdfInWindow}
              exam={exam}
              isLevelTwo={exam.is_level_two}
              getExams={getExams}
            />
          ))}
        </>
      )}

      {hasOlympiads && (
        <>
          <Wrap>
            <Heading
              className={hasExams ? "mt-4" : `mt-0`}
              style={{ color: "#242424" }}
            >
              Other Popular Olympiads
            </Heading>
          </Wrap>
          {tokenData.is_narayana_student && (
            <ImportantWrapper>
              <p className="imp-note">*Important Note</p>
              <ListWrapper>
                <li className="list-item">
                  Congratulations, as a Student of Narayana Group you get an additional discount upto{""}
                  <img src={SmallRupee} className="icon" alt="" />
                  {/* &#8377; */}
                  {DISCOUNT_AMOUNT} per exam.
                  {/* Total Discount of{" "} */}
                  {/* <img src={SmallRupee} className="icon" alt="" /> */}
                  {/* <span className="bold">{getTotalDiscount()}</span>. */}
                </li>
                <li className="list-item">
                  To apply for more exams , Please contact your{" "}
                  <span className="bold"> School Admin</span>.
                </li>
              </ListWrapper>
            </ImportantWrapper>
          )}

          <OlympiadsWrap isIndependent={tokenData.is_independent_student}>
            {olympiads.map((exm) => (
              <StudentExamCard
                key={exm.exam_model_id}
                data={exm}
                phase={selectedPhases[exm.exam_model_id]}
                isSelected={selectedExams[exm.exam_model_id]}
                onPhaseChange={onPhaseChange}
                onCheckboxChange={onCheckboxChange}
              />
            ))}
          </OlympiadsWrap>
        </>
      )}
      {hasOlympiads && tokenData.is_independent_student && (
        <ExamFooter
          className="container mr-4 pr-4"
          handlePayment={handlePayment}
          selectedExams={getSelectedExamsCount(selectedExams)}
          amount={getSelectedExamsAmount(selectedExams, olympiads)}
        />
      )}
    </WithSidepanelLayout>
  );
}
