import styled from "styled-components";
import ArrowIcon from 'assets/arrow.svg'
export const Heading = styled.h2`
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #d71a63;
  margin-top: 36px;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .count {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: right;
    color: #565656;
  }
`;

export const OlympiadsWrap = styled.div`
  padding-bottom: ${({ isIndependent }) => (isIndependent ? "96px" : "16px")};
`;

export const ImportantWrapper = styled.div`
   margin:24px 0px;
  display: flex;
  flex-direction:column;
  font-family: Poppins;
  border-radius: 6px;
  border: solid 1px #ca740b;
  background-color: #fff6ec;
  padding: 18px 0px 14px 21px;
  position: sticky;
  top:68px;
  .imp-note{
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  color: #ca740b
  }
`
export const ListWrapper = styled.ul`
  list-style: none;
  li{
    background: url(${ArrowIcon})  no-repeat left center;
    padding-left: 40px;
    margin-top:16px;
  }
  .list-item{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #242424;
}
.bold{
  font-weight: bold;
} 
   
`
