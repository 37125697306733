import React from "react";
import PropTypes from "prop-types";
import SidePanel from "share/templates/SidePanel";
import BreadCrumbs from "share/molecules/bread-crumbs";
import SchoolAdminHeader from "share/organisms/headers/school-admin-header";
import { useHistory } from "react-router-dom";
import { Wrap } from "./styles";

const routesList = [
  { to: "/result", label: "Test Result" },
  { to: "/instructions", label: "Test Instructions" },
];
const subRoutesList = {
  "/result": [{ to: "/answerkey", label: "Answer Key" }],
};

const WithSidepanelLayout = ({ children, showBreadcrumb, styles }) => {
  const history = useHistory();

  return (
    <Wrap className="d-flex" style={styles}>
      <section className="left-section">
        <SidePanel />
      </section>
      <section className="right-section">
        <SchoolAdminHeader />

        <div className="container-fluid">
          {showBreadcrumb && (
            <React.Fragment>
              <BreadCrumbs
                subroutesList={subRoutesList}
                routesList={routesList}
                history={history}
              />
              <div className="padding-gap" />
            </React.Fragment>
          )}

          {children}
        </div>
      </section>
    </Wrap>
  );
};

export default WithSidepanelLayout;

WithSidepanelLayout.propTypes = {
  children: PropTypes.node,
};
