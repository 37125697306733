import styled from "styled-components";

export const Wrap = styled.div`
  height: 100%;
  .left-section {
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    height: 100%;
    z-index: 4;
  }
  .right-section {
    width: calc(100% - 240px);
    margin-left: 240px;
    position: relative;
  }
  section .container-fluid {
    padding: 24px 48px 0;
    .padding-gap {
      padding-bottom: 24px;
    }
  }
  @media screen and (max-width: 900px) {
    .left-section {
      width: 48px;
    }
    .right-section {
      width: calc(100% - 48px);
      margin-left: 48px;
    }
    section .container-fluid {
      padding: 24px 24px 0;
    }
  }
  @media screen and (min-width: 1600px) {
    section .container-fluid {
      width: 1320px;
      padding: 24px 30px 0;
    }
  }
  @media screen and (min-width: 1920px) {
    section .container-fluid {
      padding: 24px 0;
    }
  }
`;
