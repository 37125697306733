import styled from "styled-components"

export const Wrapper = styled.div`
position:fixed;
height:100vh;
width:100vw;
background:black;
opacity:0.5;
z-index:1000;
display:flex;
justify-content: center;
align-items: center;
`