import Moment from "moment";

/**
 * this function takes the milliseconds and converts into minutes and seconds
 * @param {number} millis
 * @returns {string} combine minutes and seconds
 */
export const millisToMinutesAndSeconds = (millis) => {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes}m ${seconds}s`;
};

/**
 * this function helps to format the date in required format
 * @param {string} date
 * @param {string} format
 * @returns {string/boolean} format the date
 */

export const formatDate = (date, format) => {
  return Moment(date).isValid()
    ? format && date
      ? Moment(date).format(format)
      : date
    : false;
};
