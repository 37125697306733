import Button from "share/atoms/Button"
import FixedContainer from "share/atoms/FixedContainer"
import styled from "styled-components"

export const Instructions = styled.ul`
  padding: 24px;
  margin-bottom: 0;
  margin-top: 24px;
  li {
    color: #464646;
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.75;
    font-weight: 500;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 24px;
  h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
  }
`;

export const PhaseWrapper = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`

export const DateWrapper = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
`;

export const Wrapper = styled.div`
  padding: 0 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const ConfirmButton = styled(Button)`
  width: 136px;
  border-radius: 100px;
  background-color: #d71a63;
  font-family: Poppins;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
  opacity: ${({ opacity }) => (opacity ? 0.3 : 1)};
`;

export const IconWrapper = styled.div`
  position: relative;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const HeadingText = styled.h5`
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 10px 0px;
`;

export const Description = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
`;

export const Wrap = styled(FixedContainer)`
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: Poppins;
  overflow: auto;
  background: red;
  height: calc(100vh - 80px);
`;

export const CardWrapper = styled.div`
  min-height: 357px;
  padding: 48px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 36px 0 0 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .new-reg-col-wrap {
    border-right: 1px solid #ebe8e9;
    min-height: 270px;
    &:last-child {
      border-right: none;
    }
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ opacity }) => (opacity ? 0.3 : 1)};
  .icon {
    width: 72px;
    height: 72px;
    object-fit: contain;
  }
  .card-text {
    /* margin: 28px 70px 23px 77px; */
    font-family: Poppins;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #d71a63;
    margin-top: 28px;
    margin-bottom: 23px;
    min-width: 176px;
    max-height: 22px;
  }
  .description {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    min-width: 270px;
    min-height: 22px;
    text-align: center;
    color: #464646;
    width: 56%;
  }
  .link {
    height: 22px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    text-decoration: underline;
  }
`;

export const CustomButton = styled(Button)`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  max-width: 238px;
  max-height: 48px;
  border-radius: 24px;
  background-color: ${({ disabled }) => (disabled ? "#cccccc" : "#1e5190")}; /* Light grey for disabled */
  color: ${({ disabled }) => (disabled ? "#666666" : "#ffffff")}; /* Darker grey text for disabled */
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)}; /* Reduce opacity for disabled state */
  border: ${({ disabled }) => (disabled ? "1px solid #999999" : "none")}; /* Optional border for disabled state */
  
  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#cccccc" : "#1c4a8b")}; /* Darker shade on hover, if not disabled */
  }
`;

// export const TableWrapper = styled.div`
// display: flex;
// table{
//  width:100%;
//  border-color: rgba(148,148,151,0.2);
// }
//  thead{
//    font-family: Poppins;
//   font-size: 14px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.29;
//   letter-spacing: normal;
//   color: #242424;
//    .exams{
//      width:50%;
//      padding:12px;
//    }
//   .available{
//      width: 50%;
//      padding: 12px;
//       text-align: center;
//    }
//  }
// `

export const TableWrapper = styled.div`
display: flex;
.second-table{
  border-left-color:transparent
}
 table{
  width:100%;
  border-color: rgba(148,148,151,0.2);
  border-collapse: collapse;
 }
  thead{
   font-family: Poppins;
   font-size: 14px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 1.29;
   letter-spacing: normal;
   color: #242424;
    .exams{
      width:60%;
      padding:12px;
      padding-left:24px;
    }
   .exam-date-heading{
      width: 40%;
      padding: 12px;
      padding-left:24px;
  }
}
.second-table{
  border-collapse:collapse;
}

.exam-info{
  /* padding-left: 24px;
  padding-top:12px; */
  padding:12px 0px 12px 24px
}
.exam-name{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}
  .exam-abbr{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #666;
  margin-top:8px;
  }
  .exam-date{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #666;
  padding-left:24px;
  }
  .new-exam{
    font-size: 12px;
  font-weight: bold;
  font-style: italic;
  color:#d71a63;
  margin-left: 5px;
  }
 `


export const ExamDetailsWrapper = styled.div`
padding:12px;
.name{
font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}
.details{
font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-top:8px;
}
`
export const Message = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 2;
  color:#e21313;
`