import SchoolLogin from "school-admin/Login/Loadable";
import SchoolDashboard from "school-admin/Dashboard/Loadable";
import ForgotPassword from "school-admin/ForgotPassword/Loadable";
import Registration from "school-admin/Registration/Loadable";
import NewRegistration from "school-admin/NewRegistration";
import StudentList from "school-admin/StudentList";
import DivisionalAccntDashboard from "school-admin/DivisionalAccntDashboard/Loadable";
// import SchoolProfile from "school-admin/SchoolProfile/profile";
import ChangePasswordScreen from "share/templates/ChangePasswordTemp";
import LandingPage from "school-admin/Landing";
import Profilescreen from "share/templates/ProfileScreen";
import Monitoring from "monitoring";

const schoolRoutes = [
  { component: SchoolLogin, exact: true, path: "/school/login" },
  { component: SchoolDashboard, exact: true, path: "/school/dashboard" },
  { component: ForgotPassword, exact: false, path: "/school/forgot-password" },
  { component: Registration, exact: false, path: "/school/registration" },
  // { component:Monitoring,exact:false,path:"/school/deliveries"},
  {
    component: NewRegistration,
    exact: false,
    path: "/school/new-registration",
  },
  { component: StudentList, exact: true, path: "/school/student-list" },
  {
    component: DivisionalAccntDashboard,
    exact: false,
    path: "/school/divisional-accountant",
  },
  { component: Profilescreen, exact: true, path: "/school/profile" },
  {
    component: ChangePasswordScreen,
    exact: true,
    path: "/school/change-password",
  },
  { component: LandingPage, exact: true, path: "/school" },
];

export default schoolRoutes;
