import {Circle} from 'share/molecules/collapsable/circle'


const List = ({ item, width }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          overflow: "hidden",
          backgroundColor: "transparent",
          marginTop: "8px",
          width: "100%",
        }}
      >
        {item.map((item) => (
          <span
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: "5px",
              whiteSpace: "nowrap",
              fontSize:"14px",
              fontWeight:500,
              lineHeight:1.57,
              color:"#242424"
            }}
          >
            <Circle />
            {item}
          </span>
        ))}
      </div>
    );
  };

  export default List