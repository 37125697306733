import request from "utils/axios/service";

const profileEndPoints = {
    studentProfile: "cnaps-student/v1/student/profile",
    schoolProfile: "cnaps-admin/v1/admin/get-profile-info"
}
const changePasswordEndPoints = {
    school: "cnaps-admin/v1/admin/change-password",
    student: "cnaps-student/v1/student/change-password"
}
class ProfileService {
    async getStudentProfile() {
        return await request.get(profileEndPoints.studentProfile);
    }
    async getSchoolProfile(req) {
        return await request.get(profileEndPoints.schoolProfile);
    }
    async postSchoolChangePassword(req) {
        return await request.post(changePasswordEndPoints.school, req)
    }
    async postStudentChangePassword(req) {
        return await request.post(changePasswordEndPoints.student, req)
    }
}

export default ProfileService;
