import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import Button from "share/atoms/Button";
import OOPSIcon from "assets/oops.svg";

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 36px 36px;
`;

const Image = styled.img``;

const ErrorText = styled.h3`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
  margin-top: 16px;
`;

const ThemeButton = styled(Button)`
  margin-top: 24px;
`;

const ErrorModal = ({ error, history }) => {
  let message = null;
  if (error && error.data && error.data.hasOwnProperty('message'))
    message = error.data.message;
  else if (error && error.message) message = error.message;

  const handleClick = () => {
    history.push('/')
  };

  return (
    <Modal show={true} centered size="lg" onHide={() => {}}>
      <ModalBody>
        <Image src={OOPSIcon} alt="error image" />
        <ErrorText>{message}</ErrorText>
        <ThemeButton size="md" onClick={handleClick}>Go Home</ThemeButton>
      </ModalBody>
    </Modal>
  );
};

export default withRouter(ErrorModal);
