import styled from "styled-components";
import Tick from "assets/exams/IconTick.svg";
export const Heading = styled.h2`
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #d71a63;
`;

export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const Count = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: #565656;
  margin-bottom: 0;
  /* cursor: pointer; */
`;

export const InstructionWrapper = styled.div`
   margin:24px 0px;
   padding:24px;
  display: flex;
  flex-direction:column;
  font-family: Poppins;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: white;
  .heading{
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom:18px;
  }

`
export const ValuesList = styled.ul`
  margin-left: 16px;
  list-style-image: url(${Tick});
  margin-bottom: 0;
  li {
    margin-bottom: 16px;
  }
`;

export const Icon = styled.img`
  margin-left:10px;
`