// import Axios from "axios";
import request from "utils/axios/service";
import { endpoints } from "./constants";

class LandingPageService {
  async submitHallTicket(req) {
    return await request.get(endpoints.SUBMIT_HALL_TICKET, req);
  }
  async getAlertMessages() {
    return await request.get(endpoints.ALERT_MESSAGES)
  }
}

export default LandingPageService;
