import {
  Container,
  FeatureHeaderWrapper,
  CardsWrapper,
  CarasoulContainer,
} from "./styles";
import { FEATURES_LIST } from "./helper";
import FeaturesCard from "./card";
import { useResize } from "share/hooks/useResize";
import Carasoul from "./carasoul";

export default function WhyChooseUs(props) {
  const { width } = useResize();
  return (
    <Container>
      <FeatureHeaderWrapper>
        <h1>Advantages of <span style={{color:'#d71a63'}}>CNAPS</span></h1>
        <h6>
          CNAPS provides you with a unique set of advantages which makes it a
          preferred choice
        </h6>
      </FeatureHeaderWrapper>
      <CardsWrapper className="row">
        {width <= 800 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <CarasoulContainer>
              <Carasoul length={FEATURES_LIST.length}>
                {FEATURES_LIST.map((featureData) => (
                  <div style={{ width: "100px" }}>
                    <FeaturesCard data={featureData} />
                  </div>
                ))}
              </Carasoul>
            </CarasoulContainer>
          </div>
        ) : (
          <CardsList />
        )}
      </CardsWrapper>
    </Container>
  );
}

const CardsList = () => {
  return (
    <>
      {FEATURES_LIST.map((featureData) => (
        <FeaturesCard data={featureData} />
      ))}
    </>
  );
};
