import React from "react";
import FitlerButton from "share/atoms/filter-buttons/filter-button";
// import CloseIcon from "@mui/icons-material/Close";
import {GrFormClose} from 'react-icons/gr'
import FilterListIcon from "assets/monitoring/filter-icon.svg";


function Index({ toggle, handleClick }) {
  return (
    <FitlerButton handleClick={handleClick} toggle={toggle}>
      {toggle ? (
        <>
         <GrFormClose fontSize="small" />&nbsp;Close
        </>
      ) : (
        <>
          <img src={FilterListIcon}/> &nbsp; Filters
        </>
      )}
    </FitlerButton>
  );
}

export default Index;
