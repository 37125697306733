import Queue from "./queue";

class Meter {
  constructor() {
    this.context = null;
    this.decibles = 0;
    this.callback = null;
    this.audioLimit = 0;
    this.totalIntervals = 0;
    this.queue = null;
    this.interval = null;
    this.offset = 40;
    this.mediaStream = null;
    this.body = document.body;
    this.count = document.createElement("p");
    this.analyseAudio = this.analyseAudio.bind(this);
    this.databox = this.databox.bind(this);
  }

  start(audioLimit, audioInterval, callback) {
    callback({
      type: "audio",
      state: "loading",
      data: "",
    });
    this.audioLimit = audioLimit;
    this.totalIntervals = audioInterval / 200;
    this.callback = callback;
    this.queue = new Queue(this.totalIntervals);
    this.interval = setInterval(() => {
      this.analyseAudio(this.decibles);
    }, 200);
    this.initiateAudio();
  }

  initiateAudio() {
    if (!this.context) {
      this.context = new (window.AudioContext || window.webkitAudioContext)();
    }

    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          const source = this.context.createMediaStreamSource(stream);
          const processor = this.context.createScriptProcessor(2048, 1, 1);
          const analyser = this.context.createAnalyser();
          this.mediaStream = stream;

          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 256;
          source.connect(analyser);
          analyser.connect(processor);
          processor.connect(this.context.destination);

          processor.onaudioprocess = () => {
            let data = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(data);
            let rms = 0;

            for (let i = 0; i < data.length; i++) {
              if (data[i] > 120) data[i] = 120;
              rms += data[i] * data[i];
            }
            rms = Math.sqrt(rms / data.length);
            this.decibles = rms + this.offset;
          };

          if (this.context.state === "suspended") {
            this.context.resume();
          }
        })
        .catch((err) => {
          this.callback({
            type: "audio",
            state: "denied",
            data: "no media found",
            error: err.message,
          });
        });
    } catch (error) {
      console.log(error);
      this.callback({
        type: "audio",
        state: "denied",
        data: "no media found",
        error: error.message,
      });
    }
  }

  analyseAudio(db) {
    this.queue.add(db);
    if (this.queue.size() === this.totalIntervals) {
      const totalSum = this.queue.print().reduce((acc, curr) => acc + curr, 0);
      const avgDb = totalSum / this.totalIntervals;
      if (Math.round(avgDb) >= this.audioLimit) {
        console.log(Math.round(avgDb),this.queue.print())
        this.queue.clear();
        this.callback({
          type: "audio",
          state: "alert",
          data: avgDb,
        });
        window.clearInterval(this.interval);
      }
    }
  }

  stop() {
    if (this.mediaStream) {
      this.mediaStream
        .getTracks()
        .forEach((track) => track.kind === "audio" && track.stop());
    }
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  databox(avgDb) {
    this.count.style.width = "100px";
    this.count.style.height = "30px";
    this.count.style.borderRadius = "4px";
    this.count.style.backgroundColor = "red";
    this.count.style.fontWeight = "bold";
    this.count.style.fontSize = "14px";
    this.count.style.display = "flex";
    this.count.style.alignItems = "center";
    this.count.style.justifyContent = "center";
    this.count.style.zIndex = "1000";
    this.count.style.position = "absolute";
    this.count.style.top = "60px";
    this.count.style.left = 0;
    this.count.innerHTML = `${avgDb.toFixed(2)}/${this.audioLimit}`;
    this.body.appendChild(this.count);
  }
}

export default Meter;
