import React, { useEffect, useState } from "react";
import Button from "share/atoms/Button";
import TextInput from "share/atoms/form-fields/TextInput";
import MultiSelect from "share/atoms/Dropdown/multi-select";
import useSelectInputChange from "share/hooks/useSelectInputChange";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import { SearchDiv, Fields, ButtonWrap, StyledLabel } from "../styles";
import { studentListRequest, studentFiltersRequest } from "../actions";
import {
  labelValueConvertion,
  removeAllStringFromList,
} from "utils/utility/obj-arr";

export default function SearchForm() {
  const dispatch = useDispatch();
  const [classData, setClassData] = useState([]);
  const [examData, setExamData] = useState([]);
  const studentListState = useSelector((state) => state.studentList.list.data);
  const { value, handleChange, handleInputChange } = useSelectInputChange({
    class: [],
    exam: [],
    name: "",
  });

  useEffect(() => {
    dispatch(studentFiltersRequest());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    const reqBody = {
      student_name: value.name,
      "class_id[]": removeAllStringFromList(value.class),
      "exam_list[]": removeAllStringFromList(value.exam),
    };
    dispatch(studentListRequest(reqBody));
  };
   

/**
 * this function returns the examlist dropdown options
 * @returns {Array} examlist array or []
 */
  const getDropdownData=()=>{
    const { class_list } = studentListState;
    if(Array.isArray(value["class"])){
      const exmList=class_list?.reduce((list,cls)=>{
        if(value["class"].includes(`${cls.objectId}~${cls.name}`)){
          return list=[...list, ...cls.examList]
        }
        return list
      },[])
      return exmList && exmList.length?labelValueConvertion(exmList,'name','objectId'):[]
    } 
    return []
  }


  useEffect(() => {
    const { class_list, exam_list } = studentListState;
    setClassData(labelValueConvertion(class_list, "name", "objectId"));
    setExamData(labelValueConvertion(exam_list, "name", "objectId"));
  }, [studentListState]);


  return (
    <SearchDiv>
      <h4>Search</h4>
      <Fields className="row">
        <div className="col-xl-4">
          <StyledLabel letterSpcing="normal" lineHeight="1.57" fontWeight="800">
            Class
          </StyledLabel>
          <MultiSelect
            value={value["class"]}
            data={classData}
            field={"class"}
            showArrow={true}
            optionClassName={"antd-multi-select-option"}
            onChange={handleChange}
            placeholder={"Select Class"}
            suffixIcon={<FaChevronDown />}
            data-testid="select-class"
          />
        </div>

        <div className="col-xl-4">
          <StyledLabel letterSpcing="normal" lineHeight="1.57" fontWeight="500">
            Exam
          </StyledLabel>
          <MultiSelect
            value={value["exam"]}
            data={getDropdownData()}
            field={"exam"}
            showArrow={true}
            optionClassName={"antd-multi-select-option"}
            onChange={handleChange}
            placeholder={"Select Exam"}
            suffixIcon={<FaChevronDown />}
            data-testid="select-exam"
            hasDuplicateOptions
            disabled={value.class.length===0}
          />
        </div>

        <div className="col-xl-4">
          <StyledLabel letterSpcing="normal" lineHeight="1.57" fontWeight="500">
            Student Name
          </StyledLabel>
          <TextInput
            variant="outlined"
            placeholder="Enter Student Name"
            name="name"
            value={value["name"]}
            onChange={handleInputChange}
            data-testid="enter-student-name"
          />
        </div>
      </Fields>
      <ButtonWrap>
        <Button
          borderRadius="100px"
          padding="12px 36px"
          bgColor="#d71a63"
          fontSize="16px"
          className="mr-0"
          onClick={handleSubmit}
          data-testid="student-list-submit"
        >
          Submit
        </Button>
      </ButtonWrap>
    </SearchDiv>
  );
}
