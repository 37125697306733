const pocNames = {
  name: "Name",
  admission_number: "Admission Number",
  user_id: "User Id",
  school_user_id: "User Id",
  designation: "Designation",
  email: "Email ID",
  institute: "schoolName",
  school_name: "schoolName",
  locality: "Branch Name / Locality",
  address: "Address line 1",
  landmark: "Address line 2 / Landmark",
  city: "Town / City",
  state: "State",
  pincode: "Pin Code",
  email: "School Email ID",
  land_line_number: "School Landline Number",
  school_type: "School Type",
  affiliated_to: "School Affiliated to",
  referrer: "How did you hear about CNAPS?",
  pincode: "pincode",
  sur_name: "Sur Name",
  gender: "Gender",
  class_name: "Class",
  father_name: "Father's / Gaurdian's Name",
  father_mobile_number: "Father's / Gaurdian's Mobile Number",
  mobile_number: "Mobile Number",
};

export const ProfileData = (objData) => {
    const pocData = []
    for (let property in objData) {
        pocData.push({ label: pocNames[property], value: objData[property] })
    }
    return pocData
}


