import { createAudioMeter } from "./helper";
import Queue from "./queue";

class MicProctor {
  constructor() {
    this.start = this.start.bind(this);
    this.processAudio = this.processAudio.bind(this);
    this.analyse = this.analyse.bind(this);
    this.databox = this.databox.bind(this);
    this.audioContext = null;
    this.meter = null;
    this.mediaStreamSource = null;
    this.preTime = 0;
    this.audioLimit = 0;
    this.callback = null;
    this.queue = null;
    this.totalIntervals = 0;
    this.body = document.body;
    this.count = document.createElement("p");
    this.interval = null;
    this.intervalsArr = [];
    this.intervalCount = 0;
  }
  start(audioLimit = 60, audioInterval = 2000, callback) {
    callback({
      type: "audio",
      state: "loading",
      data: "",
    });
    if (!this.audioContext) {
      this.callback = callback;
      this.audioLimit = audioLimit;
      this.audioContext = new AudioContext();
      this.totalIntervals = audioInterval / 200;
      this.queue = new Queue(this.totalIntervals);

      try {
        navigator.mediaDevices
          .getUserMedia({
            audio: {
              mandatory: {
                googEchoCancellation: "false",
                googAutoGainControl: "false",
                googNoiseSuppression: "false",
                googHighpassFilter: "false",
              },
              optional: [],
            },
          })
          .then((stream) => {
            this.mediaStreamSource =
              this.audioContext.createMediaStreamSource(stream);
            this.meter = createAudioMeter(this.audioContext);
            this.mediaStreamSource.connect(this.meter);
            this.processAudio();
          })
          .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
  processAudio() {
    const milliseconds = Math.round(new Date().getMilliseconds() / 10);
    if (milliseconds % 20 === 0) {
      this.analyse(this.meter.volume * 1000);
    }
    requestAnimationFrame(this.processAudio);
  }
  analyse(db) {
    this.queue.add(db);
    if (this.queue.size() === this.totalIntervals) {
      const avgDb =
        this.queue.print().reduce((acc, curr) => acc + curr, 0) /
        this.totalIntervals;
      if (true) {
        this.databox(avgDb);
      }
      if (avgDb >= this.audioLimit) {
        this.callback({
          type: "audio",
          state: "alert",
          data: avgDb,
        });
        this.queue.clear();
      }
    }
  }
  databox(avgDb) {
    this.count.style.width = "100px";
    this.count.style.height = "30px";
    this.count.style.borderRadius = "4px";
    this.count.style.backgroundColor = "red";
    this.count.style.fontWeight = "bold";
    this.count.style.fontSize = "14px";
    this.count.style.display = "flex";
    this.count.style.alignItems = "center";
    this.count.style.justifyContent = "center";
    this.count.style.zIndex = "1000";
    this.count.style.position = "absolute";
    this.count.style.top = '30px';
    this.count.style.left = 0;
    this.count.innerHTML = `${avgDb.toFixed(2)}/${this.audioLimit}`;
    this.body.appendChild(this.count);
  }
}

export default MicProctor;
