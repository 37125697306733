import React, { useState } from "react";
import Modal from "share/organisms/modal";
import styled from "styled-components";
import ExcelIcon from "assets/school-admin/icons/excel-upload.svg";
import CloseImg from "assets/school-admin/icons/closeIcon.svg";
import { FaTimesCircle } from "react-icons/fa";
import { saveAs } from "file-saver";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  img {
    width: 96px;
    height: 96px;
    margin: 20px;
  }
`;

export const ErrorTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #e21313;
`;

const ErrorDetailsWrapper = styled.div`
  font-family: Poppins;
  font-size: 16px;
  margin: 0 24px;
  max-height: 400px;
  overflow: auto;
  .heading {
    color: #e21313;
  }
  ul {
    font-size: 12px;
    li {
      margin: 5px 0;
      color: #242424;
      display: flex;
    }
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`;

const Cross = styled.div`
  color: #e21313;
  margin-right: 8px;
`;

const BulkUploadErrorModal = ({ errorList, onClose }) => {
    const errorCsv = errorList?.data?.processed_file_url;
    // console.log("bulk", errorList?.data?.processed_file_url);
  const [openModal, setOpenModal] = useState(true);

  return (
    <div>
      <Modal
        open={openModal}
        onSubmit={() => setOpenModal(false)}
        btnSet={{
          ok: {
            show: true,
            type: "primary",
            label: "Close",
          },
          cancel: {
            show: false,
          },
        }}
      >
        <Wrapper style={{ position: "relative" }}>
          <CloseBtn onClick={() => { setOpenModal(false); onClose(); }}>
            <img src={CloseImg} alt="close" />
          </CloseBtn>
          <img src={ExcelIcon} alt="excel" />
          <ErrorTitle>Upload Failed</ErrorTitle>
        </Wrapper>
        <ErrorDetailsWrapper>
          <div className="heading">
          The upload has failed. Check the error log <a href={errorCsv} download >[download]</a>. Correct or remove the failed rows and try again.
          </div>
        </ErrorDetailsWrapper>
      </Modal>
    </div>
  );
};

export default BulkUploadErrorModal;
