import { useRef } from 'react';

const useScroll = props => {
  const scrollRef = useRef(null);
  const setScroll = () => {
    scrollRef.current && scrollRef.current.scrollIntoView(props);
  };

  return [scrollRef, setScroll];
};
export default useScroll;
