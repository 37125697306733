export const CNAPS_TEST_SCHEDULED = "cnaps-test/v1/scheduled";
export const TEST = "test/v1";

export const endpoints = {
  EXAM_DETAILS: `${CNAPS_TEST_SCHEDULED}/exam-details`,
  SCHEDULE_PROCEED_TEST: `${CNAPS_TEST_SCHEDULED}/proceed-status`,
  MOCK_PROCEED_TEST: `${CNAPS_TEST_SCHEDULED}/proceed-status`,
};

export const PROCEED_TEST_REQUEST =
  "testEngine/instructions/PROCEED_TEST_REQUEST";
export const PROCEED_TEST_SUCCESS =
  "testEngine/instructions/PROCEED_TEST_SUCCESS";
export const PROCEED_TEST_ERROR = "testEngine/instructions/PROCEED_TEST_ERROR";

export const RESET_LOADING = "testEngine/instructions/RESET_LOADING";

export const EXAM_DETAILS_REQUEST = "EXAM_DETAILS_REQUEST";
export const EXAM_DETAILS_SUCCESS = "EXAM_DETAILS_SUCCESS";
export const EXAM_DETAILS_ERROR = "EXAM_DETAILS_ERROR";
