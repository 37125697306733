import React from 'react';
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom';
import { StyledLi, RouteName } from './styles';
const activeRoute = (location, rout) => {
  const { path, multi } = rout;
  if (!multi)
    return location.pathname.indexOf(path) > -1
      ? 'active-route'
      : 'normal-route';
  else {
    let bol = false;
       // eslint-disable-next-line array-callback-return
    multi.map(item => {
      if (location.pathname.indexOf(item) > -1) {
        bol = true;
      }
    });
    return bol ? 'active-route' : 'normal-route';
  }
};


const NavItem = ({ location, route, type }) => {
  return (
    <StyledLi className={activeRoute(location, route)}>
      <NavLink to={route.path} className="nav-link" activeClassName="active">
        {route.icon && !route.isImage && <route.icon />}
        {route.isImage && <route.icon icon='dashboard' className="custom-image" />}
        {/* <img src={DashboardIcon} /> */}
        <RouteName type={type}>{route.name}</RouteName>
      </NavLink>
    </StyledLi>
  );
};

NavItem.propTypes = {
  route: PropTypes.object,
  type: PropTypes.string
}

export default withRouter(NavItem);
