import styled from "styled-components";

export const Title = styled.div`
  font-size: clamp(1.5rem, 1.061rem + 2.1951vw, 2.625rem);
  font-weight: 900;
  margin: 2px 0px 28px;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.palette.common.white};
  @media screen and (max-width: 335px) {
    font-size: 23px;
  }
`;

export const SubTitle = styled.h4`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
`;

export const Home = styled.div`
  padding-right: 6px;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

export const BreadCrumbContainer = styled.div`
  margin: 0px auto;
  position: relative;
  height: 227px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  right: 12px;
  padding-left: 16px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 766px) {
    right: 0;
  }
`;
