const CNAPS_TEST = `cnaps-test/v1/result`;

export const endpoints = {
  EXAM_SUMMARY: `${CNAPS_TEST}/exam-summary`,
  SUBJECTIVE_EVALUATION: `${CNAPS_TEST}/subjective-evaluation`,
  QUESTION_ANALYSIS: `${CNAPS_TEST}/question-analysis`,
  TOPIC_ANALYSIS: `${CNAPS_TEST}/topic-analysis`,
  ANSWER_KEY: `${CNAPS_TEST}/answer-key`,
};

export const GET_EXAM_SUMMARY_REQUEST = "GET_EXAM_SUMMARY_REQUEST";
export const GET_EXAM_SUMMARY_SUCCESS = "GET_EXAM_SUMMARY_SUCCESS";
export const GET_EXAM_SUMMARY_ERROR = "GET_EXAM_SUMMARY_ERROR";

export const GET_SUBJECTIVE_EVALUATION_REQUEST =
  "GET_SUBJECTIVE_EVALUATION_REQUEST";
export const GET_SUBJECTIVE_EVALUATION_SUCCESS =
  "GET_SUBJECTIVE_EVALUATION_SUCCESS";
export const GET_SUBJECTIVE_EVALUATION_ERROR =
  "GET_SUBJECTIVE_EVALUATION_ERROR";

export const GET_QUESTION_ANALYSIS_REQUEST = "GET_QUESTION_ANALYSIS_REQUEST";
export const GET_QUESTION_ANALYSIS_SUCCESS = "GET_QUESTION_ANALYSIS_SUCCESS";
export const GET_QUESTION_ANALYSIS_ERROR = "GET_QUESTION_ANALYSIS_ERROR";

export const GET_TOPIC_ANALYSIS_REQUEST = "GET_TOPIC_ANALYSIS_REQUEST";
export const GET_TOPIC_ANALYSIS_SUCCESS = "GET_TOPIC_ANALYSIS_SUCCESS";
export const GET_TOPIC_ANALYSIS_ERROR = "GET_QUESTION_TOPIC_ERROR";

export const INITIAL = "initial";
export const FINAL = "final";
