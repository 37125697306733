import Monitoring from "monitoring/Login";
import ListDeliveries from "monitoring/ListDeliveries";
import MonitorTestTakers from 'monitoring/MonitorTestTakers'

const monitorRoutes = [
  { component: Monitoring, exact: true, path: "/monitor/login" },
  { component: ListDeliveries, exact: true, path: "/monitor/deliveries" },
  {component:MonitorTestTakers,exact:true,path:"/monitor/deliveries/monitor-test-takers"}
];

export default monitorRoutes;
