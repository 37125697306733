import React from "react";

function UserImgage({ imgSrc, commonStyles }) {
  return (
    <>
      <div style={{ ...commonStyles, position: "relative" }}>
        <img
          src={imgSrc}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: "8px",
          }}
          alt="user"
        />
        <div
          style={{
            position: "absolute",
            width: "inherit",
            height: "inherit",
            top: 0,
            left: 0,
            alignItems: "flex-end",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              backgroundColor: "#464646",
              color: "#ffffff",
              padding: "4px 8px",
              borderRadius: "20px",
              marginBottom: "18px",
            }}
          >
            Captured Successfully
          </div>
        </div>
      </div>
    </>
  );
}

export default UserImgage;
