import * as types from './types'
import produce from 'immer'
import {combineReducers} from 'redux'

export const initialState = {
    isLoading: false,
    data: false,
    error: false,
  };

  
const examSummaryDetailsReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
        case types.GET_EXAM_SUMMARY_REQUEST:
          draft.isLoading = true;
          draft.error = false;
          break;
        case types.GET_EXAM_SUMMARY_SUCCESS:
          draft.isLoading = false;
          draft.data = action.payload.data;
          break;
        case types.GET_EXAM_SUMMARY_ERROR:
          draft.isLoading = false;
          draft.error = action.error;
          break;
      default:
        draft = state;
        break;
    }
  });

  const examSubjectiveEvaluationReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
        case types.GET_SUBJECTIVE_EVALUATION_REQUEST:
          draft.isLoading = true;
          draft.error = false;
          break;
        case types.GET_SUBJECTIVE_EVALUATION_SUCCESS:
          draft.isLoading = false;
          draft.data = action.payload.data;
          break;
        case types.GET_SUBJECTIVE_EVALUATION_ERROR:
          draft.isLoading = false;
          draft.error = action.error;
          break;
      default:
        draft = state;
        break;
    }
  });

  const examQuestionAnalysisReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
        case types.GET_QUESTION_ANALYSIS_REQUEST:
          draft.isLoading = true;
          draft.error = false;
          break;
        case types.GET_QUESTION_ANALYSIS_SUCCESS:
          draft.isLoading = false;
          draft.data = action.payload.data;
          break;
        case types.GET_QUESTION_ANALYSIS_ERROR:
          draft.isLoading = false;
          draft.error = action.error;
          break;
      default:
        draft = state;
        break;
    }
  });

  const examTopicAnalysisReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
        case types.GET_TOPIC_ANALYSIS_REQUEST:
          draft.isLoading = true;
          draft.error = false;
          break;
        case types.GET_TOPIC_ANALYSIS_SUCCESS:
          draft.isLoading = false;
          draft.data = action.payload.data;
          break;
        case types.GET_TOPIC_ANALYSIS_ERROR:
          draft.isLoading = false;
          draft.error = action.error;
          break;
      default:
        draft = state;
        break;
    }
  });


const resultReducer= combineReducers({
    examSummaryDetailsReducer,
    examSubjectiveEvaluationReducer,
    examQuestionAnalysisReducer,
    examTopicAnalysisReducer
})

export default resultReducer