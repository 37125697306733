import React from "react";
import { Wrapper } from "./styles";
import Header from "../../../share/organisms/headers/features-header";
import Container from "../../../share/atoms/container/index";
import Footer from "../../LandingPage/Footer/index";
import { useScrollTo } from "share/hooks/useScrollTo";

const FaqPage = (props) => {
  useScrollTo(0, 0);
  return (
    <div style={{ backgroundColor: "#f9f9ff" }}>
      {!props.isHome && <Header {...props} />}
      <Container>
        <Wrapper>
          <iframe
            width="100%"
            style={{ border: "none", height: "700px" }}
            src="https://downloads.cnaps.co.in/faqs/v1/CNAPS_FAQS.html"
            title="faq"
          />
        </Wrapper>
      </Container>
      <Footer />
    </div>
  );
};
export default FaqPage;
