import React, { useEffect } from "react";
import WithoutSidepanelLayout from "layouts/WithoutSidepanelLayout";
import { Heading, PaperForm, Wrapper, StyledTable, Paper,TableWrapper } from "./styles.js";
import FitlerToggleButton from "share/atoms/filter-buttons/filter-toggle-button";
import { columns, data } from "./helper";
import CollapsableForm from "./collapsable-form.js";
import Form from "./form";
import DeliveryService from "./services.js";
import reducer from "./reducers";
import { useInjectReducer } from "utils/saga/injectReducer.js";
import { useInjectSaga } from "utils/saga/injectSaga.js";
import saga from "./saga";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeliveryListRequest,
  setFilterDeliveries,
} from "./actions/list-deliveries-action.js";
import FilterChips from "./filter-chips.js";
import Overlay from "share/atoms/overlay/index.js";
import SchoolAdminHeader from "share/organisms/headers/school-admin-header/index.js";


const ListDeliveries = () => {
  const key = "delivery";
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [showFiltersForm, setShowFiltersForm] = React.useState(false);
  const state = useSelector((state) => state?.delivery?.deliveryList);
  const getStatsByDeliveryId = useSelector(state=>state?.delivery?.getStatsByDeliveryId);
  const open = useSelector(({backDrop}) => backDrop.open)
   const {isLoading}=getStatsByDeliveryId?getStatsByDeliveryId:{}
  const { data, filtersData, filters } = state ? state : {};
  const dispatch = useDispatch();

  const hasFilterInList = (actualList, filterList) => {
    let flag = false;
    filterList?.forEach((filter) => {
      if (actualList?.includes(filter)) flag = true;
    });
    return flag;
  };


   /**
 * this function takes the obj and assignes to the new object if  the values are present 
 * @param {Object} fltrs
 * @returns {Object} returns new object
 */

  const getFilledFormFields = (fltrs = {}) => {
    const { status_id, sub_batch_id, class_id } = fltrs;
    let obj = {};
    if (status_id && status_id.length) obj["status_id"] = status_id;
    if (class_id) obj["class_id"] = class_id;
    if (sub_batch_id && sub_batch_id.length) obj["sub_batch_id"] = sub_batch_id;
    return obj;
  };

  /**
 * this function filters the given fields
 * @param {Array} res
 * @param {Object} fltrs
 * @returns {void} 
 */

  const localFilters = async (res = [], fltrs = {}) => {
    const obj = getFilledFormFields(fltrs);
    if (res.length) {
      let filteredList = [];

      filteredList = res.filter((item) => {
        const keys = obj && Object.keys(obj);
        let flag = 0;
        keys.map((key) => {
          if (key === "status_id" && fltrs[key].includes(item.delivery_status))
            flag += 1;
          if (key === "class_id" && item.class_list.includes(fltrs[key]))
            flag += 1;
          if (
            key === "sub_batch_id" &&
            hasFilterInList(item.subbatch_ids, fltrs[key])
          )
            flag += 1;
        });
        if (flag === keys.length) return item;
      });
      dispatch(setFilterDeliveries(filteredList));
    }
  };


  useEffect(() => {
    localFilters(data, filters);
  }, [
    filters?.status_id,
    filters?.sub_batch_id,
    filters?.batch_id,
    filters?.class_id,
  ]);
  
  // useEffect(() => {
  //   DeliveryService.getMetaDataApi();
  // }, []);


  useEffect(() => {
    dispatch(getDeliveryListRequest());
  }, []);


  const handleFilters = () => {
    setShowFiltersForm(!showFiltersForm);
  };
  const hasFilters =
    filters?.status_id?.length ||
    filters?.sub_batch_id?.length ||
    filters?.class_id;
    
  const filtersDataWithIndex = filtersData?.map((item, index) => ({
    key: index,
    ...item,
  }));
  const dataWithIndex = data?.map((item, index) => ({ key: index, ...item }));

  return (
    <>
    {isLoading&&<Overlay/>}
    {open&&<Overlay/>}
    <SchoolAdminHeader/>
      <TableWrapper>
        <Wrapper>
          <Heading>List Deliveries</Heading>
          <FitlerToggleButton
            toggle={showFiltersForm}
            handleClick={handleFilters}
          />
        </Wrapper>
        {hasFilters && !showFiltersForm && (
          <FilterChips setShowFiltersForm={setShowFiltersForm} />
        )}
          {/* to show form when showfilterform is true and hasfilter is true or showfilterform is true and hasfilters is false */}
        {((showFiltersForm && hasFilters) ||
          (showFiltersForm && !hasFilters)) && (
          <PaperForm>
            <Form setShowFiltersForm={setShowFiltersForm} />
          </PaperForm>
        )}
        <Paper>
          <StyledTable
            columns={columns}
            dataSource={hasFilters ? filtersDataWithIndex : dataWithIndex}
            pagination={false}
            scroll={{
              x: 1500,
              y: 800,
            }}
            expandable={{
              expandedRowRender: (row) => <CollapsableForm row={row} />,
            }}
          />
        </Paper>
        </TableWrapper>
    </>
    
  );
};

export default ListDeliveries;
