import styled from 'styled-components'

export const Loader = styled.div`
    position: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    /* background-color: black; */
    z-index: 100;
    height:70%;
    width:100%;
    /* opacity: 0.4; */
`
export const Image = styled.img`
    width:100px;
`