/**
 * this function returns the amount based on key and data 
 * @param {string} key
 * @param {Object} studentPaymentSummary
 * @returns {string} returns amount
 */
export const getAmount = (key, studentPaymentSummary) => {
  const { data } = studentPaymentSummary;
  if (data && key === "amount_to_be_paid") return data[key];
  else if (data && key === "amount") return data.school_charges[key];
  else if (data && key === "total")
    return data.payment_summary.reduce(
      (acc, curr) => (acc = acc + curr.amount),
      0
    );
  else return "";
};

/**
 * this function  takes the data as object and returns the array which required for antd table 
 * @param {Object} studentPaymentSummary
 * @returns {Array} returns array format
 */

export const getDataSource = (studentPaymentSummary) => {
  const { data } = studentPaymentSummary;
  if (data && data.payment_summary) {
    return data.payment_summary.map((item, idx) => ({
      key: idx + 1,
      sno: idx + 1,
      ...item,
      fee: `X  ${item.fee}`,
    }));
  }
  return [];
};

/**
 * this function  takes the data as object and returns the array which required for antd table 
 * @param {Object} studentPaymentSummary
 * @returns {Array} returns array format
 */

export const getChargesDataSource = (studentPaymentSummary) => {
  const { data } = studentPaymentSummary;
  if (data && data.school_charges) {
    return [
      {
        key: 1,
        text: "Total Handling charges retained by school ",
        ...data.school_charges,
        per_reg_charge: `X ${data.school_charges.per_reg_charge}`,
      },
    ];
  }
  return [];
};
