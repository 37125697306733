const CNAPS_ADMIN = 'cnaps-admin/v1/admin';

export const endpoints = {
  SEND_OTP: `${CNAPS_ADMIN}/send-otp`,
  VALIDATE_OTP: `${CNAPS_ADMIN}/validate-otp`,
  SELECT_DATA:`cnaps-admin/v1/common/get-registration-metadata`,
  REGISTER:`${CNAPS_ADMIN}/register`
};

export const otpTypes = {
  VERIFY_OTP_REQUEST: "registration/VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "registration/VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "registration/VERIFY_OTP_ERROR",
  RESET_OTP: "registration/RESET_OTP",

  SEND_OTP_REQUEST: "registration/SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "registration/SEND_OTP_SUCCESS",
  SEND_OTP_ERROR: "registration/SEND_OTP_ERROR",

  SELECT_DATA_REQUEST: "registration/SELECT_DATA_REQUEST",
  SELECT_DATA_SUCCESS: "registration/SELECT_DATA_SUCCESS",
  SELECT_DATA_ERROR: "registration/SELECT_DATA_ERROR",

  REGISTER_REQUEST: "registration/REGISTER_REQUEST",
  REGISTER_SUCCESS: "registration/REGISTER_SUCCESS",
  REGISTER_ERROR: "registration/REGISTER_ERROR",
};
