import { getSystemIPs } from "user-detail-from-browser";
import { useEffect, useState } from "react";

export const useIP = () => {
  const [systemIP, setSystemIP] = useState("");
  useEffect(() => {
    getSystemIPs().then((ip) => {
      if (Array.isArray(ip)) {
        setSystemIP(ip[0]?.ip_address);
      }
    });
  }, []);
  return systemIP;
};
