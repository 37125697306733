import React, { useEffect, useState } from "react";
import {
  BottomSheetContainer,
  BottomSheetOverlay,
  BottomSheetClose,
} from "./styles";
import CloseIcon from "assets/deletion/close.svg";

function BottomSheet({
  children,
  show,
  handleClose,
  isEnableCloseIcon = true,
  isDisableOverlayAction = false,
  style,
}) {
  const [isDisplay, setIsDisplay] = useState("none");
  useEffect(() => {
    let timeout;
    if (show) {
      timeout = setTimeout(() => {
        setIsDisplay(true);
      }, 500);
    } else {
      timeout = setTimeout(() => {
        setIsDisplay(false);
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [show]);
  return (
    <>
      {isDisplay && (
        <BottomSheetOverlay
          $show={show}
          onClick={!isDisableOverlayAction ? handleClose : undefined}
        />
      )}
      <BottomSheetContainer $show={show} style={style}>
        {isEnableCloseIcon && (
          <BottomSheetClose onClick={handleClose}>
            <img src={CloseIcon} alt="" />
          </BottomSheetClose>
        )}
        {children}
      </BottomSheetContainer>
    </>
  );
}

export default BottomSheet;
