import produce from "immer";
import { types } from "./constants";
import { combineReducers } from "redux";

export const initialState = {
  isLoading: false,
  data: false,
  requestBody: false,
  error: false,
};

export const studentListReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.STUDENT_LIST_REQUEST:
        draft.isLoading = true;
        draft.requestBody = action.payload;
        draft.error = false;
        break;
      case types.STUDENT_LIST_SUCCESS:
        draft.data = { ...state.data, ...action.payload };
        draft.isLoading = false;
        break;
      case types.STUDENT_LIST_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        draft.data = false;
        break;
      case types.RESET_STUDENT_LIST:
        draft.error = false;
        draft.isLoading = false;
        draft.data = false;
        break;
      case types.STUDENT_FILTERS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.STUDENT_FILTERS_SUCCESS:
        draft.data = { ...state.data, ...action.payload };
        draft.isLoading = false;
        break;
      case types.STUDENT_FILTERS_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const StudentListReducer = combineReducers({
  list: studentListReducer,
});

export default StudentListReducer;
