export const GET_DELIERY_REQUEST = 'GET_DELIERY_REQUEST'
export const GET_DELIERY_SUCCESS = 'GET_DELIERY_SUCCESS'
export const SET_DELIERY_LIST='SET_DELIERY_LIST'
export const GET_DELIERY_FAIL = 'GET_DELIERY_FAIL'
export const SET_FILTER_DELIVERIES = 'SET_FILTER_DELIVERIES'
export const SET_DELIVERY_FILTERS = 'SET_DELIVERY_FILTERS'

export const GET_STATS_BY_DELIVERY_ID_REQUEST = 'GET_STATS_BY_DELIVERY_ID_REQUEST'
export const GET_STATS_BY_DELIVERY_ID_SUCCESS = 'GET_STATS_BY_DELIVERY_ID_SUCCESS'
export const GET_STATS_BY_DELIVERY_ID_FAIL = 'GET_STATS_BY_DELIVERY_ID_FAIL'

export const GET_DELIVERY_FILTERS_REQUEST = 'GET_DELIVERY_FILTERS_REQUEST'
export const GET_DELIVERY_FILTERS_SUCCESS = 'GET_DELIVERY_FILTERS_SUCCESS'
export const GET_DELIVERY_FILTERS_FAIL = 'GET_DELIVERY_FILTERS_FAIL'