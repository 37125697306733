export const routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About us",
    path: "/about-us",
  },
  {
    name: "About Olympiad",
    path: "/why-olympiad",
  },
  {
    name: "CNAPS School",
    path: "/school",
  },
  {
    name: 'CNAPS Olympiad',
    isDropdown: true,
    path: '',
    list: [
      {
        name: 'Exams',
        path: '/exams'
      },
      {
        name: 'Student Register',
        path: '/student/registration'
      },
      {
        name: 'Student Login',
        path: '/student/login'
      },
    ]
  }
];
