import styled from "styled-components";
import Button from "share/atoms/Button";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    margin-top: 104px;
  }
`;
export const ModalBody = styled(Modal.Body)`
 padding:24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ModalFooter = styled(Modal.Footer)`
  border: none;
  .bg-green {
    background-color: #0d9c2b;
  }
`;
export const TextButton = styled(Button)`
  background-color: transparent;
  color: black;
`;
export const Icon = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  margin-bottom: 16px;
`;
export const Title = styled.h2`
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding:8px 0px;
`;
export const Description = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-bottom: 0;
  padding: 0 72px;
`;
