import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import { IoClose } from "react-icons/all";
import {
  getDeliveryListRequest,
  setDeliveryFilters,
} from "./actions/list-deliveries-action";
import { filter, wrap } from "lodash";
import { StyledFilterButton } from "./styles";

const FilterChips = ({ setShowFiltersForm }) => {
  const stringifyData = localStorage.getItem("metadata");
  const metadata = stringifyData ? JSON.parse(stringifyData) : [];
  const { filters } = useSelector((state) => state.delivery.deliveryList);

  const dispatch = useDispatch();

  const handleClassDelete = () => {
    dispatch(setDeliveryFilters({ ...filters, class_id: "" }));
  };

  const handleStatusDelete = (statusId) => {
    dispatch(
      setDeliveryFilters({
        ...filters,
        status_id: filters.status_id.filter((item) => item !== statusId),
      })
    );
  };
  const handleSubBatchDelete = (subbatchId) => {
    dispatch(
      setDeliveryFilters({
        ...filters,
        sub_batch_id: filters.sub_batch_id.filter(
          (item) => item !== subbatchId
        ),
      })
    );
  };

  const subBatchData = metadata?.subbatch_list?.filter((item) => {
    if (
      filters &&
      filters.sub_batch_id &&
      filters.sub_batch_id.includes(item.phase_id)
    ) {
      return item;
    }
  });

  const handleResetFilters = () => {
    dispatch(
      setDeliveryFilters({
        ...filters,
        status_id: "",
        batch_id: "",
        select_date: "",
        sub_batch_id: "",
        class_id: "",
      })
    );
    setShowFiltersForm(false);
    dispatch(getDeliveryListRequest());
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "24px",
        flexWrap:"wrap"
       
      }}
    >
      <div style={{display:"flex"}}>
      <span style={{width:"80px"}}>Filter By: &nbsp;</span>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop:"-20px",
          flexWrap:"wrap"
        }}
      >
        {filters?.class_id && (
          <div
            style={{
              marginRight: "8px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "16px",
              display: "flex",
              padding: "8px",
              justifyContent: "center",
              alignItems: "center",
              marginTop:"8px"
            }}
          >
            <p style={{ marginRight: "8px" }}>{filters.class_id}</p>
            <div onClick={handleClassDelete}>
              {" "}
              <IoClose fontSize="20px" cursor="pointer" />
            </div>
          </div>
        )}

        {filters?.status_id && (
          <div style={{ display: "flex" }}>
            {filters.status_id.map((item) => (
              <div
                style={{
                  marginRight: "8px",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "16px",
                  display: "flex",
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop:"8px"
                }}
              >
                <p style={{ marginRight: "8px" }}>{item}</p>
                <div onClick={() => handleStatusDelete(item)}>
                  {" "}
                  <IoClose fontSize="20px" cursor="pointer" />
                </div>
              </div>
            ))}
          </div>
        )}

        {subBatchData && (
          <div style={{ display: "flex" }}>
            {subBatchData.map((item) => (
              <div
                style={{
                  marginRight: "8px",
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "16px",
                  display: "flex",
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop:"8px"
                }}
              >
                <p style={{ marginRight: "8px" }}>{item.phase_name}</p>
                <div onClick={() => handleSubBatchDelete(item.phase_id)}>
                  {" "}
                  <IoClose fontSize="20px" cursor="pointer" />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
      <StyledFilterButton onClick={handleResetFilters}>
        <IoClose fontSize="20px" cursor="pointer" /> &nbsp; Clear All
      </StyledFilterButton>
    </div>
  );
};

export default FilterChips;
