import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
${({fullHeightConfig}) =>
   fullHeightConfig
      ? css`
          display: flex;
          justify-content: center;
          flex-direction:column;
          align-items: center;
          height: 100vh;
        `
      : ''};
`;
