import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DropdownItems,
  Wrapper,
  DropDown,
  DropdownItem,
  Image,
} from "./styles";
import DropdownLogo from "assets/dropdown.svg";
import DropDownActiveLogo from "assets/dropdown-active.svg";
import { useResize } from "share/hooks/useResize";

function Component({ list, name, handleClose = () => {} }) {
  const history = useHistory();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handleRoute = (path) => {
    history.push(path);
    handleClose();
  };
  useEffect(() => {
    if (list.some((item) => item.path === location.pathname)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location]);
  const { width } = useResize();
  const mainlist = width <= 800 ? [list[0]] : list;
  const handleClick = () => {
    setIsClicked((prev) => !prev);
  };
  return (
    <Wrapper>
      <DropDown $isActive={isActive} onClick={handleClick}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{name}</span>
          {isActive ? (
            <Image
              src={DropDownActiveLogo}
              alt=""
              className="arrow-active"
              $isClicked={isClicked}
            />
          ) : (
            <Image
              src={DropdownLogo}
              alt=""
              className="arrow-inactive"
              $isClicked={isClicked}
            />
          )}
        </div>
      </DropDown>
      <DropdownItems
        style={width <= 800 ? { display: isClicked ? "block" : "none" } : {}}
      >
        {mainlist.map((item) => (
          <DropdownItem onClick={() => handleRoute(item.path)}>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownItems>
    </Wrapper>
  );
}

export default Component;
