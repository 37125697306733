import React from "react";
import "./styles.css";

function ScrollDown({ onScoll }) {
  return (
    <>
      <div class="center-con" onClick={onScoll}>
        <div class="round">
          <span class="arrow primera next bounceAlpha"></span>
          <span class="arrow segunda next bounceAlpha"></span>
        </div>
      </div>
    </>
  );
}

export default ScrollDown;
