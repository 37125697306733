import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import OlympiadCard from "share/molecules/card/home-olympiad-card";
import styled from "styled-components";
import "./styles.css";

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #254082;
  opacity: 0.4;
  border-radius: 6px;
`;

const Carasoul = ({ data, centerPadding, handleMoreInfo, handleBuy }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlide, setTotalSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: centerPadding,
    arrows: false,
    afterChange: (current) => setCurrentSlide(current),
    autoplay: true,
    autoplaySpeed: 2000
  };
  useEffect(() => {
    setTotalSlide(data.length);
  }, [data]);
  return (
    <>
      <Slider {...settings}>
        {data.map((item) => (
          <OlympiadCard
            exam={item}
            handleMoreInfo={handleMoreInfo}
            handleBuy={handleBuy}
          />
        ))}
      </Slider>

      <CarasoulPagination
        totalCount={totalSlide}
        currentCount={currentSlide + 1}
      />
    </>
  );
};
export default Carasoul;

const CarasoulPagination = ({ totalCount = 0, currentCount = 0 }) => {
  return (
    <div
      style={{
        height: "30px",
        display: "flex",
        gap: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dot />
      <Dot />
      <div
        style={{
          backgroundColor: "#254082",
          color: "#ffffff",
          padding: "6px 8px",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "24px",
          height: "14px",
          fontSize: "10px",
          fontWeight: "500",
          lineHeight: 0.8,
        }}
      >
        <span style={{fontWeight:'600'}}>{currentCount}</span>/{totalCount}
      </div>
      <Dot />
      <Dot />
    </div>
  );
};
