const cnapsAdmin = `cnaps-admin/v1`;
const cnapsAdminv2= `cnaps-admin/v2`
export const endpoints = {
  STUDENT_LIST: `${cnapsAdmin}/admin/register-student-list`,
  STUDENT_FILTERS: `${cnapsAdminv2}/common/get-student-filters`,
  UPDATE_STUDENT_DETAILS: `${cnapsAdmin}/admin/register-student-list`,
  DOWNLOAD_EXCEL: `${cnapsAdmin}/admin/get-download-students-list`,
  DOWNLOAD_SCHOOL_RESULT: `cnaps-test/v1/usage/download-school-result`
};

export const types = {
  DOWNLOAD_EXCEL: "register-student-list/DOWNLOAD_EXCEL",
  DOWNLOAD_SCHOOL_RESULT: "register-student-list/DOWNLOAD_SCHOOL_RESULT",
  STUDENT_LIST_REQUEST: "register-student-list/STUDENT_LIST_REQUEST",
  STUDENT_LIST_SUCCESS: "register-student-list/STUDENT_LIST_SUCCESS",
  STUDENT_LIST_ERROR: "register-student-list/STUDENT_LIST_ERROR",
  RESET_STUDENT_LIST: "register-student-list/RESET_STUDENT_LIST",

  STUDENT_FILTERS_REQUEST: "register-student-list/STUDENT_FILTERS_REQUEST",
  STUDENT_FILTERS_SUCCESS: "register-student-list/STUDENT_FILTERS_SUCCESS",
  STUDENT_FILTERS_ERROR: "register-student-list/STUDENT_FILTERS_ERROR",
  UPDATE_STUDENT_MOBILE_NUMBER: "register-student-list/UPDATE_STUDENT_MOBILE_NUMBER"
};
