import React from "react";
import { Wrappering } from "./styles";

function Index() {
  return (
    <Wrappering>
      <div className="lines"></div>
      <div className="or-styles">OR</div>
    </Wrappering>
   
  );
}
export  default Index

