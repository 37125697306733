import * as actionTypes from '../actionTypes'

export const getDeliveryFiltersRequest=(payload)=>{
    return{
        type: actionTypes.GET_DELIVERY_FILTERS_REQUEST,
        payload
    }
}

export const getDeliveryFiltersSuccess=(payload)=>{
    return{
        type: actionTypes.GET_DELIVERY_FILTERS_SUCCESS,
        payload
    }
}

export const getDeliveryFiltersFail=(payload)=>{
    return{
        type: actionTypes.GET_STATS_BY_DELIVERY_ID_FAIL,
        payload
    }
}