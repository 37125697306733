import styled from "styled-components";
import Wave from "assets/WaveImage.svg";
import WaveMob from "assets/wave-image.svg";
export const ModalWrap = styled.div`
  .cancel-wrap {
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 84%;
      margin: 0 auto;
    }
    .download-image {
      cursor: pointer;
      transition: transform 0.55s;
      :hover {
        transform: scale(1.03);
      }
    }
  }
  .title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #d71a63;
  }
  .desc {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #242424;
    margin-top: 24px;
    margin-bottom: 36px;
  }
  .input {
    margin-bottom: 24px;
    width: 100%;
    border: 1px solid #949497;
    border-radius: 6px;
    height: 42px;
    padding-left: 12px;
    padding-bottom: unset;
    padding-top: unset;
  }
  button {
    width: 100%;
    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;
export const CertificateWrap = styled.div`
  display: flex;
  justify-content: center;
  .certificate-card {
    background-color: white;
    color: black;
    padding: 8px 16px;
    border-radius: 40px;
    a {
      color: #d71a63;
      border-bottom: 2px solid #d71a63;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
    }
  }
`;

export const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  @media screen and (max-width: 480px) {
    top: 72px;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  height: 600px;
  background: linear-gradient(83.53deg, #d71a63 -0.4%, #254082 99.21%);
  min-height: 600px;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    -webkit-clip-path: ellipse(100% 100% at 0% 0%);
    clip-path: ellipse(100% 100% at 0% 0%);
  }
  @media screen and (max-width: 480px) {
    height: 500px;
    min-height: 500px;
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  position: relative;
  height: 600px;
  background: linear-gradient(83.53deg, #d71a63 -0.4%, #254082 99.21%);
  min-height: 600px;
  width: 100%;
  @media screen and (max-width: 480px) {
    height: 500px;
    min-height: 500px;
  }
  @media screen and (min-width: 481px) {
    display: none;
  }
`;
export const HeaderWrap = styled.div`
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  .ViewBlocker {
    position: relative;
    width: 100%;
  }
  @media screen and (max-width: 1441px) {
    height: inherit;
  }
`;

export const LogoImage = styled.img`
  position: absolute;
  top: 12px;
  left: 0px;
  cursor: pointer;
  height: 48px;
  @media screen and (max-width: 1023px) {
    left: 8px;
    top: 10px;
  }
  @media screen and (max-width: 480px) {
    left: 12px;
    top: 20px;
    height: 36px;
  }
`;

export const LogoBackgroundBlock = styled.div`
  @media screen and (max-width: 1023px) {
    display: block;
    width: 100%;
    height: 600px;
  }
`;

export const LogoBackgroundImage = styled.img`
  margin-left: -60px;
`;

export const WaveImage = styled.div`
  background-image: url(${Wave});
  height: 94.4px;
  @media screen and (max-width: 1023px) {
    background: url(${WaveMob}) no-repeat bottom;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    background-image: url(${Wave});
    margin-top: 40px;
  }
  @media screen and (min-width: 1441px) {
    background: url(${Wave}) repeat left bottom;
    height: 124px;
    z-index: -1;
    position: absolute;
    background-size: cover;
    width: 100%;
    bottom: -30px;
  }
`;

export const ArrowImage = styled.div`
  position: absolute;
  left: 50%;
  bottom: -24px;
  z-index: 2;
  cursor: pointer;
  margin-left: -24px;

  @media screen and (max-width: 1023px) {
    bottom: -132px;
  }
  @media screen and (min-width: 1441px) {
    bottom: -60px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    bottom: -200px;
  }
  @media screen and (max-width: 600px) {
    left: 45%;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const MobileArrowImage = styled.div`
  position: absolute;
  left: 50%;
  bottom: -25px;
  cursor: pointer;
  margin-left: -24px;
  @media screen and (min-width: 481px) {
    display: none;
  }
`;
export const SideGraphic = styled.div`
  display: block;
  position: absolute;
  width: 400px;
  height: 105%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
  right: -300px;
  top: 0;
  @media screen and (max-width: 600px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

export const CorosalWrapper = styled.div`
  padding-top: 36px;
  .slick-slider {
    overflow: hidden;
  }
  @media screen and (min-width: 1441px) {
    width: 1140px;
    margin: 0 auto;
    z-index: 10;
    .slick-slider {
      overflow: hidden;
    }
  }
  @media screen and (max-width: 480px) {
    padding-top: 12px;
  }
`;
export const LogoBackgroundImage1 = styled.img`
  @media screen and (max-width: 1023px) {
    width: 400px;
    height: 170.5px;
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  .result-card {
    background-color: white;
    color: black;
    padding: 8px 24px;
    border-radius: 40px;
    font-size: 14px;
    line-height: 24px;
    color: #242424;
  }
  span {
    color: #000000;
    font-weight: bold;
  }
  img {
    margin-right: 16px;
  }
  a {
    color: #d71a63;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
  }
  a:hover {
    border-bottom: 1px solid #d71a63;
    color: #d71a63;
  }
`;

export const UpdateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Content = styled.div`
  padding: 6px 6px 6px 5px;
  border-radius: 22px;
  box-shadow: 0 20px 20px -20px rgba(253, 176, 89, 0.25);
  background-image: linear-gradient(96deg, #ffe985 0%, #fa742b);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #553517;
  padding: 6px 20px;
  .year {
    font-weight: bold;
  }
  .register-now {
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Box = styled.div`
  position: relative;
`;
