/* eslint-disable no-param-reassign */
import produce from "immer";
import { combineReducers } from "redux";
import * as types from "./types";

export const initialState = {
  isLoading: true,
  error: false,
  data: false,
};

const questionPaperDownload = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_QUESTION_PAPER_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.GET_QUESTION_PAPER_SUCCESS:
        draft.data = action.questionPaper;
        draft.isLoading = false;
        break;
      case types.GET_QUESTION_PAPER_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const sectionQuesIds = (state = { secQuesIds: {} }, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_SECTION_QUESTION_IDS:
        draft.secQuesIds = action.payload;
        break;
      default:
        draft = state;
        break;
    }
  });

const syncAnswersReducer = (
  state = { ...initialState, isLoading: false, syncTimeStamp: "" },
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SYNC_ANSWERS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.SYNC_ANSWERS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        draft.syncTimeStamp = new Date().toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        break;
      case types.SYNC_ANSWERS_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

const previousAnswersReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PREVIOUS_ANSWERS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case types.GET_PREVIOUS_ANSWERS_SUCCESS:
        draft.isLoading = false;
        break;
      case types.GET_PREVIOUS_ANSWERS_ERROR:
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const submitAnswersReducer = (
  state = { ...initialState, isLoading: false },
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SUBMIT_ANSWERS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case types.SUBMIT_DATA_RESET:
        draft.isLoading = false;
        draft.error = false;
        draft.data = false;
        break;
      case types.SUBMIT_ANSWERS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.SUBMIT_ANSWERS_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      default:
        draft = state;
        break;
    }
  });

export const currentQuestionReducer = (
  state = { id: false, num: false, secId: "all" },
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CURRENT_QUESTION:
        draft.id = action.payload.id;
        draft.num = action.payload.num;
        if (action.payload.secId) draft.secId = action.payload.secId;
        break;
      default:
        draft = state;
        break;
    }
  });

export const answersReducer = (state = { answers: false }, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_ANSWERS:
        draft.answers = action.answers;
        break;
      case types.UPDATE_ANSWER_SUCCESS:
        draft.answers = action.answers;
        break;
      default:
        draft = state;
        break;
    }
  });

export const spentTimesReducer = (
  state = { times: false, spent: 0, currentTime: new Date().getTime() },
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_SPENT_TIMES:
        draft.times = action.times;
        break;
      case types.UPDATE_SPENT_TIME:
        draft.times = { ...draft.times, ...action.time };
        break;
      case types.COUNT_SPENT_TIME:
        draft.spent = action.time;
        break;
      case types.SET_CURRENT_TIME:
        draft.currentTime = action.payload;
        break;
      case types.GET_CURRENT_TIME:
        draft.currentTime = { ...draft.currentTime };
        break;
      default:
        draft = state;
    }
  });

const profileReducer = (state = { profile: false }, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.PROFILE_REQUEST:
        draft.error = false;
        break;
      case types.PROFILE_SUCCESS:
        draft.profile = action.payload;
        const student_data = {
          id: action.payload.studentId,
          addmissionNumber: action.payload.admissionNumber,
        };
        sessionStorage.setItem("student_data", JSON.stringify(student_data));
        break;
      case types.PROFILE_ERROR:
        draft.error = action.error;
        break;
      default:
        draft = state;
    }
  });
const menuReducer = (state = { isMenuOpen: false }, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.TOGGLE_QUESTIONS_DRAWER:
        draft.isMenuOpen = !state.isMenuOpen;
        break;

      default:
        draft = state;
    }
  });

const proctorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_IMAGE_PROCTOR_REQUEST:
        draft.isLoading = true;
        break;
      case types.SET_IMAGE_PROCTOR_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.SET_IMAGE_PROCTOR_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      case types.TAB_SWITCHED_REQUEST:
        draft.isLoading = true;
        break;
      case types.TAB_SWITCHED_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.TAB_SWITCHED_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
        break;
      case types.AUDIO_PROCTORING_REQUEST:
        draft.isLoading = true;
        break;
      case types.AUDIO_PROCTORING_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.AUDIO_PROCTORING_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
    }
  });

const updateProctorEventReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.UPDATE_PROCTOR_EVENT_REQUEST:
        draft.isLoading = true;
        break;
      case types.UPDATE_PROCTOR_EVENT_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.UPDATE_PROCTOR_EVENT_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
    }
  });

const getSignedUrlReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_SIGNED_URL_REQUEST:
        draft.isLoading = true;
        break;
      case types.GET_SIGNED_URL_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case types.GET_SIGNED_URL_ERROR:
        draft.isLoading = false;
        draft.error = action.error;
    }
  });

const testReducer = combineReducers({
  questionPaper: questionPaperDownload,
  // currentQuestion: currentQuestionReducer,
  // answersReducer,
  // sectionQuesIds,
  syncAnswersReducer,
  // spentTimesReducer,
  previousAnswersReducer,
  submitAnswersReducer,
  profileReducer,
  menuReducer,
  proctorReducer,
  updateProctorEventReducer,
  getSignedUrlReducer,
});

export default testReducer;
