import request from "utils/axios/service";
import { endpoints } from "../constants";

class ScheduledExamsService {
  async getScheduledExams(req) {
    return await request.get(endpoints.SCHEDULED_EXAMS, req);
  }
}

export default ScheduledExamsService;
