import { loginTypes } from './constants';

//school admin login actions
export const loginRequest = payload => ({
  type: loginTypes.LOGIN_REQUEST,
  payload,
});
export const loginSuccess = payload => ({
  type: loginTypes.LOGIN_SUCCESS,
  payload,
});
export const userIdLoginError = error => ({
  type: loginTypes.USER_ID_LOGIN_ERROR,
  error,
});
export const passwordLoginError = error => ({
  type: loginTypes.PASSWORD_LOGIN_ERROR,
  error,
});