import React from "react";

function ProfileIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill={fill}
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M12.781 11.5c-.906 0-1.312.5-2.781.5-1.5 0-1.906-.5-2.813-.5C4.876 11.5 3 13.406 3 15.719v.781c0 .844.656 1.5 1.5 1.5h11c.813 0 1.5-.656 1.5-1.5v-.781c0-2.313-1.906-4.219-4.219-4.219zm2.719 5h-11v-.781C4.5 14.219 5.688 13 7.188 13c.468 0 1.187.5 2.812.5 1.594 0 2.313-.5 2.781-.5 1.5 0 2.719 1.219 2.719 2.719v.781zM10 11c2.469 0 4.5-2 4.5-4.5C14.5 4.031 12.469 2 10 2 7.5 2 5.5 4.031 5.5 6.5 5.5 9 7.5 11 10 11zm0-7.5c1.625 0 3 1.375 3 3 0 1.656-1.375 3-3 3-1.656 0-3-1.344-3-3 0-1.625 1.344-3 3-3z"
      />
    </svg>
  );
}

ProfileIcon.defaultProps = {
  fill: "#212226",
};

export default ProfileIcon;
