import { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fpPromise = FingerprintJS.load();

export const useDeviceID = () => {
  const [devID, setDevID] = useState("");
  useEffect(() => {
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        const visitorId = result.visitorId;
        setDevID(visitorId);
      });
  }, []);
  return devID;
};
