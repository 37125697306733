import styled,{css} from 'styled-components';

export const StyledButton = styled.button`
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  padding-left: 24px;
  padding-right: 24px;
  color: #ffffff;
  border-image-slice: 1;
  letter-spacing: 1.25px;
  text-align: center;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid transparent !important;
  outline: 0 !important;
  box-shadow: 0 !important;
  cursor: pointer;
  font-family: Poppins;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1")};
  letter-spacing: ${({ spacing }) => (spacing ? spacing : "normal")};
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  font-size: ${({ size }) =>
    size === "lg" ? "20px" : size === "md" ? "16" : "14px"};
  height: ${({ size }) =>
    size === "lg" ? "48px" : size === "md" ? "36px" : "24px"};
  width: ${({ width }) => (width ? width : "inherit")};

  ${({ disabled }) =>
    !disabled &&
    css`
      background-color: #d71a63;
    `};
  ${({ stoke, bg, color }) =>
    stoke &&
    css`
      background-color: white;
      border: 1px solid ${bg ? bg : "#d71a63"}!important;
      color: ${color};
    `};

  ${({ stoke, bg }) =>
    !stoke &&
    bg &&
    css`
      background-color: ${bg};
    `};

  :hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background-color: #fff;
        color: #d71a63;
        border: 1px solid #d71a63 !important;
      `}
    ${({ stoke, bg }) =>
      !stoke &&
      bg &&
      css`
        background-color: ${bg};
        color: #fff;
        border: 1px solid ${bg} !important;
      `};
  }
  :disabled {
    background-color: grey;
  }
`;

export const ButtonIcon = styled.img`
  max-width: 11px;
  min-height: 12px;
`;
