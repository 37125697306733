import React, { useState,useEffect } from "react";
import ProfileDetails from "share/molecules/profile-details";
import { getLocalObj, setLocalObj } from "utils/utility/local-storage";
import { isPathExist } from "utils/utility/url";
import DropdownOptionTemp from "../DropdownOptionTemp";
import { ProfileData } from "./helper";
import ProfileService from "./services";
import { ProfileCardWrapper } from "./styles";

const Profilescreen = ({ location }) => {
  const profileService = new ProfileService();
  const [data, setData] = useState({});

  const getProfileData = async () => {
    let type = localStorage.getItem("login-type");
    let profileData = getLocalObj("profile-data");
    let response = {};
    if (profileData) response = profileData;
    else if (type === "school") {
      response = await profileService.getSchoolProfile();
      setLocalObj("profile-data", response);
    } else {
      response = await profileService.getStudentProfile();
      setLocalObj("profile-data", response);
    }
    if (response?.data?.data) setData(response.data.data);
  };

  useEffect(() => {
    getProfileData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropdownOptionTemp headingText={"My Profile"}>
      {Object.keys(data).length > 0 && data.constructor === Object ? (
        <ProfileCardWrapper>
          <ProfileDetails
            dataArr={
              !isPathExist(location, "school")
                ? ProfileData(data.personal_details)
                : ProfileData(data.point_of_contact)
            }
            heading={`${
              !isPathExist(location, "school")
                ? "Personal Details"
                : "Point Of Contact"
            }`}
          />
          <ProfileDetails
            dataArr={ProfileData(data.school_details)}
            heading={"School Details"}
          />
        </ProfileCardWrapper>
      ) : (
        <h1 className="d-flex justify-content-center align-items-center h-100">
          Loading...
        </h1>
      )}
    </DropdownOptionTemp>
  );
};


export default Profilescreen
