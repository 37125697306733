import React from "react";
import { Title, SubTitle, Home, BreadCrumbContainer } from "./styles";
import RightIcon from "assets/chevron-right.svg";
import GlobalContainer from "../GlobalContainer";
import { useResize } from "share/hooks/useResize";
const BreadCrumbs = (props) => {
  const location = props.location ? props.location.pathname.split("/") : "";
  const examName = localStorage.getItem("examName");
  const { width } = useResize();
  const getTitle = (val, type) => {

    let title = "";
    if (val === "about-us") {
      title = "About us";
    } else if (val === "why-olympiad") {
      title = "About Olympiad";
    } else if (val === "exams" || (val === "exam-details" && type === "sh")) {
      title = "Exams";
    } else if (val === "explore") {
      title = "Explore";
    } else if (val === "contact-us") {
      title = "Contact us";
    } else if (val === "refund-policy") {
      title = "Refunds & Cancellation Policy";
    } else if (val === "faqs") {
      title = "Frequenly asked Questions";
    } else if (val === "privacy-policy") {
      title = "Privacy Policy";
    } else if (val === "terms-conditions") {
      title = "Terms and Conditions";
    } else if (val === "exam-details") {
      title = examName;
    }
    return title;
  };
  const goToHome = () => {
    const { history } = props;
    history.replace({ pathname: "/" });
  };
  const redirectExam = () => {
    const { history } = props;
    history.replace({ pathname: "/exams" });
  };
  return (
    <GlobalContainer>
      {location[1] !== "" && (
        <BreadCrumbContainer>
          <SubTitle>
            <Home onClick={() => goToHome()}> Home </Home>
            <img src={RightIcon} alt="arrow" />
            <div
              style={{
                paddingLeft: "6px",
                cursor: location[1] === "exam-details" ? "pointer" : "inherit",
                textDecoration:
                  location[1] === "exam-details" ? "underline" : "normal",
              }}
              onClick={location[1] === "exam-details" ? redirectExam : ""}
            >
              {getTitle(location[1], "sh")}
            </div>
            {location[1] !== "" && location[1] === "exam-details" && (
              <img style={{ padding: "0 15px" }} src={RightIcon} alt="arrow" />
            )}
            {location[1] !== "" && location[1] === "exam-details" && (
              <div style={{ margin: "0px 0px" }}> {examName}</div>
            )}
          </SubTitle>
          <Title
            style={{
              overflow: "hidden",
            }}
          >
            {getTitle(location[1], "th")}
          </Title>
        </BreadCrumbContainer>
      )}
    </GlobalContainer>
  );
};

export default BreadCrumbs;
