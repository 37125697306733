import notify from "utils/notifications";

export const redirectSchoolRegToLandingPage = (history) => {
  const isSchoolRegRedirect = process.env.REACT_APP_SCHOLL_REG_REDIRECT;
  if (isSchoolRegRedirect || isSchoolRegRedirect === "true") {
    notify(
      "error",
      "New school registrations are closed at the moment. Please check back later."
    );
    history.push("/school");
  } else history.push("/school/registration");
};

export const getProctorIntervals = (duration = 15, interval = 2) => {
  const intervals = [duration];
  for (let i = duration; i > 0; i = i - interval) {
    const res = intervals[intervals.length - 1] - interval;
    if (res > 0) {
      intervals.push(res);
    }
  }
  return intervals;
};

export const hideMobileNumber = (nmbr) => {
  if (nmbr.length === 10) {
    let number = nmbr;
    for (let i = 0; i < 10; i++) {
      if (![0, 1, 8, 9].includes(i)) {
        number = number.substring(0, i) + "X" + number.substring(1 + i);
      }
    }
    return number;
  } else {
    // throw new Error("Invalid Mobile Number");
    return "1111111111";
  }
};

export const onlineDecorator = (callback, type) => {
  return (...args) => {
    if (!navigator.onLine) {
      return notify(
        "error",
        type === "otp"
          ? "Connection lost! Please check your internet and try re-entering the OTP."
          : "No Internet Connection"
      );
    }
    return callback(...args);
  };
};
