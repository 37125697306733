import styled from "styled-components";
import { Modal, DropdownButton } from "react-bootstrap";
export const StyledModal = styled(Modal)`
  height: 100vh;
  width: 240px;
  .modal-dialog {
    margin: 0;
    height: inherit;
    .modal-content {
      height: inherit;
    }
  }
`;
export const StyledDropdown = styled(DropdownButton)`
  background-color: transparent;
  text-align: left;
  button {
    padding: 0;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    h3 {
      color: #fff !important;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.37px;
      text-align: left;
    }
    :focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
    :after {
      content: "";
      border: none;
    }
  }
  .dropdown-menu {
    width: 236px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  .dropdown-menu.show {
    top: 10px !important;
  }
  .dropdown-menu.show {
    position: relative;
    background: #ffffff;
    border: 1px solid #dedede;
  }
  .dropdown-menu.show:after,
  .dropdown-menu.show:before {
    bottom: 100%;
    right: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .dropdown-menu.show:after {
    border-color: rgba(255, 5, 5, 0);
    border-bottom-color: #ffffff;
    border-width: 13px;
    margin-left: -6px;
  }
  .dropdown-menu.show:before {
    border-color: rgba(222, 222, 222, 0);
    border-bottom-color: #dedede;
    border-width: 7px;
  }
  .dropdown-item {
    width: 204px;
    height: 36px;
    padding: 6px;
    margin-left: 16px;
    margin-right: 16px;
    /* background-color: rgba(255, 255, 255, 0.7); */
    :not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  .dropdown-item.active,
  .dropdown-item:active,
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: transparent;
    .item-name {
      color: #d71a63 !important;
    }
    svg {
      path {
        fill: #d71a63;
        font-weight: 500;
      }
    }
  }
`;
export const Wrap = styled.div`
  height: 68px;
  width: 100%;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: #d71a63;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .edge-block {
    display: flex;
    align-items: center;
    padding: 16px 28px 16px 0;
  }
  .logo-wrap {
    padding: 8px 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 240px;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-top: ${({ loginType }) => (loginType === "student" ? "16px" : "0")};
    img {
      width: 115.6px;
      height: 36px;
      flex-grow: 0;
      object-fit: contain;
    }
    h3 {
      background-image: linear-gradient(to top, #27397d, #0597c9);
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      margin-top: -8px;
      margin-left: -12px;
      -webkit-text-fill-color: transparent;
    }
  }
`;
export const CircleWrap = styled.div`
  display: inline-block;
`;
export const Circle = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-grow: 0;
  margin: 0 16px 0 0;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #d71a63;
  span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.37px;
    text-align: left;
    color: #d71a63;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 12px;
    margin-bottom: 10px;
  }
  .item-name {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #242424;
    margin-bottom: 5px;
  }
`;

export const MonitorLogo = styled.div`
margin-left: 25px;
cursor: pointer;
img{
  height:46px;
}
 h3{
  color:#fff;
  margin-Left: 32px;
  font-family: poppins;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1.13;
 }

`