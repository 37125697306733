import React from "react";
import { Wrap, Buttons } from "./styles";
import Button from "share/atoms/Button";

export default function ExamFooter(props) {
  const { className, selectedExams, amount, handlePayment } = props;
  return (
    <React.Fragment>
      {selectedExams !== 0 && (
        <Wrap>
          <div className={className}>
            <Buttons>
              <h3 className="selection-count">{selectedExams} Selected</h3>
              <Button className="mr-0" onClick={handlePayment}>
                {amount}
                {amount ? "/-" : ""} Pay Now
              </Button>
            </Buttons>
          </div>
        </Wrap>
      )}
    </React.Fragment>
  );
}
