import history from "utils/history";
import notify from "utils/notifications";
import NewRegistrationService from "school-admin/NewRegistration/services/index";
import ExamSelectionService from "student/ExamsSelection/services";
import { getQueryParam } from "utils/utility/url";

const {
  REACT_APP_RAZORPAY_KEY_ID,
  // REACT_APP_BASE_URL,
  // REACT_APP_RAZORPAY_KEY,
} = process.env;
const newRegistrationService = new NewRegistrationService();
const examSelectionService = new ExamSelectionService();

const schoolCallback = async function (response, orderRes) {
  // alert(response.razorpay_payment_id);
  // alert(response.razorpay_order_id);
  // alert(response.razorpay_signature);
  let res = await newRegistrationService.paymentSucessCallback(response);
  if (res.data.status === "success") {
    const groupId = getQueryParam("groupId", history.location);
    const admsnNumber = getQueryParam("admissionNumber", history.location);
    const { pending_group_id } = orderRes;

    if (groupId)
      history.replace({
        pathname: `/school/new-registration/congrats?groupId=${groupId}`,
      });
    else if (admsnNumber) {
      history.replace({
        pathname: `/school/new-registration/congrats?admissionNumber=${admsnNumber}`,
      });
    } else {
      history.replace({
        pathname: `/school/new-registration/congrats?groupId=${pending_group_id}`,
      });
    }
  } else {
    notify("error", res.data.message);
  }
};

const studentCallback = async (response, orderRes) => {
  let res = await examSelectionService.processOrder({
    ...response,
    payment_status:response.payment_status?response.payment_status:"success",
    order_id: orderRes.order_id,
  });
  if (res.data.status === "success" && response.razorpay_order_id) {
    history.replace({
      pathname: `/student/congratulations`,
    });
    if (res && res.data.data && res.data.data.payment_receipt)
      localStorage.setItem("receipt", res.data.data.payment_receipt);
  } else if (!response.razorpay_order_id) {
    notify("error", "Payment failed please retry again");
  } else {
    notify("error", res.data.message);
  }
};

const paymentOptionData = (orderRes, feature) => {
  return {
    key: REACT_APP_RAZORPAY_KEY_ID,
    amount: orderRes.amount, // multiply amount x100
    currency: "INR",
    name: "CNAPS",
    description:
      "Register for the olympiads through any of the online payment modes",
    image:
      "https://assets.cnaps.co.in/assets/logos/CNAPS_RAZORPAY_LOGO.png",
    // success callback
    handler:
      feature === "student"
        ? (r) => studentCallback(r, orderRes)
        : (r) => schoolCallback(r, orderRes),
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#d71a63",
    },
  };
};

export const initPaymentProcess = (data, feature) => {
  const { pg_order_id, pg_details, order_amount } = data;
  //   change this : need to pass created order id here
  let options = {
    ...paymentOptionData(data, feature),
    prefill: {
      name: pg_details.prefill.name,
      email: pg_details.prefill.email,
      contact: pg_details.prefill.contact,
    },
    order_id: pg_order_id,
  };
  let rzp1 = window.Razorpay(options);
  // failed callback
  rzp1.on("payment.failed", function (response) {
    // alert(response.error.code);
    if (feature === "student") {
      const respons = {
        razorpay_payment_id: "",
        razorpay_order_id: "",
        razorpay_signature: "",
        payment_id: response.error.metadata.payment_id,
        payment_status: "failure",
      };
      studentCallback(respons, data);
    }
    notify("error", response.error.description);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  });
  rzp1.open();
};
