import { loginTypes } from './constants';
import produce from 'immer';

export const initialState = {
  isLoading: false,
  data: false,
  userIdError: false,
  passwordError:false
};

export const monitorLoginReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case loginTypes.LOGIN_REQUEST:
        draft.isLoading = true;
        draft.data = false;
        draft.userIdError=false;
        draft.passwordError=false;
        break;
      case loginTypes.LOGIN_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case loginTypes.USER_ID_LOGIN_ERROR:
        draft.userIdError = action.error;
        draft.isLoading = false;
        break;
        case loginTypes.PASSWORD_LOGIN_ERROR:
        draft.passwordError = action.error;
        draft.isLoading = false;
        break;
      case loginTypes.RESET_ERROR:
        draft.error = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export default monitorLoginReducer;
