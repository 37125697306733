import styled, { css } from "styled-components";
 
export const Wrap = styled.div`
  display: inline-block;
  margin: 0 9px 0 0px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ color }) => color};
  ${({ active, activeColor }) =>
    active &&
    css`
      color: ${activeColor};
    `}
`;

export const ArrowIcon = styled.div(({active})=>`
  display: inline-block;
  margin: 1px 0px 1px 9px;
  ${(active) && css`
   display:none;
  `} 
`)
  


export const Text = styled.div(({active,color})=>`
  display: inline-block;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${color};
    ${active && css`
        border-bottom:none;
    `}
  }

  ${active && css`
    cursor: default;
  `}
`)
 
