import produce from 'immer';
import { combineReducers } from 'redux';
import { newRegistrationTypes } from './constants';

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

export const studentPaymentSummaryReducer = (
  state = initialState,
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const bulkRegistrationManualReducer = (
  state = initialState,
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.BULK_REGISTRATION_MANUAL_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.BULK_REGISTRATION_MANUAL_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.BULK_REGISTRATION_MANUAL_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.BULK_REGISTRATION_MANUAL_RESET:
        draft.error = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const examPhaseSelectionReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case newRegistrationTypes.EXAM_PHASE_SELECTION_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.EXAM_PHASE_SELECTION_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.EXAM_PHASE_SELECTION_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  })


export const examPhaseListReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.EXAM_PHASE_LIST_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case newRegistrationTypes.EXAM_PHASE_LIST_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.EXAM_PHASE_LIST_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const excelBulkRegistrationReducer = (
  state = initialState,
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const initBulkPaymentReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.INIT_BULK_REG_PAYMENT_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.INIT_BULK_REG_PAYMENT_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.INIT_BULK_REG_PAYMENT_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const filtersReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.STUDENT_FILTERS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.STUDENT_FILTERS_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.STUDENT_FILTERS_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const studentDataReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.GET_STUDENT_DATA_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.GET_STUDENT_DATA_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.GET_STUDENT_DATA_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const individualPaymentSummaryReducer = (
  state = initialState,
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export const initIndividualPaymentReducer = (
  state = initialState,
  action = {}
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_FAILURE:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const NewRegistrationReducer = combineReducers({
  studentPaymentSummary: studentPaymentSummaryReducer,
  manualRegistration: bulkRegistrationManualReducer,
  examPhaseListReducer: examPhaseListReducer,
  excelRegisteredData: excelBulkRegistrationReducer,
  initBulkPayment: initBulkPaymentReducer,
  filters: filtersReducer,
  studentDetails: studentDataReducer,
  individualPaymentSummary: individualPaymentSummaryReducer,
  initIndividualPayment: initIndividualPaymentReducer,
});

export default NewRegistrationReducer;
