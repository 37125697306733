const TEST_ANSWERS = 'test-answers';
const SYNC_INTERVALS = 'sync-intervals';
const PREVIOUS_TIME = 'previous-time';
const EXAM_STATUS = 'exam-status';
const QUESTION_PREVIOUS_TIME = 'question-previous-time';

export const stringify = obj => JSON.stringify(obj);
export const parse = str => JSON.parse(str);

export const getItem = key => parse(localStorage.getItem(key));
export const setItem = (key, data) =>
  localStorage.setItem(key, stringify(data));

export const setTestAnswers = (key, answers) => {
  const data = getItem(TEST_ANSWERS) || {};
  data[key] = answers;
  setItem(TEST_ANSWERS, data);
};

export const getTestAnswers = id => {
  const answers = getItem(TEST_ANSWERS);
  if (answers) return answers[id] || false;
  if (!answers) return false;
};

export const removeTestAnswers = id => {
  const answers = getItem(TEST_ANSWERS);

  if (delete answers[id]) {
    setItem(TEST_ANSWERS, answers);
  }
};

export const setSyncIntervals = (key, answers) => {
  const data = getItem(SYNC_INTERVALS) || {};
  data[key] = answers;
  setItem(SYNC_INTERVALS, data);
};

export const getSyncIntervals = id => {
  const intervals = getItem(SYNC_INTERVALS);
  if (intervals) return intervals[id] || false;
  if (!intervals) return false;
};

export const removeSyncIntervals = id => {
  const intervals = getItem(SYNC_INTERVALS);

  if (delete intervals[id]) {
    setItem(SYNC_INTERVALS, intervals);
  }
};

export const setPreviousTime = (key, time) => {
  const data = getItem(PREVIOUS_TIME) || {};
  data[key] = time;
  setItem(PREVIOUS_TIME, data);
};

export const getPreviousTime = id => {
  const time = getItem(PREVIOUS_TIME);
  if (time) return time[id] || false;
  if (!time) return false;
};

export const removePreviousTime = id => {
  const time = getItem(PREVIOUS_TIME);
if(getPreviousTime(id)){
  if (delete time[id]) {
    setItem(PREVIOUS_TIME, time);
  }
}
};

export const setExamStatus = (key, status) => {
  const data = getItem(EXAM_STATUS) || {};
  data[key] = status;
  setItem(EXAM_STATUS, data);
};

export const getExamStatus = id => {
  const status = getItem(EXAM_STATUS);
  if (status) return status[id] || false;
  if (!status) return false;
};

export const removeExamStatus = id => {
  const status = getItem(EXAM_STATUS);

  if (status && delete status[id]) {
    setItem(EXAM_STATUS, status);
  }
};

export const setQuestionPreviousTime = (key, time) => {
  const data = getItem(QUESTION_PREVIOUS_TIME) || {};
  data[key] = time;
  setItem(QUESTION_PREVIOUS_TIME, data);
};

export const getQuestionPreviousTime = id => {
  const time = getItem(QUESTION_PREVIOUS_TIME);
  if (time) return time[id] || false;
  if (!time) return false;
};

export const removeQuestionPreviousTime = id => {
  const time = getItem(QUESTION_PREVIOUS_TIME);
if(getQuestionPreviousTime(id)){
  if (delete time[id]) {
    setItem(QUESTION_PREVIOUS_TIME, time);
  }
}
};
