import React from "react";
import { Wrap, Container } from "./styles";

export default function WelcomeCard({ name }) {
  return (
    <Wrap>
      <Container>
        <h4 className="welcome-text">
          Welcome,{" "}
          <h3 className="name" style={{ display: "inline-block" }}>
            {name}
          </h3>
        </h4>
        {/* <p className="name">{name}</p> */}
      </Container>
    </Wrap>
  );
}
