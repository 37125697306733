export const GET_STUDENT_LIST_FOR_MONITOR_REQUEST = 'GET_STUDENT_LIST_FOR_MONITOR_REQUEST'
export const GET_STUDENT_LIST_FOR_MONITOR_SUCCESS = 'GET_STUDENT_LIST_FOR_MONITOR_SUCCESS'
export const GET_STUDENT_LIST_FOR_MONITOR_FAIL = 'GET_STUDENT_LIST_FOR_MONITOR_FAIL'
export const SET_STUDENT_LIST_FOR_MONITOR_FITERS = 'SET_STUDENT_LIST_FOR_MONITOR_FITERS'
export const SET_STUDENT_LIST_FOR_MONITOR_FITERS_DATA = 'SET_STUDENT_LIST_FOR_MONITOR_FITERS_DATA'

export const GET_STUDENT_DETAILS_FOR_MONITOR_REQUEST = 'GET_STUDENT_DETAILS_FOR_MONITOR_REQUEST'
export const GET_STUDENT_DETAILS_FOR_MONITOR_SUCCESS = 'GET_STUDENT_DETAILS_FOR_MONITOR_SUCCESS'
export const GET_STUDENT_DETAILS_FOR_MONITOR_FAIL = ' GET_STUDENT_DETAILS_FOR_MONITOR_FAIL'
export const SET_STUDENT_DETAILS_FOR_MONITOR_FITERS = 'SET_STUDENT_DETAILS_FOR_MONITOR_FITERS'

