import React, { useEffect, useState } from "react";
import ErrorModal from "./modal";
import { useHistory, Redirect } from "react-router-dom";
import OOPSIcon from "assets/oops.svg";
import { Wrapper } from "./styles";
import Button from "share/atoms/Button";
import MultipleDevices from "assets/MultipleDevices.svg";

const errorDetails = (error) => {
  let [title, desc] = ["", ""];
  if (error && error.includes("Network Error")) {
    title = "INTERNET CONNECTION LOST";
    desc = "Check your internet and try again.";
  } else if (error && error.includes("no longer ")) {
    title = (
      <div style={{ width: "100%", margin: "16px" }}>
        Question paper doesn't exist
      </div>
    );
    desc = <div style={{ width: "100%", margin: "16px" }}>{error}</div>;
  } else if (error && error.includes("500")) {
    title = (
      <div>
        SERVER ERROR
        <br />
        SORRY!
      </div>
    );
    desc = (
      <div>We lost touch with our spaceship. We are fixing the issue.</div>
    );
  } else if (error && error.includes("502")) {
    title = (
      <div>
        NETWORK ERROR <br />
        SORRY!
      </div>
    );
    desc = <div>Houston, we have a problem. We are fixing the issue.</div>;
  } else if (error && error.includes("404")) {
    title = (
      <div>
        PAGE NOT FOUND
        <br />
        BEEP BOOP
      </div>
    );
    desc = <div>The page you requested is lost in space.</div>;
  } else if (error && error.includes("504")) {
    title = (
      <div>
        TIMEOUT ERROR
        <br />
        SORRY!
      </div>
    );
    desc = <div>Looks like we ran out of oxygen. We’re on it.</div>;
  } else if (error && error.includes("unauthorized access")) {
    title = <div>SOMETHING WENT WRONG</div>;
    desc = <div>Please try again later.</div>;
  }
  return {
    title: title,
    desc: desc,
  };
};
const ErrorPage = ({ title, desc, hasRedirection, error }) => {
  const history = useHistory();
  const [showPage, setShowPage] = useState(false);
  let resultRoute = localStorage.getItem("result-nav");
  if (resultRoute && history.location.pathname === "/result") {
    return (
      <Redirect
        to={{
          pathname: `/result/${resultRoute}`,
        }}
      />
    );
  }

  const handleRedirect = () => {
    if (error && error?.message === "Session expired") {
      history.push({ pathname: "/student/login" });
    } else {
      history.push({ pathname: "/" });
    }
  };
  let isMultipleDevice = null;
  if(error && error?.message === "Session expired" ){
      isMultipleDevice = true;
  }
  return (
    <Wrapper>
      <img
        src={isMultipleDevice ? MultipleDevices : OOPSIcon}
        onLoad={() => setShowPage(true)}
      />
      <h1 style={isMultipleDevice? {fontSize:"24px", color:"#434343", marginTop:"50px"}:{}}>{title || (error && errorDetails(error.message).title)}</h1>
      <div style={isMultipleDevice? {fontSize:"14px", color:"#434343", marginTop:"16px"}:{}}>{desc || (error && errorDetails(error.message).desc)}</div>
      {hasRedirection && (
        <Button onClick={handleRedirect}>
          {error && error?.message === "Session expired" ? "Go to Login" : "Go to Home"}
        </Button>
      )}
    </Wrapper>
  );
};
ErrorPage.defaultProps = {
  title: "",
  desc: "",
  hasRedirection: true,
};
export default ErrorPage;
export { ErrorModal };
