import styled from "styled-components";
import Button from "share/atoms/Button";

export const Wrapper = styled.div`
  height: 64px;
  background: linear-gradient(83.53deg, #ffa7a9 -0.4%, #ff5ea3 99.21%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  animation: fade-in 2s;
  position: ${(props) => (props.$isColapsible ? "fixed" : "sticky")};
  bottom: ${(props) => props.$isColapsible && 0};
  left: ${(props) => props.$isColapsible && 0};
  top: ${({ $isColapsible }) => !$isColapsible && 0};
  z-index: 221;
  padding: 8px 24px;
  font-size: clamp(0.75rem, 0.6768rem + 0.2439vw, 0.875rem);

  &.title {
    font-weight: 500;
    line-height: 1.43;
    color: #242424;
  }
`;

export const RegisterButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  font-size: clamp(0.75rem, 0.6037rem + 0.4878vw, 1rem);
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-weight: bold;
    min-width: 108px;
  }
`;
export const CloseImage = styled.img`
  cursor: pointer;
`;
