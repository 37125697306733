import request from "utils/axios/request";
import { endpoints } from "./constants";

class MonitiorTestTakers {
  static getStudentListMonitorApi(payload) {
    return request.post(endpoints.GET_STUDENT_LIST_FOR_MONITOR, payload);
  }
  static getStudentDetailsMonitorApi(payload) {
    return request.post(endpoints.GET_STUDENT_DETAILS_FOR_MONITOR, payload);
  }
  static extendTimeApi(reqBody) {
    return request.post(endpoints.EXTEND_TIME, reqBody);
  }

}

export default MonitiorTestTakers;
