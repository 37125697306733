import Button from "share/atoms/Button";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 36% !important;
  }
  .modal-header {
    justify-content: center;
  }
  .modal-content {
  }
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
`;
export const Announcement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  font-size: 22px;
  align-items: center;
  height: 200px;
  justify-content: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #57638c;
  text-align: center;
`;
export const StyledButton = styled(Button)`
  width: fit-content;
  margin-top: 24px;
`;
