import React, { useEffect, useState } from "react";
import { getOSDetails } from "analytics";
import styled from "styled-components";
import { useResize } from "share/hooks/useResize";
function GlobalContainer({ children, style, ...rest }) {
  const { width } = useResize();
  const [flexibleWidth, setFlexibleWidth] = useState("100vw");
  useEffect(() => {
    const os = getOSDetails();
    if (os.includes("Windows")) {
      setFlexibleWidth("98.7vw");
    } else {
      setFlexibleWidth("100vw");
    }
  }, []);
  return (
    <div
      style={{
        Width: flexibleWidth,
        display: "flex",
        justifyContent: "center",
        // overflowX: width <= 800 ? "hidden" : "auto",
        ...style,
      }}
      {...rest}
    >
      <div
        style={{
          width: width > 1140 ? "1140px" : width + "px",
          margin: "0px 16px 0px 16px",
        }}
        className="sub-container"
      >
        {children}
      </div>
    </div>
  );
}

export default GlobalContainer;
