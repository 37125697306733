import React, { useEffect } from "react";
// import Radio from "share/molecules/radio-group";
// import { DateWrapper, Wrapper, ButtonWrapper, ConfirmButton, TableWrapper, ExamDetailsWrapper, Message } from "./styles";
// import { getExamPhaseNames, getSelectedValues } from "../helper";
// import { formatDate } from "utils/utility/date";
// import WarningIcon from "assets/warning.svg";
// import ModalTemp from "share/templates/ModalTemp";
import { useDispatch, useSelector } from "react-redux";
import { examPhaseListRequest } from "../actions";
import PhasesTable from "./phases";

const PhaseSelection = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.newRegistration.examPhaseListReducer);
  // const [data, setData] = useState({ exam_phase_list: [] });
  // const [open, setOpen] = useState(false);
  // const [phaseList, setPhaseList] = useState([[]]);

  useEffect(() => {
    dispatch(examPhaseListRequest());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (typeof state.data === "object") {
  //     setData(state.data);
  //   }
  // }, [state]);

  // useEffect(() => {
  //   if (data && data.exam_phase_list && data.exam_phase_list.length > 0) {
  //     setPhaseList(getSelectedValues(data));
  //   }
  // }, [data]);

  // const handleSelect = (isChecked, exam_id, phase_id) => {
  //   const dummyData = JSON.parse(JSON.stringify(data));
  //   const newArr = dummyData.exam_phase_list.map((dummy) => {
  //     return dummy.exam_id === exam_id ? {
  //       ...dummy,
  //       phase_list: dummy.phase_list.map((phase) =>
  //         phase.phase_id === phase_id
  //           ? { ...phase, is_selected: isChecked }
  //           : { ...phase, is_selected: !isChecked }
  //       ),
  //     }
  //       : dummy;
  //   });
  //   setData({ exam_phase_list: newArr, is_edit: dummyData.is_edit });
  //   setPhaseList(getSelectedValues({ exam_phase_list: newArr, is_edit: dummyData.is_edit }));
  // };

  // const onCancel = () => setOpen(false);

  // const handleSubmit = () => setOpen(true);

  // const onOk = () => {
  //   dispatch(examPhaseSelectionRequest(getSelectedValues(data)));
  //   setPhaseList(getSelectedValues(data));
  //   dispatch(examPhaseListRequest());
  //   setOpen(false);
  // };

  return (
    <React.Fragment>
      {/* <TableWrapper data={data}>
        <table border="1">
          <thead>
            <tr>
              <th className="exams">Exams</th>
              <th colSpan={2} className="available">
                {" "}
                We're available for{" "}
              </th>
            </tr>
          </thead>
          {data.exam_phase_list &&
            data.exam_phase_list.map((list) => (
              <tbody key={list.exam_id}>
                <tr>
                  <td>
                    <ExamDetails exam_name={list.exam_name} />
                  </td>
                  {list.phase_list.map((phase, index) => (
                    <React.Fragment key={phase.phase_id}>
                      <td>
                        <Wrapper>
                          <Radio
                            labelName={phase.name}
                            id={`${list.exam_name}${phase.phase_id}`}
                            name={list.exam_name}
                            isSelected={phase.is_selected}
                            is_edit={data.is_edit}
                            handleSelect={(isChecked) =>
                              handleSelect(
                                isChecked,
                                list.exam_id,
                                phase.phase_id
                              )
                            }
                          />
                          <DateWrapper className="date">
                            {formatDate(phase.date, "DD-MMM-YYYY")}{" "}
                          </DateWrapper>
                          <div>
                            {list.exam_name === "ISMO" && <Message>*For Class 10 (25th Jan 2022)</Message>}
                          </div>
                        </Wrapper>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              </tbody>
            ))}
        </table>
      </TableWrapper>
      {data.is_edit && (
        <ButtonWrapper>
          {" "}
          <ConfirmButton
            onClick={handleSubmit}
            disabled={!(data.exam_phase_list.length === phaseList.length)}
            opacity={!(data.exam_phase_list.length === phaseList.length)}
          >
            Confirm
          </ConfirmButton>{" "}
        </ButtonWrapper>
      )}
      {open && (
        <ModalTemp
          icon={WarningIcon}
          title="Confirm Availability"
          desc="Are you sure you want to block your availability? Once confirmed, it cannot be changed."
          onOk={onOk}
          onCancel={onCancel}
        />
      )} */}
      <PhasesTable phaseData={state.data}/>
    </React.Fragment>
  );
};

// const ExamDetails = ({ exam_name }) => {
//   return (
//     <ExamDetailsWrapper >
//       <div className="name">{exam_name}</div>
//       <div className="details">{getExamPhaseNames[exam_name]}</div>
//     </ExamDetailsWrapper>
//   );
// };

export default PhaseSelection;
