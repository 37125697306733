/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  PROCEED_TEST_REQUEST,
  PROCEED_TEST_SUCCESS,
  PROCEED_TEST_ERROR,
  EXAM_DETAILS_REQUEST,
  EXAM_DETAILS_ERROR,
  EXAM_DETAILS_SUCCESS
} from './types';

export const initialState = {
  isLoading: false,
  instructions:
    'https://gcf-media.s3.ap-south-1.amazonaws.com/instructions/2020urls/MATHS+(Main+2020).HTML',
  data: false,
  error: false,
};

const instructionsReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case PROCEED_TEST_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case PROCEED_TEST_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case PROCEED_TEST_ERROR:
        draft.error = action.error;
        draft.data = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });


  const examDetailsReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case EXAM_DETAILS_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        break;
      case EXAM_DETAILS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.payload;
        break;
      case EXAM_DETAILS_ERROR:
        draft.error = action.error;
        draft.data = false;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });


export default instructionsReducer;
export {examDetailsReducer}