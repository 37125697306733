const CNAPS_ADMIN = "cnaps-student/v1/auth";

export const endpoints = {
  SENDOTP: `${CNAPS_ADMIN}/send-otp`,
  VALIDATEOTP: `${CNAPS_ADMIN}/verify-otp`,
  UPDATE_PASSWORD: `${CNAPS_ADMIN}/reset-password`,
};

export const forgotTypes = {
  VERIFY_OTP_REQUEST: " student/forgot-password/VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "student/forgot-password/VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "student/forgot-password/VERIFY_OTP_ERROR",
  RESET_OTP: "student/registration/RESET_OTP",

  SEND_OTP_REQUEST: "student/forgot-password/SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "student/forgot-password/SEND_OTP_SUCCESS",
  SEND_OTP_ERROR: "student/forgot-password/SEND_OTP_ERROR",

  UPDATE_PWD_REQUEST: "student/forgot-password/UPDATE_PWD_REQUEST",
  UPDATE_PWD_SUCCESS: "student/forgot-password/UPDATE_PWD_SUCCESS",
  UPDATE_PWD_ERROR: "student/forgot-password/UPDATE_PWD_ERROR",
};
