import styled from "styled-components";

const FixedContainer = styled.div`
  width: 1104px;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    width: 500px;
  }
  @media screen and (max-width: 1280px) {
    width: 920px;
  }
  @media screen and (max-width: 1366px) {
    width: 1064px;
  }
`;
export default FixedContainer