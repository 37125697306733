export const GET_PROCTOR_CONFIG_REQUEST =
  "testEngine/proctor/GET_PROCTOR_CONFIG_REQUEST";
export const GET_PROCTOR_CONFIG_SUCCESS =
  "testEngine/proctor/GET_PROCTOR_CONFIG_SUCCESS";
export const GET_PROCTOR_CONFIG_ERROR =
  "testEngine/proctor/GET_PROCTOR_CONFIG_ERROR";

export const SET_IMAGE_PROCTOR_REQUEST =
  "testEngine/proctor/SET_IMAGE_PROCTOR_REQUEST";
export const SET_IMAGE_PROCTOR_SUCCESS =
  "testEngine/proctor/SET_IMAGE_PROCTOR_SUCCESS";
export const SET_IMAGE_PROCTOR_ERROR =
  "testEngine/proctor/SET_IMAGE_PROCTOR_ERROR";

export const UPDATE_PROCTOR_CONSENT_REQUEST =
  "testEngine/proctor/UPDATE_PROCTOR_CONSENT_REQUEST";
export const UPDATE_PROCTOR_CONSENT_SUCCESS =
  "testEngine/proctor/UPDATE_PROCTOR_CONSENT_SUCCESS";
export const UPDATE_PROCTOR_CONSENT_ERROR =
  "testEngine/proctor/UPDATE_PROCTOR_CONSENT_ERROR";

export const UPDATE_PROCTOR_EVENT_REQUEST =
  "testEngine/proctor/UPDATE_PROCTOR_EVENT_REQUEST";
export const UPDATE_PROCTOR_EVENT_SUCCESS =
  "testEngine/proctor/UPDATE_PROCTOR_EVENT_SUCCESS";
export const UPDATE_PROCTOR_EVENT_ERROR =
  "testEngine/proctor/UPDATE_PROCTOR_CONSENT_ERROR";

export const EXAM_DETAILS_REQUEST = "testEngine/proctor/EXAM_DETAILS_REQUEST";
export const EXAM_DETAILS_SUCCESS = "testEngine/proctor/EXAM_DETAILS_SUCCESS";
export const EXAM_DETAILS_ERROR = "testEngine/proctor/EXAM_DETAILS_ERROR";

export const PROCEED_TEST_REQUEST = "testEngine/proctor/PROCEED_TEST_REQUEST";
export const PROCEED_TEST_SUCCESS = "testEngine/proctor/PROCEED_TEST_SUCCESS";
export const PROCEED_TEST_ERROR = "testEngine/proctor/PROCEED_TEST_ERROR";

export const GET_SIGNED_URL_REQUEST = "testEngine/test/GET_SIGNED_URL_REQUEST";
export const GET_SIGNED_URL_SUCCESS = "testEngine/test/GET_SIGNED_URL_SUCCESS";
export const GET_SIGNED_URL_ERROR = "testEngine/test/GET_SIGNED_URL_ERROR";