import React, { useState, useEffect } from "react";
import { Wrap, Text } from "./styles";

export default function Timer(props) {
  const sec = sessionStorage.getItem("otp-sec");
  let [count, setCount] = useState(sec && sec > 0 ? sec : 0);
  useEffect(() => {
    if (!props.startTimer && count === 0) {
      setTimeout(() => {
        setCount(props.seconds);
      }, 200);
    }
  }, [props.startTimer]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    var timeOut;
    if (count > 0) {
      timeOut = setTimeout(() => {
        setCount(count - 1);
        sessionStorage.setItem("otp-sec", count - 1);
      }, 1000);
    } else {
      if (!props.startTimer && parseInt(sec) === 1) {
        props.timerCompleted(count);
      }
      setCount(0);
    }
    return () => clearTimeout(timeOut);
  });

  return (
    <Wrap>
      <Text style={props.hasWarning && count <= 10 ? { color: "#e21313" } : {}}>
        {count < 10 ? `0${count}` : count}
      </Text>
    </Wrap>
  );
}
