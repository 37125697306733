import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #ffff;
  .left {
    width: 480px;
    @media screen and (max-width: 1080px) {
      display: none;
    }
    @media screen and (min-width: 1440px) {
      width: 516px;
    }
  }
  .right {
    flex: 1;
    padding: 32px 0;
    background-color: #f9f9ff;
  }
`;
