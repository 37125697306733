import axios from "axios";
import { backdropRequest } from "share/molecules/backdrop/actions";
import request from "utils/axios/service";
import notify from "utils/notifications";
import { getTime } from "utils/time/getTime";
import { endpoints } from "./constants";
import FileSaver from "file-saver";

const { REACT_APP_SERVER_URL } = process.env;

const downloadXLSXFile = (res, type, dispatch) => {
  try {
    const formattedDate = getTime.dateTime(new Date(), "dd-mm-yy");
    res.then((response) => {
      if (response?.data?.size > 78) {
        // dispatch(snackbarRequest({ message: "Download Successfully" }));
        notify("success", "Download Successfully");
        FileSaver.saveAs(response.data, `${type}_${formattedDate}.csv`);
      } else {
        // dispatch(
        //   snackbarRequest({ message: "No Result Found", type: "error" })
        // );
        notify("error", "No Result Found");
      }
    });
  } catch (e) {
    // dispatch(
    //   snackbarRequest({ message: "Something went wrong", type: "error" })
    // );
    notify("error", "Something went wrong");
  }
};

const csvAxiosRequest = async (endpoint, payload) => {
  const { reqBody, dispatch } = payload;
  let token = localStorage.getItem("token") || "";
  token =
    token?.includes('"') && token?.startsWith('"')
      ? token.replaceAll('"', "")
      : token;
  dispatch(backdropRequest(true));
  return axios
    .get(`${REACT_APP_SERVER_URL}/${endpoint}`, {
      responseType: "blob",
      params: reqBody,
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch(backdropRequest(false));
      return res;
    })
    .catch((error) => {
      dispatch(backdropRequest(false));
      notify("error", "Something went wrong!");
    });
};

class DeliveryService {
  static getListDeliveriesApi(payload) {
    return request.get(endpoints.GET_LIST_DELIVERIES, payload);
  }

  static getStatsByDeliveryIdApi(payload) {
    return request.get(endpoints.GET_STATS_BY_DELIVERY_ID, payload);
  }

  static getDelieryFiltersApi(payload) {
    return request.get(endpoints.GET_DELIVERY_FITLERS, payload);
  }

  //downloads

  static downloadResultApi(reqBody) {
    const res = csvAxiosRequest(endpoints.DOWNLOAD_RESULT, reqBody);
    downloadXLSXFile(
      res,
      `Results_${reqBody?.reqBody?.name}`,
      reqBody.dispatch
    );
  }

  static downloadMonitorTestTakersApi(reqBody) {
    const res = csvAxiosRequest(
      endpoints.DOWNLOAD_MONITOR_TEST_TAKERS,
      reqBody
    );
    downloadXLSXFile(
      res,
      `Test-takers_${reqBody?.reqBody?.name}`,
      reqBody.dispatch
    );
  }

  static downloadResponseApi(reqBody) {
    const res = csvAxiosRequest(endpoints.DOWNLOAD_RESPNONSE, reqBody);
    downloadXLSXFile(
      res,
      `Response_${reqBody?.reqBody?.name}`,
      reqBody.dispatch
    );
  }
}

export default DeliveryService;
