import React, { useEffect, useState } from "react";
import {
  Container,
  Wrapper,
  Header,
  Body,
  RightSection,
  LeftSection,
  Banner,
} from "./styles";
import { exploredata } from "./helper";
import Card from "./card";
import { useResize } from "share/hooks/useResize";

function Explore() {
  const [counter, setCounter] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const { width } = useResize();
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prev) => (prev + 1) % 4);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [isRefresh]);
  const handleSelect = (_id) => {
    setCounter(_id);
    setIsRefresh((prev) => !prev);
  };
  const isResponsive = width <= 800;
  return (
    <Container>
      <Wrapper>
        <Header>
          <div className="title">Explore CNAPS</div>
          <div className="tag">
            Council For Nurturing Academic Potential in Students
          </div>
        </Header>
        <Body $isResonsive={isResponsive}>
          {isResponsive ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {exploredata.map((item, index) => (
                    <Card
                      key={index}
                      item={item}
                      isActive={index === counter}
                      id={index}
                      handleSelect={handleSelect}
                      isResponsive={isResponsive}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <LeftSection $isResonsive={isResponsive}>
                <Banner src={exploredata[counter].image} alt="" />
              </LeftSection>
              <RightSection>
                {exploredata.map((item, index) => (
                  <Card
                    key={index}
                    item={item}
                    isActive={index === counter}
                    id={index}
                    handleSelect={handleSelect}
                    isResponsive={isResponsive}
                  />
                ))}
              </RightSection>
            </>
          )}
        </Body>
      </Wrapper>
    </Container>
  );
}

export default Explore;
