import React from "react";
import { HeaderWrap, HeaderInnerWrap, BlurDiv } from "./styles";
import BreadCrumbs from "share/atoms/breadcrumbs";
import { StickyHeader } from "share/organisms/headers";
import "./styles";
function Header(props) {
  const { isShowBreadCrumbs } = props;
  const goToHome = () => {
    const { history } = props;
    history.replace({ pathname: "/" });
  };

  return (
    <HeaderWrap>
      {/* <div style={{ padding: "0 12% 0" }}> */}
      {isShowBreadCrumbs && <BreadCrumbs {...props} />}
      <BlurDiv />
      {/* </div> */}
    </HeaderWrap>
  );
}
Header.defaultProps = {
  isShowBreadCrumbs: true,
};

export default Header;
