import { newRegistrationTypes } from "./constants";

export const verifyOtpSuccess = (data) => {
  return ({
    type: newRegistrationTypes.VERIFY_OTP_SUCCESS,
    payload: data
  })
}
export const verifyOtpError = (error) => {
  return ({
    type: newRegistrationTypes.VERIFY_OTP_ERROR,
    payload: error
  })
}

//student list in congratulations screen
export const getStudentListRequest=(payload)=>({
  type:newRegistrationTypes.STUDENT_LIST_REQUEST,
  payload
})

//get student data actions
export const getStudentDataRequest = (payload) => ({
  type: newRegistrationTypes.GET_STUDENT_DATA_REQUEST,
  payload,
});
export const getStudentDataSuccess = (payload) => ({
  type: newRegistrationTypes.GET_STUDENT_DATA_SUCCESS,
  payload,
});
export const getStudentDataError = (error) => ({
  type: newRegistrationTypes.GET_STUDENT_DATA_FAILURE,
  error,
});

//individual payment actions
export const individualPaymentSummaryRequest = (payload) => ({
  type: newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_REQUEST,
  payload,
});
export const individualPaymentSummarySuccess = (payload) => ({
  type: newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_SUCCESS,
  payload,
});
export const individualPaymentSummaryError = (error) => ({
  type: newRegistrationTypes.INDIVIDUAL_PAYMENT_SUMMARY_FAILURE,
  error,
});

//init individual payment actions
export const initIndividualPaymentRequest = (payload) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_REQUEST,
  payload,
});
export const initIndividualPaymentSuccess = (payload) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_SUCCESS,
  payload,
});
export const initIndividualPaymentError = (error) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_FAILURE,
  error,
});

//student filters
export const studentFiltersRequest = (payload) => ({
  type: newRegistrationTypes.STUDENT_FILTERS_REQUEST,
  payload,
});
export const studentFiltersSuccess = (payload) => ({
  type: newRegistrationTypes.STUDENT_FILTERS_SUCCESS,
  payload,
});
export const studentFiltersError = (error) => ({
  type: newRegistrationTypes.STUDENT_FILTERS_ERROR,
  error,
});

//delete student
export const deleteStudentRequest = (payload) => {
  return {
    type: newRegistrationTypes.DELETE_STUDENT_REQUEST,
    payload,
  };
};

//pending registrations
export const getPendingRegistrationsRequest = (payload) => {
  return {
    type: newRegistrationTypes.PENDING_REGISTRATIONS_REQUEST,
    payload,
  };
};

//registration summary
export const getStudentPaymentSummaryRequest = (payload) => {
  return {
    type: newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_REQUEST,
    payload,
  };
};
export const getStudentPaymentSummarySuccess = (payload) => {
  return {
    type: newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_SUCCESS,
    payload,
  };
};
export const getStudentPaymentSummaryFailure = (error) => {
  return {
    type: newRegistrationTypes.STUDENT_PAYMENT_SUMMARY_FAILURE,
    error,
  };
};

//Bulk Registration Manual
export const bulkRegistrationManualRequest = (formData) => {
  return {
    type: newRegistrationTypes.BULK_REGISTRATION_MANUAL_REQUEST,
    payload: formData,
  };
};
export const bulkRegistrationManualSuccess = (data) => {
  return {
    type: newRegistrationTypes.BULK_REGISTRATION_MANUAL_SUCCESS,
    payload: data,
  };
};
export const bulkRegistrationManualFailure = (error) => {
  return {
    type: newRegistrationTypes.BULK_REGISTRATION_MANUAL_FAILURE,
    error,
  };
};
export const bulkRegistrationManualReset = (error) => {
  return {
    type: newRegistrationTypes.BULK_REGISTRATION_MANUAL_RESET,
    error,
  };
};

// excel upload - bulk registration
export const bulkRegistrationExcelUploadRequest = (formData) => {
  return {
    type: newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_REQUEST,
    payload: formData,
  };
};
export const bulkRegistrationExcelUploadSuccess = (data) => {
  return {
    type: newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_SUCCESS,
    payload: data,
  };
};
export const bulkRegistrationExcelUploadFailure = (error) => {
  return {
    type: newRegistrationTypes.EXCEL_UPLOAD_BULK_REGISTRATION_FAILURE,
    error,
  };
};
//Exam Phase Selection
export const examPhaseSelectionRequest = (selectedData) => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_SELECTION_REQUEST,
    payload: selectedData,
  };
};
export const examPhaseSelectionSuccess = (data) => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_SELECTION_SUCCESS,
    payload: data,
  };
};
export const examPhaseSelectionFailure = (error) => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_SELECTION_FAILURE,
    error,
  };
};

//Exam Phase List
export const examPhaseListRequest = () => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_LIST_REQUEST,
  };
};
export const examPhaseListSuccess = (data) => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_LIST_SUCCESS,
    payload: data,
  };
};
export const examPhaseListFailure = (error) => {
  return {
    type: newRegistrationTypes.EXAM_PHASE_LIST_FAILURE,
    error,
  };
};

//SAVE LIST ACTIONS
export const saveListRequest = (payload) => {
  return {
    type: newRegistrationTypes.SAVE_LIST_REQUEST,
    payload
  };
};
export const saveListSuccess = (payload) => {
  return {
    type: newRegistrationTypes.SAVE_LIST_SUCCESS,
    payload,
  };
};
export const saveListFailure = (error) => {
  return {
    type: newRegistrationTypes.SAVE_LIST_FAILURE,
    error,
  };
};

//INIT_BULK_REG_PAYMENT ACTIONS
export const initBulkRegPaymentRequest = (payload) => {
  return {
    type: newRegistrationTypes.INIT_BULK_REG_PAYMENT_REQUEST,
    payload
  };
};
export const initBulkRegPaymentSuccess = (payload) => {
  return {
    type: newRegistrationTypes.INIT_BULK_REG_PAYMENT_SUCCESS,
    payload,
  };
};
export const initBulkRegPaymentFailure = (error) => {
  return {
    type: newRegistrationTypes.INIT_BULK_REG_PAYMENT_FAILURE,
    error,
  };
};

