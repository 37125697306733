import Modal from "antd/lib/modal/Modal";
import styled from "styled-components";
import DefaultButton from "share/atoms/Button";

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledButton = styled.div`
  width: 114px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
  padding: 6px 16px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #fff;
  cursor: pointer;
  opacity:${({disabled})=>disabled?0.3:1};
  cursor:${({disabled})=>disabled?"not-allowed":"pointer"};
  pointer-events:${({disabled})=>disabled?"none":"auto"};
`;

//viewedit styles
export const DrawerWrapper = styled.div`
  .ant-drawer-header {
    padding: 0;
    border-bottom: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const Wrap = styled.div`
  margin: 20px 0px;
  .title-wrapper {
    margin-bottom: 16px;
  }
`;
export const MainHeading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: #242424;
`;
export const ButtonText = styled.div`
  padding: 6px 16px;
  background-color: #d71a63;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
`;
export const Header = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;
  color: #242424;
  @media screen and (max-width: 575px) {
    padding-bottom: 12px;
  }
`;
export const Title = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.67;
  color: #464646;
  margin-bottom: 4px;
`;
export const Info = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #242424;
`;

export const HorizantalLine = styled.div`
  height: 1px;
  background-color: #ededed;
`;

export const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;
export const Button = styled(ButtonText)`
  font-size: 14px;
  cursor: pointer;
  opacity: ${({ isPassword }) => (isPassword ? 1 : 0.5)};
  pointer-events: ${({ isPassword }) => (isPassword ? "auto" : "none")};
  cursor: ${({ isPassword }) => (isPassword ? "pointer" : "not-allowed")};
`;
export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .edit-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }
  .edit-text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.7;
    color: #0065ff;
  }
`;

export const CloseWrapper = styled.div`
  height: 36px;
  position: fixed;
  right: 20px;
  bottom: calc(100% - 60px);
  border-radius: 6px;
  .close-button {
    width: 80px;
    outline: none;
    border: none;
    padding: 6px 9px 6px 8px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    color: #464646;
  }
`;

//Modal styles
export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    min-width: 380px;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 rgba(33, 33, 52, 0.1);
    @media screen and (max-width: 412px) {
      min-width: 320px;
      max-width: 320px;
    }
  }
  .ant-modal-body {
    padding: 8px 16px;
  }
  .heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    color: #253858;
  }
  .divider {
    border-width: 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    margin-top:0px;
    margin-bottom:0px;
  }
  .wrapper {
    display: flex;
  }
  .admission {
    margin-right: 16px;
    margin-top: 16px;
  }
  .student-name {
    padding-left: 16px;
    border-left: 1px solid #c0c9d5;
    margin-top: 16px;
  }
  .label {
    font-size: 12px;
    line-height: 1.67;
    color: #74849c;
  }
  .value {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: #253858;
  }
  .text {
    font-size: 14px;
    line-height: 1.57;
    color: #253858;
    margin: 16px 0px;
  }
  .input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #cecece;
  }
  .input-helper {
    color: #ff8080;
    font-size: 12px;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .without-border {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.7;
    color: #74849c;
    border: 0;
    outline: 0;
    background-color: transparent;
    margin: 0;
  }
  .border-button {
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    color: #fff;
    outline: 0;
  }
`;
 export const  OkButton= styled(DefaultButton)`
 opacity:${({disabled})=>disabled?0.3:1};
 cursor:${({disabled})=>disabled?"not-allowed":"pointer"};
 pointer-events:${({disabled})=>disabled?"none":"auto"}

`
