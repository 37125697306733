import { FeaturesCardWrapper, FeaturesIcon, FeaturesContent } from "./styles";

const FeaturesCard = ({ data }) => {
  const { image, title, desc } = data;
  return (
    <FeaturesCardWrapper>
      <FeaturesIcon>
        <img src={image} alt="feature" />
      </FeaturesIcon>
      <FeaturesContent>
        <h2>{title}</h2>
        <p>{desc}</p>
      </FeaturesContent>
    </FeaturesCardWrapper>
  );
};

export default FeaturesCard;
