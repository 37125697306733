import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router";
import { FaTimes } from "react-icons/fa";
import {
  Ul,
  Heading,
  CustomTable,
  ModalHeader,
  StyledInput,
  PopUpBtn,
} from "./styles";
import { useDispatch } from "react-redux";
import { SubmitBtn } from "school-admin/Login/styles";
import { CancelBtn } from "school-admin/NewRegistration/styles";
import Button from "share/atoms/Button";

const EditStudentDetailsModal = ({
  handleClose,
  handleYes,
  student,
  showAddExam,
  showOpacity,
}) => {
  const [mobileNumber, setMobileNumber] = useState(student.mobile_number);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [triggerOnce, setTriggerOnce] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const mobileRef = useRef(null);

  const handleAddExam = () => {
    const admsnNum = student.admissionNumber || student.admission_number;
    const phnNum = mobileNumber;
    history.push({
      pathname: `/school/new-registration/individual?admissionNumber=${admsnNum}&mobile_number=${phnNum}`,
    });
  };

  const hasExams = () => {
    let dummyStudent = [];
    if (parseInt(student.class) <= 5) {
      dummyStudent = student.exams_list.filter((exm) => {
        if (["ISEO", "ISMO", "ISSO", "ISCO", "ISKO"].includes(exm.exam_name)) {
          return exm;
        }
      });
    } else {
      dummyStudent = [...student.exams_list];
    }
    return dummyStudent.filter((exm) => !exm.isSelected).length > 0;
  };

  const handleSave = () => {
    if (student.admissionNumber && isValid) {
      handleYes(student, mobileNumber);
    }
  };

  const handleMobileNumber = (event) => {
    let { value } = event.target;
    const newvalue = value.replace(/[^0-9]+/g, "");
    setMobileNumber(newvalue);
    if (student.mobile_number !== newvalue || triggerOnce) {
      setTriggerOnce(true);
      if (!["6", "7", "8", "9"].includes(newvalue[0])) {
        setIsValid(false);
        setErrorMessage("Mobile numbers should start from 6, 7, 8 or 9 only");
      } else if (newvalue.length != 10) {
        setIsValid(false);
        setErrorMessage("Mobile numbers should be 10 digits only");
      } else {
        setIsValid(true);
        setErrorMessage("");
      }
    }
  };
  useEffect(() => {
    if (mobileRef?.current?.focus) {
      mobileRef.current.focus();
    }
  }, [mobileRef]);


  return (
    <Modal show={true} size="lg" onHide={handleClose}>
      <ModalHeader>
        {showAddExam ? (
          <Heading>Edit Details</Heading>
        ) : (
          <Heading>View Details</Heading>
        )}
        <span
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          data-test-id="edit-details-close-button"
        >
          <FaTimes />
        </span>
      </ModalHeader>
      <CustomTable showOpacity={showOpacity}>
        <tr>
          <th>User ID</th>
          <td colSpan="12">{student.admissionNumber}</td>
        </tr>
        <tr>
          <th>User Name</th>
          <td>{student.name || student.studentName}</td>
        </tr>
        <tr>
          <th>Sur Name</th>
          <td>{student.sur_name || student.surName}</td>
        </tr>
        <tr>
          <th>Password</th>
          <td>{student.password}</td>
        </tr>
        <tr>
          <th>Class</th>
          <td>{student.class}</td>
        </tr>
        <tr>
          <th>Roll Number</th>
          <td>{student.roll_number || student.rollNumber}</td>
        </tr>
        <tr>
          <th>Mobile Number</th>
          <td>{mobileNumber}</td>
          {/* <td style={{ opacity: 1 }}>
            {showAddExam ? (
              <>
                <StyledInput
                  type="text"
                  value={mobileNumber}
                  // onChange={(e) => setMobileNumber(e.target.value)}
                  onChange={handleMobileNumber}
                  autoFocus={true}
                  ref={mobileRef}
                />
                {!isValid && (
                  <p style={{ color: "red", fontSize: "small" }}>
                    {errorMessage}
                  </p>
                )}
              </>
            ) : (
              mobileNumber
            )}
          </td> */}
        </tr>
        <tr>
          <th>Exams</th>
          <td className="exams">
            <Ul>
              {student.exams_list.map((exam) => {
                if (exam.isSelected && exam.is_approved === false) {
                  return <li>{exam.exam_name}(Not Approved)</li>;
                } else if (exam.isSelected) {
                  return <li>{exam.exam_name}</li>;
                }
              })}
            </Ul>
            {showAddExam && hasExams() && (
              <h4
                style={{ cursor: "pointer", color: "#000", fontWeight: 700 }}
                onClick={handleAddExam}
                data-testid="edit-details-add-exam"
              >
                Add Exam
              </h4>
            )}
          </td>
        </tr>
        <tr>
          <th>Gender</th>
          <td>{student.gender}</td>
        </tr>
      </CustomTable>
      {/* {showAddExam && (
        <PopUpBtn>
          <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          <SubmitBtn onClick={handleSave} disabled={!isValid}>
            Save
          </SubmitBtn>
        </PopUpBtn>
      )} */}
    </Modal>
  );
};

export default EditStudentDetailsModal;
