import produce from "immer";
import { paymentReceiptTypes } from "./constants";

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

export const PaymentReceiptReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case paymentReceiptTypes.PAYMENT_RECEIPT_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case paymentReceiptTypes.PAYMENT_RECEIPT_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case paymentReceiptTypes.PAYMENT_RECEIPT_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

export default PaymentReceiptReducer;
