import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin: 36px 0;
  @media screen and (max-width: 600px) {
    margin: 20px 0;
    flex-direction: row;
  }
  @media screen and (max-width: 600px) {
    overflow: scroll;
    @media screen and (max-width: 600px) {
      border: 1px solid grey;
    }
  }
`;
export const TableHeading = styled.div`
  border: solid 0.5px #c6c6cf;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  width: 50%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #242424;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const TableContent = styled(TableHeading)`
  color: #464646;
  padding-bottom: 3px;
  font-weight: 600;
  a {
    color: #464646;
    border-bottom: 1.6px solid black;
    padding-bottom: 3px;
    font-weight: 600;
    &:hover {
      text-decoration: none;
      color: #d71a63;
      border-bottom: 1.6px solid #d71a63;
    }
  }
`;

export const TableContentWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
