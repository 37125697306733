class ImageProctor {
  shutterTimer = 3000;
  constructor() {
    this.videoElement = document.createElement("video");
    this.modal = document.createElement("div");
    this.box = document.createElement("div");
    this.timer = document.createElement("p");
    this.counter = document.createElement("p");
    this.canvasElement = document.createElement("canvas");
    this.mediaStream = null;
    this.imageUrl = "";
    this.wrapper = document.getElementById("videobox");
    this.location = window.location;
  }

  start(stream, snapTimer, callback) {
    this.createVideoBox("start");
    this.shutterTimer = snapTimer;
    this.mediaStream = stream;
    this.videoElement.srcObject = this.mediaStream;
    setTimeout(()=>{
      this.videoElement.play();
    },200)
    this.videoElement.addEventListener("loadedmetadata", () => {
      this.captureImage((imageUrl) => {
        callback({
          type: "video",
          status: "success",
          data: imageUrl,
        });
      });
    });
  }
  stop() {
    clearInterval(this.intervalId);
    this.wrapper = null;
    this.videoElement.srcObject = null;
    this.createVideoBox("eject");
    if (this.mediaStream) {
      this.mediaStream
        .getTracks()
        .map((track) => track.kind === "video" && track.stop());
    }
  }
  stopAll() {
    clearInterval(this.intervalId);
    this.videoElement.srcObject = null;

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
      this.mediaStream = null;
    }
  }

  captureImage(callback) {
    clearInterval(this.intervalId);
    this.counter.innerText = this.shutterTimer.toString();
    this.canvasElement.width = this.videoElement.videoWidth;
    this.canvasElement.height = this.videoElement.videoHeight;
    this.intervalId = setInterval(() => {
      this.shutterTimer -= 1;
      this.counter.innerText = this.shutterTimer.toString();
      if (this.shutterTimer < 0) {
        this.counter.innerText = "";
        clearInterval(this.intervalId);
        const context = this.canvasElement.getContext("2d");
        context?.drawImage(
          this.videoElement,
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height
        );
        this.imageUrl = this.canvasElement.toDataURL("image/jpeg");
        // this.stop();
        callback(this.imageUrl);
      }
    }, 1000);
  }

  createVideoBox() {
    const container = document.getElementById("videobox");
    this.box.style.cssText += `width:inherit; height:inherit; position:relative; background-color:#464646; border-radius:inherit`;
    this.videoElement.style.cssText += `width:inherit; height:inherit; border-radius:inherit`;
    this.timer.style.cssText += `position:absolute; top:0; left:0; width:inherit; height:inherit; display:flex; justify-content:center; align-items:center; color:white`;
    this.counter.style.cssText += `font-size:36px; background-color:#464646; display:flex; justify-content:center; align-items:center; padding:12px; height:72px; width:72px; border-radius:50%`;
    this.timer.appendChild(this.counter);
    this.box.appendChild(this.videoElement);
    this.box.appendChild(this.timer);
    if (this.location.pathname.includes("/proctor/") && this.wrapper) {
      this.wrapper.appendChild(this.box);
    }
    if (this.location.pathname.includes("/test/") && container) {
      container.appendChild(this.box);
    }
  }
}

export default ImageProctor;
