export const removeDuplicateOptions=(options)=>{
     const uniqueLabel=[];
     const uniqueArray = options.filter(element=>{
         const isDuplicate = uniqueLabel.includes(element.label)
         if(!isDuplicate){
             uniqueLabel.push(element.label)
             return true
         }
         return false
     })
    return uniqueArray
}