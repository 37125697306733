export default function useSession(sessionName) {
  const setSession = (data) => {
    sessionStorage.setItem(sessionName, JSON.stringify(data));
  };
  const getSession = () => {
    return JSON.parse(sessionStorage.getItem(sessionName));
  };
  const removeSession = () => {
    sessionStorage.removeItem(sessionName);
  };

  return [setSession, getSession, removeSession];
}
