import styled from "styled-components";
import { InputLabel } from "share/atoms/form-fields";
import A from "share/atoms/A";
import Button from "share/atoms/Button";

export const StyledLabel = styled(InputLabel)`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;

export const H3 = styled.h3`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-bottom: 32px;
`;

export const BackendError = styled.p`
  font-size: 12px;
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledA = styled(A)`
  text-decoration: underline !important;
  color: #464646 !important;
  cursor: pointer !important;
  align-self: flex-end;
  :hover {
    color: #242424 !important;
  }
`;
export const SubmitButton = styled(Button)`
  opacity: ${({ opacity }) => (opacity ? 0.3 : 1)};
  cursor: ${({ opacity }) => (opacity ? "not-allowed" : "pointer")};
`;
export const SubmitBtn = styled(Button)`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: bold;
`;
