export const CNAPS_TEST_SCHEDULED = "cnaps-test/v1/scheduled";
export const CNAPS_PROCTOR = "cnaps-test/v1/proctor";
export const PROCTOR = "cnaps-test/v1/proctor";

export const endpoints = {
  SCHEDULE_QUESTION_PAPER: `${CNAPS_TEST_SCHEDULED}/download`,
  MOCK_QUESTION_PAPER: `${CNAPS_TEST_SCHEDULED}/question-paper`,
  PREVIOUS_USER_ANSWERS: `${CNAPS_TEST_SCHEDULED}/previous-answers?`,
  UPDATE_USER_ANSWERS: `${CNAPS_TEST_SCHEDULED}/update-user-answers`,
  SUBMIT_USER_ANSWERS: `${CNAPS_TEST_SCHEDULED}/submit-user-answers`,

  UPDATE_PROCTOR_EVENT: `${CNAPS_PROCTOR}/update-event`,
  TAB_SWITCHED: `${PROCTOR}/update-event`,
  AUDIO_PROCTOR: `${PROCTOR}/update-event`,
  SIGNED_URL: `${PROCTOR}/pre-signed-url`,
};

export const CHECK_TEST_STATUS_REQUEST =
  "testEngine/test/CHECK_TEST_STATUS_REQUEST";
export const CHECK_TEST_STATUS_SUCCESS =
  "testEngine/instructions/CHECK_TEST_STATUS_SUCCESS";
export const CHECK_TEST_STATUS_ERROR =
  "testEngine/test/CHECK_TEST_STATUS_ERROR";

export const GET_QUESTION_PAPER_REQUEST =
  "testEngine/test/GET_QUESTION_PAPER_REQUEST";
export const GET_QUESTION_PAPER_SUCCESS =
  "testEngine/test/GET_QUESTION_PAPER_SUCCESS";
export const GET_QUESTION_PAPER_ERROR =
  "testEngine/test/GET_QUESTION_PAPER_ERROR";

export const GET_PREVIOUS_ANSWERS_REQUEST =
  "testEngine/test/GET_PREVIOUS_ANSWER_REQUEST";
export const GET_PREVIOUS_ANSWERS_SUCCESS =
  "testEngine/test/GET_PREVIOUS_ANSWER_SUCCESS";
export const GET_PREVIOUS_ANSWERS_ERROR =
  "testEngine/test/GET_PREVIOUS_ANSWER_ERROR";

export const SYNC_ANSWERS_REQUEST = "testEngine/test/SYNC_ANSWER_REQUEST";
export const SYNC_ANSWERS_SUCCESS = "testEngine/test/SYNC_ANSWER_SUCCESS";
export const SYNC_ANSWERS_ERROR = "testEngine/test/SYNC_ANSWER_ERROR";

export const SUBMIT_ANSWERS_REQUEST = "testEngine/test/SUBMIT_ANSWERS_REQUEST";
export const SUBMIT_ANSWERS_SUCCESS = "testEngine/test/SUBMIT_ANSWERS_SUCCESS";
export const SUBMIT_ANSWERS_ERROR = "testEngine/test/SUBMIT_ANSWERS_ERROR";
export const SUBMIT_DATA_RESET = "testEngine/test/SUBMIT_DATA_RESET";

export const PROFILE_REQUEST = "testEngine/test/PROFILE_REQUEST";
export const PROFILE_SUCCESS = "testEngine/test/PROFILE_SUCCESS";
export const PROFILE_ERROR = "testEngine/test/PROFILE_ERROR";

export const CURRENT_QUESTION = "testEngine/test/CURRENT_QUESTION";
export const SET_ANSWERS = "testEngine/test/SET_ANSWERS";
export const UPDATE_ANSWER = "testEngine/test/UPDATE_ANSWER";
export const UPDATE_ANSWER_SUCCESS = "testEngine/test/UPDATE_ANSWER_SUCCESS";
export const SET_SPENT_TIMES = "testEngine/test/SET_SPENT_TIMES";
export const UPDATE_SPENT_TIME = "testEngine/test/UPDATE_SPENT_TIME";

export const COUNT_SPENT_TIME = "testEngine/test/COUNT_SPENT_TIME";

export const GET_CURRENT_TIME = "testEngine/test/GET_CURRENT_TIME";
export const SET_CURRENT_TIME = "testEngine/test/SET_CURRENT_TIME";

export const UPDATE_SECTION_QUESTION_IDS =
  "testEngine/test/UPDATE_SECTION_QUESTION_IDS";

export const TOGGLE_QUESTIONS_DRAWER =
  "testEngine/test/TOGGLE_QUESTIONS_DRAWER";

export const SET_IMAGE_PROCTOR_REQUEST =
  "testEngine/test/SET_IMAGE_PROCTOR_REQUEST";
export const SET_IMAGE_PROCTOR_SUCCESS =
  "testEngine/test/SET_IMAGE_PROCTOR_SUCCESS";
export const SET_IMAGE_PROCTOR_ERROR =
  "testEngine/test/SET_IMAGE_PROCTOR_ERROR";

export const UPDATE_PROCTOR_EVENT_REQUEST =
  "testEngine/test/UPDATE_PROCTOR_EVENT_REQUEST";
export const UPDATE_PROCTOR_EVENT_SUCCESS =
  "testEngine/test/UPDATE_PROCTOR_EVENT_SUCCESS";
export const UPDATE_PROCTOR_EVENT_ERROR =
  "testEngine/test/UPDATE_PROCTOR_EVENT_ERROR";
export const TAB_SWITCHED_REQUEST = "testEngine/test/TAB_SWITCHED_REQUEST";
export const TAB_SWITCHED_SUCCESS = "testEngine/test/TAB_SWITCHED_SUCCESS";
export const TAB_SWITCHED_ERROR = "testEngine/test/TAB_SWITCHED_ERROR";

export const AUDIO_PROCTORING_REQUEST =
  "testEngine/test/AUDIO_PROCTORING_REQUEST";
export const AUDIO_PROCTORING_SUCCESS =
  "testEngine/test/AUDIO_PROCTORING_SUCCESS";
export const AUDIO_PROCTORING_ERROR = "testEngine/test/AUDIO_PROCTORING_ERROR";

export const GET_SIGNED_URL_REQUEST = "testEngine/test/GET_SIGNED_URL_REQUEST";
export const GET_SIGNED_URL_SUCCESS = "testEngine/test/GET_SIGNED_URL_SUCCESS";
export const GET_SIGNED_URL_ERROR = "testEngine/test/GET_SIGNED_URL_ERROR";
