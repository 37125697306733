import React from "react";
import { BlockWrapper, BlockTitle } from "./styles";

function Block({ item }) {
  return (
    <BlockWrapper>
      <img src={item.logo} alt="logo" />
      <BlockTitle>{item.title}</BlockTitle>
    </BlockWrapper>
  );
}

export default Block;
