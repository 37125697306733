import ExtendTime from "./ExtendTime";
import { DrawerWrapper, StyledButton, Wrapper, CloseWrapper } from "./styles";
import { Drawer } from "antd";
import StudentDetails from "../student-details";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/all";

const ActionButtons = ({ row }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <Wrapper>
      <ExtendTime row={row}/>
      <DrawerWrapper>
        {open && (
          <Drawer
            placement="bottom"
            closable={false}
            key={"bottom"}
            getContainer={false}
            visible={open}
            onClose={onClose}
            height="90%"
          >
            <CloseWrapper onClick={onClose}>
              <button className="close-button">
                Close{" "}
                <p className="close-icon">
                  <IoCloseOutline fontSize="18px" />
                </p>
              </button>
            </CloseWrapper>
            <StudentDetails admissionNumber={row.admission_number} />
          </Drawer>
        )}
        <StyledButton bgColor="#d71a63" onClick={showDrawer}>
          View and Edit
        </StyledButton>
      </DrawerWrapper>
    </Wrapper>
  );
};

export default ActionButtons;
