export const detatils = [
  {
    title: "Online Olympiads",
    content:
      "CNAPS endeavours to identify the latent potential through quantitative and qualitative assessments and nurture the students thereafter.",
  },
  {
    title: "Research Based Testing",
    content:
      "CNAPS groundwork and research made it possible to compare and contrast the best practices from various International Olympiads and competitions to distill the CNAPS's olympiads to better suit academic environments in India.",
  },
  {
    title: "Performance Analytics",
    content:
      '"Something that is not measured is not understood", 5Q Analysis is provided for the first time in the country.',
  },
];
