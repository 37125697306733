import produce from 'immer';
import { loginTypes } from './constants';

export const initialState = {
    isLoading: false,
    data: false,
    error: false,
};

export const StudentLoginReducer = (state = initialState, action = {}) =>
    produce(state, draft => {
        switch (action.type) {
            case loginTypes.LOGIN_REQUEST:
                draft.isLoading = true;
                draft.error = false;
                draft.data = false;
                break;
            case loginTypes.LOGIN_SUCCESS:
                draft.data = action.payload;
                draft.isLoading = false;
                break;
            case loginTypes.LOGIN_ERROR:
                draft.error = action.error;
                draft.isLoading = false;
                break;
            case loginTypes.RESET_ERROR:
                draft.error = false;
                draft.isLoading = false;
                break;
            default:
                draft = state;
                break;
        }
    });

export default StudentLoginReducer;
