import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { PasswordInput } from "share/atoms/form-fields";
import ProfileService from "share/templates/ProfileScreen/services";
import { isPathExist } from "utils/utility/url";
import notify from "utils/notifications";
import DropdownOptionTemp from "../DropdownOptionTemp";
import { Wrapper, PasswordWrapper, Hint, StyledButton } from "./styles";

const ChangePasswordScreen = () => {
  const profileService = new ProfileService();
  // const [error, setError] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [passwordFields, setPasswordFields] = useState({
    password: "",
    confirmPassword: "",
  });
  const handlePassword = (event) => {
    setPasswordFields((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const responseCall = (response) => {
    if (response.data.status === "success") {
      notify("success", response.data.message);
      // setError(false);
      isPathExist(location, "school")
        ? history.replace("/school/dashboard")
        : history.replace("/student/home");
      setPasswordFields({ password: "", confirmPassword: "" });
    } else {
      notify("error", response.data.message);
      // setError(true);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const { password, confirmPassword } = passwordFields;
    const reqBody = {
      new_password: password,
      confirm_password: confirmPassword,
    };
    /* eslint-disable no-useless-escape */
    const regrex =
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()_+`\-={}:"; '<>?,.\/])[0-9a-zA-Z~!@#$%^&*()_+`\-={}:"; '<>?,.\/]{8,}$/;
    if (
      password === confirmPassword &&
      password.length >= 8 &&
      regrex.test(password)
    ) {
      if (isPathExist(location, "school")) {
        const response = await profileService.postSchoolChangePassword(reqBody);
        responseCall(response);
      } else {
        const response = await profileService.postStudentChangePassword(
          reqBody
        );
        responseCall(response);
      }
    } else {
      if (password.length < 8)
        notify("error", "passwords should  be 8 characters ");
      else if (!regrex.test(password))
        notify(
          "error",
          "  Use 8 or more characters with a mix of letters, numbers & symbols"
        );
      else notify("error", "passwords don’t match ");
    }
  };
  return (
    <DropdownOptionTemp headingText={"Change Password"}>
      <form onSubmit={onSubmit}>
      <Wrapper>
        <PasswordWrapper>
          <div className="label-password">New Password</div>
          <PasswordInput
            id="password"
            name="password"
            type="password"
            placeholder="Enter New Password"
            value={passwordFields.password}
            name="password"
            onChange={handlePassword}
          />
          <div className="label-confirm-password">Confirm Password</div>
          <PasswordInput
            id="changePassword"
            name="changePassword"
            type="password"
            placeholder="Enter Confirm Password"
            value={passwordFields.changePassword}
            onChange={handlePassword}
            name="confirmPassword"
          />
          <Hint>
            <span>
              <BsInfoCircle />
            </span>
            &nbsp;&nbsp;{" "}
            <span>
              Use 8 or more characters with a mix of letters, numbers & symbols
            </span>
          </Hint>
          <StyledButton type="submit">Submit</StyledButton>
        </PasswordWrapper>
      </Wrapper>
      </form>
    </DropdownOptionTemp>
  );
};

export default ChangePasswordScreen;
