import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { CardWrap, TitleWrap, IconWrap } from "./styles";
import useCountRenders from "share/hooks/useCountRenders";

function StatsCard(props) {
  useCountRenders("Dashboard stat card");
  const { children, type, examData, title, cursor } = props;

  const linkClick = () => {
    if (type === "total-subjects") props.handleLink(type, examData);
    else props.handleLink(type);
  };
  
  return (
    <CardWrap key={title}>
      <TitleWrap cursor={cursor} onClick={linkClick}>
        <h2 className="title">{title}</h2>
        <IconWrap cursor={cursor}>
          <FiArrowUpRight size="20px" />
        </IconWrap>
      </TitleWrap>
      {children}
    </CardWrap>
  );
}

export default React.memo(StatsCard);