import { call, takeLatest } from "redux-saga/effects";
import { types } from "school-admin/StudentList/constants";
import notify from "utils/notifications";
import StudentListService from "school-admin/StudentList/services";
import FileSaver from "file-saver";
import Moment from "moment";

const studentListService = new StudentListService();

//download excel sheet
export function* downloadExcelSheetWorker(action) {
  try {
    const res = yield call(
      studentListService.downloadExcelSheet,
      action.payload
    );
    // const NewFileName = res.headers["content-disposition"];
    // const regex =
    //   /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
    // var fileName = NewFileName.match(regex)[3];
    // const timeStamp = parseInt(fileName.split("_")[2].split(".")[0]);
    const formattedDate = Moment().format("DD-MM-YYYY");
    var blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // eslint-disable-next-line no-useless-concat
    FileSaver.saveAs(blob, "studentslist" + "_" + formattedDate + ".xlsx");
  } catch (e) {
    notify("error", "Something went wrong!");
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(types.DOWNLOAD_EXCEL, downloadExcelSheetWorker);
}
