import * as actionTypes from '../actionTypes'

export const getStudentListMonitorRequest=(payload,cb)=>{
    return{
        type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_REQUEST,
        payload,cb
    }
}

export const getStudentListMonitorSuccess=(payload)=>{
    return{
        type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_SUCCESS,
        payload
    }
}

export const getStudentListMonitorFail=(payload)=>{
    return{
        type: actionTypes.GET_STUDENT_LIST_FOR_MONITOR_FAIL,
        payload
    }
}