/**
 * this function returns the array of keys or false
 * @param {Object} obj
 * @returns {Array|boolean} array of keys or false
 */
export const getObjectKeys = (obj) => {
  if (!obj || !Object.keys(obj).length) return false;
  return Object.keys(obj);
};

/**
 * this function  takes the examsarray and order the exam details to id key
 * @param {Array} exams
 * @returns {Object|boolean}  if there is no exams return false otherwise exams object
 */
export const getExamById = (exams) => {
  if (!exams) return false;
  return exams.reduce((acc, exam) => {
    return { ...acc, [exam.exam_model_id]: exam };
  }, {});
};


/**
 * this function  gives the selected exams details
 * @param {Array} keys
 *  @param {Array} exams
 * @returns {Array}  exam details
 */

export const getSelectedExams = (keys, exams, selectedPhases) => {
  const examById = getExamById(exams);

  return keys.reduce((examList, id) => {
    return [
      ...examList,
      {
        exam_name: examById[id]?examById[id].exam_model_name:'',
        exam_object_id: id,
        // phase_object_id: selectedPhases?selectedPhases[id]:'',
        // isSelected: true,
        fee: examById[id]?examById[id].fee:'',
      },
    ];
  }, []);
};


/**
 * this function takes the selected exams and returns how many exams selected value
 * @param {Object} selectedExams
 * @returns {number} selected exams
 */
export const getSelectedExamsCount = (selectedExams) => {
  const keys = getObjectKeys(selectedExams);
  if (!keys) return 0;
  return keys.reduce((acc, curr) => {
    if (selectedExams[curr]) return (acc += 1);
    return acc;
  }, 0);
};


/**
 * this function takes the selected exams id, total array data  and caluculate the total price of selected examid's
 * @param {Object} selectedExams
 * @param {Array} exams
 * @returns {number} sum of selected exam id's
 */
export const getSelectedExamsAmount = (selectedExams, exams) => {
  
  const keys = getObjectKeys(selectedExams);

  if (!keys) return "";

  const examAmounts =
    exams &&
    exams.reduce((acc, exam) => {
      return { ...acc, [exam.exam_model_id]: exam.fee };
    }, {});

  return keys.reduce((sum, examId) => {
    if (selectedExams[examId]) return (sum += parseInt(examAmounts[examId]));
    return sum;
  }, 0);
};

/**
 * this function gives all selected exams id  as a true  in a object
 * @param {Object} selectedExams
 * @returns {Object|boolean} object with selected exam ids or false
 */
export const getSelectedExamIds = (selectedExams, selectedPhases) => {
  const keys = getObjectKeys(selectedExams);
  if (!keys) return false;

  return keys.reduce((res, examId) => {
    if (selectedExams[examId])
      return (res = { ...res, [examId]: true });
    else if (selectedExams[examId])
      return (res = { ...res, [examId]: false });
    return res;
  }, {});
};

/**
 * this function checks if exam tab is active or not
 * @returns {boolean} true/false
 */
export const isActiveExamTab = () => {
  const status = sessionStorage.getItem("active");
  if (!status || status !== "yes") return false;

  return true;
};


/**
 * this function to set the session storage
 */
export const setAsActiveExamTab = () => sessionStorage.setItem("active", "yes");

/**
 * this function to set the session storage
 */
export const removeActiveExamTab = () => sessionStorage.removeItem("active");