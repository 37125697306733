import { ModalWrapper } from "./styles";
const Modal = ({ children, headingText, show, handleClose, width }) => {
  return (
    <ModalWrapper show={show} onHide={handleClose} $width={width}>
      <ModalWrapper.Header closeButton>
        <ModalWrapper.Title>{headingText}</ModalWrapper.Title>
      </ModalWrapper.Header>
      <ModalWrapper.Body>{children}</ModalWrapper.Body>
    </ModalWrapper>
  );
};

export default Modal;
