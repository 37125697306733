import React from "react";
import AboutUsDesc from "./AboutUsDesc";
import Header from "share/organisms/headers/features-header";
import Footer from "student/LandingPage/Footer";
import { useScrollTo } from "share/hooks/useScrollTo";

const AboutUs = (props) => {
  useScrollTo(0, 0);
  return (
    <>
      <Header {...props} />
      <AboutUsDesc />
      <Footer />
    </>
  );
};

export default AboutUs;
