/**
 * this function helps  To scroll to the very beginiing
 */

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};


/**
 * this function checks if the browser is online or not 
 * @returns {boolean} true/false
 */
export const checkInternet = () => {
  return navigator.onLine;
};

/**
 * this function to open the pd in next window
 *  @param {string} link
 * @param {string} title
 * @returns {void}
 */
export const openPdfInWindow = (link, title) => {
  if (link.includes(".amazonaws.com/")) {
    const arr = link.split("/");
    const bckt = arr[2].split(".")[0];
    const rem = link.split(".amazonaws.com/")[1];
    window.open(`/pdf-viewer/${bckt}\`~\`${rem}\`~\`${title}?type=aws`,"_blank");
  } else {
    const arr = link.split("/");
    const bckt = arr[2].split(".")[0];
    const rem = link.split("cnaps.co.in/")[1];
    window.open(`/pdf-viewer/${bckt}\`~\`${rem}\`~\`${title}?type=cdn`,"_blank");
  }
};

/**
 * this function to add the eventlistener
 *  @param {string} type
 * @param {function} fn
 * @returns {void}
 */
export const addEventListener = (type, fn) => {
  window.addEventListener(type, fn);
};

/**
 * this function to remove the eventlistener
  *  @param {string} type
 * @param {function} fn
 * @returns {void}
 */
export const removeEventListener = (type, fn) => {
  window.removeEventListener(type, fn);
};

/**
 * this function returns the browswervisibility
  * @returns {string} event name
 */
export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}
