import styled from "styled-components";

export const NoDataWrap = styled.div`
  flex: 1;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const EllipseText = styled.div`
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:60px;
  text-align: left;
`;
export const NormalText = styled.div`
cursor:auto;
width:60px;
text-align: left;

`