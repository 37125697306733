import request from "utils/axios/service";
import { endpoints } from "../constants";
import history from "utils/history.js";

class LoginService {
  async loginApi(req) {
    return await request.post(endpoints.LOGIN, req);
  }
   async getMetaDataApi(screen) {
    try {
      const res = await request.get(endpoints.GET_META_DATA, {});
      if (
        res &&
        res.status === 200 &&
        res.data &&
        res.data.status === "success"
      ) {
        await localStorage.setItem("metadata", JSON.stringify(res.data.data));
        if (screen === 'login-page') history.push("/monitor/deliveries")
      }
    } catch (error) {
      alert("error");
    }
  }
}

export default LoginService;
