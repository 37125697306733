import styled from "styled-components";

export const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 5;
  background-color: #fff;
  height: 76px;
  width: 100%;
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.06);
  left: 0;
  .container {
    height: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  .selection-count {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
`;
