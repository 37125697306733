import React, { useEffect, useState } from "react";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import AntdTable from "share/organisms/table/antd-table";
import Modal from "share/atoms/modal";
import useModal from "share/hooks/useModal";
import reducer from "./reducer";
import saga from "./saga";
import StatsCard from "./card";
import WelcomeCard from "share/molecules/card/welcome-card";
import { getLocalObj } from "utils/utility/local-storage";
import { isNarayanaSchool } from "utils/utility/school";
import { hasArrData } from "utils/utility/obj-arr";
import { Count, CountWithSymbol, TotalCounts } from "./card/styles";
import { useInjectSaga } from "utils/saga/injectSaga";
import { useInjectReducer } from "utils/saga/injectReducer";
import { useDispatch, useSelector } from "react-redux";
import { ExamNames, getDashboardModifiedData } from "./helper";
import { getDashboardRequest } from "./actions";
import { getPendingRegistrationsRequest } from "./actions";
import { CardsWrapper, Wrap } from "./styles";

const key = "dashboard";

let schoolName;

export default function Dashboard(props) {
  const [tableData, setTableData] = useState({
    columns: [],
    dataArr: [],
    title: "",
  });
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const DashboardState = useSelector((state) => state.dashboard);
  const { data } = DashboardState ? DashboardState : {};
  const { isVisible, handleModal } = useModal();

  useEffect(() => {
    dispatch(getDashboardRequest());
    const tokenData = getLocalObj("token-data");
    if (tokenData) schoolName = tokenData.school_name;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLink = (key, examData) => {
    const obj = getDashboardModifiedData(key, data, examData);
    const { columns, title, dataArr } = obj;
    handleModal();
    setTableData((prevState) => ({ ...prevState, columns, dataArr, title }));
  };

  const getTotalCount = (countArray) =>
    countArray.reduce((acc, curr) => acc + curr.count, 0);

  const navigateToPendingReg = () => {
    if (DashboardState.data.pending_payments) {
      sessionStorage.setItem("feature", "pending");
      dispatch(
        getPendingRegistrationsRequest("/school/new-registration/summary")
      );
    }
  };

  return (
    <WithSidepanelLayout>
      <Wrap>
        <WelcomeCard name={schoolName} />
        {DashboardState && data ? (
          <CardsWrapper className={`row`}>
            <div className="col-xl-4">
              <StatsCard
                handleLink={handleLink}
                type="total-registrations"
                title="Total Registrations"
                data-testid="total-registrations"
              >
                <TotalCounts
                  students={data["total-registrations"]["total_students"]}
                  exams={data["total-registrations"]["total_exams"]}
                />
              </StatsCard>
            </div>
            <div className="col-xl-4">
              <StatsCard
                handleLink={handleLink}
                type="total-payments"
                title="Total Payment Made"
                data-testid="total-payments"
              >
                <CountWithSymbol>
                  {Math.round(data.total_payment)}
                </CountWithSymbol>
              </StatsCard>
            </div>
            <div className="col-xl-4">
              <StatsCard
                cursor={data.pending_payments ? "pointer" : "default"}
                handleLink={navigateToPendingReg}
                title={`Pending ${
                  isNarayanaSchool() ? "Approved" : "Registrations"
                }`}
                data-testid="pending-registrations"
              >
                <Count>{data.pending_payments}</Count>
              </StatsCard>
            </div>
            {hasArrData(data.exam_wise_counts) &&
              data.exam_wise_counts.map((examData) => (
                <div className="col-xl-4">
                  <StatsCard
                    handleLink={handleLink}
                    examData={examData}
                    type="total-subjects"
                    title={ExamNames[examData["exam_name"]]}
                    data-testid={`exam-wise-counts-${examData}`}
                  >
                    <Count>{getTotalCount(examData.counts)}</Count>
                  </StatsCard>
                </div>
              ))}
          </CardsWrapper>
        ) : (
          <p>Loading...</p>
        )}
      </Wrap>
      <Modal
        show={isVisible}
        handleClose={handleModal}
        headingText={tableData.title}
      >
        <AntdTable
          columns={tableData.columns}
          dataSource={tableData.dataArr}
          pagination={false}
          padding="10px"
          data-testid="table-data"
        />
      </Modal>
    </WithSidepanelLayout>
  );
}
