//ISCO
import ISCOPatternOne from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 1).pdf'
import ISCOPatternTwo from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 2).pdf'
import ISCOPatternThree from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 3).pdf'
import ISCOPatternFour from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 4).pdf'
import ISCOPatternFive from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 5).pdf'
import ISCOPatternSix from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 6).pdf'
import ISCOPatternSeven from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 7).pdf'
import ISCOPatternEight from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 8).pdf'
import ISCOPatternNine from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 9).pdf'
import ISCOPatternTen from 'assets/new/ISCO/pattern/ISCO Exam Pattern 2023 (class - 10).pdf'

import ISCOSyllabusOne from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-1.pdf'
import ISCOSyllabusTwo from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-2.pdf'
import ISCOSyllabusThree from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-3.pdf'
import ISCOSyllabusFour from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-4.pdf'
import ISCOSyllabusFive from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-5.pdf'
import ISCOSyllabusSix from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-6.pdf'
import ISCOSyllabusSeven from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-7.pdf'
import ISCOSyllabusEight from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-8.pdf'
import ISCOSyllabusNine from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-9.pdf'
import ISCOSyllabusTen from 'assets/new/ISCO/syllabus/ISCO SYLLABUS CLASS-10.pdf'
//ISEO
import ISEOPatternOne from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 1).pdf'
import ISEOPatternTwo from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 2).pdf'
import ISEOPatternThree from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 3).pdf'
import ISEOPatternFour from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 4).pdf'
import ISEOPatternFive from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 5).pdf'
import ISEOPatternSix from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 6).pdf'
import ISEOPatternSeven from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 7).pdf'
import ISEOPatternEight from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 8).pdf'
import ISEOPatternNine from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 9).pdf'
import ISEOPatternTen from 'assets/new/ISEO/pattern/ISEO Exam Pattern 2024 (Class - 10).pdf'

import ISEOSyllabusOne from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-1.pdf'
import ISEOSyllabusTwo from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-2.pdf'
import ISEOSyllabusThree from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-3.pdf'
import ISEOSyllabusFour from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-4.pdf'
import ISEOSyllabusFive from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-5.pdf'
import ISEOSyllabusSix from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-6.pdf'
import ISEOSyllabusSeven from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-7.pdf'
import ISEOSyllabusEight from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-8.pdf'
import ISEOSyllabusNine from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-9.pdf'
import ISEOSyllabusTen from 'assets/new/ISEO/syllabus/ISEO SYLLABUS CLASS-10.pdf'
//ISKO
import ISKOPatternOne from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 1).pdf'
import ISKOPatternTwo from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 2).pdf'
import ISKOPatternThree from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 3).pdf'
import ISKOPatternFour from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 4).pdf'
import ISKOPatternFive from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 5).pdf'
import ISKOPatternSix from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 6).pdf'
import ISKOPatternSeven from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 7).pdf'
import ISKOPatternEight from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 8).pdf'
import ISKOPatternNine from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 9).pdf'
import ISKOPatternTen from 'assets/new/ISKO/pattern/ISKO Exam Pattern 2023 (class - 10).pdf'

import ISKOSyllabusOne from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-1 2024-25.pdf'
import ISKOSyllabusTwo from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-2 2024-25.pdf'
import ISKOSyllabusThree from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-3 2024-25.pdf'
import ISKOSyllabusFour from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-4 2024-25.pdf'
import ISKOSyllabusFive from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-5 2024-25.pdf'
import ISKOSyllabusSix from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-6 2024-25.pdf'
import ISKOSyllabusSeven from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-7 2024-25.pdf'
import ISKOSyllabusEight from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-8 2024-25.pdf'
import ISKOSyllabusNine from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-9 2024-25.pdf'
import ISKOSyllabusTen from 'assets/new/ISKO/syllabus/ISKO SYLLABUS CLASS-10 2024-25.pdf'
//ISMO
import ISMOPatternOne from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 1).pdf'
import ISMOPatternTwo from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 2).pdf'
import ISMOPatternThree from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 3).pdf'
import ISMOPatternFour from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 4).pdf'
import ISMOPatternFive from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 5).pdf'
import ISMOPatternSix from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 6).pdf'
import ISMOPatternSeven from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 7).pdf'
import ISMOPatternEight from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 8).pdf'
import ISMOPatternNine from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 9).pdf'
import ISMOPatternTen from 'assets/new/ISMO/pattern/ISMO PATTERN 2024 (class - 10).pdf'

import ISMOSyllabusOne from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-1 2024-25.pdf'
import ISMOSyllabusTwo from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-2 2024-25.pdf'
import ISMOSyllabusThree from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-3 2024-25.pdf'
import ISMOSyllabusFour from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-4 2024-25.pdf'
import ISMOSyllabusFive from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-5 2024-25.pdf'
import ISMOSyllabusSix from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-6 2024-25.pdf'
import ISMOSyllabusSeven from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-7 2024-25.pdf'
import ISMOSyllabusEight from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-8 2024-25.pdf'
import ISMOSyllabusNine from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-9 2024-25.pdf'
import ISMOSyllabusTen from 'assets/new/ISMO/syllabus/ISMO SYLLABUS CLASS-10 2024-25.pdf'
//ISSO
import ISSOPatternOne from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 1).pdf'
import ISSOPatternTwo from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 2).pdf'
import ISSOPatternThree from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 3).pdf'
import ISSOPatternFour from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 4).pdf'
import ISSOPatternFive from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 5).pdf'
import ISSOPatternSix from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 6).pdf'
import ISSOPatternSeven from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 7).pdf'
import ISSOPatternEight from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 8).pdf'
import ISSOPatternNine from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 9).pdf'
import ISSOPatternTen from 'assets/new/ISSO/pattern/ISSO Exam Pattern 2023 (Class - 10).pdf'

import ISSOSyllabusOne from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-1 2024-25.pdf'
import ISSOSyllabusTwo from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-2 2024-25.pdf'
import ISSOSyllabusThree from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-3 2024-25.pdf'
import ISSOSyllabusFour from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-4 2024-25.pdf'
import ISSOSyllabusFive from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-5 2024-25.pdf'
import ISSOSyllabusSix from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-6 2024-25.pdf'
import ISSOSyllabusSeven from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-7 2024-25.pdf'
import ISSOSyllabusEight from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-8 2024-25.pdf'
import ISSOSyllabusNine from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-9 2024-25.pdf'
import ISSOSyllabusTen from 'assets/new/ISSO/syllabus/ISSO SYLLABUS CLASS-10 2024-25.pdf'
//JOSA
import JOSAPatternSix from 'assets/new/JOSA/pattern/JOSA Exam Pattern 2024 ( class - 6).pdf'
import JOSAPatternSeven from 'assets/new/JOSA/pattern/JOSA Exam Pattern 2024 ( class - 7).pdf'
import JOSAPatternEight from 'assets/new/JOSA/pattern/JOSA Exam Pattern 2024 ( class - 8).pdf'
import JOSAPatternNine from 'assets/new/JOSA/pattern/JOSA Exam Pattern 2024 ( class - 9).pdf'
import JOSAPatternTen from 'assets/new/JOSA/pattern/JOSA Exam Pattern 2024 ( class - 10).pdf'

import JOSASyllabusSix from 'assets/new/JOSA/syllabus/JOSA SYLLABUS CLASS-6 2024-25.pdf'
import JOSASyllabusSeven from 'assets/new/JOSA/syllabus/JOSA SYLLABUS CLASS-7 2024-25.pdf'
import JOSASyllabusEight from 'assets/new/JOSA/syllabus/JOSA SYLLABUS CLASS-8 2024-25.pdf'
import JOSASyllabusNine from 'assets/new/JOSA/syllabus/JOSA SYLLABUS CLASS-9 2024-25.pdf'
import JOSASyllabusTen from 'assets/new/JOSA/syllabus/JOSA SYLLABUS CLASS-10 2024-25.pdf'
//NTEX
import NTEXPatternSix from 'assets/new/NTEX/pattern/NTEX EXAM PATTERN 2024 ( CLASS - 6).pdf'
import NTEXPatternSeven from 'assets/new/NTEX/pattern/NTEX EXAM PATTERN 2024 ( CLASS - 7).pdf'
import NTEXPatternEight from 'assets/new/NTEX/pattern/NTEX EXAM PATTERN 2024 ( CLASS - 8).pdf'
import NTEXPatternNine from 'assets/new/NTEX/pattern/NTEX EXAM PATTERN 2024 ( CLASS - 9).pdf'
import NTEXPatternTen from 'assets/new/NTEX/pattern/NTEX EXAM PATTERN 2024 ( CLASS - 10).pdf'

import NTEXSyllabusSix from 'assets/new/NTEX/syllabus/NTEX SYLLABUS  CLASS-6 2024-25.pdf'
import NTEXSyllabusSeven from 'assets/new/NTEX/syllabus/NTEX SYLLABUS  CLASS-7 2024-25.pdf'
import NTEXSyllabusEight from 'assets/new/NTEX/syllabus/NTEX SYLLABUS  CLASS-8 2024-25.pdf'
import NTEXSyllabusNine from 'assets/new/NTEX/syllabus/NTEX SYLLABUS  CLASS-9 2024-25.pdf'
import NTEXSyllabusTen from 'assets/new/NTEX/syllabus/NTEX SYLLABUS  CLASS-10 2024-25.pdf'
//ROM
import ROMPatternSix from 'assets/new/ROM/pattern/ROM EXAM PATTERN 2024 (CLASS - 6).pdf'
import ROMPatternSeven from 'assets/new/ROM/pattern/ROM EXAM PATTERN 2024 (CLASS - 7).pdf'
import ROMPatternEight from 'assets/new/ROM/pattern/ROM EXAM PATTERN 2024 (CLASS - 8).pdf'
import ROMPatternNine from 'assets/new/ROM/pattern/ROM EXAM PATTERN 2024 (CLASS - 9).pdf'
import ROMPatternTen from 'assets/new/ROM/pattern/ROM EXAM PATTERN 2024 (CLASS - 10).pdf'

import ROMSyllabusSix from 'assets/new/ROM/syllabus/ROM SYLLABUS CLASS-6 2024-25.pdf'
import ROMSyllabusSeven from 'assets/new/ROM/syllabus/ROM SYLLABUS CLASS-7 2024-25.pdf'
import ROMSyllabusEight from 'assets/new/ROM/syllabus/ROM SYLLABUS CLASS-8 2024-25.pdf'
import ROMSyllabusNine from 'assets/new/ROM/syllabus/ROM SYLLABUS CLASS-9 2024-25.pdf'
import ROMSyllabusTen from 'assets/new/ROM/syllabus/ROM SYLLABUS CLASS-10 2024-25.pdf'

export const examData = {
    isco: [
        { class: '1st', pattern: ISCOPatternOne, syllabus: ISCOSyllabusOne },
        { class: '2nd', pattern: ISCOPatternTwo, syllabus: ISCOSyllabusTwo },
        { class: '3rd', pattern: ISCOPatternThree, syllabus: ISCOSyllabusThree },
        { class: '4th', pattern: ISCOPatternFour, syllabus: ISCOSyllabusFour },
        { class: '5th', pattern: ISCOPatternFive, syllabus: ISCOSyllabusFive },
        { class: '6th', pattern: ISCOPatternSix, syllabus: ISCOSyllabusSix },
        { class: '7th', pattern: ISCOPatternSeven, syllabus: ISCOSyllabusSeven },
        { class: '8th', pattern: ISCOPatternEight, syllabus: ISCOSyllabusEight },
        { class: '9th', pattern: ISCOPatternNine, syllabus: ISCOSyllabusNine },
        { class: '10th', pattern: ISCOPatternTen, syllabus: ISCOSyllabusTen },
    ],
    iseo: [
        { class: '1st', pattern: ISEOPatternOne, syllabus: ISEOSyllabusOne },
        { class: '2nd', pattern: ISEOPatternTwo, syllabus: ISEOSyllabusTwo },
        { class: '3rd', pattern: ISEOPatternThree, syllabus: ISEOSyllabusThree },
        { class: '4th', pattern: ISEOPatternFour, syllabus: ISEOSyllabusFour },
        { class: '5th', pattern: ISEOPatternFive, syllabus: ISEOSyllabusFive },
        { class: '6th', pattern: ISEOPatternSix, syllabus: ISEOSyllabusSix },
        { class: '7th', pattern: ISEOPatternSeven, syllabus: ISEOSyllabusSeven },
        { class: '8th', pattern: ISEOPatternEight, syllabus: ISEOSyllabusEight },
        { class: '9th', pattern: ISEOPatternNine, syllabus: ISEOSyllabusNine },
        { class: '10th', pattern: ISEOPatternTen, syllabus: ISEOSyllabusTen },
    ],
    isko: [
        { class: '1st', pattern: ISKOPatternOne, syllabus: ISKOSyllabusOne },
        { class: '2nd', pattern: ISKOPatternTwo, syllabus: ISKOSyllabusTwo },
        { class: '3rd', pattern: ISKOPatternThree, syllabus: ISKOSyllabusThree },
        { class: '4th', pattern: ISKOPatternFour, syllabus: ISKOSyllabusFour },
        { class: '5th', pattern: ISKOPatternFive, syllabus: ISKOSyllabusFive },
        { class: '6th', pattern: ISKOPatternSix, syllabus: ISKOSyllabusSix },
        { class: '7th', pattern: ISKOPatternSeven, syllabus: ISKOSyllabusSeven },
        { class: '8th', pattern: ISKOPatternEight, syllabus: ISKOSyllabusEight },
        { class: '9th', pattern: ISKOPatternNine, syllabus: ISKOSyllabusNine },
        { class: '10th', pattern: ISKOPatternTen, syllabus: ISKOSyllabusTen },
    ],
    ismo: [
        { class: '1st', pattern: ISMOPatternOne, syllabus: ISMOSyllabusOne },
        { class: '2nd', pattern: ISMOPatternTwo, syllabus: ISMOSyllabusTwo },
        { class: '3rd', pattern: ISMOPatternThree, syllabus: ISMOSyllabusThree },
        { class: '4th', pattern: ISMOPatternFour, syllabus: ISMOSyllabusFour },
        { class: '5th', pattern: ISMOPatternFive, syllabus: ISMOSyllabusFive },
        { class: '6th', pattern: ISMOPatternSix, syllabus: ISMOSyllabusSix },
        { class: '7th', pattern: ISMOPatternSeven, syllabus: ISMOSyllabusSeven },
        { class: '8th', pattern: ISMOPatternEight, syllabus: ISMOSyllabusEight },
        { class: '9th', pattern: ISMOPatternNine, syllabus: ISMOSyllabusNine },
        { class: '10th', pattern: ISMOPatternTen, syllabus: ISMOSyllabusTen },
    ],
    isso: [
        { class: '1st', pattern: ISSOPatternOne, syllabus: ISSOSyllabusOne },
        { class: '2nd', pattern: ISSOPatternTwo, syllabus: ISSOSyllabusTwo },
        { class: '3rd', pattern: ISSOPatternThree, syllabus: ISSOSyllabusThree },
        { class: '4th', pattern: ISSOPatternFour, syllabus: ISSOSyllabusFour },
        { class: '5th', pattern: ISSOPatternFive, syllabus: ISSOSyllabusFive },
        { class: '6th', pattern: ISSOPatternSix, syllabus: ISSOSyllabusSix },
        { class: '7th', pattern: ISSOPatternSeven, syllabus: ISSOSyllabusSeven },
        { class: '8th', pattern: ISSOPatternEight, syllabus: ISSOSyllabusEight },
        { class: '9th', pattern: ISSOPatternNine, syllabus: ISSOSyllabusNine },
        { class: '10th', pattern: ISSOPatternTen, syllabus: ISSOSyllabusTen },
    ],
    josa: [
        { class: '6th', pattern: JOSAPatternSix, syllabus: JOSASyllabusSix },
        { class: '7th', pattern: JOSAPatternSeven, syllabus: JOSASyllabusSeven },
        { class: '8th', pattern: JOSAPatternEight, syllabus: JOSASyllabusEight },
        { class: '9th', pattern: JOSAPatternNine, syllabus: JOSASyllabusNine },
        { class: '10th', pattern: JOSAPatternTen, syllabus: JOSASyllabusTen },
    ],
    ntex: [
        { class: '6th', pattern: NTEXPatternSix, syllabus: NTEXSyllabusSix },
        { class: '7th', pattern: NTEXPatternSeven, syllabus: NTEXSyllabusSeven },
        { class: '8th', pattern: NTEXPatternEight, syllabus: NTEXSyllabusEight },
        { class: '9th', pattern: NTEXPatternNine, syllabus: NTEXSyllabusNine },
        { class: '10th', pattern: NTEXPatternTen, syllabus: NTEXSyllabusTen },
    ],
    rom: [
        { class: '6th', pattern: ROMPatternSix, syllabus: ROMSyllabusSix },
        { class: '7th', pattern: ROMPatternSeven, syllabus: ROMSyllabusSeven },
        { class: '8th', pattern: ROMPatternEight, syllabus: ROMSyllabusEight },
        { class: '9th', pattern: ROMPatternNine, syllabus: ROMSyllabusNine },
        { class: '10th', pattern: ROMPatternTen, syllabus: ROMSyllabusTen },
    ],
}