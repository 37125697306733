import styled from "styled-components";

export const Wrapper = styled.div`
  width: 360px;
  padding-top: clamp(1.25rem, -0.1346rem + 6.1538vw, 4.25rem);
  padding-bottom: clamp(1.5rem, -0.6923rem + 9.7436vw, 5rem);
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);

  @media screen and (max-width: 1162px) {
    width: 100%;
    margin-top: 24px;
  }
  @media screen and (max-width: 760px) {
    width: 100%;
    margin-top: 0px;
  }
`;

export const BlockWrapper = styled.div`
  width: 100%;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: clamp(1rem, -0.8462rem + 8.2051vw, 5rem);

  & img {
    width: clamp(2.5rem, 0.8846rem + 7.1795vw, 6rem);
  }
`;

export const BlockTitle = styled.div`
  color: #242424;
  font-size: clamp(0.75rem, 0.5192rem + 1.0256vw, 1.25rem); //12px360 & 20px1140
  font-weight: 600;
  line-height: 1.4;
  margin-top: clamp(1rem, 0.7692rem + 1.0256vw, 1.5rem);
  text-align: center;
  padding: 0px 10px;
`;

export const Header = styled.div`
  font-size: clamp(1rem, 0.7692rem + 1.0256vw, 1.5rem); //16@360 & 24@1140
  font-weight: bold;
  text-align: center;
  margin-right: 12px;

  & .hightlight {
    color: #d71a63;
  }
`;

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1162px) {
    flex-direction: row;
  }
`;
