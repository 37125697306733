import { useEffect, useState } from "react";

export const useMediaPermission = () => {
  const [audioPermission, setAudioPermission] = useState("");
  const [videoPermission, setVideoPermission] = useState("");
  const setPermission = (name, state) => {
    name === "video_capture"
      ? setVideoPermission(state)
      : setAudioPermission(state);
  };
  const getPermission = (type) => {
    try {
      navigator.permissions
        .query({ name: type })
        .then((res) => {
          setPermission(res.name, res.state);
          res.onchange = () => {
            setPermission(res.name, res.state);
          };
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log("naviagator.permission", error);
    }
  };
  useEffect(() => {
    getPermission("camera");
    getPermission("microphone");
  }, [navigator.permissions]);
  return [audioPermission, videoPermission];
};

export const useTriggerMediaPermission = (callback) => {
  const getPermission = (type) => {
    try {
      navigator.permissions
        .query({ name: type })
        .then((res) => {
          res.onchange = () => {
            callback({
              name: res.name,
              state: res.state,
            });
          };
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPermission("camera");
    getPermission("microphone");
  }, [navigator.permissions]);
};
