import styled from "styled-components";
import SingleSelect from "share/atoms/Dropdown/single-select";

export const Error = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #e21313;
`;
export const Wrap = styled.div`
  min-height: 198px;
  display: flex;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  background-color: ${(props) => props.bgColor || "#fffff"};
  border: ${(props) => `1px solid ${props.isSelected ? "#d71a63" : "#fff"}`};
  border-radius: 6px;
  & .logo {
    width: 100px;
    padding-top: 24px;
    padding-left: 24px;
    margin-right: 10px;
  }
  .exam-details {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
`;

export const Header = styled.div`
  font-size: 30px;
  width: 144px;
  font-family: Poppins;
  font-style: Semi-bold 600 italic;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  align-items: flex-start;
  width: 700px;
  .note {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #e21313;
    margin-top: 16px;
  }
  .select {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.4px;
    text-align: left;
    color: #242424;
  }
`;

export const StyledSelect = styled(SingleSelect)`
  width: 200px !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #464646;
  padding-left: 8px;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #565656;
`;

export const Value = styled(Label)`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #464646;
  padding-left: 8px;
  padding-right: 46px;
`;
export const SelectInput = styled.select`
  padding: 7px 150px;
  text-align: start;
  margin-left: 5px;
  padding-left: 0;
`;

export const Exams = styled(Info)`
  width:325px;
  align-items: flex-end;
  padding-top: 24px;
  padding-right: 24px;
  .checkbox-wrap {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.28px;
    text-align: right;
    color: #464646;
    cursor: ${({isDisable})=>isDisable?"not-allowed":"pointer"};
    .checkbox & {
      color: red;
      border: 1px solid black;
    }
    .checkbox-span {
      color: #d71a63;
      padding-right: 6px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    .box {
      width: 16px;
      height: 16px;
      padding: 2px;
      opacity: 0.3;
      border-radius: 2px;
      border: solid 1px #d71a63;
      background-color: #d71a63;
      display:flex;
      justify-content: center;
      align-items:center;
    }
  }
  .fee {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.28px;
    text-align: left;
    color: #464646;
    margin-bottom: 0;
  }
  .cost {
    font-family: Poppins;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.72px;
    text-align: left;
    color: #d71a63;
    margin-bottom: 0;
  }
  .reg-students {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .value {
      padding-right: 0;
    }
  }
  .rupee{
    display:inline-flex;
    align-items: center;
  }
  .base-price{
  font-size: 20px;
  text-decoration: line-through;
  }
`;

export const  SelectWrapper = styled.div`
opacity:${({isDisable})=>isDisable?"0.5":"1"};
font-size:14px;
color:#464646;
padding-left:6px;
`

export const StudentsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 296px;
  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }
  h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #d71a63;
  }
`;
export const Message = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.67;
  color: #e21313;
  padding: 12px 0px;
  span {
    font-weight: 600;
  }
`;

export const ExamInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  align-items: flex-start;
  width: 1000px;
  .title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: #242424;
  }
  .wrapper {
    display: flex;
    margin-top: 9px;
    flex-wrap: wrap;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding: 10px 16px;
    border-radius: 6px;
    border: solid 1px rgba(70, 70, 70, 0.1);
    margin-right: 32px;
    align-self: flex-start;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
  }
  .heading {
    font-family: Poppins;
    font-size: 16px;
    line-height: 1.5;
    color: #565656;
  }
  .para {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #464646;
  }
  .registration-closed{
  font-family: Poppins;
  margin-top:12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #e21313;
  }
  .level2 {
    font-family: Poppins;
    font-size: 12px;
    line-height: 1.67;
    color: #e21313;
    margin-top: 12px;
  }
`;

export const Wrapper = styled.div`
display:flex;
justify-content:center;
align-items: center;
`