import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
`;
export const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px;
  height: 536px;
  position: relative;
  .right-block {
    .image {
      object-fit: contain;
      position: absolute;
      left: 0px;
      height: 552px;
    }
    .vector-img {
      position: absolute;
      top: 30px;
      left: -100px;
      -webkit-animation: ${rotate} 30s linear infinite;
      -moz-animation: ${rotate} 30s linear infinite;
      animation: ${rotate} 30s linear infinite;
    }
    @media screen and (max-width: 768px) {
      .image,
      .vector-img {
        display: none;
      }
    }
    @media screen and (min-width: 769px) {
      .vector-img {
        left: -20px;
      }
      .image {
        height: 500px;
      }
    }
    @media screen and (min-width: 1024px) {
      .image {
        height: 559px;
        left: 30px;
      }
    }
    @media screen and (min-width: 1080px) {
      .image {
        height: 591px;
        left: 30px;
      }
    }
    @media screen and (min-width: 1366px) {
      .image {
        height: 545px;
        left: 0;
      }
    }
    @media screen and (min-width: 1400px) {
      .image {
        height: 553px;
        left: 0;
      }
    }
    @media screen and (min-width: 1512px) {
      .image {
        left: 40px;
      }
    }
    @media screen and (min-width: 1536px) {
      .image {
        height: 600px;
        left: 0;
      }
    }
    @media screen and (min-width: 1600px) {
      .image {
        height: 615px;
        left: 0;
      }
    }
    @media screen and (min-width: 1800px) {
      .image {
        height: 700px;
        left: 0;
      }
    }
    @media screen and (min-width: 1920px) {
      .image {
        height: 740px;
        left: 0;
      }
    }
    @media screen and (min-width: 200px) and (max-width: 800px) {
      gap: 0;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 769px) {
    height: 480px;
  }
  @media screen and (min-width: 1366px) {
    height: 542px;
  }
  @media screen and (min-width: 1440px) {
    height: 552px;
  }
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
  @media screen and (min-width: 1800px) {
    height: 700px;
  }
  @media screen and (min-width: 1920px) {
    height: 730px;
  }
`;
export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sub-heading {
    font-size: clamp(1.5rem, 1.1538rem + 1.5385vw, 2.25rem);
    font-weight: bold;
    text-align: left;
    line-height: 1.4;
    color: #fff;
    position: relative;
    z-index: 1;
  }
  .description {
    font-size: clamp(1rem, 1rem + 0vw, 1rem);
    text-align: left;
    color: #fff;
    line-height: 1.4;
    margin-top: 16px;
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 320px;
    justify-content: center;
    margin-top: 0;
    .sub-heading {
      text-align: center;
    }
    .description {
      text-align: center;
    }
  }
`;
