import styled from 'styled-components'
export const StyledWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding:20px 70px;
background-color: #fff;
`
export const TestDetails = styled.h6`
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.5;
  color: #242424;
  margin-bottom: 10px;
`;
export const Description = styled.p`
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.86;
  color: #464646;
  font-weight: 100;
`;
export const IconWrapper=styled.div`
align-self:flex-start;
padding:5px 15px;
`
export const Wrapper =styled.div`
 display: flex;
 flex-direction:column;
`
