import styled from "styled-components";
import GlobalContainer from "share/atoms/GlobalContainer";

export const Container = styled(GlobalContainer)`
  background-color: #f9f9ff;
  padding-top: clamp(1.5rem, -0.6923rem + 9.7436vw, 2.25rem);
  padding-bottom: clamp(1.5rem, -0.6923rem + 9.7436vw, 2.25rem);
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: clamp(0.375rem, -0.1442rem + 2.3077vw, 1.5rem);
`;

export const FeaturesCardWrapper = styled.div`
  display: flex;
  padding: 36px 24px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  max-width: clamp(20.5rem, 13.8654rem + 29.4872vw, 34.875rem);
  height: 204px;

  @media screen and (max-width: 850px) {
    height: 250px;
    margin-bottom: 20px;
    box-shadow: 0 16px 12px 0 rgba(221, 230, 237, 0.4);
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 304px;
  }
`;

export const FeaturesContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 0px 0px 8px;
  h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000000;
  }
  p {
    padding-top: 15px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.75; */
    line-height: 1.5;
    letter-spacing: normal;
    color: #565656;
  }

  @media screen and (max-width: 800px) {
    text-align: center;
    margin: 5px 0px;
  }

  /* @media only screen and (min-width: 481px) and (max-width: 1140px) {
    text-align: left;
    margin: 0;
  } */
`;

export const FeatureHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20 to 36
  }
  h6 {
    font-family: Poppins;
    font-size: clamp(1rem, 0.8846rem + 0.5128vw, 1.25rem);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    max-width: 90%;
    margin-bottom: 36px;
  }
`;

export const FeaturesIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 50px;
    width: 72px;
    height: 72px;
  }
  @media screen and (max-width: 600px) {
    img {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
`;

export const CarasoulContainer = styled.div`
  max-width: clamp(22.5rem, 0rem + 100vw, 50rem);
  height: 344px;
`;
