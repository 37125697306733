import React from "react";
import { Header, ValueList } from "./styles";
import { proctotInstructions } from "./helpers";

function HeaderSection() {
  return (
    <>
      <Header>
        <div className="title">
          CNAPS Examination is a digitally proctored examinaticon.
        </div>
        <div className="tag">
          Allow access to Camera and Microphone for proctoring system to work
          efficiently.
          <br />
          Failure to give Camera and Microphone access may result in
          cancellation of:
        </div>
        <ValueList>
          {proctotInstructions.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ValueList>
      </Header>
    </>
  );
}

export default HeaderSection;
