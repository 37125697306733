const CNAPS_ADMIN = 'cnaps-admin/v1/admin';

export const endpoints = {
  GETDASHBOARD: `${CNAPS_ADMIN}/dashboard`
};

export const getDashboardTypes = {
  GET_PENDING_REGISTRATIONS_REQUEST:
    " dashboard/GET_PENDING_REGISTRATIONS_REQUEST",
  GET_DASHBOARD_REQUEST: " dashboard/GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "dashboard/GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_ERROR: "dashboard/GET_DASHBOARD_ERROR",
};