import * as types from './types'
import produce from 'immer'
import {combineReducers} from 'redux'

export const initialState = {
    isLoading: false,
    data: false,
    error: false,
  };

  
const answerKeyDetailsReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
        case types.FETCH_ANSWER_KEY_DETAILS_REQUEST:
          draft.isLoading = true;
          draft.error = false;
          break;
        case types.FETCH_ANSWER_KEY_DETAILS_SUCCESS:
          draft.isLoading = false;
          draft.data = action.payload.data;
          break;
        case types.FETCH_ANSWER_KEY_DETAILS_ERROR:
          draft.isLoading = false;
          draft.error = action.error;
          break;
      default:
        draft = state;
        break;
    }
  });


const answerKeyReducer= combineReducers({
    answerKeyDetailsReducer,
})

export default answerKeyReducer