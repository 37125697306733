import React from "react";
import Block from "./block";
import { accessdetails } from "./helper";
import { Wrapper, Header, BlocksWrapper } from "./styles";

function OlympiadAccessDetails() {
  return (
    <Wrapper>
      <Header>
        For each <span className="hightlight">Olympiad Exam</span>, you get
        access to
      </Header>
      <BlocksWrapper>
        {accessdetails.map((item) => (
          <Block item={item} />
        ))}
      </BlocksWrapper>
    </Wrapper>
  );
}

export default OlympiadAccessDetails;
