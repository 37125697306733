import GlobalContainer from "share/atoms/GlobalContainer";
import styled, { keyframes } from "styled-components";

export const Container = styled(GlobalContainer)`
  background-color: #182441;
  color: #ffffff;
  padding-top: clamp(1.5rem, -0.6923rem + 9.7436vw, 6.25rem);
  padding-bottom: clamp(1.5rem, -0.6923rem + 9.7436vw, 6.25rem);
`;

export const Wrapper = styled.div``;
export const Header = styled.div`
  text-align: center;

  & .title {
    font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20 to 36
    font-weight: 600;
    margin-bottom: 8px;
  }
  & .tag {
    font-size: clamp(0.875rem, 0.8173rem + 0.2564vw, 1rem); //14 to 16
    font-weight: 500;
    line-height: 1.5;
  }
`;

export const Body = styled.div`
  display: ${(props) => (props.$isResonsive ? "block" : "flex")};
  justify-content: space-between;
  margin-top: 48px;
  position: relative;
  gap: 16px;
`;
export const RightSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const LogoAnimation = keyframes`
    0%{opacity:0}
    100%{opacity:1}
`;

export const LeftSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &::after {
    content: "";
    background: radial-gradient(
      circle at 47% 49%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 49%
    );
    width: 829px;
    height: 600px;
    opacity: 0.2;
    position: absolute;
    left: -150px;
    top: -20px;
    z-index: 2;
  }
`;

export const Banner = styled.img`
  z-index: 3;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
`;

export const CardAnimation = keyframes`
   0%{opacity:.50}
   50%{opacity:.75}
  100%{opacity:1}
`;

export const CardWrapper = styled.div`
  position: relative;
  max-width: 560px;
  min-height: 136px;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  padding: 24px 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.$isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.1)"};
  color: ${(props) => (props.$isActive ? "#242424" : "#ffffff")};
  animation-name: ${(props) => props.$isActive && CardAnimation};
  animation-duration: 0.3s;
  animation-timing-function: linear;
  cursor: pointer;

  & .main-section {
    position: absolute;
    left: 30px;
  }

  & .progress-section {
    display: ${(props) => (props.$isActive ? "block" : "none")};
  }

  & .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  & .content {
    font-size: 14px;
    line-height: 1.71;
  }
`;

export const ResponsiveImgContainer = styled.div`
  display: ${(props) => (props.$isActive ? "block" : "none")};
  transition: all 3s ease-in-out;
  margin: auto;

  & img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
`;
