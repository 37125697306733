import request from 'utils/axios/service';
import { endpoints } from '../constants'

class NewRegistrationService {
  downloadXlsx() {
    return request.get(endpoints.GET_XLSX_TEMPLATE);
  }

  getStudentData(req) {
    return request.get(endpoints.GET_STUDENT_DATA, req);
  }

  individualPaymentSummary(req) {
    return request.get(endpoints.INDIVIDUAL_PAYMENT_SUMMARY, req);
  }

  initIndividualPayment(req) {
    return request.post(endpoints.INIT_INDIVIDUAL_PAYMENT, req);
  }

  sendBulkRegistrationManual(req) {
    return request.post(endpoints.BULK_MANUAL_REGISTRATIONS, req);
  }

  excelUpload(req, config) {
    return request.post(endpoints.EXCEL_BULK_REGISTRATION_UPLOAD, req, config);
  }

  sendExamPhaseSelection(req) {
    return request.post(endpoints.EXAM_PHASE_SELECTION, req);
  }

  getStudentPaymentSummary(req) {
    let feature = sessionStorage.getItem("feature");
    if (feature === "pending")
      return request.get(endpoints.PENDING_REGISTRATIONS_PAYMENT_SUMMARY, req);
    else if (feature === "individual")
      return request.get(endpoints.STUDENT_PAYMENT_SUMMARY, req);
    else return request.get(endpoints.STUDENT_PAYMENT_SUMMARY, req);
  }

  saveList(req) {
    return request.post(endpoints.SAVE_LIST, req);
  }

  paymentSucessCallback(req) {
    return request.post(endpoints.PAYMENT_SUCCESS_CALLBACK, req);
  }

  pendingRegistrations(req) {
    return request.get(endpoints.PENDING_REGISTRATIONS, req);
  }
  
  initBulkRegPayment(req) {
    let feature = sessionStorage.getItem("feature");
    if (feature === "pending")
      return request.post(endpoints.INIT_PENDING_REG_PAYMENT, req);
    else if (feature === "individual")
      return request.post(endpoints.INIT_BULK_REG_PAYMENT, req);
    else return request.post(endpoints.INIT_BULK_REG_PAYMENT, req);
  }

  deleteStudent(req) {
    let feature = sessionStorage.getItem("feature");
    if (feature === "pending")
      return request.post(endpoints.DELETE_STUDENT, req);
    else return request.post(endpoints.DELETE_EXCEL_STUDENT, req);
  }

  studentFilters(req) {
    return request.get(endpoints.STUDENT_FILTERS, req);
  }

  sendPhaseSelection(req) {
    return request.post(endpoints.EXAM_PHASE_SELECTION, req);
  }

  getExamPhaseList() {
    return request.get(endpoints.EXAM_PHASE_LIST);
  }
  
}

export default NewRegistrationService;
