import styled from "styled-components";
import InputLabel from "share/atoms/form-fields/labels";

export const StyledLabel = styled(InputLabel)`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;

export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #d71a63;
`;

export const Wrap = styled.div`
  padding-bottom: 24px;
  font-family: Poppins;
  .wrapper{
    display:flex;
    justify-content: space-between;
  }
`;

export const SearchDiv = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  h4 {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.41px;
    color: #000000;
  }
`;

export const Fields = styled.div``;

export const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const DownLoadLink = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #d71a63;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    margin-right: 10px;
  }
`;

export const DownloadStudent = styled(DownLoadLink)`
/* margin-right:20px; */
`;
export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  .downloads {
    display: flex;
  }
  
`;
