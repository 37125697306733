import React from "react";
import Card from "./card";
import { Wrapper, Header, Body, Container } from "./style";
import { legacydetails } from "./helper";
import BackDrop from "./backdrop";

function Legacy() {
  return (
    <Container>
      <Wrapper>
        {/* <BackDrop /> */}
        <Header>
          <div className="header">
            CNAPS <span className="highlight"> Legacy</span>
          </div>
          <div className="content">
            <div>
              CNAPS has a long history of providing a quality Olympiad
              preparation platform. Armed with data from millions of
              assessments,
            </div>
            <div>
              CNAPS stand out as the most taken Olympiad preparatory exams. The
              numbers speak for themselves.
            </div>
          </div>
        </Header>
        <Body>
          {legacydetails.map((item, index) => (
            <Card item={item} key={index} id={index} />
          ))}
        </Body>
      </Wrapper>
    </Container>
  );
}

export default Legacy;
