import React from "react";
import cnapsSchoolLogo from "assets/cnaps-school-bg-white-logo.svg";
import cnapsLogo from "assets/landingpage/CnapsLogo.svg";
import { FooterWrapper, Wrapper, HorizontalLine } from "./styles";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import packageJson from "../../../../package.json";

const Footer = () => {
  const route = useRouteMatch();
  return (
    <FooterWrapper>
      <div className="container">
        <div className="row ">
          <div className="col-xs-12 col-sm-6 mb-4 mb-xl-0 col-xl-3 image-container order-4 order-sm-0">
            <img
              src={cnapsLogo}
              alt={
                route.path === "/school" ? "cnaps-school-logo" : "cnaps-logo"
              }
              className="image d-sm-block"
            />
            <p className="footer-copyright">
              © 2024 cnaps all rights reserved.
            </p>
            <p className="footer-copyright" style={{ opacity: 0.6 }}>
              version {packageJson.version}
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 mb-4 mb-xl-0 col-xl-3 order-0 order-sm-1">
            <ul className="navbar-nav footer-links flex-column">
              <li className="nav-item-header">Quick Links</li>
              <li className="nav-item">
                <Link to="/faqs">FAQ's</Link>
              </li>
              <li className="nav-item">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="nav-item">
                <Link to="/terms-conditions">T&#38;C</Link>
              </li>
              <li className="nav-item">
                <Link to="/refund-policy">Refunds & Cancellation</Link>
              </li>
            </ul>
            <HorizontalLine />
          </div>

          <div className="col-xs-12 col-sm-6 mb-4  mb-xs-0 mb-xl-0  col-xl-3 order-2 order-sm-2">
            <Wrapper>
              <p className="heading">Send us a email</p>
              <a
                className="list-item list-mail"
                href="mailto:support@cnaps.co.in"
              >
                support@cnaps.co.in
              </a>
            </Wrapper>
            <HorizontalLine />
          </div>
          <div className="col-xs-12 col-sm-6 col-xl-3 order-3 order-sm-3">
            <Wrapper>
              <p className="heading">Contact number</p>
              <p className="list-item">+91 9154952127 / +91 9154952125</p>
              <p className="list-item">+91 8008772864 / +91 8008772095</p>
            </Wrapper>
            <HorizontalLine />
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
