import React from "react";
import RefreshIcon from "assets/monitoring/monitor-refresh-icon.svg";
import { RefreshWrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDetailsMonitorRequest } from "./actions/get-student-details-for-monitor";

const RefreshIconBody = ({ row,setShowOpen }) => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state?.monitorTestTakers?.studentListMonitor
  );


  const handleRefresh = () => {
    setShowOpen(false);
    const payload = {
      admission_number: row?.admission_number,
      delivery_id: row?.delivery_id,
      tableData: state.data,
      index: row.key,
    };
    dispatch(getStudentDetailsMonitorRequest(payload))
  };
  return (
    <RefreshWrapper onClick={handleRefresh}>
      <img src={RefreshIcon} />
    </RefreshWrapper>
  );
};

export default RefreshIconBody;
