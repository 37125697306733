import styled from "styled-components";
export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  ${({ isExpanded }) => (isExpanded ? "display:block" : "display:none")}
`;
export const ListWrap = styled.div`
  &.side-panel-expanded {
    position: fixed;
    left: 0;
    right: 0;
    display: block;
    width: 240px;
    height: 100vh;
    background: white;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 15%);

    .hamburger-menu {
      display: none;
    }
    .logo-wrap {
      display: flex;
    }
    h3 {
      display: inline-block;
    }
    .close-icon {
      display: block;
      position: fixed;
      left: 256px;
      top: 32px;
      color: white;
    }
  }
  .close-icon {
    display: none;
  }
  .hamburger-menu {
    display: none;
  }
  .logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ loginType }) => (loginType === "student" ? "76px" : "76px")};
    img {
      height: 46px;
      flex-grow: 0;
      object-fit: contain;
    }
    h3 {
      background-image: linear-gradient(to top, #27397d, #0597c9);
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      -webkit-background-clip: text;
      background-clip: text;
      margin-left: -42px;
      margin-top: -8px;
      -webkit-text-fill-color: transparent;
    }
  }
  @media screen and (max-width: 1366px) {
    width: 240px;
    min-width: 224px;
  }
  @media screen and (max-width: 900px) {
    width: 48px;
    min-width: 48px;
    .logo-wrap {
      display: none;
    }
    li a {
      padding-top: 12px;
      padding-bottom: 12px;
      svg {
        height: 16px;
        width: 16px;
      }
    }
    .normal-route,
    .active-route {
      .nav-link {
        width: 100% !important;
      }
    }
    .hamburger-menu {
      height: 76px;
      display: block;
      color: ${({ theme }) => theme.palette.primary.main};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;
export const StyledLi = styled.li`
  width: 100%;
  list-style-type: none;
  &.active-route {
    .nav-link.active {
      color: #fff;
      font-weight: 700;
    }
    .nav-link {
      width: 100%;
      height: 100%;
      color: ${({ theme }) => theme.palette.common.white} !important;
      background-color: ${({ theme }) => theme.palette.primary.main} !important;
      display: flex !important;
      align-items: center;
      h3 {
        color: #fff;
        font-weight: 700;
      }
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
  &.normal-route {
    .nav-link {
      width: 100%;
      height: 100%;
      color: ${({ theme }) => theme.palette.grey[900]} !important;
      display: flex !important;
      align-items: center;

      h3 {
        color: #254082;
      }
      :hover {
        background-color: #f3f8ff;
      }
    }
  }
`;

export const RouteName = styled.h3`
  margin-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: left;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;