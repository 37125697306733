import { call, put, takeLatest } from "redux-saga/effects";
import notify from "utils/notifications";
import * as actions from "./actions";
import PaymentReceiptService from "./services";
import {paymentReceiptTypes} from './constants'

const paymentReceiptService= new PaymentReceiptService();

export function* getPaymnetReceiptWorker(action) {
  try {
    const res = yield call(paymentReceiptService.paymentReceiptApi, action.payload);
    if (res.data.status === "success") {
      const data = res.data.data;
      yield put(actions.getPaymentReceiptSuccess(data));
    } else {
      notify("error", res.data.message);
      yield put(actions.getPaymentReceiptError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong");
    yield put(actions.getPaymentReceiptError(e));
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield takeLatest(paymentReceiptTypes.PAYMENT_RECEIPT_REQUEST, getPaymnetReceiptWorker);
  }
