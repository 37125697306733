import * as actionTypes from "../actionTypes";

export const getDeliveryListRequest = (payload) => {
  return {
    type: actionTypes.GET_DELIERY_REQUEST,
    payload,
  };
};

export const getDeliveryListSuccess = (payload) => {
  return {
    type: actionTypes.GET_DELIERY_SUCCESS,
    payload,
  };
};

export const setDeliveryList = (payload) => {
  return {
    type: actionTypes.SET_DELIERY_LIST,
    payload,
  };
};

export const getDeliveryListFail = (payload) => {
  return {
    type: actionTypes.GET_DELIERY_FAIL,
    payload,
  };
};

export const setFilterDeliveries = (payload) => ({
  type: actionTypes.SET_FILTER_DELIVERIES,
  payload,
});

export const setDeliveryFilters = (payload) => ({
  type: actionTypes.SET_DELIVERY_FILTERS,
  payload,
});
