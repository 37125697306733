import styled, { css, keyframes } from "styled-components";
import Tick from "assets/exams/IconTick.svg";
import DefaultButton from "share/atoms/Button";
import CommonButton from "share/atoms/Button";
import GlobalContainer from "share/atoms/GlobalContainer";

export const Container = styled(GlobalContainer)`
  background-color: #f9f9ff;
  padding-bottom: ${({ $isExamPage }) => !$isExamPage && "97px"};

  @media screen and (max-width: 800px) {
    padding-bottom: 32px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 100px 0px;
`;

export const fadeInDownKeyframe = keyframes`
0% {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
`;

export const Animated = styled.div`
  -webkit-animation-name: ${fadeInDownKeyframe};
  animation-name: ${fadeInDownKeyframe};
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: ${({ idx }) => `${(idx + 1) * 0.19}s`};
`;

export const StyledButton = styled(DefaultButton)`
  opacity: ${({ disabled }) => (disabled ? "50%" : "100%")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  svg {
    font-weight: bold;
    transform: translateX(0px);
    -webkit-transition: transform 1s;
    transition: transform 1s;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 3px;
  }
  &:hover svg {
    transform: ${({ disabled }) =>
      disabled ? "translateX(0px)" : "translateX(5px)"};
  }
  padding: 9px 14px;
`;

export const FirstContainer = styled.div`
  border-radius: 6px;
  ${(props) =>
    !props.isHome &&
    css`
      box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
      background: #ffff;
      padding: 35px 4px 36px 18px;
    `}
  @media (min-width: 764px) and (max-width: 1139px) {
    margin: 30px 0px;
  }
`;

export const Content = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  margin-left: 10px;
  margin-right: 10px;
  ul {
    /* margin: 10px 0 10px 20px; */
    margin: 4px 10px 10px 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.44;
  }
  ${(props) =>
    props.isHome &&
    css`
      text-align: center;
      margin: 0px;
    `}
`;

export const H1 = styled.div`
  color: #242424;
  font-family: Poppins;
  font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20@360 & 36@1140
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 10px;
  margin-top: ${({ $isExamPage }) =>
    $isExamPage ? "32px" : "clamp(1.5rem, -0.6923rem + 9.7436vw, 6.25rem)"};
  @media screen and (max-width: 600px) {
    /* font-size: 24px; */
  }
`;

export const ContentDesc = styled.div`
  line-height: 1.56;
  padding: 0px 100px;
  @media screen and (max-width: 600px) {
    padding: 0px 0px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0px 0px;
  }
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isHome ? "center" : "space-between")};
  flex-wrap: wrap;
  @media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-basis: 32%;
  flex-flow: column;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
  padding: 10px 20px 32px 20px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  align-items: stretch;
  &:hover {
    box-shadow: 0 20px 20px -20px rgba(36, 36, 36, 0.25);
  }
  ${(props) =>
    props.isHome &&
    css`
      max-width: 458px;
    `}
  @media (min-width: 768px) and (max-width: 1023px)  and (orientation: portrait) {
    min-width: 320px;
    margin: 15px auto;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Registration = styled.div`
  display: flex;
  height: 228px;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  @media screen and (max-width: 600px) {
    height: 100%;
    flex-flow: column;
    padding: 40px 0;
  }
`;

export const CardBody = styled.div`
  display: none;
  position: relative;
  flex-flow: column;
  margin-top: 26px;
  ${(props) =>
    props.isHome &&
    css`
      display: flex;
    `}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  img {
    width: 164px;
    height: 84px;
  }
  @media screen and (max-width: 600px) {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    img {
      width: 164px;
      height: 84px;
    }
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    width: 110%;
  }
  ${(props) =>
    !props.isHome &&
    css`
      text-align: center;
    `}
`;

export const BodyTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

export const BodyContent = styled.div`
  margin-top: 16px;
  width: 95%;
  font-size: 14px;
  div {
    font-family: Poppins;
    min-height: 100px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.75; */
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }
  ul {
    margin-left: 20px;
    margin-top: 16px;
    list-style-image: url(${Tick});
  }
  li {
    margin-bottom: 12px;
  }
`;

export const BodyFooter = styled.div`
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 40px 10% 10px 10%;

  cursor: pointer;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.3));
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RegImg = styled.img``;

export const RegTitle = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-bottom: 12px;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-top: 30px;
  }
`;

export const RegContent = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #565656;
  max-width: 65%;
  @media screen and (max-width: 600px) {
    max-width: 80%;
    text-align: center;
  }
`;

export const RegFooter = styled.div`
  padding: 12px 5px 15px 5px;
  border-radius: 40px;
  border: solid 1px #f99ec1;
  background-color: #fed6e5;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #950b3e;
  margin-top: 36px;
  @media screen and (max-width: 600px) {
    padding: 12px 30px 15px 30px;
  }
`;

export const MainWrapper = styled.div`
  position: relative;
  & .new-icon {
    object-fit: contain;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 10px;
  }
  .fifty-off {
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 40px;
  }
  .curve-line {
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 25px;
  }

  .fifty-off-with-arc {
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 25px;
  }
`;

// export const HeaderWrapper = styled.div`
//   height: 56px;
//   display: flex;
//   justify-content: space-between;
//   & .header-details {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     justify-content: center;
//   }

//   & .header-exam {
//     font-size: 12px;
//     line-height: 1.67;
//     color: #464646;
//   }

//   & .header-fee {
//     font-size: 24px;
//     font-weight: bold;
//     color: #d71a63;
//     font-family: "Poppins";
//   }
//   & .header-logo {
//     width: 116px;
//   }
// `;

export const ColoredText = styled.div`
  color: #d71a63;
  font-weight: 1000;
  margin-right: 10px;
`;

export const ResultOutH1 = styled.div`
  display: flex;
  justify-content: center;
  font-family: Poppins;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 0px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    /* font-size: 24px; */
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.75; */
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #18a0fb;
  margin-top: 20px;
  cursor: pointer;
  svg {
    margin-left: 15px;
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 0px;
    svg {
      margin-left: 15px;
      font-size: 18px;
    }
  }
`;

export const OlympiadsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 64px;

  .heading {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #242424;
  }

  .para {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    margin-top: 12px;
    margin-bottom: 72px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexibleWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  margin-bottom: 50px;

  .heading {
    font-family: Poppins;
    font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424;
  }

  & .heading:nth-child(2) {
    margin-bottom: 12px;
  }
  .paragraph {
    width: 322px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #464646;
  }

  @media screen and (max-width: 1162px) {
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    margin-bottom: 0px;

    & .paragraph {
      width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: 800px) {
    text-align: center;
    & .paragraph {
      width: 100%;
      text-align: center;
      padding: 0px 24px 0px 12px;
    }
  }
`;

export const FlexibleTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1162px) {
    flex-direction: row;
    gap: 8px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: white;
  padding: 24px;
  font-family: Poppins;
  margin-bottom: 30px;
  padding-bottom: 0;
  border-radius: 6px;
  transition: all 0.1s linear;
  &:hover {
    box-shadow: 0 20px 20px -20px rgba(36, 36, 36, 0.25);
  }
`;

export const Header = styled.div`
  max-width: 312px;
  height: 56px;
  display: flex;
  justify-content: space-between;

  & .header-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  & .header-exam {
    font-size: 12px;
    line-height: 1.67;
    color: #464646;
  }

  & .header-fee {
    font-size: 24px;
    font-weight: bold;
    color: #d71a63;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #242424;
  margin-top: 12px;
  height: 48px;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SingleInfo = styled.div`
  border-left: ${(props) =>
    props.border ? "1px solid #dbdbdb" : "1px solid transperant"};
  width: calc(100% / 2);
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: space-between;
  margin-top: 12px;

  & .singleinfo-title {
    font-weight: bold;
    height: 24px;
    font-size: 12px;
    line-height: 1.67;
  }

  & .singleinfo-details {
    font-size: 14px;
    line-height: 1.71;
    color: #464646;
    height: 24px;
  }
`;

export const ExamInfo = styled(SingleInfo)`
  width: calc(100% / 2);
  margin-bottom: 10px;
  margin-top: -2px;
  & .completed {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.71;
    color: #e21313;
  }
`;

export const ExamDate = styled(SingleInfo)`
  width: 100%;
`;

export const List = styled.ul`
  width: 312px;
  height: 148px;
  list-style-type: circle;
  margin-left: -16px;
  font-size: 12px;
  line-height: 1.67;
  color: #464646;
  margin-left: 24px;

  & li {
    height: 24px;
    margin: 7px 0px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  position: relative;
  box-shadow: -20px -10px 15px white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  padding-bottom: 24px;

  & .footer-more {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 22px;
    border: solid 1px #254082;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: #254082;
    cursor: pointer;
  }

  & .footer-more:hover {
    background-color: rgba(37, 64, 130, 0.1);
  }
  button {
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    .hide-button {
      display: none;
    }
    padding-bottom: 24px;
  }
`;

export const Button = styled.button`
  width: 115px;
  height: 42px;
  background-color: #d71a63;
  color: white;
  border: none;
  outline: none;
  border-radius: 24px;
  margin: 0;

  & .fas {
    margin: 0px 8px;
  }
`;

export const SchoolWrapper = styled.div`
  display: flex;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  font-family: Poppins;
  background-color: #fff;
  align-items: center;
  align-items: center;
  border-radius: 6px;
  padding: 24px 150px;
  margin: ${({ isExamsPath }) =>
    isExamsPath ? "0px 180px 88px" : "92px 0px 0px"};
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    .details-btns {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  width: 456px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Details = styled.div`
  & .details-header {
    font-size: 24px;
    font-weight: 600;
    margin-top: 24px;
    text-align: left;
  }

  & .details-info {
    font-size: 16px;
    line-height: 1.5;
    color: #565656;
    margin-top: 10px;
  }

  & .details-btns {
    margin-top: 28px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SchoolButton = styled.button`
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 14px;
  text-align: center;
  line-height: 1.43;
  font-weight: bold;
  margin-right: 12px;
  border: none;
  outline: none;
  color: #ffff;
`;

export const ValuesList = styled.ul`
  margin-left: 20px;
  list-style-image: url(${Tick});
  margin-bottom: -12px;
  li {
    margin-bottom: 10px;
  }
`;

export const Icon = styled.img`
  margin-left: 10px;
`;

export const Message = styled.div`
  padding: 12px 0px;
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.67;
  color: #e21313;
  & .date {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const FilterButton = styled.button`
  height: 48px;
  padding: 12px 24px;
  border-radius: 36px;
  margin-right: 24px;
  border: solid 0.5px #d71a63;
  background-color: ${({ isActive }) => (isActive ? "#d71a63" : "transparent")};
  outline: none !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  line-height: 1.5;
  color: ${({ isActive }) => (isActive ? "#fff" : "#242424")};
  @media screen and (max-width: 767px) {
    padding: 12px;
  }
  @media screen and (max-width: 509px) {
    font-size: 10px;
  }
`;

export const ExamWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  justify-content: center;
`;
export const InfoWrapper = styled.div`
  padding: 8px 16px;
  border-radius: 12px;
  border: solid 0.3px rgba(70, 70, 70, 0.2);
  background-color: #fff;
  text-align: center;
  &:nth-child(1) {
    margin-right: 24px;
  }
  .label {
    font-size: 12px;
    line-height: 1.67;
    color: #464646;
  }
  .data {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: #242424;
  }
`;
export const RegistrationButton = styled(CommonButton)`
  opacity: ${({ disabled }) => (disabled ? "50%" : "100%")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const SwitchWrapper = styled.div`
  margin-top: clamp(2rem, 0.9615rem + 4.6154vw, 4.25rem);
  margin-bottom: clamp(2rem, 1.1923rem + 3.5897vw, 3.75rem);
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 34px;
`;
export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.$isExam ? "24px" : "0px")};
  justify-content: flex-start;
  margin-left: ${(props) => (props.$isExam ? "26px" : "0px")};

  @media screen and (max-width: 1162px) {
    margin-left: ${(props) => (props.$isExam ? "0px" : "0px")};
    /* padding-left: clamp(0rem, -22.0994rem + 44.1989vw, 10rem); */
    padding-left: clamp(0.625rem, -20.0932rem + 41.4365vw, 10rem);
  }

  @media screen and (max-width: 800px) {
    margin-left: ${(props) => (props.$isExam ? "0px" : "0px")};
  }
`;

export const OlympidaCardsWrapper = styled.div``;
export const HomePageContentWrapper = styled.div`
  line-height: 1.56;
  padding: 0px 85px;
  font-size: 16px;

  @media screen and (max-width: 1162px) {
    padding: 0px 0px;
  }

  @media screen and (max-width: 800px) {
    padding: 0px 24px 0px 12px;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: ${({ $isExamPage }) => $isExamPage && "white"};
  margin: ${({ $isExamPage }) => $isExamPage && "25px 0px 40px"};
  padding: ${({ $isExamPage }) => $isExamPage && "16px"};
  border-radius: ${({ $isExamPage }) => $isExamPage && "6px"};
`;

export const CarasoulContainer = styled.div`
  max-width: clamp(22.5rem, 0rem + 100vw, 50rem);
  height: 320px;
`;

export const OlympiadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
