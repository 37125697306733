import styled from "styled-components";
import GlobalContainer from "share/atoms/GlobalContainer";

export const SchoolContainer = styled.div`
  margin: 0 150px;
  @media screen and (max-width: 912px) {
    margin: 0 16px;
  }
`;
export const VeryLightPink = styled.div`
  background: #f9f9ff;
`;
export const WhiteBg = styled.div`
  background: #fff;
`;
export const Wrap = styled.div`
  background: #fff;
`;
export const BannerWrap = styled.div`
  background-color: #182441;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: 320px;
  }
`;
export const ResearchWrap = styled.div`
  background-color: #f9f9ff;
`;
export const Blur = styled.div`
  background-image: radial-gradient(
    circle at 24% 59%,
    #b265ff,
    rgba(14, 39, 128, 0.03) 100%
  );
  position: absolute;

  width: 600px;
  height: 200px;
  top: 35%;
  filter: blur(140px);
  @media screen and (min-width: 320px) {
    top: 7%;
    right: 5%;
  }
  @media screen and (min-width: 320px) {
    width: 300px;
    right: 5%;
  }
  @media screen and (min-width: 512px) {
    width: 350px;
    right: 25%;
  }
  @media screen and (min-width: 1060px) {
    top: 7%;
    right: 15%;
    filter: blur(120px);
  }
  @media screen and (width: 1080px) {
    top: 5%;
  }
`;

export const SchoolLandingLayout = styled(GlobalContainer)`
  margin-right: 0px;
  @media screen and (max-width: 820px) {
    padding: 0;
  }
  @media screen and (min-width: 821px) and (max-width: 912px) {
    padding: 20px;
  }
`;
