import styled from "styled-components";
import WebContainer from "share/atoms/container/";
const WrapContainer = styled.div`
  padding: 60px 0;
  background-color: #f9f9ff;
`;
const Wrap = styled.div`
  /* padding-top: 60px; */
  background-color: #f9f9ff;
  /* padding-bottom: 60px; */
`;
const Container = styled(WebContainer)`
  /* margin: 25px auto 120px auto; */
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  padding-bottom: 36px;
`;
const Heading = styled.div`
  display: flex;
  justify-content: center;
`;

const HeadingText = styled.div`
  font-family: Poppins;
  font-size: clamp(1.5rem, 1.2073rem + 1.4634vw, 2.25rem);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-top: 50px;
  margin-bottom: 20px;
`;
const StyledDiv = styled.div`
  /* height: 224px; */
  margin: 14px 24px 0 24px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;
const SubHeading = styled.div`
  /* width: 434px; */
  /* height: 29px; */
  margin: 24px 24px 0 24px;
  font-family: Poppins;
  font-size: ${(props) => (props.type === ("h" | "sh") ? "20px" : "16px")};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #d71a63;
  /* margin-bottom:10px; */
`;

export {
  WrapContainer,
  Container,
  Wrapper,
  Heading,
  HeadingText,
  StyledDiv,
  SubHeading,
  Wrap,
};
