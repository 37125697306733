import GlobalContainer from "share/atoms/GlobalContainer";
import styled from "styled-components";

export const Container = styled(GlobalContainer)`
  background-color: #ffffff;
  padding-top: clamp(1.5rem, 0.2308rem + 5.641vw, 4.25rem);
  padding-bottom: clamp(3.875rem, 3.2404rem + 2.8205vw, 5.25rem);
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Header = styled.div`
  text-align: center;
  & .header {
    font-size: 36px;
    font-weight: bold;
    color: #d71a63;
  }
  & .highlight {
    color: #242424;
  }
  & .content {
    margin-top: 12px;
    color: #464646;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 40px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* gap: 24px; */
  justify-content: space-between;
`;

export const CardWrapper = styled.div`
  width: 267px;
  height: 236px;
  background-color: #ffffff;
  box-shadow: 0 0 32px 0 rgba(221, 230, 237, 0.4);
  padding: 24px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 8;
`;

export const Logo = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #d71a63;
  margin-bottom: 8px;
  line-height: 1.4;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #464646;
`;

export const LeftTopImg = styled.img`
  position: absolute;
  top: -48px;
  left: -41px;
  z-index: -4;
`;

//------------------------------------
export const BackdropWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
