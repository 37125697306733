import React from "react";

export default function useSelectInputChange(initState, type) {
  const [value, setValue] = React.useState({...initState});
  const [formsList, setFormsList] = React.useState([{ ...initState }]);

  const intializingData = (name, value, index) => {
    const list = [...formsList];
    list[index][name] = value;
    setFormsList(list);
  };

  const handleChange = (e, data, key, index = 0) => {
    let val = formsList[index][key];
    if (val.includes("all") && e.includes("all") && data.length === e.length) {
      let arr = [];
         // eslint-disable-next-line array-callback-return
      e.map((item) => {
        if (item !== "all") arr.push(item);
      });
      setValue({ ...value, [key]: [...arr] });
      intializingData(key, [...arr], index);
    } else if (
      val.includes("all") &&
      e.includes("all") &&
      data.length === e.length - 1
    ) {
      setValue({ ...value, [key]: [] });
      intializingData(key, [], index);
    } else if (!e.includes("all") && data.length === e.length) {
      let arr = data.map((item) => `${item.value}~${item.label}`);
      setValue({ ...value, [key]: ["all", ...arr] });
      intializingData(key, ["all", ...arr], index);
    } else if (!val.includes("all") && e.includes("all")) {
      let arr = data.map((item) => `${item.value}~${item.label}`);
      setValue({ ...value, [key]: ["all", ...arr] });
      intializingData(key, ["all", ...arr], index);
    } else {
      setValue({ ...value, [key]: e });
      intializingData(key, e, index);
    }
  };


  const handleMonitorChange = (e, data, key, index = 0) => {
    let val = formsList[index][key];
    if (val.includes("all") && e.includes("all") && data.length === e.length) {
      let arr = [];
         // eslint-disable-next-line array-callback-return
      e.map((item) => {
        if (item !== "all") arr.push(item);
      });
      setValue({ ...value, [key]: [...arr] });
      intializingData(key, [...arr], index);
    } else if (
      val.includes("all") &&
      e.includes("all") &&
      data.length === e.length - 1
    ) {
      setValue({ ...value, [key]: [] });
      intializingData(key, [], index);
    } else if (!e.includes("all") && data.length === e.length) {
      let arr = data.map((item) => `${item.value}`);
      setValue({ ...value, [key]: ["all", ...arr] });
      intializingData(key, ["all", ...arr], index);
    } else if (!val.includes("all") && e.includes("all")) {
      let arr = data.map((item) => `${item.value}`);
      setValue({ ...value, [key]: ["all", ...arr] });
      intializingData(key, ["all", ...arr], index);
    } else {
      setValue({ ...value, [key]: e });
      intializingData(key, e, index);
    }
  }

  const handleInputChange = (e, index = 0) => {
    const { name, value: val } = e.target;
    setValue({ ...value, [name]: val });
    intializingData(name, val, index);
  };

  const handleSelect = (val, name, index = 0) =>
    intializingData(name, val, index);

  return {
    value,
    formsList,
    setFormsList,
    handleChange,
    handleInputChange,
    handleSelect,
    handleMonitorChange
  };
}
