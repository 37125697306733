import React, { useState } from "react";
import Modal from "share/organisms/modal";
import ExcelIcon from "assets/school-admin/icons/excel-upload.svg";
import styled from "styled-components";
import CloseImg from "assets/school-admin/icons/closeIcon.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaTimesCircle } from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  img {
    width: 96px;
    height: 96px;
    margin: 20px;
  }
`;

const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 10px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
`;

export const ErrorTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #e21313;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 12px;
  &::selection {
    background: red;
  }
`;

const ErrorDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  margin: 0 24px;
  max-height: 400px;
  overflow: auto;
  .heading {
    color: #e21313;
  }
  ul {
    font-size: 12px;
    li {
      margin: 5px 5px 8px 0;
      color: #242424;
      display: flex;
    }
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
`;

const Cross = styled.div`
  color: #e21313;
  margin-right: 8px;
`;

const UploadModal = ({ progress, onClose }) => {
  const [openModal, setOpenModal] = useState(true);

  const handleClose = () => {
    setOpenModal(false);
    onClose();
  };

  return (
    <div>
      <Modal
        open={openModal}
        onSubmit={handleClose}
        btnSet={{
          ok: {
            show: true,
            type: "primary",
          },
          cancel: {
            show: false,
            type: "outline",
          },
        }}
      >
        <Wrapper style={{ position: "relative" }}>
          <img src={ExcelIcon} alt="excel" />
          {progress === 100 && (
            <CloseBtn onClick={handleClose}>
              <img src={CloseImg} alt="close" />
            </CloseBtn>
          )}
          <StyledProgressBar
            className="coloredProgress"
            now={progress}
            style={{ width: "52%" }}
          ></StyledProgressBar>
          <ProgressDetails>
            <div>{progress === 100 ? "Successfull" : "Uploading"}</div>
            <div>{progress}%</div>
          </ProgressDetails>
        </Wrapper>
      </Modal>
    </div>
  );
};

const UploadErrorModal = ({ errorList, onClose }) => {
  const [openModal, setOpenModal] = useState(true);
  return (
    <div>
      <Modal
        open={openModal}
        onSubmit={() => setOpenModal(false)}
        btnSet={{
          ok: {
            show: true,
            type: "primary",
          },
          cancel: {
            show: false,
            type: "outline",
          },
        }}
      >
        <>
          <Wrapper style={{ position: "relative" }}>
            <CloseBtn
              onClick={() => {
                onClose();
                setOpenModal(false);
              }}
            >
              <img src={CloseImg} alt="close"/>
            </CloseBtn>
            <img src={ExcelIcon} alt="excel" />
            <ErrorTitle>Fill Error</ErrorTitle>
          </Wrapper>
          <ErrorDetailsWrapper>
            <div className="heading">Error info</div>
            <ul>
              {errorList &&
                errorList.map((item) => (
                  <li>
                    <Cross>
                      <FaTimesCircle />
                    </Cross>
                    {Object.keys(item)[0]}&nbsp;:&nbsp;
                    {item[Object.keys(item)[0]]}
                  </li>
                ))}
            </ul>
          </ErrorDetailsWrapper>
        </>
      </Modal>
    </div>
  );
};

export default UploadModal;
export { UploadErrorModal };
