import React from "react";

const LogoutIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M5 4C3.344 4 2 5.344 2 7v6c0 1.656 1.344 3 3 3h2.625c.188 0 .375-.156.375-.375v-.75c0-.188-.188-.375-.375-.375H5c-.844 0-1.5-.656-1.5-1.5V7c0-.813.656-1.5 1.5-1.5h2.625c.188 0 .375-.156.375-.375v-.75C8 4.187 7.812 4 7.625 4H5zm7.219.625l-.625.625c-.156.125-.156.375 0 .531l3.531 3.407h-7.75c-.219 0-.375.187-.375.374v.876c0 .218.156.374.375.374h7.75l-3.531 3.438c-.156.156-.156.406 0 .531l.625.625c.125.156.375.156.531 0l5.125-5.125c.156-.156.156-.375 0-.531L12.75 4.625c-.156-.156-.406-.156-.531 0z"
      />
    </svg>
  );
};

LogoutIcon.defaultProps = {
  fill: "#212226",
};
export default LogoutIcon;
