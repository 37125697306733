export const AnalyticsBaseAttributes = {
    PLATFORM: 'platform',
    OS_VERSION: 'osVersion',
    APP_VERSION: 'appVersion',
    UUID: 'uuid',
    STUDENT_ID: 'studentId',
    ADMISSION_NUMBER: 'admissionNo',
    EVENT_GROUP: 'eventGroup',
    TIMESTAMP: 'timeStamp',
    BROWSER_VERSION: 'browserVersion',
    NETWORK_TYPE: 'networkType',
  };
  
  export const AnalyticsEventType = {
    API_ERROR: 'apiError',
    API_SUCCESS: 'apiSuccess',
    USER_ACTION: 'userAction',
    SCREEN_TIME_SPENT: 'screenTimeSpent',
    SUBMIT_REPORT: 'submitReport',
  };
  
  export const AnalyticsEventGroup = {
    AUTHENTICATION: 'authentication',
    FORGOT_PASSWORD: 'forgotPassword',
    TABS_NAVIGATION: 'tabsNavigation',
    PROFILE: 'profile',
    GENERAL_ACTION: 'generalAction',
    HELP: 'help',
    PERFORMANCE: 'performance',
    PRACTICE: 'practice',
    SCHEDULED_TEST: 'scheduledTest',
    API: 'api',
    APP_ACTION: 'appAction',
    HOME: 'home',
    NLEARN_CORNER: 'nlearnCorner',
    CUMULATIVE_ANALYSIS: 'cumulativeAnalysis',
    VIDEO: 'video',
    TEST: 'test',
  };
  
  export const AppActionValues = {
    NETWORK_WIFI: 'networkWifi',
    NETWORK_MOBILE: 'networkMobile',
    NO_NETWORK: 'noNetwork',
    APP_OPEN: 'appOpen',
    APP_CLOSE: 'appClose',
  };
  
  export const ErrorModalConstants = {
    ERROR_MODAL: 'errorModalScreen',
    ERROR_MODAL_MOUNT: 'errorModal',
    ERROR_MODAL_GO_HOME: 'errorModalGoHome',
  };
  
  export const DownloadProgressModalConstants = {
    DOWNLOAD_PROGRESS_MODAL: 'downloadProgressModalScreen',
    DOWNLOAD_PROGRESS_MODAL_MOUNT: 'downloadProgressModalMount',
    DOWNLOAD_PROGRESS_MODAL_CANCEL: 'downloadProgressModalCancel',
  };
  
  export const ForgotPasswordNavigation = {
    // forgot password (these are values of keys)
    FORGOT_PASSWORD_ADMISSION_NO: 'forgotPasswordAdmissionNumScreen',
    FORGOT_PASSWORD_MOBILE_OR_EMAIL: 'forgotPasswordMobileOrEmailScreen',
    FORGOT_PASSWORD_MOBILE: 'forgotPasswordMobileScreen',
    FORGOT_PASSWORD_EMAIL: 'forgotpasswordEmailScreen',
    FORGOT_PASSWORD_ENTER_OTP: 'forgotPasswordEnterOtpScreen',
    FORGOT_PASSWORD_RESET_PASSWORD: 'forgotPasswordResetPasswordScreen',
    FORGOT_PASSWORD_CONGRATS: 'forgotPasswordCongrats',
  };
  
  export const ForgotPasswordUserActionArea = {
    // forgot password (these are values of keys)
    LOGIN: 'login',
    BACK_BUTTON: 'backButton',
    CONTINUE: 'continue',
    MOBILE: 'mobile',
    EMAIL: 'email',
    DROPDOWN: 'dropdown',
    GET_OTP: 'getOtp',
    RESEND_OTP: 'resendOtp',
    VERIFY: 'verify',
  };
  
  export const ForgotPasswordAttributes = {
    // forgot password (these are keys itself)
    ADMISSION_NO: 'admissionNo',
  };
  
  export const PerformanceNavigation = {
    // performance (these are keys of values)
    PERFORMANCE_TAB_SWITCH: 'performanceTabSwitch',
    PERFORMACE_VIEW_RESULT: 'performanceViewResultScreen',
    PERFORMANCE_TOPIC_ANALYSIS: 'performanceTopicAnalysisScreen',
    PERFORMANCE_ERROR_LIST: 'performanceErrorListScreen',
    PERFORMANCE_ERROR_LIST_DIALOG: 'performanceErrorListDialog',
    PERFORMANCE_ERROR_LIST_SECTION: 'performanceErrorListSection',
  };
  
  export const PerformanceUserActionArea = {
    // performance (these are keys of values)
    PERFORMANCE_DATE: 'date',
    PERFORMANCE_TEST_NAME: 'testName',
    PERFORMANCE_EXAM_MODEL: 'examModel',
    PERFORMANCE_AIR_RANK: 'airRank',
    PERFORMANCE_SCORE: 'score',
    PERFORMANCE_VIEW_RESULT: 'testViewResult',
    PERFORMANCE_VIEW_TOPIC_ANALYSIS: 'viewTopicAnalysis',
    PERFORMANCE_VIEW_ERROR_LIST: 'viewErrorList',
    PERFORMANCE_BACK_BUTTON: 'backButton',
    PERFORMANCE_ALL_QUESTION: 'allQuestion',
    PERFORMANCE_ERRORS_ONLY: 'errorsOnly',
    PERFORMANCE_SKIPPED_ONLY: 'skippedOnly',
    PERFORMANCE_TOUGH_QUESTION_YOU_GOT_CORRECT: 'toughQuestionYouGotCorrect',
    PERFORMANCE_EASY_QUESTION_YOU_DIDNT_GET_CORRECT:
      'easyQuestionYouDidNtGetCorrect',
    PERFORMANCE_QUESTION_NUMBER: 'questionNumber',
    PERFORMANCE_STATUS: 'status',
    PERFORMANCE_DIFFICULTY_LEVEL: 'difficultyLevel',
    PERFORMANCE_SUBJECT_NAME: 'subjectName',
    PERFORMANCE_ERROR_LIST_ITEM: 'errorListItem',
    PERFORMANCE_ERROR_LIST_DIALOG_CLOSE_BUTTON: 'errorListDialogCloseButton',
    PERFORMANCE_ERROR_LIST_DIALOG_EXPLANATION_LIKED:
      'errorListDialogExplanationLiked',
    PERFORMANCE_ERROR_LIST_DIALOG_EXPLANATION_DISLIKED:
      'errorListDialogExplanationDisliked',
  };
  
  export const PerformanceAttributes = {
    EXAM_CATEGORY: 'examCategory',
    TEST_ID: 'testId',
    DELIVERY_ID: 'deliveryId',
    ITEM_ID: 'itemId',
    STUDENT_RESPONSE: 'studentResponse',
    ANSWER_KEY: 'answerKey',
    RESPONSE_ID: 'responseId',
    TEST_NAME: 'testName',
    ERROR_LIST_QUESTION_NUMBER: 'errorListQuestionNumber',
    ERROR_LIST_QUESTION_STATUS: 'errorListQuestionStatus',
  };
  
  export const ProfileNavigation = {
    // profile (these are values of keys)
    PROFILE_PERSONAL_INFO: 'profilePersonalInfo',
    PROFILE_PERSONAL_INFO_REPORT_ERROR_DIALOG:
      'profilePersonalInfoReportErrorDialog',
    PROFILE_COLLEGE_INFO: 'profileCollegeInfo',
    PROFILE_COLLEGE_INFO_REPORT_ERROR_DIALOG:
      'profileCollegeInfoReportErrorDialog',
    PROFILE_SET_PASSWORD: 'profileSetPassword',
  };
  
  export const ProfileUserActionArea = {
    // profile (these are values of keys)
    PERSONAL_INFO: 'personalInfo',
    COLLEGE_INFO: 'collegeInfo',
    SET_PASSWORD: 'setPassword',
    REPORT_ERROR: 'reportError',
    SAVE: 'save',
    SUBMIT_REPORT: 'submitReport',
    CLOSE: 'close',
  };
  
  // auth navigation
  export const AuthenticationNavigation = {
    // login screen (these are values of keys)
    LOGIN_SCREEN: 'loginScreen',
    // confirm detail (these are values of keys)
    CONFIRM_DETAIL: 'confirmDetailScreen',
    SET_PASSWORD: 'setPasswordScreen',
    LOGOUT_DIALOG: 'logoutDialog',
    // logout (these are values of keys)
  };
  
  // auth user action area
  export const AuthenticationUserActionArea = {
    // login screen (these are values of keys)
    FORGOT_PASSWORD: 'forgotPassword',
    SHOW: 'show',
    HIDE: 'hide',
    LOGIN: 'login',
  
    // confirm detail (these are values of keys)
    ADD: 'add',
    REMOVE: 'remove',
    CANCEL: 'cancel',
    SAVE: 'save',
    CONFIRM_AND_CONTINUE: 'confirmAndContinue',
  
    // set password
    CONTINUE: 'continue',
    BACK_BUTTON: 'backButton',
  
    // logout (these are values of keys)
    YES: 'yes',
    NO: 'no',
  };
  
  // help navigation
  export const HelpNavigation = {
    // help (these are values of keys)
    HELP_FAQS: 'helpFaqsScreen',
    HELP_NLEARN_BENEFITS: 'helpNLearnBenefitsScreen',
    HELP_DEMO_VIDEO: 'helpDemoVideoScreen',
    HELP_REQUEST_A_DEMO: 'helpRequestADemoScreen',
    HELP_CONTACT_US: 'helpContactUs',
  };
  
  // help user action area
  export const HelpUserActionArea = {
    // help (these are values of keys)
    FAQS: 'faqs',
    NLEARN_BENEFITS: 'nLearnBenefits',
    DEMO_VIDEO: 'demoVideo',
    REQUEST_A_DEMO: 'requestADemo',
    CONTACT_US: 'contactUs',
    SUBMIT: 'submit',
    ADD_SCREENSHOT: 'addScreenshot',
    REMOVE_SCREENSHOT: 'removeScreenshot',
    HAVE_YOU_READ_OUR_FAQ_YET: 'haveYouReadOurFaqYet',
  };
  
  // home navigation
  export const HomeScreenNavigation = {
    // home screen (these are values of keys)
    HOME: 'homeScreen',
    HOME_HOME: 'homeHomeScreen',
    HOME_TEST: 'homeTestScreen',
    HOME_PRACTICE: 'homePracticeScreen',
    HOME_PERFORMANCE_LOCKED: 'homePerformanceLockedScreen',
    HOME_PERFORMANCE_UNLOCKED: 'homePerformanceUnLockedScreen',
    HOME_VIDEO: 'homeVideo',
  };
  
  // home user action area
  export const HomeScreenUserActionArea = {
    // home screen (these are values of keys)
    TAB_CHANGE: 'tabChange',
    DROPDOWN: 'dropdown',
    DROPDOWN_ITEM: 'dropdownItem',
  };
  
  // practice navigation
  export const PracticeNavigation = {
    // practice (these are values of keys)
    PRACTICE_CHAPTER: 'practiceChapterScreen',
    PRACTICE_TOPIC: 'practiceTopicScreen',
    PRACTICE_TOPIC_LEARN: 'practiceTopicLearn',
    PRACTICE_TOPIC_LEARN_READ_DIALOG: 'practiceTopicLearnReadDialog',
    PRACTICE_TOPIC_LEARN_WATCH_DIALOG: 'practiceTopicLearnWatchDialog',
    PRACTICE_TOPIC_EXERCISE: 'practiceTopicExercise',
    PRACTICE_CHAPTER_EXERCISE: 'practiceChapterExercise',
    PRACTICE_TOPIC_EXERCISE_SUMMARY: 'practiceTopicExerciseSummary',
    PRACTICE_CHAPTER_EXERCISE_SUMMARY: 'practiceChapterExerciseSummary',
    PRACTICE_TOPIC_EXERCISE_SUMMARY_DIALOG: 'practiceTopicExerciseSummaryDialog',
    PRACTICE_CHAPTER_EXERCISE_SUMMARY_DIALOG:
      'practiceChapterExerciseSummaryDialog',
    PRACTICE_TOPIC_EXERCISE_REPORT_ERROR_DIALOG:
      'practiceTopicExerciseReportErrorDialog',
    PRACTICE_CHAPTER_EXERCISE_REPORT_ERROR_DIALOG:
      'practiceChapterExerciseReportErrorDialog',
  };
  
  // practice user action area
  export const PracticeUserActionArea = {
    // PRACTICE (these are keys of values)
    CHAPTER_ITEM: 'chapterItem',
    SUBJECT_CHAPTER_ITEM: 'subjectChapterItem',
    LEARN: 'learn',
    EXERCISE: 'exercise',
    CHAPTER_EXERCISE: 'chapterExercise',
    WATCH: 'watch',
    READ: 'read',
    NEXT: 'next',
    PREVIOUS: 'previous',
    CLOSE_BUTTON: 'closeButton',
    TOPIC_EXERCISE_DROPDOWN: 'topicExerciseDropdown',
    CHAPTER_EXERCISE_DROPDOWN: 'chapterExerciseDropdown',
    STAsuRT: 'start',
    RESUME: 'resume',
    HISTORY: 'history',
    EXIT: 'exit',
    SEE_QUESTIONS: 'seeQuestion',
    SKIP: 'skip',
    SUBMIT: 'submit',
    EXPLANATION_LIKED: 'explanationLiked',
    EXPLANATION_DISLIKED: 'explanationDisliked',
    REPORT_ERROR: 'reportError',
    VIEW_REPORT: 'viewReport',
    BACK_BUTTON: 'backButton',
    SUCCESS: 'success',
    FAILURE: 'failure',
  };
  
  // practice attributes
  export const PracticeAttributes = {
    // practice (these are keys itself)
    CATEGORY_NAME: 'categoryName',
    SUBJECT_ID: 'subjectId',
    SUBJECT_NAME: 'subjectName',
    TOPIC_ID: 'topicId',
    CHAPTER_ID: 'chapterId',
    SYNOPSIS_ID: 'synopsisId',
    SYNOPSIS_ITEM_ID: 'synopsisItemId',
    RESPONSE_ID: 'responseId',
    ITEM_URI: 'itemUri',
    QUESTION_ID: 'questionId',
    QUESTION_STATUS: 'questionStatus',
    PRACTICE_FORMAT_ID: 'practiceFormatId',
    QUESTION_TYPE: 'questionType',
    CHAPTER_NAME: 'chapterName',
  };
  
  // test navigation
  export const TestNavigation = {
    // scheduledTest (these are values of keys)
    TEST_INSTRUCTION: 'testInstructionScreen',
    TEST_ENGINE: 'testEngineScreen',
    TEST_ENGINE_DOWNLOAD_DIALOG: 'testEngineDownloadDialog',
    TEST_ENGINE_INSTRUCTION_DIALOG: 'testEngineInstructionDialog',
    TEST_ENGINE_SECTION: 'testEngineSection',
    TEST_ENGINE_VIEW_QUESTION_PAPER_DIALOG: 'testEngineViewQuestionPaperDialog',
    TEST_ENGINE_VIEW_QUESTION_PAPER_DIALOG_SECTION:
      'testEngineViewQuestionPaperDialogSection',
  
    // scheduledTestSubmit
    TEST_ENGINE_EXAM_SUMMARY: 'testEngineExamSummaryScreen',
  
    // scheduledTestThankYou
    TEST_ENGINE_THANK_YOU: 'testEngineThankYouScreen',
  
    // scheduledTestResult
    TEST_ENGINE_RESULT: 'testEngineResultScreen',
    TEST_ENGINE_RESULT_REPORT_ERROR_DIALOG: 'testEngineResultReportErrorDialog',
    TEST_ENGINE_RESPONSE_SHEET: 'viewUserAnswersScreen',
    TEST_ENGINE_PAPER1_RESULT: 'testEnginePaper1ResultScreen',
    TEST_ENGINE_PAPER2_RESULT: 'testEnginePaper2ResultScreen',
    TEST_ENGINE_OVERALL_RESULT: 'testEngineOverallResultScreen',
  
    // scheduledFeedback
    TEST_ENGINE_FEEDBACK: 'testEngineFeedbackScreen',
  
    TEST_ENGINE_TIMEOUT_DIALOG: 'testEngineTimeOutDialog',
    TEST_ENGINE_DEVICE_TIME_CHANGE_DIALOG: 'testEngineDeviceTimeChangeDialog',
    TEST_ENGINE_SYNC_FAILURE_DIALOG:'testEngineSyncFailureDialog',
    TEST_ENGINE_ERROR_DIALOG:'testEngineErrorDialog',
    TEST_ENGINE_QUESTION_DOWNLOAD_RETRY_DIALOG:'testEngineQuestionDownloadRetryDialog'
  };
  
  // test user action area
  export const TestUserActionArea = {
    START: 'start',
    RESUME: 'resume',
    CHECKBOX_ENABLE: 'checkboxEnable',
    CHECKBOX_DISABLE: 'checkboxDisable',
    PROCEED: 'proceed',
    BACK_BUTTON: 'backButton',
    CANCEL_DOWNLOAD: 'cancelDownload',
    TRY_AGAIN: 'tryAgain',
    CANCEL: 'cancel',
    SAVE_AND_NEXT: 'saveAndNext',
    SAVE_AND_MARK_FOR_REVIEW: 'saveAndMarkForReview',
    CLEAR_RESPONSE: 'clearResponse',
    QUESTION_BACK: 'questionBack',
    QUESTION_NEXT: 'questionNext',
    SUBMIT: 'submit',
    SECTION_CHANGE: 'sectionChange',
    QUESTION_NUMBER_CLICK: 'questionNumberClick',
    EXPAND_VIEW: 'expandView',
    COLLAPSE_VIEW: 'collapseView',
    SYNC: 'sync',
    INSTRUCTION: 'instruction',
    VIEW_QUESTION_PAPER: 'viewQuestionPaper',
    VIEW_QUESTION_PAPER_SECTION_CHANGE: 'viewQuestionPaperSectionChange',
    CLOSE_BUTTON: 'closeButton',
    YES: 'yes',
    NO: 'no',
    VIEW_RESULT: 'viewResult',
    RESPONSE_SHEET: 'viewUserAnswers',
    REPORT_ERROR: 'reportError',
    SUBMIT_REPORT: 'submitReport',
    LOGOUT: 'logout',
    SEND_FEEDBACK: 'sendFeedback',
    SUBMIT_FEEDBACK: 'submitFeedback',
    EXIT: 'exit',
    START_TEST: 'startTest',
    RESULT_TAB: 'resultsTab',
    ANALYTICS_TAB: 'analyticsTab',
    OVERALL_RESULT: 'overallResult',
    TIMEOUT_SUBMIT: 'timeoutSubmit',
    TIME_CHANGE_REFRESH: 'timeChangeRefresh',
    DOWNLOAD_STARTED:'downloadStarted',
    FIRST_SYNC:'firstSync',
    ERROR_DIALOG:'errorDialog',
    GO_HOME:'goHome',
    QUESTION_DOWNLOAD_RETRY:'questionDownloadRetry'
  };
  
  // test attributes
  export const TestAttributes = {
    // scheduledTest (these are keys itself)
    TEST_ID: 'testId',
    DELIVERY_ID: 'deliveryId',
    EXAM_STATE: 'examState',
    TEST_URI_ID: 'testUriId',
    IS_FIRSTTIME_SYNC_COMPLETED: 'isFirstTimeSyncCompleted',
    QUESTION_ID: 'questionId',
    RESPONSE_ID: 'responseId',
    QUESTION_ANSWER: 'questionAnswer',
    QUESTION_STATE: 'questionState',
    TIME_TAKEN: 'timeTaken',
    EXAM_CATEGORY: 'examCategory',
    TEST_NAME: 'testName',
    QUESTION_TYPE: 'questionType',
    EXAM_SECTION: 'examSection',
    SYNC_SUCCESS: 'syncSuccess',
    SYNC_FAILURE: 'syncFailure',
    PREVIOUS_ANSWERS_SUCCESS: 'previousAnswersSuccess',
    PREVIOUS_ANSWERS_FAILURE: 'previousAnswersFailure',
  };
  
  // api erros
  export const AnalyticsApiErrorAttributes = {
    READ_TIME: 'readTime',
    WRITE_TIME: 'writeTime',
    ERROR_CODE: 'errorCode',
    HEADERS: 'headers',
    API_NAME: 'apiName',
    ERROR_MESSAGE: 'errorMessage',
  };
  
  // event types
  export const AnalyticsFilterAttributes = {
    EVENT_TYPE: 'eventType',
    USER_ACTION_KEY: 'userActionKey',
    USER_NAVIGATION_KEY: 'userNavigationKey',
    SCREEN_TIME_SPENT: 'screenTimeSpent',
  };
  
  // common attributes
  export const AnalyticsEndAttributes = {
    USER_ACTION_AREA: 'userActionArea',
    FROM_NODE: 'fromNode',
    TO_NODE: 'toNode',
    VALIDATION_ERROR: 'validationError',
    SCREEN_TIME_SPENT: 'screenTimeSpent',
  };
  
  // navigation type
  export const AnalyticsNavigationType = {
    NAVIGATION: 'navigation',
    IN_SCREEN_NAVIGATION: 'inScreenNavigation',
    SUB_NAVIGATION: 'subNavigation',
  };
  
  // user action types
  export const AnalyticsUserActionKeyType = {
    CLICK: 'click',
    SCROLL_UP: 'scrollUp',
    SCROLL_RIGHT: 'scrollRight',
    SCROLL_LEFT: 'scrollLeft',
    SCROLL_DOWN: 'scrollDown',
    ZOOM_IN: 'zoomIn',
    ZOOM_OUT: 'zoomOut',
    SWIPE_LEFT: 'swipeLeft',
    SWIPE_RIGHT: 'swipeRightt',
    SWIPE_UP: 'swipeUp',
    SWIPE_DOWN: 'swipeDown',
    APP_ACTION: 'appAction',
  };
  
  // home navigation
  export const HomeNavigation = {
    // home (these are values of keys)
    FEATURE_NOT_AVAILABLE_DIALOG: 'homeHomeScreenDialog',
  };
  
  export const HomeUserActionArea = {
    // home (these are values of keys)
    START_TEST: 'startTest',
    RESUME: 'resume',
    CLICK_TO_SEE: 'clickToSee',
    VIEW_RESULT: 'viewResult',
    START_PRACTICE: 'startPractice',
    SIDEBAR_ITEM: 'sidebarItem',
  };
  
  export const HomeAttributes = {
    // home (these are keys itself)
  
    // common
    TEST_ID: 'testId',
    DELIVERY_ID: 'deliveryId',
  
    // for test specific
    EXAM_STATE: 'examState',
    TEST_URI_ID: 'testUriId',
  
    // for practice specific
    SUBJECT_ID: 'subjectId',
    TOPIC_ID: 'topicId',
    CHAPTER_ID: 'chapterId',
  
    // for performance specific
    EXAM_CATEGORY: 'examCategory',
  };
  
  // nlearn corner navigation
  export const NLearnCornerNavigation = {
    // home (these are values of keys)
    NLEARN_CORNER_HOME_SCREEN: 'nlearnCornerHomeScreen',
    NLEARN_CORNER_DETAIL_SCREEN: 'nlearnCornerDetailScreen',
    REPORT_CARD_PRACTICE_SCREEN: 'reportCardPracticeScreen',
    REPORT_CARD_TEST_SCREEN: 'reportCardTestScreen',
    TOPIC_ANALYSIS_SCREEN: 'topicAnalysisScreen',
  };
  
  export const NLearnCornerUserActionArea = {
    // home (these are values of keys)
    NLEARN_CORNER: 'nlearnCorner',
    BACK_BUTTON: 'backButton',
    NLEARN_CORNER_CARD: 'nlearnCornerCard',
    NLEARN_CORNER_CARD_FAVORITE: 'nlearnCornerCardFavorite',
    LOAD_MORE: 'loadMore',
    FEEDBACK_LIKE: 'feedbackLike',
    FEEDBACK_DISLIKE: 'feedbackDislike',
  };
  
  export const NLearnCornerAttributes = {
    ITEM_ID: 'itemId',
    CATEGORY_ID: 'categoryID',
  };
  
  export const CumulativeTopicAnalysisActionArea = {
    BACK_BUTTON: 'backButton',
    CHOOSE_SUBJECT_DROPDOWN: 'chooseSubjectDropdown',
    CHAPTER_TEST: 'chapterTest',
    CHAPTER_PRACTICE: 'chapterPractice',
    CHAPTER_EXPAND: 'chapterExpand',
    CHAPTER_COLLAPSE: 'chapterCollapse',
  };
  
  export const CumulativeTopicAnalysisAttributes = {
    SUBJECT_ID: 'subjectId',
    ANALYSIS_TYPE: 'analysisType',
    CHAPTER_ID: 'chapterId',
    CHAPTER_NAME: 'chapterName',
  };
  //test screen navigation
  export const TestScreenNavigation = {
    // test screen (these are values of keys)
    HOME_TEST: 'homeTestScreen',
    MODEL_LIST: 'testSeriesScreen',
    ACTIVE_TEST: 'activeTestScreen',
    ATTEMPTED_TEST: 'attemptedTestScreen',
    MISSED_TEST: 'missedTestScreen',
    SYLLABUS: 'testSyllabusScreen',
    EXAM_CATEGORY: 'examCategory',
    RESULT: 'testEngineResultScreen',
    OVERALL_RESULT: 'overallResultScreen',
    PERFORMANCE: 'performanceScreen',
    RESPONSE: 'viewUserAnswersScreen',
    ERROR_LIST: 'performanceErrorListScreen',
    TOPICWISE_ANALYSIS: 'performanceTopicAnalysisScreen',
    PAPER1_RESULT: 'paper1Screen',
    PAPER2_RESULT: 'paper2Screen',
  };
  
  //test screen action area
  export const TestScreenActionArea = {
    TAKE_TEST: 'takeTest',
    PICK_SUBJECT: 'pickSubject',
    EXAM_TYPE_TAB: 'examTypeTabChange',
    MODEL_CARD: 'modelCard',
    ATTEMPTED_EXAM_ITEM: 'attemptedExamItem',
    ACTIVE_EXAM_ITEM: 'activeExamItem',
    MISSED_EXAM_ITEM: 'missedExamItem',
    START_TEST: 'startTest',
    OVERALL_RESULT: 'overallResultItem',
    PAPER1_RESULT: 'paper1OverallResultItem',
    PAPER2_RESULT: 'paper2OverallResultItem',
    RESULT_TYPE_TAB: 'resultTypeTabChange',
    ANALYTICS_TAB: 'analayticsTabChange',
    BACK_BUTTON: 'backButton',
    VIEW_TOPIC_ANALYSIS: 'viewTopicAnalysis',
    VIEW_ERROR_LIST: 'viewErrorlist',
    SUBJECT_DROPDOWN: 'chooseSubjectDropdown',
    LEVEL_SELECTION_CHECKBOX: 'selectLevelCheckbox',
    TOGGLE_TOPIC_WISE: 'toggleTopicWise',
  };
  export const VideoScreenNavigation = {
    CHAPTER_WISE_VIDEOS: 'chapterWiseVideoScreen',
    CHAPTERS: 'chaptersVideoScreen',
    TOPIC_WISE_VIDEOS: 'topicWiseVideoScreen',
    WATCH_VIDEO_SCREEN: 'watchVideoScreen',
  };
  export const VideoActionArea = {
    START: 'start',
  };
  export const VideoAttributes = {
    SUBJECT_ID: 'subjectId',
    SUBJECT_NAME: 'subjectName',
    CHAPTER_ID: 'chapterId',
    CHAPTER_NAME: 'chapterName',
    CONTENT_ID: 'contentId',
    CONTENT_NAME: 'contentName',
    REFERENCE: 'reference',
    SOURCE: 'source',
  };
  export const MultichapterActionArea = {
    CREATE_TEST_BUTTON: 'createTestButton',
    VIEW_ATTEMPTED: 'viewAttempted',
    START_TEST: 'startTest',
    NEXT_BUTTON: 'nextButton',
    ATTEMPTED_EXAM_ITEM: 'attemptedExamItem',
    RESUME: 'resume',
  };
  export const MultiChapterNavigation = {
    // multi-chapter tests
    INSTRUCTIONS: 'multiChapterInstructionsScreen',
    SELECT_SUBJECT: 'multiChapterSelectSubjectScreen',
    SELECT_CHAPTER: 'multiChapterSelectChapterScreen',
    SELECT_FORMAT: 'multiChapterSelectFormatScreen',
    REVIEW: 'multiChapterReviewScreen',
    VIEW_ATTEMPTED: 'multiChapterViewAttemptedScreen',
  };
  