import { SingleInfo } from "student/LandingPage/Exams/styles";
import styled, { css } from "styled-components";
import Tick from "assets/exams/IconTick.svg";
import WebContainer from "share/atoms/container/";
import DefaultButton from "share/atoms/Button";
// import Thumbnail from "assets/demo-thumbnail.jpg";

const LargeDiv = styled(WebContainer)`
  display: flex;
  flex-flow: column;
  margin: 37px auto;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
`;
const SubHeading = styled.div`
  margin: 10px 35px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #d71a63;
  @media (min-width: 764px) and (max-width: 1139px) {
    height: 100%;
  }
  @media screen and (max-width: 600px) {
    margin: 10px 10px;
  }
`;
const Title = styled.div`
  /* height: 29px; */
  margin: 15px 35px 0 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;
const Div1 = styled.div`
  width: 100%;
  margin: 13px 35px 13px 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.75; */
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`;
const ValuesList = styled.ul`
  margin-left: 20px;
  margin-bottom: 20px;
  list-style-image: url(${Tick});
  li {
    margin-bottom: 5px;
    padding-left: 5px;
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
  width: 100%;
`;
const MainHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-left: 40px;
    width: 210px;
    height: 108px;
  }

  @media screen and (max-width: 645px) {
    img {
      margin-left: 0px;
      margin-top: 25px;
      width: 168px;
      height: auto;
    }
    flex-flow: column;
  }
`;
const LeftDesc = styled.div`
  /* width:50%; */
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: 70%;
  @media screen and (max-width: 815px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 414px) {
    margin-left: 10px;
    width: 60%;
  }
  @media screen and (max-width: 375px) {
    width: 70%;
  }
  @media (min-width: 764px) and (max-width: 1139px) {
    height: 100%;
  }
`;

export const Price = styled.div`
  display: flex;
  & .price {
    @media screen and (max-width: 600px) {
      font-size: 20px;
      display: flex;
      svg {
        font-size: 20px;
      }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1139px) {
    font-size: 20px;
    margin-right: 70px;
    width: 70px;
    svg {
      font-size: 20px;
    }
    h1 {
      display: flex;
    }
    .price {
      font-size: 20px;
    }
  }

  h2 {
    position: absolute;
    bottom: -10%;
    left: -25%;
  }
  h1 {
    font-size: 42px;
    font-weight: 900;
    margin-top: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.76;
    letter-spacing: normal;
    text-align: right;
    flex-wrap: wrap;
    color: #242424;
  }
`;
const RightDesc = styled.div`
  display: flex;
  height: 150px;
  margin: 0px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  width: 20%;
  @media screen and (max-width: 815px) {
    width: 50%;
  }
  @media screen and (max-width: 414px) {
    width: 40%;
  }
  @media screen and (max-width: 375px) {
    width: 40%;
  }
  @media (min-width: 600px) and (max-width: 1139px) {
    /* height: 100%; */
    b {
      margin-right: 80px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 10px 10px;
    b {
      padding-bottom: 5px;
    }
  }
`;

export const StyledButton = styled(DefaultButton)`
  opacity: ${({ disabled }) => (disabled ? "50%" : "100%")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 9px 14px;
  margin-top: 16px;
`;

const ExamName = styled.div`
  width: 100%;
  padding-top: 32px;
  height: auto;
  font-family: Poppins;
  font-size: clamp(1.25rem, 1.1524rem + 0.4878vw, 1.5rem);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  @media screen and (max-width: 645px) {
    display: none;
  }
  @media screen and (min-width: 646px) {
    padding-top: 60px;
  }
`;
const Deatils = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  color: #464646;
  justify-content: flex-start;
  font-size: 20px;
  b {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  @media screen and (max-width: 815px) {
    flex-flow: column;
    margin-top: 4px;
  }
`;
const Class = styled.div`
  width: 39%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 22px;
    margin-top: 46px;
  }
  @media screen and (min-width: 600px) and (max-width: 1139px) {
    width: 190px;
  }
`;
const Exam = styled.div`
  b {
    width: 120px;
    margin-left: 40px;
  }
  display: flex;
  align-items: center;
`;
const DescrTable = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  padding: 10px;
  @media screen and (max-width: 600px) {
    margin: 0px 0px;
  }
  p {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  table {
    width: 100%;
    height: 117px;
    margin-top: 20px;
    padding: 0 0 0 1px;
    border-radius: 6px;
    border: solid 1px #c6c6cf;
    background-color: #ffffff;
    border-collapse: collapse;
    @media (min-width: 764px) and (max-width: 1139px) {
      height: 137px;
    }
    th {
      border: solid 1px #c6c6cf;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 800;
      text-align: center;
    }
    td {
      border: solid 1px #c6c6cf;
      border-radius: 6px;
      text-decoration: underline;
      align-items: center;
      text-align: center;

      a {
        color: black;
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  font-family: Poppins;
  font-size: 16px;
  line-height: 1.5;
  color: #e21313;
  margin: 16px 0;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: rgba(255, 220, 233, 0.3);
  .info {
    font-weight: bold;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin: 36px 0;
  @media screen and (max-width: 600px) {
    margin: 20px 0;
    flex-direction: row;
  }
`;
export const TableHeading = styled.div`
  border: solid 0.5px #c6c6cf;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  width: 50%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #242424;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const TableContent = styled(TableHeading)`
  a {
    color: #464646;
    border-bottom: 1.6px solid black;
    padding-bottom: 3px;
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const TableContentWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Registration = styled.div`
  display: flex;
  height: 228px;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  margin: 5px auto;
  ${(props) =>
    props.isHome &&
    css`
      max-width: 948px;
    `}
  @media  screen and  (max-width: 600px) {
    height: 100%;
    flex-flow: column;
    padding: 40px 0;
  }
`;
export const Container = styled(WebContainer)`
  @media (min-width: 764px) and (max-width: 1139px) {
    width: 728px;
    margin: auto;
  }
`;

export const RegImg = styled.img``;
export const RegContent = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  color: #565656;
  max-width: 60%;
  @media screen and (max-width: 600px) {
    max-width: 82%;
    text-align: center;
  }
`;
export const RegTitle = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-bottom: 12px;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-top: 10px;
  }
`;
export const RegFooter = styled.div`
  padding: 12px 34px 15px 30px;
  border-radius: 40px;
  border: solid 1px #f99ec1;
  background-color: #fed6e5;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: center;
  color: #950b3e;
  margin-top: 36px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 15px;
  @media screen and (max-width: 600px) {
    flex-flow: row;
    margin-left: 0px;
  }
`;
export const ICON = styled.img`
  width: 210px;
  height: 108px;
  /* margin-left:30px; */
  @media screen and (min-width: 645px) and (max-width: 1139px) {
    width: 80px !important;
  }
`;

export const Benefits = styled.div`
  max-width: 100%;
  padding: 0 30px;
  @media screen and (max-width: 600px) {
    padding: 0 10px;
    padding-bottom: 20px;
  }
`;
export const Analytics = styled.div`
  max-width: 100%;
  padding: 0 30px;
  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
`;
export const FlexWrap = styled.div`
  display: flex;
`;
export const ExamNameMobile = styled.div`
  display: none;
  width: 100%;
  height: auto;
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  padding: 5px 30px;
  @media screen and (max-width: 645px) {
    display: block;
    padding-left: 10px;
    text-align: center;
  }
`;
export const StyledSingleInfo = styled(SingleInfo)`
  margin-top: 0px;
  width: 100px;
  flex-wrap: wrap;
`;

export const MessageWrapper = styled.div`
  background: green;
  text-align: right;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 12px 0;
    margin-right: 50px;
    width: 100%;
  }
`;
export const Message = styled.div`
  padding: 12px 0px;
  font-family: Poppins;
  font-size: 12px;
  line-height: 1.67;
  color: #e21313;
  margin-bottom: 10px;
  & .date {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
  }
`;

export const HelpfulWrapper = styled.div`
  margin: 0px 35px 24px;
`;
export const Heading = styled.div`
  font-family: Poppins;
  font-size: 24px;
  font-weight: bold;
  color: #d71a63;
`;

export const VideosWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .intro {
    font-size: 14px;
    line-height: 2;
    color: #d71a63;
    font-weight: 500;
  }
  .para {
    font-size: 16px;
    font-weight: 600;
    /* line-height: 1.75; */
    line-height: 1.5;
    color: #464646;
    margin: 1px 0px 12px;
  }

  .time {
    font-size: 14px;
    line-height: 2;
    color: #464646;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const IntroductionVideoWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #f6f6f6;
  width: 48%;
  padding: 24px;
  .video-container {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
  }
  .thumbnail {
    width: 224px;
    height: 124px;
    border-radius: 4px;
  }
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 1140px) {
    flex-wrap: wrap;
    .thumbnail {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const SampleVideoWrapper = styled(IntroductionVideoWrapper)``;

export const Hightlight = styled.div`
  border-radius: 4px;
  box-shadow: 0 20px 20px -20px rgba(253, 176, 89, 0.25);
  background-image: linear-gradient(96deg, #ffe985 0%, #fa742b);
  /* font-size: 18px; */
  font-size: clamp(0.5625rem, 0.3029rem + 1.1538vw, 1.125rem);
  font-weight: bold;
  line-height: 1.71;
  color: #553517;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export {
  LargeDiv,
  Class,
  DescrTable,
  Exam,
  SubHeading,
  Div1,
  ValuesList,
  Title,
  MainHeadingWrapper,
  LeftDesc,
  RightDesc,
  ExamName,
  Deatils,
};
