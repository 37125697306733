import styled, { keyframes } from "styled-components";
import GlobalContainer from "share/atoms/GlobalContainer";
import * as modes from "utils/modes";

const timeInterval = "5s";

export const Flex = styled.div`
  display: flex;
  align-items: ${(props) => (props.$align ? props.$align : "center")};
  justify-content: ${(props) => (props.$justify ? props.$justify : "center")};
  flex-direction: ${({ $direction }) => ($direction ? $direction : "row")};
`;

export const Container = styled(GlobalContainer)`
  background: #182441;
  
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
  padding: 10px 0px 0px;
  min-height: 536px;
  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    flex-direction: column;
    padding: 48px 16px;
  }
`;
export const LeftContainer = styled.div`
  width: 55%;
  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 45%;
  align-self: flex-end;

  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ArrowAnimation = keyframes`
    0% { transform:scale(1) }
    50% { transform:scale(1.5)}
    100% { transform:scale(1)}
`;

export const ArrowSection = styled.div`
  text-align: center;
  animation-name: ${ArrowAnimation};
  animation-duration: ${timeInterval};
  animation-iteration-count: infinite;
  position: relative;

  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 10%;
    & .arrow-hero {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      transform: rotate(70deg);
    }
  }
`;
export const ContentAnimation = keyframes`
    0%{transform: translateY(100px)}
    50%{transform:translateY(10px)}
    100%{transform:translateY(100px)}
`;

export const TitleAnimation = keyframes`
    0%{transform: translateY(45px)}
    50%{transform:translateY(0px)}
    100%{transform:translateY(45px)}
`;

export const ContentSection = styled.div`
  color: #ffffff;
  font-weight: bold;
  font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem); //20@360 & 36@1140
`;

export const Title = styled.div`
  color: #d71a63;
  display: inline-block;
  padding: 0px 10px;
  animation-name: ${TitleAnimation};
  animation-duration: ${timeInterval};
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;
`;

export const AnimationBlock = styled.div`
  display: inline-block;
  overflow: hidden;
`;

export const Content = styled.div`
  font-size: clamp(0.875rem, 0.8173rem + 0.2564vw, 1rem); //14@360 & 16@1140
  font-weight: normal;
  line-height: 1.5;
  animation-name: ${ContentAnimation};
  animation-duration: ${timeInterval};
  animation-iteration-count: infinite;
  transition-timing-function: ease-in-out;

  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    text-align: center;
  }
`;

export const AnalyticsLogo = styled.div`
  position: relative;

  & svg {
    max-width: 480px;
    max-height: 480px;
  }

  &::before {
    content: "";
    background: radial-gradient(
      circle at 47% 49%,
      #b265ff,
      rgba(14, 39, 128, 0.03) 49%
    );
    width: 100%;
    height: 600px;
    opacity: 0.3;
    position: absolute;
    left: -10px;
    top: -20px;
  }

  @media screen and (max-width: 500px) {
    &::before {
      height: 400px;
    }
  }
`;

export const ResponsiveText = styled.div`
  text-align: left;

  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    text-align: center;
  }
`;
