import produce from 'immer';
import { combineReducers } from 'redux';
import { otpTypes } from './constants';

export const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

export const sendOtpReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case otpTypes.SEND_OTP_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case otpTypes.SEND_OTP_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case otpTypes.SEND_OTP_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });
  
export const verifyOtpReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case otpTypes.VERIFY_OTP_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case otpTypes.VERIFY_OTP_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case otpTypes.VERIFY_OTP_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      case otpTypes.RESET_OTP:
        draft.error = false;
        draft.isLoading = false;
        draft.data = false;
      default:
        draft = state;
        break;
    }
  });

export const selectDataReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case otpTypes.SELECT_DATA_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case otpTypes.SELECT_DATA_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case otpTypes.SELECT_DATA_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
  });

const RegistrationReducer = combineReducers({
  sendOtpReducer,
  verifyOtpReducer,
  selectData:selectDataReducer
});

export default RegistrationReducer;
