import React, { useState, useEffect } from "react";
import RegistrationTemp from "share/templates/RegistrationTemp";
import AntdTable from "share/organisms/table/antd-table";
import ModalTemp from "share/templates/ModalTemp";
import deleteIcon from "assets/delete.svg";
import cancelIcon from "assets/cancel.svg";
import useModal from "share/hooks/useModal";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getLocalObj, setLocalObj } from "utils/utility/local-storage";
import { getQueryParam } from "utils/utility/url";
import { SummaryWrapper, CancelBtn } from "../styles";
import { ButtonWrapper, SubmitButton } from "../register-individually/styles";
import { deleteStudentRequest } from "../actions";
import {
  EllipseText,
  NormalText,
} from "school-admin/DivisionalAccntDashboard/ApprovalsList/styles";

const Summary = () => {
  const [tableData, setTableData] = useState({ columns: [], dataSource: [] });
  const { isVisible, modalContent, handleModal } = useModal();
  const { isVisible: isCancelModalVisible, handleModal: handleCancelModal } =
    useModal();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let studentSummary = getLocalObj("studentSummary");
    if (studentSummary) {
      const dupStudentSummary = { ...studentSummary };
      setTableData({
        columns: dupStudentSummary.columns.map((data) => {
          if (
            ["name", "sur_name", "gender", "roll_number"].includes(
              data.dataIndex
            )
          ) {
            return {
              ...data,
              width: 100,
              render: (text) =>
                text && text.length > 6 ? (
                  <EllipseText title={text}>{text}</EllipseText>
                ) : (
                  <NormalText>{text}</NormalText>
                ),
            };
          } else if (["Exams"].includes(data.title)) {
            return {
              title: "Exams",
              children: data.children.map((chData) => ({
                ...chData,
                render: (row) => {
                  return row === "NA" ? (
                    <p style={{ color: "red", fontFamily: "Poppins" }}>{row}</p>
                  ) : (
                    <p style={{ color: "green", fontFamily: "Poppins" }}>
                      {row}
                    </p>
                  );
                },
              })),
            };
          } else {
            return { ...data };
          }
        }),
        dataSource: dupStudentSummary.dataSource,
      });
    }
  }, []);

  const handlePayment = () => {
    const id = getQueryParam("groupId", location);
    history.replace({
      pathname: `/school/new-registration/payment-summary${
        id ? `?groupId=${id}` : ""
      }`,
    });
  };

  const handleCancel = () => {
    history.replace("/school/new-registration");
  };

  const successCallback = (student) => {
    let arr = [];
    // eslint-disable-next-line array-callback-return
    tableData.dataSource.map((item) => {
      let admsnNum = item.admissionNumber || item.admission_number;
      let admsnNumber = student.admissionNumber || student.admission_number;
      if (admsnNum !== admsnNumber) arr.push(item);
    });
    let newData = { ...tableData, dataSource: arr };
    setLocalObj("studentSummary", newData);
    setTableData(newData);
  };

  const handleDelete = () => {
    const student = modalContent;
    const id = getQueryParam("groupId", location);
    let reqBody = {
      admission_number: student.admissionNumber || student.admission_number,
    };
    if (id) reqBody["group_id"] = id;
    dispatch(
      deleteStudentRequest({
        reqBody,
        callback: () => successCallback(student),
      })
    );
    handleModal();
  };

  const appendDelete = (dataSrc) => {
    return dataSrc.map((item) => ({
      ...item,
      action: (
        <div className="d-flex align-items-center justify-content-center">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleModal(item)}
            data-testid="modal-delete"
          >
            Delete
          </span>
        </div>
      ),
    }));
  };

  const isDisabled = () => {
    if (!tableData) return true;
    return !tableData.dataSource.length;
  };
  return (
    <RegistrationTemp headingText={"Registration Summary"}>
      {isVisible && (
        <ModalTemp
          icon={deleteIcon}
          title={"Delete"}
          desc={"Are you sure you want to delete the record?"}
          primaryBtn="Delete"
          onCancel={handleModal}
          onOk={handleDelete}
          data-testid="delete-record"
        />
      )}
      {isCancelModalVisible && (
        <ModalTemp
          icon={cancelIcon}
          title={"Cancel"}
          desc={"Are you sure you want to Cancel List?"}
          primaryBtn="Yes"
          onCancel={handleCancelModal}
          onOk={handleCancel}
          data-testid="cancel-list"
        />
      )}
      <SummaryWrapper>
        {tableData && (
          <AntdTable
            columns={tableData.columns}
            dataSource={appendDelete(tableData.dataSource)}
            scroll={{ x: 1500 }}
            // padding={"8px 4px 8px 4px"}
            isIndicator
          />
        )}
        <ButtonWrapper>
          <CancelBtn onClick={handleCancelModal} data-testid="cancel-btn">
            Cancel
          </CancelBtn>
          <SubmitButton
            disabled={isDisabled()}
            onClick={handlePayment}
            data-testid="continue-btn"
          >
            Continue
          </SubmitButton>
        </ButtonWrapper>
      </SummaryWrapper>
    </RegistrationTemp>
  );
};

export default Summary;
