import produce from 'immer';
import { divisionalActTypes } from './constants';
import { combineReducers } from "redux";

export const initialState = {
  isLoading: false,
  data: false,
  studentList:false,
  error: false,
};

export const DashboardReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case divisionalActTypes.GET_DASHBOARD_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case divisionalActTypes.GET_DASHBOARD_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case divisionalActTypes.GET_DASHBOARD_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
});

export const StudentListReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case divisionalActTypes.GET_STUDENT_LIST_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case divisionalActTypes.GET_STUDENT_LIST_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case divisionalActTypes.GET_STUDENT_LIST_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
});

export const ApproveStudentListReducer = (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case divisionalActTypes.GET_APPROVE_STUDENT_LIST_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = false;
        break;
      case divisionalActTypes.GET_APPROVE_STUDENT_LIST_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      case divisionalActTypes.GET_APPROVE_STUDENT_LIST_ERROR:
        draft.error = action.error;
        draft.isLoading = false;
        break;
      default:
        draft = state;
        break;
    }
});

const DivisionalAcctReducer = combineReducers({
    dashboardReducer:DashboardReducer,
    studentListReducer:StudentListReducer,
    approveStudentListReducer:ApproveStudentListReducer
  });

export default DivisionalAcctReducer
