import styled from "styled-components";
import Button from "share/atoms/Button";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 360px;
  height: 288px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);

  &:hover {
    box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 1);
    transform: ${({ $isResponsive }) => $isResponsive && "translateY(-4px)"};
    transition: all 0.3s linear;
  }

  @media screen and (max-width: 800px) {
    width: 288px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-shadow: 0 16px 12px 0 rgba(221, 230, 237, 0.4);
  }

  .fifty_off{
    position: absolute;
    top: -6px;
    right: 10px;
  }
`;
export const Icon = styled.img`
  width: 108px;
  height: 56px;
`;

export const CardHeader = styled(Flex)``;

export const CardFooter = styled(Flex)`
  margin-top: 32px;
`;

export const CardBody = styled.div``;

export const Price = styled.div`
  text-align: right;
  & .title {
    color: #464646;
    font-size: 12px;
    line-height: 1.67;
  }
  & .cost {
    color: #d71a63;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const Abbrevation = styled.div`
  color: #242424;
  font-size: clamp(0.75rem, 0.6346rem + 0.5128vw, 1rem);
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  margin: 16px 0px;
  height: 50px;
`;

export const Details = styled(Flex)`
  & .detail-name {
    font-size: clamp(0.625rem, 0.5673rem + 0.2564vw, 0.75rem);
    line-height: 1.67;
    color: #464646;
  }
  & .detail-value {
    color: #242424;
    font-size: clamp(0.75rem, 0.6923rem + 0.2564vw, 0.875rem);
    font-weight: 600;
    line-height: 1.71;
  }
`;

export const CustomBtn = styled(Button)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreInfoBtn = styled(CustomBtn)`
  background-color: #ffffff;
  border: 1px solid #254082;
  color: #254082;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  padding: 12px;
  margin: 0;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: #254082;
    color: #ffffff;
  }
`;
export const BuyNowBtn = styled(CustomBtn)`
  font: 14px;
  font-weight: 600;
  line-height: 1.43;
  margin: 0;
  opacity: ${(props) => (props.$isDisabled ? 0.5 : 1)};
  cursor: ${(props) =>
    props.$isDisabled ? "not-allowed" : "pointer"} !important;
`;
