//LandingPage Imports
import Exams from "student/LandingPage/Exams";
import AboutUS from "student/LandingPage/AboutUs";
import ExamDetails from "student/LandingPage/ExamDetails";
import ContactUs from "student/LandingPage/ContactUs";
import WhyOlympiad from "student/LandingPage/WhyOlympaid";
import Explore from "student/LandingPage/Explore";
import AvailablePlatforms from "student/LandingPage/AvailablePlatforms";
import RefundPolicy from "student/LandingPage/Refunds";
import PrivacyPolicy from "student/LandingPage/Privacypolicy";
import TermsAndConditions from "student/LandingPage/TermsAndConditions";
import FaqPage from "student/LandingPage/Faq";

//Test Imports
import Result from "containers/result/loadable";
import AnswerKey from "containers/answer-key/loadable";
import InstructionsScreen from "containers/instructions/loadable";
import Proctoring from "containers/proctoring/index";
import ThankYou from "containers/thank-you/loadable";

export const TEST_ENGINE_ROUTES = [
  {
    path: "/proctor/:details",
    component: Proctoring,
  },
  {
    path: "/instructions/:details",
    component: InstructionsScreen,
  },
  {
    path: "/thankyou/:id",
    component: ThankYou,
  },
  {
    path: "/:id/thankyou",
    component: ThankYou,
  },
  {
    path: "/result/:details",
    component: Result,
  },
  {
    path: "/answerkey/:details",
    component: AnswerKey,
  },
];

export const HOME_SCREEN_ROUTES = [
  {
    path: "/exams",
    component: Exams,
  },
  {
    path: "/about-us",
    component: AboutUS,
  },
  {
    path: "/exam-details",
    component: ExamDetails,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/why-olympiad",
    component: WhyOlympiad,
  },
  {
    path: "/explore",
    component: Explore,
  },
  {
    path: "/contact-us",
    component: ContactUs,
  },
  {
    path: "/available-platforms",
    component: AvailablePlatforms,
  },
  {
    path: "/refund-policy",
    component: RefundPolicy,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/faqs",
    component: FaqPage,
  },
];
