import styled from "styled-components";

export const CongraguPoppinsnCard = styled.div`
  height: 236x;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
`;

export const DownloadReceiptWrap = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: flex-end;
  padding-right: 24px;
  .receipt-icon {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    object-fit: contain;
  }
  .receipt-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    color: #d71a63;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CongratsLogoWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .congrats-icon {
    width: 144px;
    height: 96px;
    object-fit: contain;
  }
  .congrats-text {
    width: 321.7px;
    height: 24px;
    font-family: Poppins;
    margin-top: 16px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #434343;
  }
  .confirm-text {
    width: 321.7px;
    height: 24px;
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #434343;
  }
`;

export const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  .title {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #d71a63;
  }
  .download-link {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    color: #d71a63;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      margin-right: 10px;
    }
  }
`;

export const PaginationWrapper = styled.div`
.ant-pagination-total-text{
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-left:24px;
   margin-top:-125px;
}
.ant-pagination :not(.ant-pagination-total-text){
 display: none;
}
`