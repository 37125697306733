import React from "react";
import ErrorPage from "share/organisms/error";
const NotFound = () => {
  return (
    <>
      <ErrorPage
        title="Page Not Found!"
        desc="The page you requested cannot be found.
Go back to the home page or try again."
        hasRedirection
      />
    </>
  );
};

export default NotFound;
