import React from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { StyledInput } from './styles';

const OtpTextInput = ({ handleChange, inputLength, otp }) => {
  return (
    <StyledInput>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={inputLength}
        isInputNum={true}
        placeholder='----'
        shouldAutoFocus={true}
      />
    </StyledInput>
  );
};

OtpTextInput.propTypes = {
  handleChange: PropTypes.func,
  inputLength: PropTypes.number,
  otp: PropTypes.string,
};

OtpTextInput.defaultProps = {
  inputLength: 4,
  handleChange: () => {},
};

export default OtpTextInput;
