import MockTestLogo from "assets/landingpage/mock-test.svg";
import ModelPapersLogo from "assets/landingpage/model-papers.svg";
import PrevYearQuestionLogo from "assets/landingpage/pre-year-question-paper.svg";

export const accessdetails = [
  {
    title: "Mock Tests",
    logo: MockTestLogo,
  },
  {
    title: "Model Papers",
    logo: ModelPapersLogo,
  },
  {
    title: "Previous Year Question Paper",
    logo: PrevYearQuestionLogo,
  },
];
