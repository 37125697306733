export const CNAPS_PROCTOR = "cnaps-test/v1/proctor";
export const CNAPS_TEST_SCHEDULED = "cnaps-test/v1/scheduled";

export const endpoints = {
  GET_PROCTOR_CONFIG: `${CNAPS_PROCTOR}/get-config`,
  UPDATE_PROCTOR_CONSENT: `${CNAPS_PROCTOR}/add-consent`,
  UPDATE_PROCTOR_EVENT: `${CNAPS_PROCTOR}/update-event`,
  EXAM_DETAILS: `${CNAPS_TEST_SCHEDULED}/exam-details`,
  SCHEDULE_PROCEED_TEST: `${CNAPS_TEST_SCHEDULED}/proceed-status`,
  MOCK_PROCEED_TEST: `${CNAPS_TEST_SCHEDULED}/proceed-status`,
  SIGNED_URL: `${CNAPS_PROCTOR}/pre-signed-url`,
};
