import React from "react";
import {
  Wrapper,
  Header,
  Tag,
  Container,
  RightSection,
  LeftSection,
  MainContainer,
  PlayStoreWrapper,
} from "./styles";
import MobileApp from "assets/cnaps-mobile-app.svg";
import PlayStoreIcon from "assets/playstore.svg";
import BooksLogo from "assets/landingpage/available/books.svg";
import AwardsLogo from "assets/landingpage/available/awards.svg";
import CertificateLogo from "assets/landingpage/available/certificate.svg";
import MobileResposnive from "assets/mobile_app_responsive.svg";
import { useResize } from "share/hooks/useResize";

function AvailablePlatforms() {
  const { width } = useResize();
  return (
    <MainContainer>
      <Wrapper>
        <Header>
          Start Exploring <span className="highlight">CNAPS</span>. <br />
          Download the app to get started with the Exams.
        </Header>
        <Tag>
          You can access your test through multiple platforms Web, mobile and
          tab.
        </Tag>
        <PlayStoreWrapper>
          <a target="_blank" href={process.env.REACT_APP_PLAY_STORE_URL}>
            <img src={PlayStoreIcon} alt="" />
          </a>
        </PlayStoreWrapper>
        <Container>
          <LeftSection>
            <div className="title">Awards & Rewards</div>
            <div className="logos">
              <img src={BooksLogo} alt="" />
              <img src={AwardsLogo} alt="" />
              <img src={CertificateLogo} alt="" />
            </div>
            <div className="content">
              Books, Certificates & Mementos Gifts, Tab, Medals and many more...
            </div>
          </LeftSection>
          <RightSection>
            {width <= 800 ? (
              <img src={MobileResposnive} alt="" height="698px" width="352px" />
            ) : (
              <img src={MobileApp} alt="" height="698px" width="352px" />
            )}
          </RightSection>
        </Container>
      </Wrapper>
    </MainContainer>
  );
}

export default AvailablePlatforms;
