import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    position: relative;
    top: 10%;
    border-radius: 12px;
    max-width: ${({ $width }) => $width || "700px"};
    border-radius: 12px;
  }
  .modal-header {
    border-bottom: none;
    padding-bottom: 20px;
    .modal-title {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #242424;
    }
    .close {
      font-size: 30px;
      font-weight: initial;
      position: relative;
      top: -10px;
      &:hover,
      &:active,
      &:focus {
        outline: none;
      }
    }
  }
  .modal-body {
    max-height: 544px;
    overflow: auto;
    height: 50%;
  }
`;
