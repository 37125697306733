import React, { useEffect, useState } from "react";
import RegistrationTemp from "share/templates/RegistrationTemp";
import IndividualRegistrationForm from "./IndividualRegistrationForm";
import useSelectInputChange from "share/hooks/useSelectInputChange";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { CardWrapper } from "../styles";
import { UploadErrorModal } from "../new-registration/upload-model";
import { ButtonWrapper, SubmitButton, NewUserButton } from "./styles";
import { getQueryParam } from "utils/utility/url";
import { getErrorList } from "utils/utility/school";
import { removeStrToClass, isDisabled } from "./helper";
import {
  bulkRegistrationManualRequest,
  getStudentDataRequest,
  studentFiltersRequest,
} from "../actions";

const RegisterIndividually = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const id = getQueryParam("admissionNumber", location);
  const phnNum = getQueryParam("mobile_number", location);
  console.log(phnNum);
  const [showErrorList, setShowErrorList] = useState(false);
  const [showAddUser, setShowAddUser] = useState(true);
  const [mobileErrorStatus, setMobileErrorStatus] = useState(true);

  const {
    formsList,
    setFormsList,
    handleChange,
    handleInputChange,
    handleSelect,
  } = useSelectInputChange({
    name: "",
    surname: "",
    class: [],
    gender: [],
    exams: [],
    rollnumber: "",
    // mobile_number: phnNum || "",
    id: new Date().getUTCMilliseconds(),
  });

  useEffect(() => {
    dispatch(studentFiltersRequest());
    setFormsList([
      {
        name: "",
        surname: "",
        class: [],
        gender: [],
        exams: [],
        rollnumber: "",
        // mobile_number: phnNum || "",
        id: new Date().getUTCMilliseconds(),
      },
    ]);
    if (id) dispatch(getStudentDataRequest({ admission_number: id }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) setShowAddUser(false);
  }, [props.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const { studentDetails } = useSelector((state) => state.newRegistration);
  useEffect(() => {
    if (studentDetails.data && id) {
      const { data: studDetails } = studentDetails;
      setFormsList([
        {
          name: studDetails.name,
          surname: studDetails.sur_name,
          class: studDetails.class,
          gender: studDetails.gender,
          rollnumber: studDetails.roll_number,
          // mobile_number: studDetails.mobile_number, // Ensure this field is included
          exams: [],
        },
      ]);
    }
  }, [studentDetails.data]); // eslint-disable-line react-hooks/exhaustive-deps
  /**
   * this function deletes the items in array based on key
   * @param {number} index
   * @returns {void}
   */
  const handleDelete = (index) => {
    if (index >= 1) {
      const deleteData = [...formsList];
      deleteData.splice(index, 1);
      setFormsList(deleteData);
    }
  };

  const handleAddClick = () => {
    setFormsList([
      ...formsList,
      {
        name: "",
        surname: "",
        class: [],
        gender: [],
        exams: [],
        rollnumber: "",
        // mobile_number: "", // Add this line
        id: new Date().getUTCMilliseconds(),
      },
    ]);
  };

  const errorCallback = (errors) => setShowErrorList(errors);

  const handleSubmit = () => {
    const dummyFormsList = JSON.parse(JSON.stringify(formsList));
    const removedIdFormsList = dummyFormsList.map((frmList) => {
      delete frmList.id;
      return frmList;
    });
    const finalData = {
      students_list: [...removedIdFormsList].map((form) => {
        return {
          ...form,
          class: removeStrToClass(form.class),
          exams: form.exams
            .filter((item) => item !== "all")
            .map((itm) => itm.split("~")[0]),
        };
      }),
      errorCallback: errorCallback,
    };
    if (id) {
      let exams = finalData.students_list[0].exams;
      if (exams.length)
        history.replace(
          `/school/new-registration/payment-summary?admissionNumber=${id}${
            exams ? `&exams=${exams.join(",")}&mobile_number=${phnNum}` : ""
          }`
        );
    } else dispatch(bulkRegistrationManualRequest(finalData));
  };

  const handleErrorModalClose = () => setShowErrorList(false);
  // const mobileErrorCallback = (mobileErr) => {
  //   if (mobileErr) {
  //     setMobileErrorStatus(true);
  //   } else {
  //     setMobileErrorStatus(false);
  //   }
  // };

  return (
    <RegistrationTemp headingText={"Individual Registration"}>
      {showErrorList && (
        <UploadErrorModal
          errorList={getErrorList(showErrorList)}
          onClose={handleErrorModalClose}
        />
      )}
      <CardWrapper height="75%">
        <React.Fragment>
          {formsList.map((data, index) => (
            <IndividualRegistrationForm
              key={data.id}
              showBorder={showAddUser}
              data={data}
              index={index}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              handleDelete={handleDelete}
              handleSelect={handleSelect}
              setFormsList={setFormsList}
              formsList={formsList}
              // mobileErrorCallback={mobileErrorCallback}
            />
          ))}
          {showAddUser && (
            <NewUserButton onClick={handleAddClick}>Add New User</NewUserButton>
          )}
        </React.Fragment>
      </CardWrapper>
      <ButtonWrapper>
        <SubmitButton
          onClick={handleSubmit}
          disabled={
            !isDisabled(formsList)
          }
        >
          Submit
        </SubmitButton>
      </ButtonWrapper>
    </RegistrationTemp>
  );
};

export default RegisterIndividually;
