import React from 'react'
import HamburgerLogo from "assets/frame.svg";
import NavBar from "../nav-bar/";
import styled from "styled-components";

const HamburgerWrapper = styled.div`
  margin: 15px 30px;
  display: flex;
  flex-direction: column;
  /* justify-content:flex-start; */
  align-items: flex-start;
`;
function HamburgerMenu({ handleSidebar }) {
  return (
    <>
      <img src={HamburgerLogo} alt="logo" width="156" style={{margin:"4px 12px 12px 15px"}}/>
      <NavBar isSideBar handleSidebar={handleSidebar} />
    </>
  );
}

export default HamburgerMenu
