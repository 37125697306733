import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { useResize } from "share/hooks/useResize";
import "./styles.css";
import { getPadding } from "./helper";

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #254082;
  opacity: 0.4;
  border-radius: 6px;
`;

function Carasoul({ children, length }) {
  const [currentCount, setCurrentCount] = useState("");
  const [cPadding, setCPadding] = useState(0);
  const { width } = useResize();
  useEffect(() => {
    if (width <= 800) {
      const val = getPadding(width);
      const { cPadding } = val;
      setCPadding(cPadding);
    }
  }, [width]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: cPadding,
    arrows: false,
    afterChange: (current) => setCurrentCount(current),
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <Slider {...settings}>{children}</Slider>
      <CarasoulPagination totalCount={length} currentCount={currentCount + 1} />
    </>
  );
}

export default Carasoul;

const CarasoulPagination = ({ totalCount = 0, currentCount = 0 }) => {
  return (
    <div
      style={{
        height: "30px",
        display: "flex",
        gap: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dot />
      <Dot />
      <div
        style={{
          backgroundColor: "#254082",
          color: "#ffffff",
          padding: "0px 6px",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "24px",
          height: "14px",
          fontSize: "8px",
          fontWeight: "500",
          lineHeight: 0.8,
        }}
      >
        {currentCount}/{totalCount}
      </div>
      <Dot />
      <Dot />
    </div>
  );
};
