import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "share/atoms/Button";
import { StyledButton, ModalWrapper, OkButton } from "./styles";
import MonitiorTestTakers from "../services";
import notify from "utils/notifications";
import { getStudentDetailsMonitorRequest } from "../actions/get-student-details-for-monitor";

const ExtendTime = ({ row }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [extendTime, setExtendTime] = useState("");
  const handleCancel = () => setIsModalOpen(false);
  const handleChange = (e) => {
    setExtendTime(e.target.value);
  };
  const state = useSelector(
    (state) => state?.monitorTestTakers?.studentListMonitor
  );
  const dispatch = useDispatch();

  const handleOk = async() => {
    const payload = {
      delivery_id: row?.delivery_id,
      admission_number: row?.admission_number.toString(),
    };
    const refreshBody = {
      delivery_id: row?.delivery_id,
      admission_number: row?.admission_number.toString(),
      tableData: state.data,
      index: row.key,
    };
    const reqBody = {
      ...payload,
      extended_time: extendTime,
    };
    const res = await MonitiorTestTakers.extendTimeApi(reqBody);
    snackbarStatus(res,setIsModalOpen);
    if(res.data.status !== 'error') dispatch(getStudentDetailsMonitorRequest(refreshBody))
  };
  return (
    <>
      <ModalWrapper visible={isModalOpen} closable={false} footer={null}>
        <div className="heading">Extend Time</div>
        <hr className="divider" />
        <div className="wrapper">
          <div className="admission">
            <label className="label">Admission Number</label>
            <div className="value">{row.admission_number}</div>
          </div>
          <div className="student-name">
            <label className="label">Student Name</label>
            <div className="value">{row.student_name}</div>
          </div>
        </div>
        <div className="text">
          <input
            className="input"
            placeholder="Enter Minutes"
            type="number"
            max={20}
            onChange={handleChange}
          />
          <div className="input-helper">Maximum 20 minutes</div>
        </div>
        <div className="button-wrapper">
          <Button className="without-border" onClick={handleCancel}>
            {" "}
            Cancel
          </Button>
          <OkButton
            className="border-button"
            disabled={
              Number(extendTime) > 20 ||
              Number(extendTime) < 1 ||
              extendTime === "" ||
              Number(extendTime) % 1 !== 0
            }
            onClick={handleOk}
          >
            ok
          </OkButton>
        </div>
      </ModalWrapper>
      <StyledButton
        bgColor="#254082"
        onClick={() => setIsModalOpen(true)}
        disabled={Number(row.time_left) <= 0}
      >
        Extend Time
      </StyledButton>
    </>
  );
};
const snackbarStatus = (res,setIsModalOpen) => {
  if (res.status === 200 && res.data.status === "success") {
    notify("success", res.data.message ? res.data.message : "success");
    setIsModalOpen(false);
  } else {
    notify("error", res.data.message ? res.data.message : "failed");
    setIsModalOpen(false);
  }
};

export default ExtendTime;
