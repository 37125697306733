import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  SchoolWrapper,
  Logo,
  Details,
  RegistrationButton,
} from "./styles";
import school from "assets/school.svg";
import Button from "share/atoms/Button";
import { redirectSchoolRegToLandingPage } from "utils/helpers";

function ForSchools() {
  const history = useHistory();
  const gotoSchoolLogin = () => {
    history.push("/school/login");
  };
  const gotoSchoolReg = () => {
    redirectSchoolRegToLandingPage(history);
    // history.push("/school/registration");
  };
  return (
    <>
      <Container>
        <SchoolWrapper isExamsPath={history.location.pathname === "/exams"}>
          <Logo>
            <img src={school} alt="" height="150px" />
          </Logo>
          <Details>
            <div className="details-header">For Schools</div>
            <div className="details-info">
              School Principals/Admins can get their schools registered into
              CNAPS School and get some benefits. Try to get your school
              registered with us for bulk student registrations.
            </div>
            <div className="details-btns">
              <RegistrationButton onClick={gotoSchoolReg}>
                {" "}
                School registration
              </RegistrationButton>
              <Button onClick={gotoSchoolLogin} bgColor="#254082">
                {" "}
                School Login
              </Button>
            </div>
          </Details>
        </SchoolWrapper>
      </Container>
    </>
  );
}

export default ForSchools;
