import DiverseStudentIcon from "assets/Student icon.svg";
import CertificateExcitingIcon from "assets/CertificateExcitingIcon.svg";
import EasySimpleIcon from "assets/EasySimpleIcon.svg";
import ExamPerformanceIcon from "assets/ExamPerformanceIcon.svg";

export const FEATURES_LIST = [
    {
      image: DiverseStudentIcon,
      title: `Exposure`,
      desc: `Exposure to a larger pool of students from different schools and syllabus backgrounds`,
    },
    {
      image: ExamPerformanceIcon,
      title: `Nurture Programs`,
      desc: `Chance to win scholarships and unique opportunities to meet the professionals in the field of Maths and Science by being a part of the nurture programs`,
    },
    {
      image: EasySimpleIcon,
      title: `Research based Olympiad`,
      desc: `CNAPS groundwork and research made it possible to compare and contrast the best practices from various International Olympiads to distill the CNAPS's olympiads to better suit academic environments in India.`,
    },
    {
      image: CertificateExcitingIcon,
      title: `Awareness and Vision`,
      desc: `You will develop an awareness towards your surroundings and start enjoying Mathematics and Science`,
    },
  ];

  export const getPadding = (width = 800) => {
    if (width <= 800 && width >= 750) {
      return {
        cPadding: "20%",
        mWidth: "600px",
      };
    } else if (width <= 750 && width >= 700) {
      return {
        cPadding: "18%",
        mWidth: "400px",
      };
    } else if (width <= 700 && width >= 650) {
      return {
        cPadding: "16%",
        mWidth: "400px",
      };
    } else if (width <= 650 && width >= 600) {
      return {
        cPadding: "14%",
        mWidth: "400px",
      };
    } else if (width <= 600 && width >= 550) {
      return {
        cPadding: "12%",
        mWidth: "400px",
      };
    } else if (width <= 550 && width >= 500) {
      return {
        cPadding: "10%",
        mWidth: "400px",
      };
    } else if (width <= 500 && width >= 450) {
      return {
        cPadding: "8%",
        mWidth: "400px",
      };
    } else if (width <= 450 && width >= 400) {
      return {
        cPadding: "6%",
        mWidth: "400px",
      };
    } else if (width <= 400 && width >= 350) {
      return {
        cPadding: "4%",
        mWidth: "400px",
      };
    } else if (width <= 350 && width >= 300) {
      return {
        cPadding: "2%",
        mWidth: "400px",
      };
    } else if (width <= 300) {
      return {
        cPadding: "2%",
        mWidth: "400px",
      };
    }
  };
  