import React, { useState } from "react";
import PropTypes from "prop-types";
import HideEye from "assets/school-admin/icons/eye-off.svg";
import ShowEye from "assets/school-admin/icons/eye.svg";
import useFocus from "share/hooks/useFocus";
import { StyledInput, VisibilityIcon, InputWrapper } from "./styles";

const PasswordInput = ({
  name,
  type,
  handleChange,
  width,
  error,
  isMonitorFeature,
  from,
  ...rest
}) => {
  // const [inputRef, setFocus] = useFocus();
  const [showText, setShowText] = useState(false);
  // useEffect(() => {
  //   setFocus();
  // }, []);
  const handleVisibility = () => {
    setShowText(!showText);
  };
  return (
    <InputWrapper className="password" $width={width}>
      <StyledInput
        onChange={handleChange}
        autoComplete="off"
        // ref={inputRef}
        name={name}
        {...rest}
        type={showText ? "text" : type}
        error={error}
        isMonitorFeature={
          from === "account-delete"
            ? true
            : isMonitorFeature
            ? isMonitorFeature
            : false
        }
      />
      <VisibilityIcon className="icon">
        <img
          src={showText ? ShowEye : HideEye}
          alt="password"
          onClick={handleVisibility}
        />
      </VisibilityIcon>
    </InputWrapper>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
};

PasswordInput.defaultProps = {
  name: "",
  type: "",
  handleChange: () => {},
};
export default PasswordInput;
