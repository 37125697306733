import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "./styles.css";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { videoGallery } from "utils/award-ceremonies";
import ReactPlayer from "react-player";
import { useResize } from "share/hooks/useResize";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const AwardVideoGallery = () => {
  const [isPlaying, setIsPlaying] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(null);
  const swiperRef = useRef(null);
  const { width } = useResize();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && isPlaying !== null) {
        setIsPlaying(null);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isPlaying]);

  const handleSlideClick = (index) => {
    if (swiperIndex === index) {
      // If the slide is already active, play the video
      setIsPlaying(index);
      if(swiperRef && swiperRef.current && swiperRef.current.swiper.autoplay){
        swiperRef.current.swiper.autoplay.stop();
      }
      
    } else {
      // If the slide is not active, make it active
      // swiperRef.current.swiper.slideToLoop(index);
      swiperRef.current.swiper.slideTo(index);
      setSwiperIndex(index);
    }
  };

  const handlePlay = (index) => {
    setIsPlaying(index);
    if(swiperRef && swiperRef.current && swiperRef.current.swiper.autoplay){
      swiperRef.current.swiper.autoplay.stop();
    }
   
  };

  const handlePause = () => {
    setIsPlaying(null);
    if(swiperRef && swiperRef.current && swiperRef.current.swiper.autoplay){
      swiperRef.current.swiper.autoplay.start();
    }
  
  };

  const handleSlideChange = () => {
    const activeIndex = swiperRef.current.swiper.realIndex;
    setSwiperIndex(activeIndex);
    setIsPlaying(null);
  };
  const style = () =>{
    if(width < 400){
      return 30; 
    }else if(width < 450){
      return 45;
    }
    else if (width < 500){
      return 40 ;
    }
    else if(width < 800 ) {
      return 95;
    }else if(width < 950){
      return 160
    }
    else if(width <= 1024 ) {
      return 290;
    }
    else {
      return 100;
    }
  }

  return (
    <div style={{position:'relative'}}>
      <button
        onClick={() => swiperRef.current.swiper.slidePrev()}
        className="prev-icon"
      >
        <FaChevronLeft />
      </button>
      <Swiper
        ref={swiperRef}
        slidesPerView={1.5}
        centeredSlides={true}
        effect="coverflow"
        speed={300}
        grabCursor={true}
        slidesOffsetBefore={style}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 10,
          depth: 100,
          modifier: 2.5,
          slideShadows: true,
        }}
        pagination={{ el: ".swiper-pagination", clickable: false }}
        // navigation={{
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        //   clickable: true,
        // }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        onSlideChange={handleSlideChange}
        initialSlide={1}
   
        
      >
        {videoGallery.map((item, index) => (
          <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
            {swiperIndex === index ? <>
            <div style={{display:'flex',flexDirection:'column',alignItems:"center"}}>
            <ReactPlayer
              url={item.youtubeLink}
              className="react-player"
              controls
              playing={isPlaying === index}
              onPlay={() => handlePlay(index)}
              onPause={handlePause}
              onEnded={handlePause}
              playsinline={true}
            />
            <h3 className="youtube_caption">{item.caption}</h3>
            </div>
             
            </>:<>
            <div style={{display:'flex',flexDirection:'column',alignItems:"center"}}>
            <ReactPlayer
              url={item.youtubeLink}
              className="react-player"
              controls
              light
            />
            <h3 className="youtube_caption">{item.caption}</h3>
            </div>
             
            </>
            }
            
          </SwiperSlide>
        ))}
        {/* <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div> */}
        <div className="slider-controller">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
      <button
        onClick={() => swiperRef.current.swiper.slideNext()}
        className="next-icon"
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

export default AwardVideoGallery;
