import React from "react";
import {
  ComputerIcon,
  OnlineLearningIcon,
  BrowserIcon,
} from "share/atoms/icons";

export const sidebarData = [
  {
    id: 1,
    testDetails: "Online Olympiads",
    description:
      "CNAPS endeavours to identify the latent potential through quantitative and qualitative assessments and nurture the students thereafter.",
    icon: <ComputerIcon />,
  },
  {
    id: 2,
    testDetails: "Overall Student Assessment",
    description:
      "CNAPS groundwork and research made it possible to compare and contrast the best practices from various International Olympiads.",
    icon: <OnlineLearningIcon />,
  },
  {
    id: 3,
    testDetails: "Test Results",
    description: `"Something that is not measured is not understood", 5Q Analysis is provided for the first time in the country.`,
    icon: <BrowserIcon />,
  },
];
