import React from 'react'

export default function CustomIcon(props) {
  const getCustomIcon = {
    dashboard: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <path stroke="#254082" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.667 2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.597 13.403 2 12.667 2z" />
        <path fill="#254082" stroke="#254082" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.667 4.667h-2v6h2v-6z" />
        <path stroke="#254082" stroke-linecap="round" stroke-linejoin="round" d="M11.333 4.667h-2V8h2V4.667z" />
      </svg>
    )

  }
  return getCustomIcon[props.icon]
}