import React from "react";
import { Wrap, Wrapper, RefundWrapper, CancellationWrapper } from "./styles";
import Header from "../../../share/organisms/headers/features-header";
import Container from "../../../share/atoms/container/index";
import Footer from "../../LandingPage/Footer/index";
import { useScrollTo } from "share/hooks/useScrollTo";

const RefundPage = (props) => {
  useScrollTo(0, 0);
  return (
    <div style={{ backgroundColor: "#f9f9ff" }}>
      {!props.isHome && <Header {...props} />}
      <Wrap>
        <Container>
          <Wrapper>
            <RefundWrapper>
              <h2 className="heading">Refunds</h2>
              <p className="paragraph">
                Once the payment has been made successfully and the registration
                for an exam has been done, refunds or cancellations are not
                encouraged in any case. If the transaction fails for some reason
                and the amount has been debited from the user’s account, the
                amount will be credited back to the user within 5 - 7 working
                days.
              </p>
            </RefundWrapper>
            <CancellationWrapper>
              <h2 className="heading">Cancellations</h2>
              <p className="paragraph">
                There are no cancellations of the orders permitted once the
                order has been successfully executed.
              </p>
              <p className="paragraph">
                Any queries or issues regarding the payments, users can reach
                out to the CNAPS support team.
              </p>
            </CancellationWrapper>
          </Wrapper>
        </Container>
      </Wrap>
      <Footer />
    </div>
  );
};

export default RefundPage;
