import Button from "share/atoms/Button";
import { InputLabel } from "share/atoms/form-fields";
import styled, { css } from "styled-components";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height:60px;
`;
export const CustomizeInputLabel = styled(InputLabel)`
  height: 22px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`

export const NewUserButton = styled(Button)`
  width: 142px;
  height: 46px;
  padding: 8px 16px;
  font-size: 12px;
  background-color: #1e5190;
  margin-top:20px;
`;

export const DeleteButton = styled(Button)`
display: flex;
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
align-self: center;
flex-grow: 0;
padding: 10px;
border-radius: 50%;
margin:50px 10px;
background-color: #d71a63;
.deleteicon{
color:#fff;
}
`
export const SubmitButton = styled(Button)`
  width: 136px;
  height: 48px;
  padding: 12px 16px;
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -0.41px;
  text-align: center;
  color: #ffffff;
  ${({ disabled }) =>
    disabled
      ? `
      cursor: not-allowed;
      opacity: 0.3;
    `
      : ""}
`;

export const Wrapper = styled.div`
  border-bottom: 1px solid #949497;
  padding-bottom:  24px;
  ${({ showBorder }) =>
    !showBorder &&
    css`
      border: none;
    `}
`;

export const SingleSelectWrapper = styled.div`
`

export const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;