import styled from "styled-components";

export const Image = styled.img`
  @media screen and (max-width: 800px) {
    transform: ${({ $isClicked }) =>
      $isClicked ? "rotate(180deg)" : "rotate(0deg)"} !important;
    transition: all 0.1s ease-in !important;
  }
`;

export const DropdownItems = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 11;
  top: 24px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 4px;
  right: 14px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 800px) {
    position: relative;
    top: 0;
    min-width: 100px;
    right: -10px;
    box-shadow: none;
  }
`;

export const Wrapper = styled.li`
  display: inline-block;
  height: 100%;
  border-bottom: 2px solid transparent;
  width: 100%;

  &:hover {
    border-bottom: 2px solid transparent;
    transition: all 0.5s ease-in;
  }

  &:hover ${DropdownItems} {
    display: block;

    & .arrow-inactive {
      border: 1px solid red;
    }
  }

  &:hover ${Image} {
    transform: rotate(180deg);
    transition: all 0.1s linear;
  }

  @media screen and (max-width: 800px) {
    position: relative;
    padding-bottom: 10px;
  }
`;

export const DropDown = styled.a`
  display: inline-block;
  text-align: center;
  vertical-align: text-top;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.$isActive ? "#d71a63" : "#254082")} !important;
  border-bottom: ${(props) =>
    props.$isActive ? "2px solid #d71a63" : "2px solid transparent"};
  text-align: left;
  width: 100%;

  & .arrow-inactive {
    margin: 0px 4px;
  }
  & .arrow-active {
    margin: 0px 4px;
  }
`;

export const DropdownItem = styled(DropDown)`
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: #d71a63;
    color: #ffffff !important;
  }
`;
