import styled from "styled-components";
import { css } from "styled-components";
import { StyledLabel } from "school-admin/Login/styles";
import Button from "share/atoms/Button";

export const Wrap = styled.div`
  background-color: #f9f9ff;
`;
export const Wrapper = styled.div`
  font-family: Poppins;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const CustomLabel = styled(StyledLabel)`
  margin-bottom: 0;
  margin-top: 32px;

  @media (max-width: 480px) {
    margin-top: 24px;
  }
`;

export const AuthHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AuthWrapper = styled.div`
  /* padding: 30px; */
`;

export const ModalHeader = styled.div`
  color: #242424;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-bottom: 12px;

  ${(props) =>
    props.$isMobile &&
    css`
      padding-bottom: 8px;
    `}
`;
export const ModalTag = styled.div`
  font-size: 14px;
  line-height: 1.57;
  text-align: center;
  color: #464646;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  margin: 0;

  ${(props) =>
    props.$isMobile &&
    css`
      font-size: 12px;
    `}
`;

export const SubmitButton = styled(Button)`
  opacity: ${({ $isValid }) => ($isValid ? 1 : 0.3)};
  cursor: ${({ $isValid }) =>
    $isValid ? "pointer" : "not-allowed !important"};
  margin: 0;
`;

export const ErrorText = styled.div`
  color: #e21313;
  font-size: 12px;
  line-height: 1.33;
  margin-top: 8px;
  height: 12px;
`;

export const ResendButton = styled.div`
  color: ${({ $isValid }) => ($isValid ? "#242424" : "#6e6e6e")};
  cursor: ${({ $isValid }) => ($isValid ? "pointer" : "not-allowed")};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin: 24px 0 32px 0;
`;
export const HyperLink = styled.span`
  color: #d71a63;
  font-size: 12px;
  line-height: 1.83;
  cursor: pointer;
  border-bottom: 1px solid #d71a63;
  padding: 4px;
`;

export const SecondaryButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
  margin: 0 12px 0 0;
`;

export const DeleteButton = styled(Button)`
  background-color: #ffdce9;
  border-radius: 26px;
  border: solid 1px #d71a63;
  color: #d71a63;
  font-size: 12px;
  font-weight: 500;
`;
