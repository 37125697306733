import React from 'react'
import { Wrapper, Heading } from "./styles";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";

const RegistrationTemp = ({ children, headingText }) => {
  return (
    <WithSidepanelLayout>
      <Wrapper>
        <Heading>{headingText}</Heading>
        {children}
      </Wrapper>
    </WithSidepanelLayout>
  );
};

export default RegistrationTemp
