import { newRegistrationTypes } from "school-admin/NewRegistration/constants";
import { types } from "./constants";

export const studentListRequest = (payload) => ({
  type: types.STUDENT_LIST_REQUEST,
  payload,
});
export const studentListSuccess = (payload) => ({
  type: types.STUDENT_LIST_SUCCESS,
  payload,
});
export const studentListError = (error) => ({
  type: types.STUDENT_LIST_ERROR,
  error,
});

export const resetStudentList = () => ({
  type: types.RESET_STUDENT_LIST,
});

//Student filters
export const studentFiltersRequest = (payload) => ({
  type: types.STUDENT_FILTERS_REQUEST,
  payload,
});
export const studentFiltersSuccess = (payload) => ({
  type: types.STUDENT_FILTERS_SUCCESS,
  payload,
});
export const studentFiltersError = (error) => ({
  type: types.STUDENT_FILTERS_ERROR,
  error,
});

//download excel
export const downloadExcelSheet = (payload) => ({
  type: types.DOWNLOAD_EXCEL,
  payload
})

//download student result
export const downloadSchoolResult = (payload) => ({
  type: types.DOWNLOAD_SCHOOL_RESULT,
  payload
})

export const updateMobileNumber = (payload) => ({
  type: types.UPDATE_STUDENT_MOBILE_NUMBER,
  payload
})

export const initIndividualPaymentRequest = (payload) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_REQUEST,
  payload,
});
export const initIndividualPaymentSuccess = (payload) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_SUCCESS,
  payload,
});
export const initIndividualPaymentError = (error) => ({
  type: newRegistrationTypes.INIT_INDIVIDUAL_PAYMENT_FAILURE,
  error,
});