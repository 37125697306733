export const microServices ={
    CNAPSMONITOR: 'cnaps-monitor/v1',
}

export const endpoints={
    // GET_META_DATA:`${microServices.CNAPSMONITOR}/usage/meta-data`,
    GET_LIST_DELIVERIES: `${microServices.CNAPSMONITOR}/delivery/list`,
    GET_STATS_BY_DELIVERY_ID: `${microServices.CNAPSMONITOR}/delivery/stats`,
    GET_DELIVERY_FITLERS:`${microServices.CNAPSMONITOR}/delivery/filters`,

    DOWNLOAD_RESULT:`${microServices.CNAPSMONITOR}/delivery/download-result`,
    DOWNLOAD_MONITOR_TEST_TAKERS:`${microServices.CNAPSMONITOR}/delivery/download-test-takers`,
    DOWNLOAD_RESPNONSE:`${microServices.CNAPSMONITOR}/delivery/download-responses`,
}