import styled from 'styled-components'

export const ProfileCardWrapper = styled.div`
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top:16px;
  margin-bottom:31px;

`