import GlobalContainer from "share/atoms/GlobalContainer";
import styled from "styled-components";


export const Container = styled(GlobalContainer)`
  background-color: #fffff;
  padding: clamp(1.5rem, -0.6923rem + 9.7436vw, 4.5rem);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const FeatureHeaderWrapper = styled.div`
  height:40px;
  h1 {
    text-align: center;
    font-size: clamp(1.25rem, 0.7885rem + 2.0513vw, 2.25rem);
  }
  h6 {
    font-family: Poppins;
    font-size: clamp(1rem, 0.8846rem + 0.5128vw, 1.25rem);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    max-width: 90%;
    margin-bottom: 36px;
  }
  h2 {
    align-self: stretch;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: var(--gray-24);
  }
`;
export const TopEllipticalWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9ff;
  border-radius: 0 0 50% 50%
}
`;
export const SectionContainer = styled(GlobalContainer)`
  background-color: #fffff;
`;


