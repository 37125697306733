import { put, call, all, takeLatest, delay, select } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import DeliveryService from "./services";
import * as deliveryActions from "./actions/list-deliveries-action";
import * as refreshActions from "./actions/refresh-delivery";
import { push } from "connected-react-router";

const dummyData={
    "status": 200,
    "message": "",
    data:{"data": [
      {
        "exam_name": "ISCO",
        "delivery_name": "Demo_ISCO_testing",
        "delivery_id": "CN46toQEFg",
        "delivery_uri": "http://35.244.54.194/gcf.rdf#16601976718237347390",
        "delivery_starttime": "2022-09-13T12:47:00.000Z",
        "delivery_endtime": "2022-08-12 15:30:00+00",
        "delivery_start_date": "2022-08-11",
        "delivery_status": "Completed",
        "auto_submit_time": "2022-08-12 16:41:00+00",
        "test_id": "xsymioe6Ts",
        "batch_list": [
          "CNAPS"
        ],
        "batch_ids": [
          "sMneFeDQ2W"
        ],
        "subbatch_list": [
          "PHASE-1"
        ],
        "subbatch_ids": [
          "Desd23BdP1"
        ],
        "college_ids": [],
        "college_list": [],
        "year_list": [
          "2022-2023"
        ],
        "class_list": [
          "10th"
        ],
        "state_list": [],
        "registration_status_list": [
          "admission",
          "registration"
        ],
        "narayana_mapping_id": 100062,
        "test_duration": "40",
        "test_question_count": "25",
        "max_marks": "25"
      },
      {
        "exam_name": "ISEO",
        "delivery_name": "Demo_ISKO_testing",
        "delivery_id": "cwVZSmpiKy",
        "delivery_uri": "http://35.244.54.194/gcf.rdf#16601975772543732755",
        "delivery_starttime": "2022-08-11 11:28:00+00",
        "delivery_endtime": "2022-08-12 19:35:00+00",
        "delivery_start_date": "2022-08-11",
        "delivery_status": "Completed",
        "auto_submit_time": "2022-08-12 20:49:00+00",
        "test_id": "wkAUeAsgUs",
        "batch_list": [
          "CNAPS"
        ],
        "batch_ids": [
          "sMneFeDQ2W"
        ],
        "subbatch_list": [
          "PHASE-1"
        ],
        "subbatch_ids": [
          "Desd23BdP1"
        ],
        "college_ids": [],
        "college_list": [],
        "year_list": [
          "2022-2023"
        ],
        "class_list": [
          "9TH"
        ],
        "state_list": [],
        "registration_status_list": [
          "admission",
          "registration"
        ],
        "narayana_mapping_id": 100063,
        "test_duration": "40",
        "test_question_count": "25",
        "max_marks": "25"
      }
    ]},
    "errorCode": null
  }

function* getDeliveryList(action) {
    try {
      const reqBody = action.payload ? { exam_date: action.payload.date } : {};
      const res = yield call(DeliveryService.getListDeliveriesApi, reqBody);
    //  const res= yield dummyData;
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_DELIERY_SUCCESS,
          payload: res.data.data,   
        });
        action.payload && action.payload.cb(res.data.data);
      } else {
        yield put({
          type: actionTypes.GET_DELIERY_FAIL,
          payload: "something went wrong",
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_DELIERY_FAIL,
        payload: e.message,
      });
    }
  }

  const response= {
    "status": 200,
     "message": "",
     "data":{
     "data": [
    {
      "delivery_id": "iJRDtyfKqU",
      "delivery_status": "In progress",
      "scheudled_count": "14012",
      "attendance_taken_count": "487",
      "inprogress_count": "1",
      "submitted_count": "0",
      "suspended_count": "0"
    }
  ],
},
  "errorCode": null
  }

  const getRefreshedData = (tableData, delivery, index) => {
    const updatedData = [...tableData];
    updatedData[index] = { ...tableData[index], ...delivery };
    return updatedData;
  };
  
  const hasFiltersFunc = (filters) =>
    filters.status_id?.length ||
    filters.sub_batch_id?.length ||
    filters.class_id;
  
  function* getStatsByDeliveryIdWorker(action) {
    try {
      const filters = yield select(({ delivery}) => delivery.deliveryList.filters);
      const hasFilters = hasFiltersFunc(filters);
      const { delivery_id, tableData, filtersData, index } = action.payload;
      const res = yield call(DeliveryService.getStatsByDeliveryIdApi, {
        delivery_id,
      });
      // const res=response
      if (res.status === 200) {
        const tData = hasFilters
          ? tableData
          : getRefreshedData(tableData, res?.data?.data[0], index);
        const fData = hasFilters
          ? getRefreshedData(filtersData, res?.data?.data[0], index)
          : [];
       
        yield delay(8);

        yield put(
          deliveryActions.setDeliveryList({
            deliveries: tData,
            filterDeliveries: fData,
          })
        );
        yield put(refreshActions.refreshDeliverySuccess(res.data.data));
      } else {
        yield put(refreshActions.refreshDeliveryError(res.data.data));
      }
    } catch (e) {
      yield put(refreshActions.refreshDeliveryError(e));
    }
  }

  function* getDeliveryFilters(action) {
    const { delivery_id, delivery_name, path } = action.payload;
    try {
      const res = yield call(DeliveryService.getDelieryFiltersApi, {
        delivery_id: delivery_id,
      });
      const { school_list} = res.data.data;
      const newData = {
        ...JSON.parse(localStorage.getItem("metadata")),
         school_list
      };
      localStorage.setItem("metadata", JSON.stringify(newData));
        yield put(
          push(
            `${path}/monitor-test-takers?delivery_id=${delivery_id}&exam_name=${delivery_name}`
          )
        );
      
    } catch (e) {
      console.log(e);
    }
  }

  export default function* () {
    yield takeLatest(actionTypes.GET_DELIERY_REQUEST, getDeliveryList);
    yield takeLatest(
      actionTypes.GET_STATS_BY_DELIVERY_ID_REQUEST,
      getStatsByDeliveryIdWorker
    );
    yield takeLatest(actionTypes.GET_DELIVERY_FILTERS_REQUEST, getDeliveryFilters)
  }