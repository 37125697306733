const CNAPS_ADMIN = "cnaps-admin/v1/admin";

export const endpoints = {
  PAYMENT_SUCCESS_CALLBACK: `${CNAPS_ADMIN}/payment-success-callback`,
  INIT_BULK_REG_PAYMENT: `${CNAPS_ADMIN}/excel-init-bulk-payment`,
  SAVE_LIST: `${CNAPS_ADMIN}/bulk-registarion-save-list`,
  STUDENT_PAYMENT_SUMMARY: `${CNAPS_ADMIN}/bulk-registration-payment-summary`,
  EXAM_PHASE_LIST: `cnaps-admin/v1/admin/get-exam-phases`,
  EXAM_PHASE_SELECTION: `${CNAPS_ADMIN}/save-school-exam-phase`,
  BULK_REGISTRATION_MANUAL: `${CNAPS_ADMIN}/bulk-registration-manual`,
  EXCEL_BULK_REGISTRATION_UPLOAD: `${CNAPS_ADMIN}/bulk-registration-excel`,
  DELETE_EXCEL_STUDENT: `${CNAPS_ADMIN}/delete-student-excel`,
  PENDING_REGISTRATIONS: `${CNAPS_ADMIN}/pending-registrations`,
  PENDING_REGISTRATIONS_PAYMENT_SUMMARY: `${CNAPS_ADMIN}/pending-registrations-payment-summary`,
  INIT_PENDING_REG_PAYMENT: `${CNAPS_ADMIN}/pending-init-bulk-payment`,
  DELETE_STUDENT: `${CNAPS_ADMIN}/delete-student`,
  BULK_MANUAL_REGISTRATIONS: `${CNAPS_ADMIN}/bulk-registration-manual`,
  STUDENT_FILTERS: `cnaps-admin/v2/common/get-student-filters`,
  GET_STUDENT_DATA: `${CNAPS_ADMIN}/get-student-exam-list`,
  INDIVIDUAL_PAYMENT_SUMMARY: `${CNAPS_ADMIN}/individual-student-payment-summary`,
  INIT_INDIVIDUAL_PAYMENT: `${CNAPS_ADMIN}/init-individual-student-add-exam-payment`,
  GET_XLSX_TEMPLATE: `cnaps-admin/v1/common/get-sample-xlsx-format`,
};

export const newRegistrationTypes = {
  VERIFY_OTP_REQUEST: "registration/VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "registration/VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "registration/VERIFY_OTP_ERROR",

  GET_STUDENT_DATA_REQUEST: "new-reg/GET_STUDENT_DATA_REQUEST",
  GET_STUDENT_DATA_SUCCESS: "new-reg/GET_STUDENT_DATA_SUCCESS",
  GET_STUDENT_DATA_FAILURE: "new-reg/GET_STUDENT_DATA_FAILURE",

  INDIVIDUAL_PAYMENT_SUMMARY_REQUEST:
    "new-reg/INDIVIDUAL_PAYMENT_SUMMARY_REQUEST",
  INDIVIDUAL_PAYMENT_SUMMARY_SUCCESS:
    "new-reg/INDIVIDUAL_PAYMENT_SUMMARY_SUCCESS",
  INDIVIDUAL_PAYMENT_SUMMARY_FAILURE:
    "new-reg/INDIVIDUAL_PAYMENT_SUMMARY_FAILURE",

  INIT_INDIVIDUAL_PAYMENT_REQUEST: "new-reg/INIT_INDIVIDUAL_PAYMENT_REQUEST",
  INIT_INDIVIDUAL_PAYMENT_SUCCESS: "new-reg/INIT_INDIVIDUAL_PAYMENT_SUCCESS",
  INIT_INDIVIDUAL_PAYMENT_FAILURE: "new-reg/INIT_INDIVIDUAL_PAYMENT_FAILURE",

  BULK_REGISTRATION_MANUAL_REQUEST: " BULK_REGISTRATION_MANUAL_REQUEST",
  BULK_REGISTRATION_MANUAL_SUCCESS: " BULK_REGISTRATION_MANUAL_SUCCESS",
  BULK_REGISTRATION_MANUAL_FAILURE: " BULK_REGISTRATION_MANUAL_FAILURE",
  BULK_REGISTRATION_MANUAL_RESET: " BULK_REGISTRATION_MANUAL_RESET",

  EXAM_PHASE_SELECTION_REQUEST: "EXAM_PHASE_SELECTION_REQUEST",
  EXAM_PHASE_SELECTION_SUCCESS: "EXAM_PHASE_SELECTION_SUCCESS",
  EXAM_PHASE_SELECTION_FAILURE: "EXAM_PHASE_SELECTION_FAILURE",

  EXCEL_UPLOAD_BULK_REGISTRATION_REQUEST:
    "EXCEL_UPLOAD_BULK_REGISTRATION_REQUEST",
  EXCEL_UPLOAD_BULK_REGISTRATION_SUCCESS:
    "EXCEL_UPLOAD_BULK_REGISTRATION_SUCCESS",
  EXCEL_UPLOAD_BULK_REGISTRATION_FAILURE:
    "EXCEL_UPLOAD_BULK_REGISTRATION_FAILURE",

  EXAM_PHASE_LIST_REQUEST: "EXAM_PHASE_LIST_REQUEST",
  EXAM_PHASE_LIST_SUCCESS: "EXAM_PHASE_LIST_SUCCESS",
  EXAM_PHASE_LIST_FAILURE: "EXAM_PHASE_LIST_FAILURE",

  PENDING_PAYMENT_SUMMARY_REQUEST: "new-reg/PENDING_PAYMENT_SUMMARY_REQUEST",
  STUDENT_PAYMENT_SUMMARY_REQUEST: "new-reg/STUDENT_PAYMENT_SUMMARY_REQUEST",
  STUDENT_PAYMENT_SUMMARY_SUCCESS: "new-reg/STUDENT_PAYMENT_SUMMARY_SUCCESS",
  STUDENT_PAYMENT_SUMMARY_FAILURE: "new-reg/STUDENT_PAYMENT_SUMMARY_FAILURE",
  PENDING_REG_PAYMENT_SUMMARY_REQUEST:
    "new-reg/PENDING_REG_PAYMENT_SUMMARY_REQUEST",

  SAVE_LIST_REQUEST: "new-reg/SAVE_LIST_REQUEST",
  SAVE_LIST_SUCCESS: "new-reg/SAVE_LIST_SUCCESS",
  SAVE_LIST_FAILURE: "new-reg/SAVE_LIST_FAILURE",

  INIT_BULK_REG_PAYMENT_REQUEST: "new-reg/INIT_BULK_REG_PAYMENT_REQUEST",
  INIT_BULK_REG_PAYMENT_SUCCESS: "new-reg/INIT_BULK_REG_PAYMENT_SUCCESS",
  INIT_BULK_REG_PAYMENT_FAILURE: "new-reg/INIT_BULK_REG_PAYMENT_FAILURE",

  PENDING_REGISTRATIONS_REQUEST: "new-reg/PENDING_REGISTRATIONS_REQUEST",
  PENDING_REGISTRATIONS_SUCCESS: "new-reg/PENDING_REGISTRATIONS_SUCCESS",
  PENDING_REGISTRATIONS_FAILURE: "new-reg/PENDING_REGISTRATIONS_FAILURE",

  DELETE_STUDENT_REQUEST: "new-reg/DELETE_STUDENT_REQUEST",

  STUDENT_FILTERS_REQUEST: "new-reg/STUDENT_FILTERS_REQUEST",
  STUDENT_FILTERS_SUCCESS: "new-reg/STUDENT_FILTERS_SUCCESS",
  STUDENT_FILTERS_ERROR: "new-reg/STUDENT_FILTERS_ERROR",

  STUDENT_LIST_REQUEST: "new-reg/STUDENT_LIST_REQUEST",
};
