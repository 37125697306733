import * as actionTypes from "../actionTypes";
import produce from "immer";

const initialState = {
  data: [],
  filtersData: [],
  filters: {},
  error: "",
  isLoading: false,
};

export const deliveryListReducer = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_DELIERY_REQUEST:
        draft.isLoading = true;
        draft.error = false;
        draft.data = [];
        break;
      case actionTypes.GET_DELIERY_SUCCESS:
        draft.data = action.payload;
        draft.filtersData = action.payload;
        draft.isLoading = false;
        break;
      case actionTypes.SET_DELIERY_LIST:
        draft.data = action.payload.deliveries;
        draft.filtersData = action.payload.filterDeliveries;
        draft.isLoading = false;
        break;
      case actionTypes.GET_DELIERY_FAIL:
        draft.error = action.payload;
        draft.isLoading = false;
        break;
      case actionTypes.SET_DELIVERY_FILTERS:
        draft.filters = action.payload;
        break;
      case actionTypes.SET_FILTER_DELIVERIES:
        draft.filtersData = action.payload;
        break;
      default:
        draft = state;
        break;
    }
  });

export default deliveryListReducer;
