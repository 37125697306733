import React from "react";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { StyledModal, Announcement, StyledButton } from "./styles";

function ExamActiveModal(props) {
  const confirmSubmit = () => {
    props.history.push("/");
  };

  return (
    <StyledModal show={props}>
      <Modal.Body>
        <Announcement>
          <div> Exam Already opened in another tab. </div>
          <StyledButton size="md" onClick={confirmSubmit}>
            {" "}
            Go Home{" "}
          </StyledButton>
        </Announcement>
      </Modal.Body>
    </StyledModal>
  );
}

export default withRouter(ExamActiveModal);
