import DownloadIcon from 'assets/dashboard/download.svg';
import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TotalPaymentMadeColumn } from 'school-admin/Dashboard/helper';
import { DownLoadLink } from 'school-admin/Dashboard/styles';
import AntdTable from "share/organisms/table/antd-table";
import injectSaga from "utils/saga/injectSaga";
import { getPaymentReceiptRequest } from "./actions";
import saga from "./saga";
import { downloadPdf } from "school-admin/NewRegistration/helper";
import { Wrapper } from "./styles";
import DropdownOptionTemp from "share/templates/DropdownOptionTemp";

const key = "paymentReceipt";

function PaymentReceipt() {
  const dispatch = useDispatch();
  const paymentReceiptState = useSelector( (state) => state.studentPaymentReceipt );

  useEffect(() => {
    dispatch(getPaymentReceiptRequest());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const totalPaymentModifiedData = (dataArr) => {
    return (
      dataArr.length > 0 &&
      dataArr.map((data) => ({
        ...data,
        createdAt: data.transaction_date,
        download: (
          <DownLoadLink onClick={() => downloadPdf(data.receipt_url)}>
            <img src={DownloadIcon} alt="downloadicon" /> Download
          </DownLoadLink>
        ),
      }))
    );
  };
  
  return (
    <DropdownOptionTemp headingText={"Payment Receipt"}>
      <Wrapper>
        <AntdTable
          columns={TotalPaymentMadeColumn}
          dataSource={totalPaymentModifiedData(paymentReceiptState.data)}
          pagination={false}
          padding="10px"
        />
      </Wrapper>
    </DropdownOptionTemp>
  );
}

const withSaga = injectSaga({ key, saga });

export default withSaga(PaymentReceipt);