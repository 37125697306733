import React, { useEffect, useState } from "react";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import {
  Heading,
  Wrap,
  DownLoadLink,
  HeadingWrap,
  DownloadStudent,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
// import { hasArrData, isTruthy } from "utils/utility/obj-arr";
import SearchForm from "./SearchForm";
import SearchList from "./SearchList/";
import DownloadIcon from "assets/school-admin/icons/download.svg";
import injectSaga from "utils/saga/injectSaga";
import saga from "./saga";
import {
  resetStudentList,
  downloadExcelSheet,
  downloadSchoolResult,
} from "./actions";
import Overlay from "share/atoms/overlay";

const key = "studentList";

function StudentList() {
  const { list } = useSelector((state) => state.studentList);
  const [loadingSchoolResult, setLoadingSchoolResult] = useState(false);
  const [loadingExcelList, setLoadingExcelList] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStudentList());
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccessDownloadExcelList = (res) => {
    if (res.status === 200) {
      setLoadingExcelList(false);
    }
  };
  const handleErrorDownloadExcelList = () => {
    setLoadingExcelList(false);
  };

  const downloadExcelFile = () => {
    setLoadingExcelList(true);
    dispatch(
      downloadExcelSheet({
        ...list.requestBody,
        handleSuccessDownloadExcelList,
        handleErrorDownloadExcelList,
      })
    );
    // dispatch(downloadExcelSheet(list.requestBody));
  };
  const handleSuccessSchoolResult = (res) => {
    if (res.status === 200) {
      setLoadingSchoolResult(false);
    }
  };
  const handleErrorSchoolResult = () => {
    setLoadingSchoolResult(false);
  };
  const downloadSchoolResultExcelFile = () => {
    setLoadingSchoolResult(true);
    dispatch(
      downloadSchoolResult({
        school_id: localStorage.getItem("school_id"),
        handleSuccessSchoolResult,
        handleErrorSchoolResult,
      })
    );
  };

  return (
    <>
      {(loadingSchoolResult || loadingExcelList) && <Overlay />}
      <WithSidepanelLayout>
        <Wrap>
          <div className="wrapper">
            <Heading>Student List</Heading>
            <DownloadStudent onClick={downloadSchoolResultExcelFile}>
              <img src={DownloadIcon} alt=" " />
              Download Student Results
            </DownloadStudent>
          </div>
          <SearchForm />
          {list.data &&
            list.data.dataSource &&
            list.data.dataSource.length >= 0 && (
              <React.Fragment>
                <HeadingWrap>
                  <Heading>Search List</Heading>
                  {list.data.dataSource.length > 0 && (
                    <div className="downloads">
                      <DownLoadLink
                        onClick={downloadExcelFile}
                        data-testid="download-excel-search-list"
                      >
                        <img src={DownloadIcon} alt=" " />
                        Download Excel List
                      </DownLoadLink>
                    </div>
                  )}
                </HeadingWrap>
                {<SearchList />}
              </React.Fragment>
            )}
        </Wrap>
      </WithSidepanelLayout>
    </>
  );
}

const withSaga = injectSaga({ key, saga });

export default withSaga(StudentList);
