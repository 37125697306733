import React from "react";
import {
  Title,
  SubTitle,
  Desc,
  StudentIcon,
  SliderContainer,
  CustomSlider,
  SlideContent,
} from "./styles";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Carousel(props) {
  const { data } = props;
  return (
    <CustomSlider {...settings}>
      {data.map((slideData, index) => (
        <SliderContainer className="row">
          <div className="desc-block col-lg-7">
            <SlideContent>
              <SubTitle> {slideData.subTitle} </SubTitle>
              <Title>{slideData.title}</Title>
              <Desc>{slideData.desc}</Desc>
            </SlideContent>
          </div>
          <div className="image-block col-lg-5 d-none d-md-none d-lg-block">
            <StudentIcon>
              <img
                className="img-fluid"
                src={slideData.image}
                alt={`slide-${index + 1}`}
              />
            </StudentIcon>
          </div>
        </SliderContainer>
      ))}
    </CustomSlider>
  );
}

export default Carousel;

Carousel.defaultProps = {
  data: [],
};
