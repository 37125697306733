export const endpoints = {
  LOGIN: `cnaps-admin/v1/admin/login`,
  PROFILE: ``,
  LOGOUT: ``,
};

export const loginTypes = {
  LOGIN_REQUEST: "login/LOGIN_REQUEST",
  LOGIN_SUCCESS: "login/LOGIN_SUCCESS",
  LOGIN_ERROR: "login/LOGIN_ERROR",
  RESET_ERROR: "login/RESET_ERROR",

  LOGOUT_REQUEST: "school/login/LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "school/login/LOGOUT_SUCCESS",
  LOGOUT_ERROR: "school/login/LOGOUT_ERROR",

  PROFILE_REQUEST: "school/login/PROFILE_REQUEST",
  PROFILE_SUCCESS: "school/login/PROFILE_SUCCESS",
  PROFILE_ERROR: "school/login/PROFILE_ERROR",
};
