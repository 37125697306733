import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AntdTable from "share/organisms/table/antd-table";
import EditStudentDetailsModal from "share/organisms/modals/EditStudentDetailsModal";
import StudentListService from "../services";
import { SearchListDiv, ActionButton } from "./styles";
import { studentListRequest, updateMobileNumber } from "../actions";

const studentListService = new StudentListService();

function SearchList() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddExam, setShowAddExam] = useState(false);
  const { list } = useSelector((state) => state.studentList);
  const [showOpacity, setShowOpacity] = useState(false);
  const [studentsDataSource, setStudentsDataSource] = useState(
    list?.data?.dataSource
  );
  useEffect(() => {
    setStudentsDataSource(list?.data?.dataSource);
  }, [list?.data?.dataSource]);

  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setShowEditModal(e);
    setShowOpacity(true);
    setShowAddExam(true);
  };

  const handleClose = () => {
    setShowEditModal(false);
    setShowAddExam(false);
  };

  const callback = (adNumber, mobileNumber) => {
    const newStudentDataSource = studentsDataSource.map((item) =>
      item.admissionNumber === adNumber
        ? { ...item, mobile_number: mobileNumber }
        : item
    );
    setStudentsDataSource(newStudentDataSource);
  };
  const updateStudentData = async (student, mobileNumber) => {
    const payload = {
      admission_number: student.admissionNumber,
      mobile_number: mobileNumber,
      cart_exam_list: student.exams_list
        .filter((exam) => exam.isSelected)
        .map((exam) => exam.exam_object_id),
      callback: callback,
    };
    dispatch(updateMobileNumber(payload));
    const reqBody = {
      admissionNumber: student.admissionNumber,
      name: student.name,
    };
    // const res = await studentListService.updateStudentDetails(reqBody);
    // dispatch(studentListRequest(reqBody));
    // if (res.data && res.data.status === "success")
    handleClose();
  };

  /**
   * this function takes the array data and append the buttons(view and edit) to the given array for antd table
   * @param {Array} dataSrc
   * @returns {Array}  returns array which has appended buttons
   */
  const appendDelete = (dataSrc) => {
    return dataSrc.map((item) => ({
      ...item,
      action: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <ActionButton
            onClick={() => handleEdit(item)}
            data-testid={`edit-search-list-${item}`}
          >
            Edit &nbsp;&nbsp;
          </ActionButton>
          <ActionButton
            onClick={() => {
              setShowEditModal(item);
              setShowOpacity(false);
            }}
            data-testid={`view-search-list-${item}`}
          >
            View
          </ActionButton>
        </div>
      ),
    }));
  };
  return (
    <SearchListDiv>
      {showEditModal && (
        <EditStudentDetailsModal
          handleClose={handleClose}
          student={showEditModal}
          showAddExam={showAddExam}
          handleYes={updateStudentData}
          showOpacity={showOpacity}
        />
      )}
      {studentsDataSource && (
        <AntdTable
          columns={list.data.columns}
          dataSource={appendDelete(studentsDataSource)}
          padding={"8px 4px 8px 4px"}
          isIndicator
        />
      )}
    </SearchListDiv>
  );
}

export default SearchList;
