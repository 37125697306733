import styled from "styled-components";
import CommonButton from "share/atoms/Button";
import GlobalContainer from "share/atoms/GlobalContainer";
import * as modes from 'utils/modes'

export const Container = styled(GlobalContainer)`
  background-color: #ffffff;
  font-family: Poppins;
  padding-top: clamp(1.5rem, 0.5769rem + 4.1026vw, 3.5rem);
  padding-bottom: clamp(1.5rem, 0.5769rem + 4.1026vw, 3.5rem);
`;

export const SchoolWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    flex-direction: column;
  }
  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    padding-bottom: 24px;
    .details-btns {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  width: 456px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Details = styled.div`
  & .details-header {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
  }

  & .details-info {
    font-size: 16px;
    line-height: 1.5;
    color: #565656;
    margin-top: 10px;
  }

  & .details-btns {
    margin-top: 28px;
  }
  @media screen and (max-width: ${modes.RESPONSIVE_MODE}px) {
    text-align: center;
    width: 100%;

    & .details-header {
    text-align: center;
    margin-top: 20px;
  }

  }
`;

export const RegistrationButton = styled(CommonButton)`
  opacity: ${({ disabled }) => (disabled ? "50%" : "100%")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
