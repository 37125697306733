import React from 'react'
import { TableWrapper, HeadingWrapper, TableHeading, TableContentWrapper, TableContent } from './styles'
import BodyItem from './body-item'

function Table({ children }) {
    return (
        <TableWrapper>
            {children}
        </TableWrapper>
    )
}

Table.Header = HeadingWrapper
Table.Body = TableContentWrapper
Table.HeaderItem = TableHeading
Table.BodyItem = BodyItem

export default Table