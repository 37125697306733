import React from "react";
import ModalTemp from "share/templates/ModalTemp";
import LogoutIcon from "assets/logout.svg";
import useModal from "share/hooks/useModal";
import { useDispatch } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { logoutRequest } from "school-admin/Login/actions";
import { Circle, StyledDropdown, Wrapper, Wrap, MonitorLogo } from "./styles";
import { getLocalObj } from "utils/utility/local-storage";
import { isPathExist } from "utils/utility/url";
import { getOptions } from "./helper";
import { useHistory, useLocation } from "react-router-dom";
import CnapsLogo from "assets/frame.svg";
import Logo from "assets/new_cnaps_logo.svg";

export default function SchoolAdminHeader({ className, leftContent }) {
  const history = useHistory();
  const location = useLocation();
  const { isVisible, handleModal } = useModal();
  const dispatch = useDispatch();
  const getFirstLetter = (name) => {
    if (!name) return "A";
    const nme = name.split("")[0].trim();
    if (nme) return nme.toUpperCase();
    return name.split("")[1].toUpperCase();
  };

  const handleClick = (key) => {
    switch (key) {
      case "Profile":
        history.push({
          pathname: `/${
            isPathExist(location, "school") ? "school" : "student"
          }/profile`,
        });
        break;
      case "Change Password":
        history.push(
          `/${
            isPathExist(location, "school") ? "school" : "student"
          }/change-password`
        );
        break;
      case "FAQ'S":
        history.push(
          `/${isPathExist(location, "school") ? "school" : "student"}/faqs`
        );
        break;
      case "Purchase History":
        history.push("/student/payment-receipt");
        break;
      default:
        handleModal();
        break;
    }
  };

  const handleLogout = () => {
    dispatch(logoutRequest());
    handleModal();
  };

  const loginDetails = getLocalObj("token-data");
  const name = loginDetails
    ? loginDetails.name || loginDetails.student_name||loginDetails.admin_name
    : "";
  const loginType = localStorage.getItem("login-type");

  const dropdownOptions = getOptions();

  return (
    <Wrap loginType={loginType} className={className}>
      {isVisible && (
        <ModalTemp
          icon={LogoutIcon}
          title={"Logout"}
          desc={"Are you sure you want to log out?"}
          onCancel={handleModal}
          onOk={handleLogout}
        />
      )}

      {isPathExist(location, "monitor") ? (
        <MonitorLogo onClick={()=>history.push("/monitor/deliveries")}>
          <img src={Logo} alt="cnaps-logo" /> <h3> Monitor </h3>{" "}
        </MonitorLogo>
      ) : (
        ""
      )}
      {leftContent ? leftContent : <div />}
      <div className="edge-block">
        {/* <Circle><FaRegBell/></Circle> */}
        <Circle>
          <span>{getFirstLetter(name)}</span>
        </Circle>
        <StyledDropdown
          variant=""
          title={
            <h3>
              {" "}
              {name} &nbsp; <FaChevronDown />{" "}
            </h3>
          }
        >
          {dropdownOptions.map((field) => (
            <Dropdown.Item
              onClick={() => handleClick(field.name)}
              key={field.id}
            >
              <Wrapper>
                <div className="icon">
                  <field.icon icon={field.iconName} />
                </div>
                <p className="item-name">{field.name}</p>
              </Wrapper>
            </Dropdown.Item>
          ))}
        </StyledDropdown>
      </div>
    </Wrap>
  );
}
