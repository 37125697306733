// import { useNetworkStatus } from 'share/hooks';
import React from 'react';
import styled from 'styled-components';
import { StyledButton, ButtonIcon } from './styles';

const Button = ({
  disabled,
  theme,
  children,
  iconLeft,
  iconRight,
  icnSrc,
  type,
  ...rest
}) => {
  // const isOnline=useNetworkStatus()
  return (
    <StyledButton
      iconLeft={iconLeft}
      iconRight={iconRight}
      disabled={disabled}
      icnSrc={icnSrc}
      {...rest}
    >
      {iconLeft ? <ButtonIcon src={icnSrc} alt={icnSrc.toString()} /> : ''}
      {children}
      {iconRight ? <ButtonIcon src={icnSrc} alt={icnSrc.toString()} /> : ''}
    </StyledButton>
  );
};

export default Button;
export const PrimaryButton=styled(Button)`
    background-color:red;
`
export const SecondaryButton=styled(Button)`
    background-color:blue;
`