//Banglore
import Banner1 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4143.JPG";
import Banner2 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4384.JPG";
import Banner3 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4450.JPG";
import Banner4 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4454.JPG";
import Banner5 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4489.JPG";
import Banner6 from "../assets/landingpage/award-ceremonies/bangalore/2P4A4537.JPG";

// Hyderabad
import Banner7 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00302.JPG";
import Banner8 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00312.JPG";
import Banner9 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00521.JPG";
import Banner10 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00606.JPG";
import Banner11 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00724.JPG";
import Banner12 from "../assets/landingpage/award-ceremonies/hyderabad/DSC00302.JPG";

// Chennai

import Banner13 from "../assets/landingpage/award-ceremonies/chennai/_DSC5265.JPG";
import Banner14 from "../assets/landingpage/award-ceremonies/chennai/_DSC5268.JPG";
import Banner15 from "../assets/landingpage/award-ceremonies/chennai/_DSC5310.JPG";
import Banner16 from "../assets/landingpage/award-ceremonies/chennai/_DSC5337.JPG";
import Banner17 from "../assets/landingpage/award-ceremonies/chennai/_DSC5344.JPG";
import Banner18 from "../assets/landingpage/award-ceremonies/chennai/_DSC5355.JPG";

// Videos

export const awardImageGallery = [
  {
    caption: "Bangalore",
    banners: [
      {
        id: 1,
        image: Banner1,
      },
      {
        id: 2,
        image: Banner2,
      },
      {
        id: 3,
        image: Banner3,
      },
      {
        id: 4,
        image: Banner4,
      },
      {
        id: 5,
        image: Banner5,
      },
      {
        id: 6,
        image: Banner6,
      },
    ],
  },
  {
    caption: "Hyderabad",
    banners: [
      {
        id: 7,
        image: Banner7,
      },
      {
        id: 8,
        image: Banner8,
      },
      {
        id: 9,
        image: Banner9,
      },
      {
        id: 10,
        image: Banner10,
      },
      {
        id: 11,
        image: Banner11,
      },
      {
        id: 12,
        image: Banner12,
      },
    ],
  },
  {
    caption: "Chennai",
    banners: [
      {
        id: 13,
        image: Banner13,
      },
      {
        id: 14,
        image: Banner14,
      },
      {
        id: 15,
        image: Banner15,
      },
      {
        id: 16,
        image: Banner16,
      },
      {
        id: 17,
        image: Banner17,
      },
      {
        id: 18,
        image: Banner18,
      },
    ],
  },
];

export const videoGallery = [
  {
    id: 1,
    caption: "Bengaluru",
    youtubeLink: "https://www.youtube.com/watch?v=fbpDA_gDv2c",
  },
  {
    id: 2,
    caption: "Hyderabad",
    youtubeLink: "https://www.youtube.com/watch?v=wvwgC_9am8o",
  },
  {
    id: 3,
    caption: "Chennai",
    youtubeLink: "https://www.youtube.com/watch?v=lbNGS3tam7M",
  },
  {
    id: 4,
    caption: "Glimpses",
    youtubeLink: "https://youtu.be/7CgsVWnJ7gQ",
  },
];
