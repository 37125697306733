import { toast } from "react-toastify";
import styled from "styled-components";
import Success from "assets/success.svg";
import Info from "assets/info.svg";
import Error from "assets/error.svg";
import Close from "assets/close.svg";
import { useNetworkStatus } from "share/hooks";

const TOAST_BACKGROUNDS = {
  success: "#5ecb5e",
  error: "#dc3545",
  info: "#2f80ed",
  warning: "#ffc37b",
};
const ToastWrapper = styled.div`
  width: 354px;
  min-height: 72px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: white;
  display: flex;
  position: relative;
  align-items: center;
  .close-icon {
    position: absolute;
    right: 70px;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
  }
`;
const ToastBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: 10px;
  width: 70%;
`;

const ToastBody = styled.p`
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  word-wrap: break-all;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColoredDiv = styled.div`
  background-color: ${(props) => TOAST_BACKGROUNDS[props.type]};
  width: 2%;
  height: 100%;
  margin-right: 5px;
`;
const TOAST_ICON_SET = {
  success: Success,
  error: Error,
  info: Info,
};

const CustomToastComp = ({ closeToast, type, payload }) => {
  return (
    <ToastWrapper>
      <ColoredDiv type={type}></ColoredDiv>
      {/* <img src={Close} className="close-icon" style={{cursor:"pointer",paddingLeft:"3px"}} onClick={closeToast} alt="closeicon"/> */}
      {type === "error" ? (
        <ErrorIcon />
      ) : (
        <img src={TOAST_ICON_SET[type]} alt="toast" />
      )}

      <ToastBodyWrapper>
        <ToastBody>
          {payload} <CloseIcon />{" "}
        </ToastBody>
      </ToastBodyWrapper>
    </ToastWrapper>
  );
};

const StyledNewToastWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  gap: 12px;
  padding: 10px;
  padding-right: 0px;
  cursor: default;

  .Toastify__toast-container {
    width: 354px;
  }

  .Toastify__toast--default {
    background-color: red !important;
  }

  .Toastify__toast-container .Toastify__toast-container--top-right {
    background-color: red !important;
  }

  .ljzPcQ {
    background-color: black;
  }
`;

const StyledCloseButtonWrapper = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCloseButton = styled.button`
  height: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  margin-right: 14px;

  &:focus {
    outline: none;
  }
`;

const CloseButton = ({ closeToast }) => {
  return (
    <StyledCloseButtonWrapper>
      <StyledCloseButton onClick={closeToast}>
        <CloseIcon />
      </StyledCloseButton>
    </StyledCloseButtonWrapper>
  );
};

const NewCustomComp = ({ type, payload }) => {
  const isOnline = useNetworkStatus();
  return (
    <StyledNewToastWrapper>
      {" "}
      {type === "error" ? (
        <ErrorIcon />
      ) : (
        <img src={TOAST_ICON_SET[type]} alt="toast" />
      )}{" "}
      <div style={{ maxWidth: "200px", color:'#464646', fontSize:'12px', fontStyle:'Poppins' }}>
        {!isOnline &&
        ["something went wrong", "Something went wrong!"].includes(
          payload.toLowerCase()
        )
          ? "No Internet Connection"
          : payload}
      </div>
    </StyledNewToastWrapper>
  );
};

const notify = (type, payload, time) => {
  const options = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time || 4000,
  };

  // return toast[type](payload || type, { ...options });
  return toast(<NewCustomComp type={type} payload={payload} />, {
    ...options,
    closeOnClick: false,
    hideProgressBar: true,
    closeButton: CloseButton,
  });
};

export default notify;

const ErrorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 52 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.987366 33.8885V20.4445C0.987366 15.9218 3.39281 11.7406 7.30001 9.46513L20.0043 2.07121C21.9448 0.942344 24.1496 0.347656 26.3946 0.347656C28.6395 0.347656 30.8444 0.942344 32.7848 2.07121L45.4891 9.46513C49.3992 11.7406 51.8046 15.9218 51.8046 20.4445V33.8885C51.8046 38.4112 49.3992 42.5923 45.492 44.8678L32.7877 52.2618C30.8472 53.3906 28.6424 53.9853 26.3974 53.9853C24.1525 53.9853 21.9477 53.3906 20.0072 52.2618L7.30289 44.8678C3.39281 42.5923 0.987366 38.4112 0.987366 33.8885Z"
        fill="#D71A63"
      />
      <path
        d="M26.396 27.1667V14.4624M26.3932 37.0478C26.0036 37.0478 25.6873 37.364 25.6902 37.7536C25.693 37.939 25.7686 38.1158 25.9007 38.2459C26.0327 38.376 26.2107 38.4489 26.396 38.4489C26.5814 38.4489 26.7594 38.376 26.8914 38.2459C27.0235 38.1158 27.0991 37.939 27.1019 37.7536C27.1018 37.6607 27.0833 37.5688 27.0476 37.483C27.0119 37.3972 26.9597 37.3194 26.8939 37.2538C26.8281 37.1883 26.75 37.1363 26.6641 37.101C26.5782 37.0657 26.4861 37.0476 26.3932 37.0478Z"
        stroke="white"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          stroke="#242424"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.4"
          d="M12 4l-8 8M4 4l8 8"
        />
      </svg>
    </div>
  );
};
