import produce from "immer";
import { BACKGDROP_REQUEST } from "./actions";

const initialState = {
  open: false,
};

export const backdropRequest = (state = initialState, action = {}) =>
  produce(state, (draft) => {
    switch (action.type) {
      case BACKGDROP_REQUEST:
        draft.open = action.payload;
        break;
      default:
        draft = state;
        break;
    }
  });

export default backdropRequest;
