import React, { useState } from "react";
import { Segmented, Space } from "antd";
import "./styles.css";
import { useResize } from "share/hooks/useResize";

const App = ({ onSwitch }) => {
  const [value, setValue] = useState("all");
  const handleChange = (opt) => {
    setValue(opt);
    onSwitch(opt);
  };
  const { width } = useResize();
  const cls = width <= 800 ? "Std" : "Standard";
  return (
    <>
      <Space direction="horizontal">
        <Segmented
          onChange={(e) => handleChange(e)}
          value={value}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>All</div>
                </div>
              ),
              value: "all",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    1<sup>st</sup> - 5<sup>th</sup> {cls}
                  </div>
                </div>
              ),
              value: "1",
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <div>
                    6<sup>th</sup> - 10<sup>th</sup> {cls}
                  </div>
                </div>
              ),
              value: "6",
            },
          ]}
        />
      </Space>
    </>
  );
};

export default App;
