import styled, { css } from 'styled-components'

export const PaginatedButton = styled.a`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin:auto 12px;
`

export const TableWrap = styled.div`
  /* pagination styles start*/
  .ant-table-pagination.ant-pagination{
      display:flex;
      align-items:center;
      height:50px;
      .ant-pagination-item {
        margin-bottom: 10px;
        height:auto;
      }
      .ant-pagination-item::hover {
        height:auto;
      }
      .ant-pagination-options{
        .ant-select-selector::after{
            margin-left:12px;
        }
      }
  }
  .ant-table-pagination-center{
    display: flex;
    justify-content: center;
    padding:25px;
    .ant-pagination-total-text{
  font-family: Poppins;
  position: absolute;
  left:10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-top:-30px;
  margin-right: 40px;
}

  }
  .ant-pagination-item-active a {
    background-color: #d71a63;
    color: #ffff !important;
  }
  .ant-pagination-item-active {
    border: none;
    font-family: Poppins;
    font-size: 16px;
  }
  .ant-pagination-item a {
    font-family: Poppins;
    font-size: 16px;
  }

  .ant-pagination-item:hover{
    border-color:#254082 ;
  }

  .ant-pagination-item a:hover {
   color:#254082;
  }

  /* pagination styles end */


  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #d71a63;
    background-color: #d71a63;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #d71a63;
  }
  .ant-table .ant-table-container {
    border: 1px solid #e0dede;
    .ant-table-content {
      table {
        border-color: #e0dede;
      }
      th {
        /* background: rgba(224, 222, 222, 0.3); */
        background:#f6f5f5;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.41px;
        text-align: left;
        color: #464646;
        padding: ${({ padding }) => (padding ? padding : "8px 4px")};
        text-align: center;
        border-bottom: 1px solid #e0dede !important;
        border-right: 1px solid #e0dede !important;
        padding: 0px 16px;
        height: 26px;
      }
      td {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #242424;
        padding: 12px 16px;
        text-align: center;
        border-bottom: 1px solid #e0dede !important;
        border-right: 1px solid #e0dede !important;
      }
    }
  }
`;
const TABLE_PADDING = {
  "sm": "9px 38px 11px 20px",
  "md": "24px 38px 22px 38px",
}
export const StyledTable = styled.table`
   * {
      box-sizing: border-box;
    }
    border-collapse: collapse;
    font-family:inherit;
    margin:3px;
    width:${({ block }) => block ? "99%" : 'auto'};

    td{
        /* padding: 24px 38px 22px 38px; */
        padding: ${({ padding }) => TABLE_PADDING[padding] ? TABLE_PADDING[padding] : padding};
        border: solid 1px #edeff9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:335px;
        vertical-align: middle;
        ${({ list }) => list && css`
            padding: 9px 57px 9px 20px;
            border-top:none;
            border-left:none;
            border-right:none;
        `}
        ${({ noPad }) => noPad && css`
            padding:0px;
            padding-right:-10px;
            z-index:100;
        `}

    };
  
`

export const TableHead = styled.th(({ align, padding }) => `
    text-align: ${align ? align : 'center'};
    vertical-align: middle;
    /* padding: 24px 38px 22px 38px; */
    padding: ${TABLE_PADDING[padding] ? TABLE_PADDING[padding] : padding};
    background-color: #f9f9ff;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #242424;
    font-family:inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:335px;
    border: solid 1px #edeff9;
    border-top-style:none;

    &:first-child {
        border-left-style:none;
    }
    &:last-child {
        border-right-style:none;
    }
`)


export const Indicator= styled.div`
margin-top: 8px;
display:flex;
.indicator-wrapper{
  display: flex;
  align-items:center;
  margin-left:10px;
}
.green{
  width:12px;
  height:12px;
  border-radius: 12px;
  background-color:green;
  margin-right: 6px;
}
.red{
  width:12px;
  height:12px;
  border-radius: 12px;
  background-color:red;
  margin-right: 6px;
}
.text{
  font-family: Poppins;
  font-size: 14px;
}
`