import React from "react";
import Banner from "./banner";
import Research from "./Research";
import Stats from "./Stats";
import Footer from "student/LandingPage/Footer";
import { BannerWrap, SchoolLandingLayout, ResearchWrap, Blur } from "./styles";
import Features from "containers/Features";

const Landingpage = (props) => {
  return (
    <React.Fragment>
      <Features />
      <BannerWrap>
        <SchoolLandingLayout>
          <Blur />
          <Banner />
        </SchoolLandingLayout>
      </BannerWrap>

      <ResearchWrap>
        <Research />
      </ResearchWrap>
      <SchoolLandingLayout style={{ backgroundColor: "#ffffff" }}>
        <Stats />
      </SchoolLandingLayout>
      <Footer />
    </React.Fragment>
  );
};

export default Landingpage;
