import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import WithSidepanelLayout from "layouts/WithSidepanelLayout";
import {
  StyledButton,
  Container,
  Footer,
  Body,
  MainHeader,
  AccessCardWrapper,
  LoadingImage,
} from "./styles";
import { getValues } from "utils/utility/obj-arr";
import { getParams } from "utils/utility/test-engine";
import * as storageUtils from "utils/localStorageUtils";
import injectSaga from "utils/saga/injectSaga";
import saga from "./saga";
import { setAsActiveExamTab } from "utils/utility/student";
import { countSpentTime } from "../test/actions";
import AccessCard from "./access-card";
import UserImgage from "./image";
import HeaderSection from "./header";
import { withoutSidePanelStyles, commonStyles } from "./helpers";
import ExamActiveModal from "containers/instructions/exam-active-modal";
import Proctor from "utils/web-proctoring/index";
import { useTriggerMediaPermission } from "utils/web-proctoring/useMediaPermission";
import { useIP } from "utils/web-proctoring/useIP";
import { useDeviceID } from "utils/web-proctoring/useDeviceID";
import { getBrowserDetails, getOSDetails } from "analytics";
import {
  setImageProctorRequest,
  getProctorConfigRequest,
  updateProctorConsentRequest,
  updateProctorEventRequest,
  proceedTestRequest,
  examDetailsRequest,
} from "./actions";
import { useScrollTo } from "share/hooks/useScrollTo";
import { useMediaPermissions } from "./useMediaPermissions";
import { setMediaPermision, getMediaPermission } from "containers/test/helpers";
import TestService from "containers/test/services";
import { Spinner } from "react-bootstrap";

function Prcotoring(props) {
  const testService = new TestService();
  const dispatch = useDispatch();
  const proctor = new Proctor();
  const { examDetailsReducer } = useSelector(
    ({ proctor: proctordata }) => proctordata
  );
  const {
    data: { paper_status, is_proctoring_enabled, exam_category_name },
  } = { ...examDetailsReducer };
  const [proctorImg, setProctotImg] = useState({});
  const [activeExam, setActiveExam] = useState(false);
  const [isAudioPermissionActivated, setIsAudioPermissionActivated] =
    useState("");
  const [isTriggerOnce, setIsTriggerOnce] = useState(true);
  const [isAudioOn, setIsAudioOn] = useState("");
  const [isVideoOn, setIsVideoOn] = useState("");
  const [audioCheck] = useMediaPermissions({ audio: true, video: false });
  const [videoCheck] = useMediaPermissions({ audio: false, video: true });
  const browserIP = useIP();
  const operating_system = getOSDetails();
  const browser_name = getBrowserDetails();
  const device_id = useDeviceID();
  const location = useLocation();
  const urlparams = getParams(location);
  const admissionNumber = JSON.stringify(localStorage.getItem("admsnNumber"));
  const exam_id = `${getValues(urlparams).join("-")}-${admissionNumber}`;
  const examStatus = storageUtils.getExamStatus(exam_id);
  const { exam_model_id } = props.examDetails;
  const pathName = location.pathname.split("/")[2];
  const [test_id, delivery_id, exam_category] =
    pathName && pathName?.split("-");
  useScrollTo(0, 0);

  const mediaPermssionCallback = (res) => {
    if (res.name === "video_capture") {
      window.location.reload();
    }
    if (res.name === "audio_capture") {
      setIsAudioPermissionActivated(res.state);
    }
  };

  useTriggerMediaPermission(mediaPermssionCallback);

  useEffect(() => {
    // if (!props.examDetails) {
    const reqBody = {
      test_id,
      delivery_id,
      exam_category,
    };
    dispatch(examDetailsRequest(reqBody));
    // }
  }, []);

  const callback = async (data) => {
    if (data.type === "video" && data.status === "success") {
      setProctotImg(data);
      proctor.ejectVideoCapture();

      const datas = JSON.parse(sessionStorage.getItem("proctor-config"));
      const { events } = { ...datas };
      const snapEvent = events?.filter((item) => item.name === "snap");
      const { eventid } = snapEvent[0];
      try {
        const res = await testService.getPreSignedUrl({
          test_id,
          event_id: eventid,
        });
        if (res && res?.data && res?.data?.data) {
          const { "pre-signed-urls": signedUrls } = res.data.data;
          const base64String = data.data;

          // Remove the data URI prefix if it exists
          const base64WithoutPrefix = base64String.replace(
            /^data:image\/(png|jpeg|jpg);base64,/,
            ""
          );

          // Decode the Base64 string to binary data
          const binaryData = atob(base64WithoutPrefix);

          // Create a Uint8Array to hold the binary data
          const byteArray = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
          }

          // Create a Blob object from the binary data
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const response = await axios.put(signedUrls[0].pre_signed_url, blob);
          const {
            config: { url },
          } = response;
          const object_url = url.split("?")[0];
          const reqBody = {
            delivery_id: delivery_id,
            test_id: test_id,
            event_id: eventid,
            event_data: {
              name: "snap",
              timestamp: new Date().getTime(),
              report_url: object_url,
              snap_type: paper_status === "in_progress" ? "resume" : "start",
            },
          };
          dispatch(updateProctorEventRequest(reqBody));
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    if (data.status === "denied") {
      setProctotImg(data);
    }
    if (data.status === "alert") {
      setProctotImg(data);
    }
  };

  useEffect(() => {
    const exam_id = localStorage.getItem("exam_id");
    props.getProctorConfig({
      exam_id: exam_id ? exam_id : exam_model_id,
      test_id: test_id,
    });
    return () => {
      proctor.ejectVideoCapture();
    };
  }, []);
  useEffect(() => {
    (async () => {
      const audioPerm = await getMediaPermission({ video: false, audio: true });
      const videoPerm = await getMediaPermission({ video: true, audio: false });
      if (audioPerm && videoPerm) {
        setIsAudioOn(audioPerm);
        setIsVideoOn(videoPerm);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      if (
        device_id &&
        isTriggerOnce &&
        browserIP &&
        is_proctoring_enabled &&
        exam_category_name === "Scheduled Tests"
      ) {
        const datas = JSON.parse(sessionStorage.getItem("proctor-config"));
        // need to check whether getting from session or not
        if (datas) {
          const { events } = datas;
          const snapEvent = events?.filter((item) => item.name === "snap");
          let snapTimer;
          if (snapEvent.length) {
            // change string to int value
            snapTimer = parseInt(snapEvent[0]["ConfigData"]["snap_timer"]);
          } else {
            snapTimer = 5;
          }
          proctor.initiateVideoCapture(snapTimer, 200, callback);
          setIsTriggerOnce(false);
        }
      }
    } catch (error) {
      console.error("proctoring page snap error", error);
      proctor.initiateVideoCapture(5, 200, callback);
    }
  }, [device_id, browserIP, is_proctoring_enabled, exam_category_name]);
  const handleExamProceed = async () => {
    const audioPerm = await setMediaPermision({ video: false, audio: true });
    const videoPerm = await setMediaPermision({ video: true, audio: false });
    const isAudio = String(audioPerm) === "true" ? true : false;
    const isVideo = String(videoPerm) === "true" ? true : false;
    localStorage.setItem(
      "is_proctered",
      JSON.stringify(isAudio && isVideo ? true : false)
    );
    const reqBody = {
      delivery_id: delivery_id,
      test_id: test_id,
      audio_consent: isAudio,
      camera_consent: isVideo,
      ip_address: browserIP,
      device_details: {
        device_type: "web",
        os: operating_system?.split(" ")[0],
        browser_name: browser_name,
        device_id: device_id,
      },
    };
    props.updateProctorConsent(reqBody);
    if (examStatus) {
      setActiveExam(true);
    } else {
      setAsActiveExamTab();
      if (pathName.includes("-")) {
        props.updateSpentTime(0);
        props.proceedTest({
          test_id,
          delivery_id,
          path: "proctor",
        });
      }
    }
  };
  const handlePermissionCheck = async () => {
    if (browser_name.includes("Firefox") || browser_name.includes("Safari")) {
      const audioPerm = await getMediaPermission({ video: false, audio: true });
      const videoPerm = await getMediaPermission({ video: true, audio: false });
      if (isVideoOn !== "" && videoPerm !== isVideoOn) {
        window.location.href = window.location.href;
      }
      if (isAudioOn !== "" && audioPerm !== isAudioOn) {
        setIsAudioOn(audioPerm);
      }
    }
  };

  return (
    <>
      {activeExam ? (
        <ExamActiveModal
          show={activeExam}
          url={props.match.url}
          admissionNumber={admissionNumber}
          location={props.location}
        />
      ) : null}
      <div onMouseEnter={handlePermissionCheck}>
        <WithSidepanelLayout styles={withoutSidePanelStyles}>
          <MainHeader>Proctoring: Device Access</MainHeader>
          <Container>
            <HeaderSection />
            <AccessCardWrapper>
              <AccessCard type="video" isActive={isVideoOn} />
              <AccessCard
                type="audio"
                isActive={
                  browser_name.includes("Firefox") ||
                  browser_name.includes("Safari")
                    ? isAudioOn
                    : String(isAudioPermissionActivated)
                    ? isAudioPermissionActivated
                    : audioCheck
                }
              />
            </AccessCardWrapper>
            <Body>
              {proctorImg.status === "success" ? (
                <UserImgage
                  imgSrc={proctorImg.data}
                  commonStyles={commonStyles}
                />
              ) : (
                <main id="videobox" style={{ ...commonStyles }}>
                  {/* {isVideoOn === "" && (
                    <LoadingImage>
                      <Spinner animation="border" variant="light" /> Initializing 
                    </LoadingImage>
                  )} */}
                </main>
              )}
            </Body>
            <Footer>
              <StyledButton
                $isDisabled={
                  !(proctorImg.status === "success") &&
                   videoCheck === "granted"
                }
                onClick={() => {
                  if (
                    !(
                      !(proctorImg.status === "success") &&
                      videoCheck === "granted"
                    )
                  ) {
                    handleExamProceed();
                  }
                }}
              >
                Start Test
              </StyledButton>
            </Footer>
          </Container>
        </WithSidepanelLayout>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { instructions, appReducer, test, examDetails } = state;
  return {
    ...instructions,
    instructionsError: instructions.error,
    appData: appReducer.data,
    examDetails: examDetails.data,
  };
};

const mapDispatchToProps = {
  proceedTest: proceedTestRequest,
  updateSpentTime: countSpentTime,
  setImageProctor: setImageProctorRequest,
  getProctorConfig: getProctorConfigRequest,
  updateProctorConsent: updateProctorConsentRequest,
};

const withSaga = injectSaga({ key: "proctoring", saga });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSaga(Prcotoring));
