import { fromRight, fromLeft,fromTop,fadeIn,fromBottom } from './keyframes';
const fontFamily = "Poppins";

export const defaultTheme = {
  palette: {
    common: {
      white: '#ffffff',
      black: '#000000',
      blackAqua:'#454545'
    },
    primary: {
      main: '#d71a63',
    },
    danger:{
      main:'#dc3545',
    },
    orange:{
      main:'#ff6161',
    },
    warning: {
      main: '#ffc008',
      aqua:'#ffe8ba'
    },
    info: {
      light: '#44ade8',
      main: '#1757e1',
      ultraLight:'#cee8ff',
    },
    success: {
      main: '#5ecb5e',
    },
    grey: {
      primary:'#e8e8f3',
      aqua:'#f9f9ff',
      40: '#666666',
      50: '#fafafa',
      100: '#fcfcfd',
      200: '#efefef',
      211: '#d3d3d3',
      300: '#e5e5e5',
      500: '#949599',
      600: '#808080',
      900: '#212226',
  
    },
    text: {
      primary: '#212226',
      secondary: '#949599',
      disabled: '#efefef',
      hint: '#808080',
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: fontFamily,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '32px',
        margin: 0,
      },
      h2: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '24px',
        margin: 0,
      },
      h3: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '20px',
        margin: 0,
      },
      h4: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '16px',
        margin: 0,
      },
      h5: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '15px',
        margin: 0,
      },
      h6: {
        fontFamily: fontFamily,
        fontWeight: 'bold',
        fontSize: '14px',
        margin: 0,
      },

      subtitle1: {
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: '16px',
        margin: 0,
      },

      subtitle2: {
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
      },
      body1: {
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: '16px',
        margin: 0,
      },
      body2: {
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
      },
      button: {
        fontFamily: fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        margin: 0,
      },
      caption: {
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: '12px',
        margin: 0,
      },
      
    },
  },
  keyframes: {
    fromRight,
    fromLeft,
    fromTop,
    fadeIn,
    fromBottom
  },
  mediaQueries: {
    mobile:'screen and (max-width: 600px) {',
  },
};
