const CNAPS = 'cnaps-student/v1/student';

export const endpoints = {
    SEND_OTP: `${CNAPS}/send-otp`,
    VALIDATE_OTP: `${CNAPS}/validate-otp`,
    SELECT_DATA: `cnaps-student/v1/usage/registration-metadata`,
    REGISTER: `${CNAPS}/register`
};

export const otpTypes = {
    VERIFY_OTP_REQUEST: "student/registration/VERIFY_OTP_REQUEST",
    VERIFY_OTP_SUCCESS: "student/registration/VERIFY_OTP_SUCCESS",
    VERIFY_OTP_ERROR: "student/registration/VERIFY_OTP_ERROR",
    RESET_OTP: "student/registration/RESET_OTP",

    SEND_OTP_REQUEST: "student/registration/SEND_OTP_REQUEST",
    SEND_OTP_SUCCESS: "student/registration/SEND_OTP_SUCCESS",
    SEND_OTP_ERROR: "student/registration/SEND_OTP_ERROR",

    SELECT_DATA_REQUEST: "student/registration/SELECT_DATA_REQUEST",
    SELECT_DATA_SUCCESS: "student/registration/SELECT_DATA_SUCCESS",
    SELECT_DATA_ERROR: "student/registration/SELECT_DATA_ERROR",

    REGISTER_REQUEST: "student/registration/REGISTER_REQUEST",
    REGISTER_SUCCESS: "student/registration/REGISTER_SUCCESS",
    REGISTER_ERROR: "student/registration/REGISTER_ERROR"
}