import { useEffect, useState } from "react";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";

export const useMediaPermissions = (constraints) => {
  const [status, setStatus] = useState("");
  useEffect(() => {
    requestMediaPermissions(constraints)
      .then(() => {
        setStatus("granted");
      })
      .catch((err) => {
        const { type, name, message } = err;
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          setStatus("no permission");
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          setStatus("denied");
        } else if (
          type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          setStatus("use other app");
        } else {
          setStatus("something went wrong");
        }
      });
  }, [navigator.permissions]);
  return [status];
};
