import WarningLogo from "assets/deletion/warning.svg";
import SuccessLogo from "assets/deletion/success.svg";
import NotAllowedLogo from "assets/deletion/not_alowed.svg";
import AuthLogo from "assets/deletion/auth.svg";
import VerifyLogo from "assets/deletion/verify.svg";

export const modalData = {
  warning: {
    type: "warning",
    header: "Warning!",
    content:
      "Deleting your account means you'll lose access permanently. You will be logged out of the mobile/desktop application if you take this action. You'll need to create a new account to use the platform again. Are you sure you want to proceed?",
    logo: WarningLogo,
    button: "No",
  },
  success: {
    type: "success",
    header: "Account Successfully Deleted",
    content:
      "Your account has been successfully deleted. You have been logged out of all devices.",
    logo: SuccessLogo,
    button: "Go to Home",
  },
  not_allowed: {
    type: "not_allowed",
    header: "Account Deletion Not Permitted",
    content:
      "Uh-oh! This action is not allowed. If you have any concerns or if there's something you'd like to discuss, please contact our support team at [support@cnaps.co.in]. We're here to make things right!",
    logo: NotAllowedLogo,
    button: "Okay",
  },
  auth: {
    type: "auth",
    header: "Authentication Required",
    content: "Verify Your Account Details to Proceed",
    logo: AuthLogo,
    button: "Submit",
  },
  verify: {
    type: "verify",
    header: "Verify OTP",
    content: "Please enter OTP sent to your registered mobile number",
    logo: VerifyLogo,
    button: "Verify",
  },
};

export const getCustomHeight = (screenHeight) => {
  return screenHeight > 1000
    ? "7400px"
    : screenHeight >= 960
    ? "7540px"
    : screenHeight >= 800
    ? "7400px"
    : screenHeight >= 768
    ? "7400px"
    : screenHeight >= 641
    ? "7400px"
    : screenHeight >= 633
    ? "7400px"
    : screenHeight > 600
    ? "8220px"
    : screenHeight >= 551
    ? "7000px"
    : "7000px";
};

export const getDeviceType = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return "phone";
  } else {
    return "computer";
  }
};

export const getBrowserType = () => {
  if (navigator.userAgent.match("SamsungBrowser")) {
    return "samsung";
  }
  if (navigator.userAgent.match("Chrome")) {
    return "chrome";
  }
  if (navigator.userAgent.match("Firefox")) {
    return "firefox";
  }
  if (navigator.userAgent.match("Edg")) {
    return "edge";
  }
};
