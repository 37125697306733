import React from "react";
import { FaRupeeSign } from "react-icons/fa";
import {
  Wrapper,
  Icon,
  CardHeader,
  CardBody,
  CardFooter,
  Price,
  Abbrevation,
  Details,
  MoreInfoBtn,
  BuyNowBtn,
} from "./style";
import { useResize } from "share/hooks/useResize";
import fiftyOffWithArc from "assets/fifttyoffwitharc.svg";

function OlympiadCard({ exam, handleMoreInfo, handleBuy }) {
  const { class: Class, fiftyOff, icon, name, abr, isBuyNowEnable } = exam;
  const { width } = useResize();
  const handleBuyNow = (condition) => {
    if (!condition) {
      handleBuy();
    }
  };
  return (
    <Wrapper $isResponsive={width > 800}>
      {fiftyOff && <img src={fiftyOffWithArc} alt="" className="fifty_off" />}
      <CardHeader>
        <Icon src={icon} alt="" />
        <Price>
          <div className="title">Exam Fee</div>
          <div className="cost">
            <FaRupeeSign fontSize="16px" />
            300/-
          </div>
        </Price>
      </CardHeader>
      <CardBody>
        <Abbrevation>{name}</Abbrevation>
        <Details>
          <span>
            <span className="detail-name">Class</span> :
            {Class === 1 ? (
              <span className="detail-value">
                {" "}
                1<sup>st</sup> - 10<sup>th</sup>
              </span>
            ) : (
              <span className="detail-value">
                {" "}
                6<sup>th</sup> - 10<sup>th</sup>
              </span>
            )}
          </span>
          <span>
            <span className="detail-name">Exam Date</span> :
            <span className="detail-value"> {exam.date}</span>
          </span>
        </Details>
      </CardBody>
      <CardFooter>
        <MoreInfoBtn onClick={() => handleMoreInfo(abr)}>More Info</MoreInfoBtn>
        <BuyNowBtn
          $isDisabled={!isBuyNowEnable}
          onClick={() => handleBuyNow(!isBuyNowEnable)}
        >
          {isBuyNowEnable ? "Buy Now" : "Coming Soon"}
        </BuyNowBtn>
      </CardFooter>
    </Wrapper>
  );
}

export default OlympiadCard;
