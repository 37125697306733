import badgeIcon from "assets/landingpage/cnaps-legacy/badgeIcon.svg";
import buildingIcon from "assets/landingpage/cnaps-legacy/buildingIcon.svg";
import assessmentIcon from "assets/landingpage/cnaps-legacy/assessmentIcon.svg";
import statesIcon from "assets/landingpage/cnaps-legacy/statesIcon.svg";
export const StatsData = [
  {
    icon: badgeIcon,
    count: "6+ Years",
    desc: "Conducting Olympiads",
    start: "800",
  },
  {
    icon: buildingIcon,
    count: "2500+ Schools",
    desc: "Registered",
    start: "600000",
  },
  {
    icon: assessmentIcon,
    count: "75+ Lakhs",
    start: "1700000",
    desc: "Assessments taken",
  },
  {
    icon: statesIcon,
    count: "17+ States",
    start: "1700000",
    desc: "Schools Registered",
  },
];
