import styled from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  margin-top: 120px;
  margin-bottom: 120px;
  flex-direction: column;
  align-items: center;
  h1 {
    text-transform: capitalize;
  }
`;

export const StyledText = styled.label`
  margin-top: 13px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
`;

export const IconDiv = styled.div`
  width: 20%;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 56px;
    height: 56px;
  }
`;

export const EmailDiv = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 17px;
  margin-right: 20px;
  a {
    text-decoration: none;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }
  b {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
    cursor: pointer;
  }
`;

export const ContactUsCardWrapper = styled.div`
  min-width: 30%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 29px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 32px 0 rgba(221, 230, 237, 0.4);
  background-color: #ffffff;
  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
`;
