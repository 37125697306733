import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "./constants";
import notify from "utils/notifications";
import StudentListService from "./services";
import * as actions from "./actions";
import { getColumns, getTableDataSource } from "./utils";
import FileSaver from "file-saver";
import Moment from "moment";
import NewRegistrationService from "school-admin/NewRegistration/services";
import { push, replace } from "connected-react-router";
import { getLocalObj, setLocalObj } from "utils/utility/local-storage";

import { initPaymentProcess } from "utils/payment";
import history from "utils/history";
import { getQueryParam } from "utils/utility/url";

const studentListService = new StudentListService();

const studentNewRegistrationServ = new NewRegistrationService();

export function* initIndividualPaymentWorker(action) {
  const { admission_number, cart_exam_list, mobile_number, callback } =
    action.payload;
  try {
    const res = yield call(studentNewRegistrationServ.initIndividualPayment, {
      admission_number,
      cart_exam_list,
      mobile_number,
    });

    if (res.data.status === "success") {
      const tokenData = getLocalObj("token-data");
      const admsnNumber = getQueryParam("admissionNumber", history.location);
      yield put(actions.initIndividualPaymentSuccess(res.data.data));
      // if (!tokenData.is_narayana_school) initPaymentProcess(res.data.data);
      callback(admission_number, mobile_number);
      notify("success", "Mobile Number Updated Successfully");
    } else {
      notify("error", res.data.message);
      yield put(actions.initIndividualPaymentError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.initIndividualPaymentError(e));
  }
}

export function* studentListWorker(action) {
  try {
    const res = yield call(studentListService.studentList, action.payload);
    if (res.data.status === "success") {
      const data = res.data.data;
      const columns = getColumns(data.students_list);
      const dataSource = getTableDataSource(data.students_list);
      yield put(actions.studentListSuccess({ columns, dataSource }));
    } else {
      notify("error", res.data.message);
      yield put(actions.studentListError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.studentListError(e));
  }
}

//Student filters
export function* studentFiltersWorker(action) {
  try {
    const res = yield call(studentListService.studentFilters, action.payload);
    if (res.data.status === "success") {
      const classList = Object.values(res.data.data);
      const data = {
        class_list: classList,
        backend_data: res.data.data,
      };
      yield put(actions.studentFiltersSuccess(data));
    } else {
      notify("error", res.data.message);
      yield put(actions.studentFiltersError(res.data));
    }
  } catch (e) {
    notify("error", "Something went wrong!");
    yield put(actions.studentFiltersError(e));
  }
}

//download excel sheet
export function* downloadExcelSheetWorker(action) {
  try {
    const res = yield call(
      studentListService.downloadExcelSheet,

      {
        "class_id[]": action.payload["class_id[]"],
        "exam_list[]": action.payload["exam_list[]"],
        student_name: action.payload.student_name,
      }
    );
    if (res.status === 200) {
      // const res = yield call(studentListService.downloadExcelSheet, action.payload)
      action.payload.handleSuccessDownloadExcelList(res);
      // const NewFileName = res.headers["content-disposition"];
      // const regex =
      //   /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      // var fileName = NewFileName.match(regex)[3];
      // const timeStamp = parseInt(fileName.split("_")[2].split(".")[0]);
      const formattedDate = Moment().format("DD-MM-YYYY");
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // eslint-disable-next-line no-useless-concat
      FileSaver.saveAs(blob, "studentslist" + "_" + formattedDate + ".xlsx");
    }
  } catch (e) {
    action.payload.handleErrorDownloadExcelList();
    notify("error", "Something went wrong!");
  }
}
//download student results
export function* downloadStudentResultWorker(action) {
  try {
    const res = yield call(studentListService.downloadStudentResult, {
      school_id: action.payload.school_id,
    });
    if (res.status === 200 && res.data.byteLength > 100) {
      action.payload.handleSuccessSchoolResult(res);
      // const NewFileName = res.headers["content-disposition"];
      // const regex =
      //   /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
      // var fileName = NewFileName.match(regex)[3];
      // const timeStamp = parseInt(fileName.split("_")[2].split(".")[0]);
      const formattedDate = Moment().format("DD-MM-YYYY");
      var blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // eslint-disable-next-line no-useless-concat
      FileSaver.saveAs(blob, "studentslist" + "_" + formattedDate + ".xlsx");
    } else {
      action.payload.handleErrorSchoolResult();
      notify("error", "Results are not available.");
    }
  } catch (e) {
    action.payload.handleErrorSchoolResult();
    notify("error", "Something went wrong!");
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeLatest(types.STUDENT_LIST_REQUEST, studentListWorker);
  yield takeLatest(types.STUDENT_FILTERS_REQUEST, studentFiltersWorker);
  yield takeLatest(types.DOWNLOAD_EXCEL, downloadExcelSheetWorker);
  yield takeLatest(types.DOWNLOAD_SCHOOL_RESULT, downloadStudentResultWorker);
  yield takeLatest(
    types.UPDATE_STUDENT_MOBILE_NUMBER,
    initIndividualPaymentWorker
  );
}
