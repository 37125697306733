import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: content;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 32px;
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #242424;
  }

  div {
    margin: 22px 0 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #464646;
    width: 60%;

    @media screen and (max-width: 600px) {
      width: 50%;
    }
  }
`;
