import React from "react";

function ExamPageContent() {
  return (
    <>
      <div style={{ lineHeight: "1.56" }}>
        CNAPS Olympiad Exams culminate in a 360 degree evaluation of the student
        (testing his/her Concepts, Comprehension, Analysis, Application and
        Vision). Through the different varieties of questions, the following are
        derived for each student:
      </div>
      <ul>
        <li>Knowledge Quotient</li>
        <li>Understanding Ability Quotient</li>
        <li>Application Ability Quotient</li>
        <li>Reasoning and Analytical ability Quotient</li>
        <li>Awareness and Vision Quotient</li>
      </ul>
      This is first of its kind in the history of Olympiad Examinations in
      India.
      <br />
      <br />
      <span style={{ marginTop: "15px", lineHeight: "1.56" }}>
        {/* CNAPS’s meticulous research on some of the most famous Olympiads across
        the world helped amalgamate the findings in proposing our prestigious
        international standard Olympiads in the fields of:{" "}
        <b style={{ fontWeight: "600" }}>Mathematics</b>,{" "}
        <b style={{ fontWeight: "600" }}>Science</b>,{" "}
        <b style={{ fontWeight: "600" }}>Astronomy</b>,{" "}
        <b style={{ fontWeight: "600" }}>General Knowledge</b>,{" "}
        <b style={{ fontWeight: "600" }}>Computers</b>,{" "}
        <b style={{ fontWeight: "600" }}>English</b>,{" "}
        <b style={{ fontWeight: "600" }}>Reasoning</b>,{" "}
        <b style={{ fontWeight: "600" }}>Mental Ability</b> etc. */}
        CNAPS’s meticulous research on some of the most famous Olympiads across
        the world helped amalgamate the findings in proposing two prestigious
        international standard Olympiads: ISMO- International Standard
        Mathematical Olympiad for classes 1st to 10th, ISSO-International
        Standard Science Olympiad for classes 3rd to 10th and ISKO -
        International Standard Knowledge Olympiad for classes 1st to 10th.
      </span>
    </>
  );
}

export default ExamPageContent;
