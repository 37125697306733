import React, { useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Error from "share/organisms/error";
import styled from "styled-components";
const Wrapper = styled.div`
  background-color: #ffff;
  width: 60%;
  margin: 70px auto;
  padding: 30px 0;
`;
const ErrorPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { message, statusCode,errors } = location.state || {};

  console.log(message,statusCode)

  useEffect(() => {
    if (message === undefined) {
      history.push({ pathname: "/" });
    }
  }, [message || "Session Expired"],);
  return (
    <Wrapper>
      <Error
        title="Multiple Devices Found"
        desc="Your account has been accessed from a new device. As a result, you have been logged out of this device."
        error ={errors}
      />
    </Wrapper>
  );
};

export default ErrorPage;
