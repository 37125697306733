import React from "react";
import PropTypes from "prop-types";
import List from "share/atoms/ListView";
import { Wrapper } from "./styles";
import { sidebarData } from "./helpers";
import SidebarItems from "share/molecules/sidebar/sidebar-items";

const SideContentLayout = ({ children }) => (
  <Wrapper>
    <section className="left">
      <List items={sidebarData} fullHeightConfig>
        {(item) => <SidebarItems key={item.id} item={item} />}
      </List>
    </section>
    <section className="right">{children}</section>
  </Wrapper>
);

export default SideContentLayout;

SideContentLayout.propTypes = {
  children: PropTypes.node,
};
