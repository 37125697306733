import Queue from "./queue";

class AudioProctor {
  constructor() {
    this.start = this.start.bind(this);
    this.processAudio = this.processAudio.bind(this);
    this.analyse = this.analyse.bind(this);
    this.callback = null;
    this.audioLimit = 0;
    this.mediaStream = null;
    this.preTime = 0;
    this.queue = null;
    this.db = 0;
    this.body = document.body;
    this.count = document.createElement("p");
    this.totalIntervals = 0
  }

  start(audioLimit = 60, audioInterval = 2000, callback) {
    callback({
      type: "audio",
      state: "loading",
      data: "",
    });
    if (!this.audioContext) {
      this.callback = callback;
      this.audioLimit = audioLimit;
      this.audioContext = new AudioContext();
      this.analyzer = this.audioContext.createAnalyser();
      this.analyzer.fftSize = 512;
      this.totalIntervals = audioInterval / 200;
      this.queue = new Queue(this.totalIntervals);

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaStream = stream;
          const source = this.audioContext.createMediaStreamSource(stream);
          source.connect(this.analyzer);
          this.processAudio();
        })
        .catch((error) => {
          callback({
            type:'audio',
            state:'denied',
            data: error.message
          })
        });
    }
  }

  processAudio() {
    const bufferLength = this.analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    this.analyzer.getByteFrequencyData(dataArray);
    const sum = dataArray.reduce((acc, val) => acc + val, 0);
    const averageVolume = sum / bufferLength;
    const currTime = parseInt(this.audioContext.currentTime * 10);
    if (currTime % 2 === 0 && this.preTime !== currTime) {
      this.preTime = currTime;
      this.analyse(averageVolume);
    }
    requestAnimationFrame(this.processAudio);
  }
  analyse(db) {
    this.queue.add(db);
    if (this.queue.size() === this.totalIntervals) {
      const avgDb =
        this.queue.print().reduce((acc, curr) => acc + curr, 0) / this.totalIntervals;
        
      if (true) {
        this.databox(avgDb);
      }
      
      if (avgDb >= this.audioLimit) {
        this.callback({
          type: "audio",
          state: "alert",
          data: avgDb,
        });
      }
    }
  }

  stop() {
    if (this.mediaStream) {
      this.mediaStream
        .getTracks()
        .map((track) => track.kind === "audio" && track.stop());
    }
  }
  databox(avgDb) {
    this.count.style.width = "100px";
    this.count.style.height = "30px";
    this.count.style.borderRadius = "4px";
    this.count.style.backgroundColor = "red";
    this.count.style.fontWeight = "bold";
    this.count.style.fontSize = "14px";
    this.count.style.display = "flex";
    this.count.style.alignItems = "center";
    this.count.style.justifyContent = "center";
    this.count.style.zIndex = "1000";
    this.count.style.position = "absolute";
    this.count.style.top = '0';
    this.count.style.left = 0;
    this.count.innerHTML = `${avgDb.toFixed(2)}/${this.audioLimit}`;
    this.body.appendChild(this.count);
  }
}

export default AudioProctor;
