import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Wrapper, RegisterButton, CloseImage } from "./styles";
import CloseIcon from "assets/strip/close.svg";
import { useResize } from "share/hooks/useResize";

const routeLinks = [
  "/",
  "/about-us",
  "/why-olympiad",
  "/faqs",
  "/privacy-policy",
  "/terms-conditions",
  "/refund-policy",
  "/exams",
  "/exam-details",
];

function NotificationStrip({ handleStrip }) {
  const history = useHistory();
  const location = useLocation();
  const [isShow, setIsShow] = useState(true);
  const handleRegister = () => {
    history.push("/student/registration");
  };
  const handleClose = () => {
    handleStrip("notificationStrip");
    setIsShow(false);
  };
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_REGISTRATION_STRIP === "false") {
      handleClose();
    }
  }, [process]);
  const { width } = useResize();
  const isAVailable = routeLinks.some((item) => item === location.pathname);
  return (
    <>
      {isShow && isAVailable && (
        <Wrapper $isColapsible={width <= 800}>
          <span className="title">
            Registrations are open for CNAPS 2023 - 2024
          </span>
          <RegisterButton onClick={handleRegister}>Register Now</RegisterButton>
          <CloseImage src={CloseIcon} alt="close" onClick={handleClose} />
        </Wrapper>
      )}
    </>
  );
}

export default NotificationStrip;
