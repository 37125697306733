import { getKeys } from "utils/utility/obj-arr";
import { EXAM_TYPES, isMissedScheduledTest } from "utils/utility/test-engine";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";

export const questionTypes = {
  SINGLE_CHOICE: "Single select",
  MULTI_CHOICE: "Multi select",
  NUMERIC: "Numeric",
  GRID: "2 column grid",
};

export const sectionWiseStructure = (sectionsById) => {
  const sectionwiseCount = {};
  getKeys(sectionsById).map((section) => {
    sectionwiseCount[section] = {
      sectionName: sectionsById[section].section_name,
      not_visited: 0,
      not_answered: 0,
      answered: 0,
      marked_for_review: 0,
      answered_and_marked_for_review: 0,
      no_of_questions: 0,
    };
  });

  return sectionwiseCount;
};

export const getSectionWiseData = (sectionsById, answers) => {
  const sectionwiseCount = sectionWiseStructure(sectionsById);
  const ans = getValues(answers);
  for (let i = 0; i < ans.length; i += 1) {
    const id = ans[i].section_id;
    const state = ans[i].status;
    sectionwiseCount[id].no_of_questions += 1;
    sectionwiseCount[id][state] += 1;
  }

  return sectionwiseCount;
};

export const getTotalAnswers = (answers) => {
  const totalAnswers = {
    not_visited: 0,
    not_answered: 0,
    answered: 0,
    marked_for_review: 0,
    answered_and_marked_for_review: 0,
    no_of_questions: 0,
  };
  const ans = getValues(answers);
  for (let i = 0; i < ans.length; i += 1) {
    totalAnswers[ans[i].status] += 1;
    totalAnswers.no_of_questions += 1;
  }
  return totalAnswers;
};

export const getCount = (type, answers) => {
  const res = Object.keys(answers).filter((id) => answers[id].status === type);
  return res.length || 0;
};

export const getValues = (obj) => {
  if (!obj) return [];
  return Object.values(obj);
};

export const getTime = (seconds) => {
  const time = seconds / 1000;
  const min = Math.floor(time / 60);
  const sec = Math.floor(time - min * 60);

  return `${min < 10 ? `0${min}` : min} : ${sec < 10 ? `0${sec}` : sec}`;
};

export const sumSpentTime = (times) => {
  let sum = 0;
  const arr = getValues(times);
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }
  return sum || 0;
};

export const appendSpentTimes = (answers, times) => {
  const ans = {};
  Object.keys(answers).map((key) => {
    const answer =
      answers[key].answer instanceof Array && answers[key].answer.length === 0
        ? ""
        : answers[key].answer;
    ans[key] = { ...answers[key], answer, spent_time: times[key] };
  });

  return ans;
};

function shuffleArray(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const checkParagraphQuestions = (res, examState) => {
  const sections = [];
  let response = {};
  for (let i = 0; i < res.section_list.length; i += 1) {
    let sec = { ...res.section_list[i] };
    let shouldJumble = res.has_jumble && examState === "not_started";
    const sectionQuestions = shouldJumble
      ? shuffleArray(res.section_list[i].question_list)
      : res.section_list[i].question_list;
    //  shuffleArray(sectionQuestions);
    const questions = [];

    for (let k = 0; k < sectionQuestions.length; k += 1) {
      if (sectionQuestions[k].type === "Paragraph") {
        const para = sectionQuestions[k].paragraph;
        for (
          let x = 0;
          x < sectionQuestions[k].sub_question_list.length;
          x += 1
        ) {
          questions.push({
            ...sectionQuestions[k].sub_question_list[x],
            paragraph: para,
            section_id: res.section_list[i].section_id,
            type: sectionQuestions[k].sub_question_list[x].type,
            visibleType: "Paragraph",
          });
        }
      } else {
        questions.push(sectionQuestions[k]);
      }
    }
    sec = { ...sec, question_list: questions };
    sections.push(sec);
  }
  response = { ...res, section_list: sections };
  return response;
};

export const getJumbledResponse = (result, type) => {
  const {
    section_list: sectionList,
    sectionById,
    has_jumble: hasJumble,
  } = result;

  for (let i = 0; i < sectionList.length; i += 1) {
    const secId = sectionList[i];
    sectionById[secId] = {
      ...sectionById[secId],
      question_list:
        isMissedScheduledTest(type) && hasJumble
          ? shuffleArray(sectionById[secId].question_list)
          : sectionById[secId].question_list,
    };
  }

  return { ...result, sectionById, has_jumble: true };
};

export const getAnswersFormat = (arr) => {
  let template = {};
  arr.map((ans) => {
    template = { ...template, [ans.question_no]: ans };
  });

  return template;
};

export const getSpentTimesFormat = (arr) => {
  let template = {};
  arr.map((ans) => {
    template = { ...template, [ans.question_no]: ans.spent_time };
  });

  return template;
};

export const getAnswersTemplate = (result) => {
  const { section_list: sectionList, sectionById, questionById } = result;

  let template = {};
  let index = 0;
  sectionList.map((secId) => {
    sectionById[secId].question_list.map((qId) => {
      template = {
        ...template,
        [index + 1]: {
          question_no: index + 1,
          section_id: secId,
          question_id: questionById[qId].question_id,
          answer: "",
          tempAns: "",
          status: "not_visited",
          spent_time: 0,
          question_type: questionById[qId].type,
          response_id: questionById[qId].response_identifier,
        },
      };
      index += 1;
    });
  });

  return template;
};

export const modData = () => {};

export const setMediaPermision = async (constraints) => {
  try {
    const res = await requestMediaPermissions(constraints);
    return res;
  } catch (error) {
    return error;
  }
};

export const getMediaPermission = async (constraints) => {
  try {
    const res = await requestMediaPermissions(constraints);
    return "granted";
  } catch (error) {
    const { type, name, message } = error;
    if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
      return "no permission";
    } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
      return "denied";
    } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
      return "use other app";
    } else {
      return "something went wrong";
    }
  }
};

export const getProctoringStatus = (exam_category, is_proctoring_enabled) => {
  if (exam_category === "scheduled_test" && is_proctoring_enabled) {
    return true;
  } else {
    return false;
  }
};

export const getBothPermissoinStatus = async () => {
  const videoPerm = await setMediaPermision({ video: true, audio: false });
  const audioPerm = await setMediaPermision({ video: false, audio: true });
  const isVideoEnable = String(videoPerm) === "true" ? true : false;
  const isAudioEnable = String(audioPerm) === "true" ? true : false;
  return { isVideoEnable, isAudioEnable };
};

export const getConfigData = (() => {
  const configdata = JSON.parse(sessionStorage.getItem("proctor-config"));
  const { events } = configdata ? configdata : {};
  return {
    decorator(type) {
      const event = events?.filter((item) => item.name === type);
      const { eventid, ConfigData } = event[0];
      return { eventid, ...ConfigData };
    },
    snap() {
      return this.decorator("snap");
    },
    not_proctored() {
      return this.decorator("not_proctored");
    },
    proctored() {
      return this.decorator("proctored");
    },
    audio_overflow() {
      return this.decorator("audio_overflow");
    },
  };
})();
