import { useState } from "react";

export default function useOnChange(initialValue) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e, exam) => {
    let exams = { ...value };
    setValue({ ...exams, [exam.exam_model_id]: e });
  };

  return { value, onChange, setValue };
}
