import React from "react";
// import { FaChevronDown } from "react-icons/fa";
import {
  Header,
  Wrap,
  Label,
  Value,
  Exams,
  StudentsWrap,
  ExamInfo,
  SelectWrapper,
  Wrapper,
} from "./styles";
import { getLocalObj } from "utils/utility/local-storage";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import smallRupee from "assets/small-rupee.svg";
import bigRupee from "assets/big-rupee.svg";
import CheckMinus from "assets/checkminus.svg";

// const getDropdownData = (data) => {
//   if (!data) return [];
//   const getLabel = (phase) => {
//     if (phase.is_registration_ended)
//       return (
//         <span>
//           {phase.name}
//           <Error>(Completed)</Error>
//         </span>
//       );
//     else return `${phase.name}(${phase.date})`;
//   };
//   return data.map((phase) => ({
//     label: getLabel(phase),
//     value: phase.id,
//     isDisabled: phase.is_registration_ended || false,
//   }));
// };

function StudentExamCard(props) {
  const { data, onCheckboxChange } = props;
  const tokenData = getLocalObj("token-data");

  return (
    <Wrap
      key={data.exam_model_id}
      isSelected={props.isSelected}
      bgColor={props.isSelected ? "#ffebf3" : "#ffffff"}
    >
      <Header>
        <img className="logo" src={data.exam_image_logo} alt="" />
      </Header>
      {/* <Info>
        <h3 className="title">{data.exam_model_name}</h3>
        <div className="exam-details">
          <div>
            <Label>Class:</Label>
            <Value>1st -10th</Value>
          </div>
          <div>
            <Label>Time:</Label>
            <Value>{data.duration}</Value>
          </div>
        </div>
        {tokenData && tokenData.is_independent_student ? (
          <>
            <div className="select">
              <Label>Select Date:</Label>
              <StyledSelect
                data={getDropdownData(data.phase_list)}
                value={props.phase}
                placeholder="Select Date"
                suffixIcon={<FaChevronDown />}
                onChange={(e) => onPhaseChange(e, data)}
                data-testid="exam-select-date"
              />
            </div>
            {data.exam_model_name === "International Standard Mathematical Olympiad" && <Message>
              *Phase 2 Exam for class 10 students is on  <span>25th Jan 2022</span>.
            </Message>}
          </>
        ) : (
          <p className="note">
            Note : If you also want to participate in this exam along with other
            students, please contact your Principal/Admin.
          </p>
        )}
      </Info> */}
      <ExamInfo>
        <h3 className="title">{data.exam_model_name}</h3>
        <div className="wrapper">
          <div className="info">
            <p className="heading">Exam Date</p>
            <p className="para">{data.exam_model_date}</p>
          </div>
          <div className="info">
            <p className="heading">Questions</p>
            <p className="para">{data.total_question_count}</p>
          </div>
          <div className="info">
            <p className="heading">Marks</p>
            <p className="para">{data.max_marks}</p>
          </div>
          <div className="info">
            <p className="heading">Time</p>
            <p className="para">{data.duration} Mins</p>
          </div>
        </div>
        {data.is_registration_ended && (
          <p className="registration-closed">*Registrations Closed</p>
        )}
        {data.is_next_level_available && (
          <p className="level2">
            *Info : Level 2 exam is conducted only for the toppers of level 1
            exam.{" "}
          </p>
        )}
      </ExamInfo>
      <Exams
        isDisable={
          data.is_registration_ended || !tokenData.is_independent_student
        }
      >
        <div className="checkbox-wrap">
          <span
            className="checkbox-span"
            data-testid="exam-selection"
            onClick={() => {
              if (
                data.is_registration_ended ||
                !tokenData.is_independent_student
              ) {
                return "";
              } else {
                onCheckboxChange(
                  { target: { checked: !props.isSelected } },
                  data
                );
              }
            }}
          >
            {data.is_registration_ended || !tokenData.is_independent_student ? (
              <Wrapper>
                <div className="box">
                  {" "}
                  <img src={CheckMinus} alt="minusicon" />
                </div>
                <SelectWrapper
                  isDisable={
                    data.is_registration_ended || tokenData.is_narayana_student
                  }
                >
                  Select
                </SelectWrapper>
              </Wrapper>
            ) : props.isSelected ? (
              <Wrapper>
                <FaCheckSquare />
                <SelectWrapper
                  isDisable={
                    data.is_registration_ended || tokenData.is_narayana_student
                  }
                >
                  Select
                </SelectWrapper>
              </Wrapper>
            ) : (
              <Wrapper>
                <FaRegSquare />
                <SelectWrapper
                  isDisable={
                    data.is_registration_ended || tokenData.is_narayana_student
                  }
                >
                  Select
                </SelectWrapper>
              </Wrapper>
            )}
          </span>
        </div>
        <p className="fee">Exam Fee</p>
        <div>
          <h3 className="cost">
            {data.is_discount_available && (
              <span className="rupee">
                {" "}
                <img src={smallRupee} alt="small-rupee" />{" "}
                <span className="base-price">{data.base_price}</span>
              </span>
            )}{" "}
            {!data.is_discount_available && (
              <img src={bigRupee} alt="big-rupee" />
            )}
            {data.fee}/-
          </h3>
        </div>
        <div className="reg-students">
          <Label>Students Registered:</Label>
          <Value className="value">{data.students_registered}</Value>
        </div>
      </Exams>
    </Wrap>
  );
}

export default StudentExamCard;
