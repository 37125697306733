import styled from 'styled-components';

export const StyledLabel = styled.label`
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.57)};
  font-size: ${({ theme }) => theme.palette.typography.caption.fontSize};
  color: ${({ theme }) => theme.palette.text.secondary};
  display: block;
  margin-top: 12px;
  margin-bottom: 8px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
`;
