import React from 'react'
import PropTypes from 'prop-types'

export default function FieldWrap({children, className}){
    return (
        <div className={className}>
            {children}
        </div>
    )
}

FieldWrap.propTypes={
    children:PropTypes.node.isRequired
}